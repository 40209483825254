import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    IAppointmentsCount,
    ICommonDataDoctor,
    ICommonDataEmailTemplate,
    ICommonDataLetterTemplate,
    ICommonDataManager,
    ICommonDataService,
    ICommonDataServiceAccount,
    ICommonDataUser,
    ICommonDataUserGroup,
    ICommonDataWebsite,
    IInsurance,
    IPrescriptionsNotificationsData,
    IState,
    ITaskAttributeCommonData,
} from '../../med-api/interfaces';
import { ApiCommonDataService } from '../../med-api/services';
import { INotificationsSubscriptionParams } from '../../med-notifications/interfaces';
import { NotificationsService } from '../../med-notifications/services';
import { CommonDataFilterType } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class CommonDataService {
    public states: IState[];
    public services: ICommonDataService[];
    public websites: ICommonDataWebsite[];
    public doctors: ICommonDataDoctor[];
    public managers: ICommonDataManager[];
    public admins: ICommonDataUser[];
    public serviceAccounts: ICommonDataServiceAccount[];
    public letterTemplates: ICommonDataLetterTemplate[];
    public emailTemplates: ICommonDataEmailTemplate[];
    public insuranceCompanies: IInsurance[];
    public fupEnabled: boolean;
    public userGroups: ICommonDataUserGroup[];
    public taskAttributes: ITaskAttributeCommonData[];

    public appointmentsCount$ = new BehaviorSubject<IAppointmentsCount>({
        today: 0,
        letters: 0,
        pending: 0,
    });

    public prescriptionNotificationsData: IPrescriptionsNotificationsData;
    public prescriptionNotificationsData$ = new Subject<IPrescriptionsNotificationsData>();

    private filters: CommonDataFilterType[];

    public get appointmentsCount(): IAppointmentsCount {
        return this.appointmentsCount$.getValue();
    }

    constructor(
        private apiCommonDataService: ApiCommonDataService,
        private notificationsService: NotificationsService
    ) {}

    public setCommonDataFilters(filters: CommonDataFilterType[]): void {
        this.filters = filters;
    }

    public async loadData(): Promise<void> {
        const res = await this.apiCommonDataService.load({
            filters: this.filters,
        });
        this.states = res.states;
        this.services = res.services;
        this.websites = res.websites;
        this.doctors = res.doctors;
        this.managers = res.managers;
        this.admins = res.admins;
        this.serviceAccounts = res.serviceAccounts;
        this.letterTemplates = res.letterTemplates;
        this.emailTemplates = res.emailTemplates;
        this.insuranceCompanies = res.insuranceCompanies;
        this.fupEnabled = res.fupEnabled;
        this.userGroups = res.userGroups;
        this.taskAttributes = res.taskAttributes;

        this.appointmentsCount$.next(res.appointmentsCount);

        this.prescriptionNotificationsData = res.prescriptionNotificationsData;
    }

    public getTaskAttributesCodes(): string[] {
        return this.taskAttributes.map((elem) => elem.code);
    }

    public getTaskAttributesTaskNames(): string[] {
        return [...new Set(this.taskAttributes.map((elem) => elem.taskName))];
    }

    public subscribeOnAppointmentsCountUpdates(params: INotificationsSubscriptionParams): void {
        this.notificationsService.subscribeOnAppointmentsCountUpdates(
            params,
            this.handleAppointmentsCountUpdate.bind(this)
        );
    }

    private handleAppointmentsCountUpdate(data: IAppointmentsCount) {
        this.appointmentsCount$.next(data);
    }

    public subscribeOnPrescriptionNotificationsDataUpdate(
        params: INotificationsSubscriptionParams
    ): void {
        this.notificationsService.subscribeOnPrescriptionNotificationsDataUpdate(
            params,
            this.handlePrescriptionNotificationsDataUpdate.bind(this)
        );
    }

    private handlePrescriptionNotificationsDataUpdate(data: IPrescriptionsNotificationsData) {
        this.prescriptionNotificationsData = data;
        this.prescriptionNotificationsData$.next(this.prescriptionNotificationsData);
    }
}
