import { Injectable } from '@angular/core';

import { SocketService } from '../../med-common/services/socket.service';
import { ManagerEventType, ManagerCallState, ManagerNotificationMessageTypes } from '../interfaces';

export interface IManagerNotificationsSubscriptionParams {
    managerId: number;
}

@Injectable({
    providedIn: 'root',
})
export class ManagerNotificationService {
    inManagerRoom = false;
    constructor(private socket: SocketService) {}

    private joinManagerRoom(params: IManagerNotificationsSubscriptionParams) {
        if (!this.inManagerRoom) {
            this.joinRoom(params);
        }
    }

    private leaveManagerRoom(params: IManagerNotificationsSubscriptionParams) {
        if (this.inManagerRoom) {
            this.leaveRoom(params);
        }
    }

    private joinRoom(params: IManagerNotificationsSubscriptionParams): void {
        const roomName = this.getRoomName(params.managerId);
        this.socket.joinRoom({ roomName });
        this.inManagerRoom = true;
    }

    private leaveRoom(params: IManagerNotificationsSubscriptionParams): void {
        const roomName = this.getRoomName(params.managerId);
        this.socket.leaveRoom(roomName);
        this.inManagerRoom = false;
    }

    private getRoomName(managerId: number) {
        return `Manager-${managerId}`;
    }

    public setCallState(state: ManagerCallState): void {
        this.socket.emit(ManagerEventType.SET_MANAGER_CALL_STATE, state);
    }

    public subscribeOnTwilioOnHoldCallMissed(
        params: IManagerNotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.joinManagerRoom(params);
        this.socket.subscribe(ManagerNotificationMessageTypes.TWILIO_ON_HOLD_CALL_MISSED, cb);
    }

    public unsubscribeOnTwilioOnHoldCallMissed(
        params: IManagerNotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.socket.unsubscribe(ManagerNotificationMessageTypes.TWILIO_ON_HOLD_CALL_MISSED, cb);
        this.leaveManagerRoom(params);
    }
}
