import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { getSelectOptions } from '@shared/utils';
import { finalize, tap } from 'rxjs/operators';
import { IGroups, IGroups as IGroupsSelect, IOption } from '../../../elements/select/select.component';
import { CallResult, RequestReasonService } from './request-reason.service';
import { Reasons, RequestReasons, SubRequestReasons } from './request-reasons.settings';

interface IDialogData {
    callSid: string;
    phone: string;
}

@Component({
    selector: 'app-request-reason-dialog',
    templateUrl: './request-reason-dialog.component.html',
    styleUrls: ['./request-reason-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestReasonDialogComponent implements OnInit {
    public requestReasons: IGroupsSelect[] = [];
    public subRequestReasons: IGroupsSelect[] = [];
    public reasons: IGroupsSelect[] = [];
    public formGroup: FormGroup;
    public loaded: boolean = true;

    constructor(
        private fb: FormBuilder,
        private requestReasonService: RequestReasonService,
        private dialogRef: MatDialogRef<IDialogData>,
        private changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) private data: IDialogData
    ) {}

    public ngOnInit(): void {
        this.reasons = getSelectOptions(Reasons) as IGroups[];
        this.requestReasons = getSelectOptions(Object.values(RequestReasons)) as IGroups[];
        this.initFormGroup();
    }

    public getFormControl(name: string): FormControl {
        return this.formGroup.controls[name] as FormControl;
    }

    public setValue(field: string, value: IOption): void {
        this.formGroup.controls[field].setValue(value.value);
        if (field === 'request') {
            this.setSubRequestsReasons(value);
        }
    }

    public submit(): void {
        if (this.formGroup.invalid) return;

        const result: CallResult = {
            ...this.formGroup.value,
            callSid: this.data.callSid,
            phone: this.data.phone,
        };

        this.loaded = false;

        this.requestReasonService
            .sendRequestReason(result)
            .pipe(
                finalize(() => {
                    this.loaded = true;
                    this.changeDetector.markForCheck();
                }),
                tap(() => this.dialogRef.close())
            )
            .subscribe();
    }

    private setSubRequestsReasons(value: IOption): void {
        this.subRequestReasons = getSelectOptions(SubRequestReasons[value.value]) as IGroups[];
        this.formGroup.controls.subRequest.setValue(null);
    }

    private initFormGroup(): void {
        this.formGroup = this.fb.group({
            request: [null, [Validators.required]],
            subRequest: [null, [Validators.required]],
            result: [null, [Validators.required]],
        });
    }
}
