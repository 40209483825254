import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormMultiInputAutoField } from '../../../classes';
import { FormGroup } from '@angular/forms';
import { ErrorTextService } from '../../../services/error-text.service';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';

@Component({
    selector: 'med-form-multi-input-auto-field',
    templateUrl: './multi-input-auto.component.html',
    styleUrls: ['./multi-input-auto.component.css'],
})
export class FormMultiInputAutoFieldComponent implements OnInit {
    @Input() field: FormMultiInputAutoField;
    @Input() formGroup: FormGroup;

    @ViewChild('stringsInput') stringsInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    selectable = true;
    removable = true;
    addOnBlur = false;
    filteredStrings: Observable<string[]>;

    readonly separatorKeysCodes: number[] = [ENTER];

    constructor(public errorTextService: ErrorTextService) {}

    ngOnInit(): void {
        this.filteredStrings = this.formGroup.get(this.field.controlName).valueChanges.pipe(
            startWith(null),
            map((str: string | null) => (str ? this._filter(str) : this.field.allStrings.slice()))
        );
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value.trim();

        if (this.field.allStrings.includes(value) && !this.field.strings.includes(value)) {
            if (value || '') {
                this.field.strings.push(value);
            }

            if (input) {
                input.value = '';
            }

            this.formGroup.get(this.field.controlName).setValue(null);
        }
    }

    remove(inputString: string): void {
        const index = this.field.strings.indexOf(inputString);

        if (index >= 0) {
            this.field.strings.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (!this.field.strings.includes(event.option.viewValue)) {
            this.field.strings.push(event.option.viewValue);
            this.stringsInput.nativeElement.value = '';
            this.formGroup.get(this.field.controlName).setValue(null);
        }
    }

    addAllString(): void {
        this.field.strings = this.field.allStrings;
    }

    removeAllString(): void {
        this.field.strings = [];
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.field.allStrings.filter(
            (fruit) => fruit.toLowerCase().indexOf(filterValue) === 0
        );
    }
}
