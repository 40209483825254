import { Injectable } from '@angular/core';
import { ApiTwilioVoiceLogsEnum, PhoneClientLogs } from './twilio-voice.interfaces';
import { AuthService, CallEventService, UserEventService } from 'medvidi';

@Injectable({ providedIn: 'root' })
export class TwilioVoiceLoggingService {
    public phoneClientLogMessages: { date: Date; message: string }[] = [];

    public get reversedPhoneClientLogMessages(): { date: Date; message: string }[] {
        return [...this.phoneClientLogMessages].reverse();
    }

    constructor(
        private userEventService: UserEventService,
        private authService: AuthService,
        private callEventService: CallEventService
    ) {}

    // UI LOG
    public storeUIEvent({
        type,
        extraLog,
        userMessage,
    }: {
        type: PhoneClientLogs;
        extraLog?: any;
        userMessage?: string;
    }): void {
        const message = userMessage ? type + ' ' + userMessage : type;
        this.phoneClientLogMessages.push({ date: new Date(), message });

        const indexOfLog = this.phoneClientLogMessages.length;
        console.log(`%c ${indexOfLog} [PHONE CLIENT] ${message}`, 'color: green');

        if (extraLog) {
            console.log('Extra info: ', extraLog);
        }
    }

    // UI LOG
    public storeUIErrorEvent({
        type,
        error,
        userMessage,
    }: {
        type: PhoneClientLogs;
        error?: any;
        userMessage?: string;
    }): void {
        const message = userMessage ? type + ' ' + userMessage : type;
        this.phoneClientLogMessages.push({ date: new Date(), message });

        const indexOfLog = this.phoneClientLogMessages.length;
        console.log(`%c ${indexOfLog} [PHONE CLIENT] ${message}`, 'color: red');

        if (error) {
            console.error('Error info: ', error);
        }
    }

    // API LOG
    public sendCallLogToApi({
        id,
        reservation,
        from,
    }: {
        id: ApiTwilioVoiceLogsEnum;
        reservation: any;
        from?: string;
    }) {
        const commonParams = {
            callSid: reservation?.task?.attributes?.call_sid,
            workerSid: this.authService.user.phoneClientIdentity,
            taskSid: reservation?.task?.sid,
        };

        if (id === ApiTwilioVoiceLogsEnum.CALL_DELIVERED) {
            void this.callEventService.createDeliveredCallLog(commonParams);
        }

        if (id === ApiTwilioVoiceLogsEnum.CALL_EXPIRED) {
            void this.callEventService.createExpiredCallLog(commonParams);
        }

        if (id === ApiTwilioVoiceLogsEnum.CALL_ACCEPTED) {
            void this.callEventService.createAcceptedCallLog(commonParams);
        }

        if (id === ApiTwilioVoiceLogsEnum.CALL_REJECTED) {
            void this.callEventService.createRejectedCallLog({
                ...commonParams,
                taskAttributes: reservation?.task?.attributes,
            });
        }

        if (id === ApiTwilioVoiceLogsEnum.CALL_ON_HOLD) {
            void this.callEventService.createCallOnHoldLog({
                ...commonParams,
                email: this.authService.user.email,
                userId: this.authService.user.id,
                from: reservation?.task?.attributes?.from || from,
            });
        }
    }

    // API LOG
    public sendActivityLogToApi({
        id,
    }: {
        id: ApiTwilioVoiceLogsEnum.DEVICE_READY | ApiTwilioVoiceLogsEnum.DEVICE_DESTROYED;
    }): void {
        if (id === ApiTwilioVoiceLogsEnum.DEVICE_READY) {
            void this.userEventService.createActivatePhoneClientEvent();
        }

        if (id === ApiTwilioVoiceLogsEnum.DEVICE_DESTROYED) {
            void this.userEventService.createDeactivatePhoneClientEvent();
        }
    }

    // API LOG
    public sendConnectionLogToApi({
        type,
        from,
        to,
        incoming,
        taskSid,
        callSid,
    }: {
        type: ApiTwilioVoiceLogsEnum.CONNECTION_OPENED | ApiTwilioVoiceLogsEnum.CONNECTION_CLOSED;
        callSid: string;
        taskSid: string;
        from: string;
        to: string;
        incoming: boolean;
    }): void {
        const body = {
            email: this.authService.user.email,
            workerSid: this.authService.user.phoneClientIdentity,
            userId: this.authService.user.id,
            callSid,
            taskSid,
            from,
            to,
            incoming,
        };

        switch (type) {
            case ApiTwilioVoiceLogsEnum.CONNECTION_OPENED:
                void this.callEventService.createConnectionOpenedCallLog(body);
                break;
            case ApiTwilioVoiceLogsEnum.CONNECTION_CLOSED:
                void this.callEventService.createConnectionClosedCallLog(body);
                break;
        }
    }

    // API LOG
    public sendDeviceLogToApi({
        id,
        callSid,
        taskSid,
        error,
    }: {
        id: ApiTwilioVoiceLogsEnum.DEVICE_CANCEL | ApiTwilioVoiceLogsEnum.DEVICE_ERROR;
        callSid: string;
        taskSid: string;
        error?: any;
    }): void {
        const commonCallInfo = {
            callSid: callSid,
            taskSid: taskSid,
            email: this.authService.user.email,
            workerSid: this.authService.user.phoneClientIdentity,
            userId: this.authService.user.id,
        };

        if (id === ApiTwilioVoiceLogsEnum.DEVICE_CANCEL) {
            void this.callEventService.createDeviceCancelLog(commonCallInfo);
        }

        if (id === ApiTwilioVoiceLogsEnum.DEVICE_ERROR) {
            void this.callEventService.createDeviceErrorCallLog({
                ...commonCallInfo,
                message: error?.message,
                code: error?.code,
                twilioError: error?.twilioError,
            });
        }
    }
}
