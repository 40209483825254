import { Injectable } from '@angular/core';
import { SocketService } from '../../med-common/services/socket.service';
import { NotificationMessageTypes, INotificationsSubscriptionParams } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    inDoctorRoom = false;
    constructor(private socket: SocketService) {}

    joinRoom(params: INotificationsSubscriptionParams): void {
        const doctorId = params.doctorId;
        const roomName = `Doctor-${doctorId}`;
        this.socket.joinRoom({ roomName, doctorId });
        this.inDoctorRoom = true;
    }

    leaveRoom(params: INotificationsSubscriptionParams): void {
        const doctorId = params.doctorId;
        const roomName = `Doctor-${doctorId}`;
        this.socket.leaveRoom(roomName);
        this.inDoctorRoom = false;
    }

    joinDoctorRoom(params: INotificationsSubscriptionParams) {
        if (!this.inDoctorRoom) {
            this.joinRoom(params);
        }
    }

    subscribeOnIncomingMessages(params: INotificationsSubscriptionParams, cb: () => void): void {
        this.joinDoctorRoom(params);
        this.socket.subscribe(NotificationMessageTypes.NEW_MESSAGE, cb);
    }

    unsubscribeFromIncomingMessages(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.socket.unsubscribe(NotificationMessageTypes.NEW_MESSAGE, cb);
    }

    subscribeOnUpcomingAppointments(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.joinDoctorRoom(params);
        this.socket.subscribe(NotificationMessageTypes.UPCOMING_APPOINTMENT, cb);
    }

    unsubscribeFromUpcomingAppointments(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.socket.unsubscribe(NotificationMessageTypes.UPCOMING_APPOINTMENT, cb);
    }

    subscribeOnStartedAppointments(params: INotificationsSubscriptionParams, cb: () => void): void {
        this.joinDoctorRoom(params);
        this.socket.subscribe(NotificationMessageTypes.STARTED_APPOINTMENT, cb);
    }

    unsubscribeFromStartedAppointments(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.socket.unsubscribe(NotificationMessageTypes.STARTED_APPOINTMENT, cb);
    }

    subscribeOnAppointmentsCountUpdates(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.joinDoctorRoom(params);
        this.socket.subscribe(NotificationMessageTypes.APPOINTMENTS_COUNT_UPDATE, cb);
    }

    subscribeOnPrescriptionNotificationsDataUpdate(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.joinDoctorRoom(params);
        this.socket.subscribe(NotificationMessageTypes.PRESCRIPTIONS_NOTIFICATIONS_DATA_UPDATE, cb);
    }

    subscribeOnDoctorAssistantStatusUpdates(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.joinDoctorRoom(params);
        this.socket.subscribe(NotificationMessageTypes.DOCTOR_ASSISTANT_STATUS_UPDATE, cb);
    }

    unsubscribeFromDoctorAssistantStatusUpdates(
        params: INotificationsSubscriptionParams,
        cb: () => void
    ): void {
        this.socket.unsubscribe(NotificationMessageTypes.DOCTOR_ASSISTANT_STATUS_UPDATE, cb);
    }
}
