<div class="doctor-item-title" [attr.data-test-id]="'doctor-name-' + doctor?.id">
    {{ doctor.user.fullName }}
    <mat-icon
        *ngIf="isPersonalAssistanceDoctor"
        class="doctor-item__preferences"
        matTooltipClass="tooltip-custom-font-md"
        matTooltipPosition="above"
        [matTooltip]="'Assigned physician'"
    >
        supervisor_account
    </mat-icon>
    <mat-icon
        *ngIf="doctor?.schedulePreferences"
        class="doctor-item__preferences"
        matTooltipClass="tooltip-custom-font-md"
        matTooltipPosition="right"
        [matTooltip]="doctor?.schedulePreferences"
    >
        event_note
    </mat-icon>
</div>
<app-time-slots
    class="appointment-section-block__time"
    [loaded]="!!schedule"
    [schedule]="schedule"
    [timezone]="momentTimezone"
    [selectedTime]="shownTime"
    [doctorId]="doctor.id"
    (selectTime)="handleTimeChange($event)"
></app-time-slots>
