export enum DoctorEventType {
    ERROR = 'error',
    LOGIN = 'login',
    LOGOUT = 'logout',
    PAGE_LOADED = 'page_loaded',
    BUTTON_TAPPED = 'button_tapped',
    POPUP_LOADED = 'popup_loaded',
    POPUP_CLOSED = 'popup_closed',
    ERX_TASK_COMPLETED = 'erx_task_completed',
    PRESCRIPTION_SENT = 'prescription_sent',
    CHECKBOX_CHECKED = 'checkbox_checked',
    CHECKBOX_UNCHECKED = 'checkbox_unchecked',
    PRESCRIPTION_DELETED = 'prescription_deleted',
    DOSESPOT_CREATION = 'dosespot_creation_request',
    SEARCH_RESULT = 'search_result',
    SELECT_DESESPOT_PATIENT = 'select_patient_dosespot_profile',
}
