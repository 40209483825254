import { Component, Inject } from '@angular/core';
import { KeyValue } from '@angular/common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IFormattedData, IFormattedDataField } from '../../../med-api/interfaces';
import { IIntakeDialogData, IntakeFieldValue, IntakeStepCode } from '../../interfaces';

@Component({
    selector: 'med-intake',
    templateUrl: './intake.component.html',
    styleUrls: ['./intake.component.css'],
})
export class IntakeComponent {
    constructor(
        private dialogRef: MatDialogRef<IntakeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IIntakeDialogData
    ) {}

    // New Intake
    public IntakeFieldValue = IntakeFieldValue;

    public get intakeSteps(): IFormattedData[] {
        return this.data?.applicationData;
    }

    public getFieldTypeByValue(value: string): IntakeFieldValue {
        const FILE_LINK = /\.(pdf|doc|docx)$/;
        const FILE_IMAGE = /\.(png|jpe?g|bmp|webp)$/;

        if (FILE_LINK.test(value)) return IntakeFieldValue.LINK;
        if (FILE_IMAGE.test(value)) return IntakeFieldValue.IMAGE;

        return IntakeFieldValue.TEXT;
    }

    public getTextValue({ label, value }: IFormattedDataField): string {
        if (!value) return '-';
        if (Array.isArray(value)) return value.map(elem => `• ${elem}`).join('\n');
        if (this.isPhoneNumber(value, label)) return `+1${value}`;

        return value;
    }

    public getFilePath(value: string | null): string {
        if (!value) return '';

        return new URL(value, this.data?.apiUrl).href;
    }

    public openMedicalRecord(file: string): void {
        window.open(this.getFilePath(file), '_blank', '');
    }

    public isShowFieldLabelByStepCode(stepCode: IntakeStepCode): boolean {
        const { ID_UPLOAD, MEDICAL_RECORDS, ACKNOWLEDGMENT, SUBSTANCES } = IntakeStepCode;

        return ![ID_UPLOAD, MEDICAL_RECORDS, ACKNOWLEDGMENT, SUBSTANCES].includes(stepCode);
    }

    public isPhoneNumber(value: string, label: string): boolean {
        if (!label || !label.includes('phone') || value.includes('+1')) return false;

        return !!Number(value);
    }

    public isMedicalRecordStep(stepCode: IntakeStepCode): boolean {
        return stepCode === IntakeStepCode.MEDICAL_RECORDS;
    }

    public closeDialog() {
        this.dialogRef.close();
    }

    // Old Intake (Needed to remove after unsupported web form)
    public hiddenImgs: string[] = [];

    public isEmptyIntake(): boolean {
        return Object.keys(this.data?.applicationData || {}).length === 0;
    }

    public getFullLink(path: string): string {
        if (!path) {
            return null;
        }

        return new URL(path, this.data.apiUrl).href;
    }

    public onErrorImgLoad(imageId: string): void {
        this.hiddenImgs.push(imageId);
    }

    public isHiddenImg(imageId: string): boolean {
        return this.hiddenImgs.includes(imageId);
    }

    public isFile(value: string = ''): boolean {
        const PATH_PATTERN_OLD = /^(\/)?files\/uploads\/.+\.\w+$/;
        const PATH_PATTERN = /^(\/)?files\/s3\/.+\.\w+$/;

        return PATH_PATTERN_OLD.test(value) || PATH_PATTERN.test(value);
    }

    public isPdfFile(value: string = ''): boolean {
        const PDF_PATH_PATTERN_OLD = /^(\/)?files\/uploads\/.+\.pdf$/;
        const PDF_PATH_PATTERN = /^(\/)?files\/s3\/.+\.pdf$/;

        return PDF_PATH_PATTERN_OLD.test(value) || PDF_PATH_PATTERN.test(value);
    }

    public isImageFile(value: string = ''): boolean {
        const IMAGE_PATH_PATTERN_OLD = /^(\/)?files\/uploads\/.+\.(jpe?g|png|gif|bmp|webp)$/;
        const IMAGE_PATH_PATTERN = /^(\/)?files\/s3\/.+\.(jpe?g|png|gif|bmp|webp)$/;

        return IMAGE_PATH_PATTERN_OLD.test(value) || IMAGE_PATH_PATTERN.test(value);
    }

    public useOriginalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
        return 0;
    }

    public order(a, b): number {
        return a.value.index - b.value.index;
    }

    public getValue(value: string | string[]): string[] {
        if (Array.isArray(value)) {
            return value;
        } else {
            return [value];
        }
    }
}
