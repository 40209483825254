import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    TaskCloseParams,
    TaskCreateParams,
    TaskResponse,
    TaskListParams,
    TaskListResponse,
    MyTasksResponse,
    IPostponeTaskParams,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiTaskService {
    constructor(private http: HttpService) {}

    async list(params: TaskListParams): Promise<TaskListResponse> {
        return this.http.post<TaskListResponse>(`task/list`, params);
    }

    async getMyTasks(): Promise<MyTasksResponse> {
        return this.http.get<MyTasksResponse>(`task/my`);
    }

    async create(params: TaskCreateParams): Promise<TaskResponse> {
        return this.http.post<TaskResponse>(`task/create`, params);
    }

    async close(id: number, params: TaskCloseParams): Promise<{ closed: boolean }> {
        return this.http.post<{ closed: boolean }>(`task/close/${id}`, params);
    }

    async assignToMe(id: number): Promise<TaskResponse> {
        return this.http.post<TaskResponse>(`task/assign-to-me/${id}`, {});
    }

    async inProgress(id: number): Promise<TaskResponse> {
        return this.http.get<TaskResponse>(`task/in-progress/${id}`);
    }

    async addToBlacklist(id: number): Promise<{ added: boolean }> {
        return this.http.post<{ added: boolean }>(`task/black-list/${id}`, {});
    }

    async postpone(id: number, params: IPostponeTaskParams): Promise<{ postponed: boolean }> {
        return this.http.post<{ postponed: boolean }>(`task/postpone/${id}`, params);
    }

    async getTaskById(id: number): Promise<TaskResponse> {
        return this.http.post<TaskResponse>(`task/get/${id}`, null);
    }

    async changePriorityById(id: number, priority: number): Promise<TaskResponse> {
        return this.http.post<TaskResponse>(`task/change-priority/${id}`, { priority });
    }
}
