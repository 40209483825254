import { TimingItem } from './timing-item';
import { TimingRange } from '../interfaces';

const timeOfEndDay = {
    hours: 24,
    minutes: 0,
};

export class Timing {
    private currentDate: Date;
    private items: TimingItem[];
    private singleSelect: boolean;

    constructor() {
        this.currentDate = new Date();
        this.currentDate.setMinutes(0);
        this.currentDate.setSeconds(0);
        this.currentDate.setMilliseconds(0);
        this.items = [];
        this.singleSelect = false;
    }

    public setItem(item: TimingItem): void {
        this.items.push(item);
    }

    public getItem(id: number): TimingItem {
        return this.items[id];
    }

    public getItems(): TimingItem[] {
        return this.items;
    }

    public setSingleSelectMode(): void {
        this.singleSelect = true;
    }

    public generateItems(
        startHours: number,
        endHours: number,
        span: number,
        fullFormat: boolean,
        timeEnabled?: TimingRange[],
        timeBusy?: TimingRange[],
        timeHalfBusy?: TimingRange[]
    ): void {
        const MINUTE = 1000 * 60;
        const startTime = new Date(this.currentDate);
        startTime.setHours(startHours);
        const endTime = new Date(this.currentDate);
        endTime.setHours(endHours);
        let activeDate: Date = startTime;
        this.items = [];

        while (activeDate < endTime) {
            const nextDate = new Date(+activeDate + MINUTE * span);
            const id = this.getNewIdForTiming();

            let disabled = false;
            let busy = false;
            let halfBusy = false;
            if (timeEnabled) {
                disabled = !timeEnabled.some((range) => {
                    return (
                        range.startHours === activeDate.getHours() &&
                        range.startMinutes === activeDate.getMinutes() &&
                        range.endHours === nextDate.getHours() &&
                        range.endMinutes === nextDate.getMinutes()
                    );
                });
            }
            if (timeBusy) {
                busy = timeBusy.some((range) => {
                    return (
                        range.startHours === activeDate.getHours() &&
                        range.startMinutes === activeDate.getMinutes() &&
                        range.endHours === nextDate.getHours() &&
                        range.endMinutes === nextDate.getMinutes()
                    );
                });
                if (busy) {
                    disabled = false;
                }
            }

            if (timeHalfBusy) {
                halfBusy = timeHalfBusy.some((range) => {
                    return (
                        range.startHours === activeDate.getHours() &&
                        range.startMinutes === activeDate.getMinutes() &&
                        range.endHours === nextDate.getHours() &&
                        range.endMinutes === nextDate.getMinutes()
                    );
                });

                if (halfBusy) {
                    disabled = false;
                    busy = false;
                }
            }

            let nextDateHours = nextDate.getHours();
            let nextDateMinutes = nextDate.getMinutes();
            if (nextDate.getHours() < activeDate.getHours()) {
                nextDateHours = timeOfEndDay.hours;
                nextDateMinutes = timeOfEndDay.minutes;
            }

            const item = new TimingItem(
                id,
                activeDate.getHours(),
                activeDate.getMinutes(),
                nextDateHours,
                nextDateMinutes,
                false,
                activeDate,
                fullFormat,
                disabled,
                busy,
                halfBusy
            );

            this.setItem(item);
            activeDate = nextDate;
        }
    }

    public getNewIdForTiming(): number {
        return this.items.length;
    }

    public refreshFilling(items: TimingRange[]): void {
        const filling = items.map((item) => {
            if (item.startHours === 23 && item.startMinutes === 30) {
                item.endHours = timeOfEndDay.hours;
                item.endMinutes = timeOfEndDay.minutes;
            }
            const begin = new Date(this.currentDate);
            begin.setHours(item.startHours);
            begin.setMinutes(item.startMinutes);
            const end = new Date(this.currentDate);
            end.setHours(item.endHours);
            end.setMinutes(item.endMinutes);

            return {
                begin,
                end,
            };
        });

        for (let i = 0; i < this.items.length; i++) {
            let isChange = false;

            for (let j = 0; j < filling.length; j++) {
                if (
                    this.items[i].getDate() >= filling[j].begin &&
                    this.items[i].getDate() < filling[j].end
                ) {
                    this.items[i].setSelected(true);
                    isChange = true;
                    break;
                }
            }

            if (!isChange) {
                this.items[i].setSelected(false);
            }
        }
    }

    public changeSelected(start: number, end: number, selected: boolean): void {
        if (start < end) {
            for (let i = start + 1; i <= end; i++) {
                const item = this.getItem(i);
                item.setSelected(selected);
            }
        } else {
            for (let i = end; i < start; i++) {
                const item = this.getItem(i);
                item.setSelected(selected);
            }
        }
    }

    public changeSelect(id: number): TimingItem {
        if (!this.singleSelect) {
            const item = this.getItem(id);
            item.setSelected(!item.getSelected());

            return item;
        } else {
            this.items.forEach((item) => {
                if (item.getSelected() && item.getId() !== id) {
                    item.setSelected(false);
                } else if (item.getId() === id) {
                    item.setSelected(true);
                }
            });

            return this.getItem(id);
        }
    }

    public getTimeRanges(): TimingRange[] {
        const ranges = [];
        const range = {
            startHours: null,
            startMinutes: null,
            endHours: null,
            endMinutes: null,
        };

        for (let i = 0, length = this.items.length; i < length; i++) {
            if (this.items[i].getSelected()) {
                if (range.startHours === null && range.startMinutes === null) {
                    if (i !== length - 1) {
                        range.startHours = this.items[i].getHourStart();
                        range.startMinutes = this.items[i].getMinuteStart();

                        continue;
                    } else {
                        range.startHours = this.items[i].getHourStart();
                        range.startMinutes = this.items[i].getMinuteStart();
                        range.endHours = this.items[i].getHourEnd();
                        range.endMinutes = this.items[i].getMinuteEnd();

                        ranges.push({ ...range });
                        range.startHours = null;
                        range.startMinutes = null;
                        range.endHours = null;
                        range.endMinutes = null;
                        continue;
                    }
                } else {
                    if (i !== length - 1) {
                        continue;
                    } else {
                        range.endHours = this.items[i].getHourEnd();
                        range.endMinutes = this.items[i].getMinuteEnd();

                        ranges.push({ ...range });
                        range.startHours = null;
                        range.startMinutes = null;
                        range.endHours = null;
                        range.endMinutes = null;
                        continue;
                    }
                }
            } else {
                if (range.startHours !== null && range.startMinutes !== null) {
                    range.endHours = this.items[i - 1].getHourEnd();
                    range.endMinutes = this.items[i - 1].getMinuteEnd();

                    ranges.push({ ...range });
                    range.startHours = null;
                    range.startMinutes = null;
                    range.endHours = null;
                    range.endMinutes = null;
                    continue;
                } else {
                    continue;
                }
            }
        }

        return ranges;
    }
}
