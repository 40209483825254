<div class="container">
    <div class="change-password-form">
        <h3 class="change-password-form-header">
            <span>Change your password</span>
            <svg
                width="80"
                height="20"
                viewBox="0 0 451 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_752_10132)">
                    <path
                        d="M243.34 9.52C243.327 8.28293 243.557 7.05537 244.019 5.90743C244.48 4.75949 245.163 3.71364 246.028 2.82961C246.894 1.94558 247.925 1.24069 249.063 0.755182C250.2 0.269675 251.423 0.0130627 252.66 0L422 0C423.788 0.00982814 425.535 0.533554 427.033 1.50873C428.531 2.48391 429.717 3.8694 430.45 5.5L450.35 47C450.774 47.9433 450.993 48.9658 450.993 50C450.993 51.0342 450.774 52.0567 450.35 53L430.44 94.5C429.708 96.1291 428.523 97.5135 427.027 98.4886C425.531 99.4636 423.786 99.9883 422 100H252.66C251.423 99.9869 250.2 99.7303 249.063 99.2448C247.925 98.7593 246.894 98.0544 246.028 97.1704C245.163 96.2864 244.48 95.2405 244.019 94.0926C243.557 92.9446 243.327 91.7171 243.34 90.48V9.52Z"
                        fill="#E0B000"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M190.51 11C199.99 11 207.6 13.25 212.66 18.37C217.72 23.49 220 31.08 220 40.6V58.4C220 67.92 217.77 75.56 212.72 80.67C207.67 85.78 200.05 88 190.51 88H165.36C164.37 88.0807 163.375 87.9446 162.444 87.6013C161.512 87.258 160.666 86.7158 159.966 86.0123C159.265 85.3088 158.726 84.461 158.387 83.528C158.047 82.595 157.915 81.5992 158 80.61V18.39C157.958 17.4037 158.113 16.419 158.457 15.4934C158.8 14.5679 159.325 13.7202 160 13C160.718 12.3215 161.565 11.7948 162.491 11.4511C163.417 11.1075 164.403 10.9541 165.39 11H190.51ZM203.84 67.86C203.491 68.7602 202.961 69.5794 202.283 70.2673C201.606 70.9551 200.795 71.4972 199.9 71.86C196.89 72.9149 193.707 73.3866 190.52 73.25H172.73V25.78H190.51C194.77 25.78 197.82 26.33 199.87 27.21C200.778 27.5617 201.603 28.0992 202.292 28.7879C202.981 29.4767 203.518 30.3017 203.87 31.21V31.26C204.75 33.31 205.29 36.36 205.29 40.6V58.4C205.422 61.6174 204.929 64.8298 203.84 67.86V67.86Z"
                        fill="#242424"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M36.16 47.34L14.73 15.16C13.9609 13.9044 12.8861 12.8643 11.606 12.1368C10.3259 11.4093 8.88226 11.0182 7.40998 11C6.42043 10.9559 5.43206 11.1102 4.50299 11.4536C3.57391 11.7971 2.7229 12.3229 1.99998 13C1.32047 13.7213 0.793185 14.5721 0.449572 15.5015C0.105958 16.431 -0.0469464 17.4202 -2.16365e-05 18.41V80.61C-0.0442327 81.5965 0.110001 82.5819 0.453543 83.5078C0.797084 84.4336 1.32295 85.2811 1.99998 86C2.72528 86.6796 3.57957 87.2068 4.51227 87.5504C5.44497 87.8939 6.43712 88.0468 7.42998 88C8.4213 88.0466 9.41187 87.8935 10.3429 87.55C11.274 87.2064 12.1265 86.6793 12.85 86C13.5295 85.2787 14.0568 84.428 14.4004 83.4985C14.744 82.569 14.8969 81.5798 14.85 80.59V41.71L28.75 62.18C30.33 64.48 32.57 66.31 35.53 66.31C37.0764 66.3395 38.605 65.9761 39.9727 65.2538C41.3404 64.5314 42.5024 63.4738 43.35 62.18L57.19 41.87V80.61C57.1534 81.6053 57.3178 82.5978 57.6735 83.5282C58.0292 84.4585 58.5687 85.3076 59.26 86.0247C59.9512 86.7418 60.7799 87.3122 61.6965 87.7018C62.6132 88.0914 63.599 88.2922 64.595 88.2922C65.591 88.2922 66.5768 88.0914 67.4934 87.7018C68.4101 87.3122 69.2388 86.7418 69.93 86.0247C70.6212 85.3076 71.1608 84.4585 71.5164 83.5282C71.8721 82.5978 72.0366 81.6053 72 80.61V18.39C72.0914 16.5353 71.5405 14.7058 70.44 13.21C69.8289 12.4863 69.0612 11.9112 68.1949 11.5283C67.3286 11.1454 66.3865 10.9648 65.44 11C63.8771 10.9454 62.3274 11.304 60.9474 12.0396C59.5674 12.7753 58.4058 13.862 57.58 15.19L36.16 47.34Z"
                        fill="#242424"
                    />
                    <path
                        d="M306.69 33.08C306.196 32.8069 305.648 32.6448 305.085 32.605C304.522 32.5653 303.957 32.6489 303.43 32.85C302.438 33.298 301.642 34.0903 301.19 35.08V35.08L289.24 62.68L278.36 35.32C277.977 34.2249 277.18 33.3237 276.14 32.81C275.618 32.6255 275.062 32.555 274.51 32.6033C273.958 32.6516 273.422 32.8176 272.94 33.09C272.4 33.2607 271.902 33.5443 271.48 33.9218C271.057 34.2993 270.72 34.7623 270.49 35.28C270.136 36.4022 270.233 37.6179 270.76 38.67L284.69 73.67C285.036 74.5886 285.627 75.3946 286.4 76C287.155 76.5655 288.077 76.8613 289.02 76.84C290.01 76.874 290.988 76.6129 291.83 76.09V76.09C292.633 75.5179 293.249 74.7211 293.6 73.8L308.67 38.75C309.189 37.7188 309.3 36.5294 308.98 35.42C308.772 34.8976 308.463 34.4218 308.07 34.0201C307.677 33.6183 307.208 33.2988 306.69 33.08V33.08Z"
                        fill="white"
                    />
                    <path
                        d="M400.46 18.33H398.13C397.322 18.3458 396.553 18.6806 395.991 19.2614C395.429 19.8422 395.119 20.6218 395.13 21.43V23.81C395.119 24.6172 395.429 25.3958 395.991 25.975C396.554 26.5542 397.323 26.8868 398.13 26.9H400.46C401.266 26.8842 402.034 26.5508 402.596 25.9721C403.157 25.3935 403.468 24.6165 403.46 23.81V21.43C403.468 20.6226 403.158 19.8445 402.596 19.2643C402.035 18.684 401.267 18.3484 400.46 18.33V18.33Z"
                        fill="white"
                    />
                    <path
                        d="M399.35 32.59C398.789 32.5522 398.225 32.6304 397.695 32.8196C397.166 33.0088 396.68 33.3051 396.27 33.69C395.514 34.5625 395.133 35.6981 395.21 36.85V72.58C395.133 73.7319 395.514 74.8675 396.27 75.74C397.137 76.4509 398.224 76.8394 399.345 76.8394C400.466 76.8394 401.553 76.4509 402.42 75.74C403.18 74.8691 403.564 73.7332 403.49 72.58V36.85C403.564 35.6968 403.18 34.5609 402.42 33.69C402.011 33.3059 401.527 33.01 400.999 32.8208C400.471 32.6315 399.91 32.553 399.35 32.59V32.59Z"
                        fill="white"
                    />
                    <path
                        d="M376.21 16C375.649 15.9646 375.086 16.0439 374.557 16.233C374.027 16.4221 373.542 16.7171 373.13 17.1C372.374 17.9725 371.993 19.1081 372.07 20.26V32.59H357.87C352.34 32.59 348.08 33.97 345.25 36.85C342.45 39.73 341.12 44.12 341.12 49.85V59.56C341.12 65.26 342.45 69.65 345.25 72.56C348.08 75.44 352.34 76.82 357.87 76.82H361C363.381 76.8987 365.756 76.529 368 75.73C369.509 75.2326 370.923 74.4819 372.18 73.51C372.268 74.3348 372.618 75.1095 373.18 75.72C374.049 76.4317 375.137 76.8206 376.26 76.8206C377.383 76.8206 378.471 76.4317 379.34 75.72C380.092 74.8454 380.472 73.7113 380.4 72.56V20.21C380.472 19.0587 380.092 17.9247 379.34 17.05C378.917 16.6712 378.421 16.3826 377.883 16.2021C377.345 16.0215 376.776 15.9528 376.21 16ZM357.87 68.32C354.39 68.32 352.22 67.65 351.12 66.54C350.02 65.43 349.4 63.2 349.4 59.54V49.86C349.4 46.26 350.04 44.04 351.12 42.93C352.2 41.8 354.36 41.12 357.87 41.12H372.07V63.2C372.085 63.9712 371.818 64.7213 371.32 65.31C370.639 66.0502 369.783 66.6073 368.83 66.93C366.346 67.9361 363.678 68.4097 361 68.32H357.87Z"
                        fill="white"
                    />
                    <path
                        d="M324.56 18.33H322.23C321.423 18.3484 320.655 18.684 320.094 19.2643C319.532 19.8445 319.222 20.6226 319.23 21.43V23.81C319.222 24.6165 319.532 25.3935 320.094 25.9721C320.656 26.5508 321.424 26.8842 322.23 26.9H324.56C325.366 26.8842 326.134 26.5508 326.696 25.9721C327.257 25.3935 327.568 24.6165 327.56 23.81V21.43C327.568 20.6226 327.258 19.8445 326.696 19.2643C326.135 18.684 325.367 18.3484 324.56 18.33V18.33Z"
                        fill="white"
                    />
                    <path
                        d="M323.3 32.59C322.739 32.5546 322.176 32.6339 321.647 32.823C321.117 33.0121 320.632 33.3071 320.22 33.69C319.464 34.5625 319.083 35.6981 319.16 36.85V72.58C319.083 73.7319 319.464 74.8675 320.22 75.74C321.089 76.4517 322.177 76.8406 323.3 76.8406C324.423 76.8406 325.511 76.4517 326.38 75.74C327.136 74.8675 327.517 73.7319 327.44 72.58V36.85C327.517 35.6981 327.136 34.5625 326.38 33.69C325.968 33.3071 325.483 33.0121 324.953 32.823C324.424 32.6339 323.861 32.5546 323.3 32.59V32.59Z"
                        fill="white"
                    />
                    <path
                        d="M85 19.5C84.9706 17.2782 85.8238 15.1355 87.3723 13.5419C88.9208 11.9484 91.0383 11.0342 93.26 11H136.74C138.962 11.0342 141.079 11.9484 142.628 13.5419C144.176 15.1355 145.029 17.2782 145 19.5C145.029 21.7218 144.176 23.8645 142.628 25.4581C141.079 27.0516 138.962 27.9658 136.74 28H93.26C91.0383 27.9658 88.9208 27.0516 87.3723 25.4581C85.8238 23.8645 84.9706 21.7218 85 19.5V19.5Z"
                        fill="#242424"
                    />
                    <path
                        d="M85 79.5C84.9706 77.2782 85.8238 75.1355 87.3723 73.5419C88.9208 71.9484 91.0383 71.0342 93.26 71H136.74C138.994 71 141.156 71.8955 142.75 73.4896C144.344 75.0837 145.24 77.2457 145.24 79.5C145.24 81.7543 144.344 83.9163 142.75 85.5104C141.156 87.1045 138.994 88 136.74 88H93.26C91.0383 87.9658 88.9208 87.0516 87.3723 85.4581C85.8238 83.8645 84.9706 81.7218 85 79.5V79.5Z"
                        fill="#242424"
                    />
                    <path
                        d="M85 49.5C84.9706 47.2782 85.8238 45.1355 87.3723 43.5419C88.9208 41.9484 91.0383 41.0342 93.26 41H136.74C138.994 41 141.156 41.8955 142.75 43.4896C144.344 45.0837 145.24 47.2457 145.24 49.5C145.24 51.7543 144.344 53.9163 142.75 55.5104C141.156 57.1045 138.994 58 136.74 58H93.26C91.0383 57.9658 88.9208 57.0516 87.3723 55.4581C85.8238 53.8645 84.9706 51.7218 85 49.5V49.5Z"
                        fill="#242424"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_752_10132">
                        <rect width="451" height="100" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </h3>
        <h2 class="change-password-form-notification" *ngIf="isExpiredPasswordMessageNeeded">
            Your MEDvidi password has expired and must be changed.
        </h2>
        <med-form [form]="passwordForm"></med-form>
    </div>
</div>
