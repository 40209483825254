import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedCommonModule } from '../med-common/med-common.module';
import { MedFormModule } from '../med-form/med-form.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

import { LoginComponent } from './components/login/login.component';
import { BaseLoginComponent } from './components/base-login/base-login.component';
import { GoogleLoginComponent } from './components/google-login/google-login.component';

@NgModule({
    declarations: [
        LoginComponent,
        BaseLoginComponent,
        GoogleLoginComponent,
        ChangePasswordComponent,
    ],
    imports: [CommonModule, MedCommonModule, MedFormModule],
    exports: [LoginComponent, ChangePasswordComponent],
})
export class MedAuthModule {}

export { LoginComponent } from './components/login/login.component';
export { ChangePasswordComponent } from './components/change-password/change-password.component';
export * from './api/auth.service';
export * from './services/auth.service';
export * from './guards/auth.guard';
export * from './guards/not-auth.guard';
export * from './guards/master-auth.guard';
