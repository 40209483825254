import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { IElectronicPrescriptionsPatient } from '../../../med-api/interfaces';

import { ElectronicPrescriptionsService } from '../../../med-api/services';
import { DoctorEventType } from '../../../med-common/interfaces/event.type';
import { EventAnalyticsService } from '../../../med-common/services/event-analytics.service';

import { ElectronicPrescriptionsPatientDialogComponent } from '../electronic-prescriptions-patient-dialog/electronic-prescriptions-patient-dialog.component';
import { DateTime } from 'luxon';

interface IDialogData {
    leadId: number;
    firstName: string;
    lastName: string;
    dob: Date;
}

@Component({
    selector: 'med-electronic-prescriptions-search-patient-dialog',
    templateUrl: './electronic-prescriptions-search-patient-dialog.component.html',
    styleUrls: ['./electronic-prescriptions-search-patient-dialog.component.css'],
})
export class ElectronicPrescriptionsSearchPatientDialogComponent implements OnInit, AfterViewInit {
    @Output() onPatientAdd = new EventEmitter<string>();

    public loaded: boolean = false;
    public dosespotPatients: IElectronicPrescriptionsPatient[];

    constructor(
        public dialogRef: MatDialogRef<ElectronicPrescriptionsSearchPatientDialogComponent>,
        private electronicPrescriptionsService: ElectronicPrescriptionsService,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private dialog: MatDialog,
        private eventAnalyticsService: EventAnalyticsService
    ) {}

    public ngOnInit(): void {
        this.searchPatient();
    }

    ngAfterViewInit(): void {
        this.emitEvent(DoctorEventType.POPUP_LOADED, {
            lead_id: this.data.leadId,
            page: 'patient/profile',
        });
    }

    public closeDialog() {
        this.dialogRef.close();
    }

    public async searchPatient() {
        try {
            this.loaded = false;

            this.dosespotPatients = await this.electronicPrescriptionsService.searchPatient({
                firstName: this.data.firstName,
                lastName: this.data.lastName,
                dob: DateTime.fromJSDate(this.data.dob).set({ hour: 12 }).toJSDate(),
            });
            if (!this.dosespotPatients.length) {
                this.openPatientCreationDialog();
                this.closeDialog();
            }

            this.emitEvent(DoctorEventType.SEARCH_RESULT, {
                element_name: 'search_patient',
                pop_up_title: 'search_dosespot_patient',
                result_count: this.dosespotPatients.length,
            });

            this.loaded = true;
        } catch (err) {
            this.loaded = true;
            this.eventAnalyticsService.emitError('[ePrescriptions] - Search patient error');
            console.warn('[ePrescriptions] - Search patient error: ', err);
        }
    }

    public async connectPatient(patient: IElectronicPrescriptionsPatient) {
        this.emitEvent(DoctorEventType.BUTTON_TAPPED, {
            element_name: 'select_current_dosespot_profile',
            patient_name: `${patient.firstName} ${patient.lastName}`,
            patient_phone: patient.phone,
            patient_date_of_birth: moment.utc(patient.dob).format(),
        });

        try {
            this.loaded = false;

            const response = await this.electronicPrescriptionsService.linkPatient(
                this.data.leadId,
                patient.dosespotId
            );

            this.emitEvent(DoctorEventType.SELECT_DESESPOT_PATIENT, {
                patient_name: `${patient.firstName} ${patient.lastName}`,
                patient_phone: patient.phone,
                patient_date_of_birth: moment.utc(patient.dob).format(),
            });
            this.onPatientAdd.emit(response.link);
            this.closeDialog();

            this.loaded = true;
        } catch (err) {
            this.loaded = true;
            this.eventAnalyticsService.emitError('[ePrescriptions] - Link patient error');
            console.warn('[ePrescriptions] - Link patient error: ', err);
        }
    }

    public openPatientCreationDialog(): void {
        this.emitEvent(DoctorEventType.BUTTON_TAPPED, {
            element_name: 'go_to_new_patient_dosespot_profile_adding',
        });

        const dialogRef = this.dialog.open(ElectronicPrescriptionsPatientDialogComponent, {
            width: '500px',
            height: '760px',
            data: {
                leadId: this.data.leadId,
            },
        });

        dialogRef.componentInstance.onPatientAdd.subscribe((link: string) => {
            this.onPatientAdd.emit(link);
            this.closeDialog();
        });

        dialogRef.afterClosed().subscribe(() =>
            this.emitEvent(DoctorEventType.POPUP_CLOSED, {
                pop_up_title: 'new_dosespot_patient_adding_details',
            })
        );
    }

    private emitEvent(type: DoctorEventType, meta: Record<string, any>): void {
        this.eventAnalyticsService.emitEvent({
            type,
            metadata: {
                lead_id: this.data.leadId,
                page: 'patient/profile',
                pop_up_title: 'connect_patient',
                ...meta,
            },
        });
    }
}
