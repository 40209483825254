import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AutotestsUtilsService {
    public getDataTestId(constPart: string | number, dynamicPart?: number | string): string {
        return `${constPart} ${dynamicPart}`;
    }
}
