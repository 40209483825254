import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AppFileService {
    public getFileAuthURL(url: string): string {
        if (!url) {
            return null;
        }

        return `${url}?token=${localStorage.getItem('apiAuthToken')}`;
    }

    public openFile(fileUrl: string, useToken: boolean = true): void {
        const a = document.createElement('a');
        a.href = useToken ? this.getFileAuthURL(fileUrl) : fileUrl;
        a.target = '_blank';
        a.click();
    }

    public printFile(fileUrl: string): void {
        return this.openFile(fileUrl);
    }

    public async downloadFile(fileUrl: string, fileName: string): Promise<void> {
        const blob = await fetch(this.getFileAuthURL(fileUrl)).then((r) => r.blob());
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.setAttribute('download', fileName);
        a.click();
        window.open(url);
    }

    public downloadFileFromBlob(blob: Blob, fileName: string): void {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
}
