import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormInputField } from '../../../classes';
import { FormGroup } from '@angular/forms';
import { ErrorTextService } from '../../../services/error-text.service';

@Component({
    selector: 'med-form-input-field',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.css'],
})
export class FormInputFieldComponent implements OnInit {
    @Input() field: FormInputField;
    @Input() formGroup: FormGroup;
    @Output() clickIcon = new EventEmitter<string>();

    constructor(public errorTextService: ErrorTextService) {}

    ngOnInit() {}

    public onClickActionIcon(): void {
        this.clickIcon.emit(this.field.controlName);
    }
}
