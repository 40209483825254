import { SafeResourceUrl } from '@angular/platform-browser';
import { DateTime } from 'luxon';

export enum LetterGenerationStep {
    INIT = 'INIT',
    PREVIEW_PDF = 'PREVIEW_PDF',
    PREVIEW_EMAIL = 'PREVIEW_EMAIL',
    EDIT_PDF_HTML = 'EDIT_PDF_HTML',
    EDIT_EMAIL = 'EDIT_EMAIL',
    EDIT_PDF_FORM = 'EDIT_PDF_FORM',
    RESULT = 'RESULT',
}

export enum LetterResult {
    FAIL = 'fail',
    SUCCESS = 'success',
}

export const LetterResultMessages: Record<LetterResult, string> = {
    [LetterResult.FAIL]: 'Something went wrong. Please try again!',
    [LetterResult.SUCCESS]: 'The letter successfully generated & sent',
};

export interface IPdfInfo {
    id: string;
    fileName: string;
    link: string;
    safeLink: SafeResourceUrl;
    html: string;
    emailHtml: string;
    form: any;
}

export interface IDialogData {
    leadId: number;
    createdByDoctor?: boolean;
    appointmentTime?: DateTime;
}

export interface ILetterInput {
    name?: string;
    type?: string;
    top?: number;
    left?: number;
    width?: number;
    height?: number;
    value?: any;
}
