import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStateService } from './app-state.service';
import { EventAnalyticsService } from './event-analytics.service';
import { RestApiService } from './rest-api.service';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(
        private http: HttpClient,
        private appStateService: AppStateService,
        private restApiService: RestApiService,
        private eventAnalyticsService: EventAnalyticsService
    ) {
        restApiService.baseUrl = HttpService.apiHost;
        eventAnalyticsService.isProd = HttpService.isProd;
    }
    private static apiHost = 'http://';

    private static isProd = false;

    static setApiHost(host: string) {
        HttpService.apiHost = host;
    }

    private static getUrl(path: string): string {
        return `${HttpService.apiHost}/${path}`;
    }

    getOptions(customHeaders?: HttpHeaders, customOptions: object = {}): object {
        return {
            headers:
                customHeaders ||
                new HttpHeaders({
                    'DctrApp-API-AuthKey': localStorage.getItem('apiAuthKey') || '',
                }),
            observe: 'response',
            withCredentials: true,
            ...customOptions,
        };
    }

    handleError(err: any): any {
        if (err.status === 400) {
            return err.error;
        }
        if (err.status === 401) {
            this.appStateService.setAppNeedAuth();
            return err.error;
        }
        if (err.status === 403) {
            this.appStateService.setAppForbidden();
            return err.error;
        }
        if (err.status !== 500) {
            return err;
        }
        this.appStateService.setAppBroken();
        console.error('Unknown HTTP-response', err);
        return {
            errors: [{ code: 'unknown_error' }],
        };
    }

    async get<T>(
        path: string,
        customHeaders?: HttpHeaders,
        skipGlobalErrorHandling: boolean = false
    ): Promise<T> {
        return new Promise((resolve, reject) => {
            this.http.get(HttpService.getUrl(path), this.getOptions(customHeaders)).subscribe(
                (response: HttpResponse<T>) => {
                    resolve(response.body);
                },
                (err: any) => {
                    if (skipGlobalErrorHandling) {
                        reject(err);
                        return;
                    }
                    reject(this.handleError(err));
                }
            );
        });
    }

    async post<T>(
        path: string,
        data: any,
        customHeaders?: HttpHeaders,
        customOptions?: object
    ): Promise<T> {
        return new Promise((resolve, reject) => {
            this.http
                .post(HttpService.getUrl(path), data, this.getOptions(customHeaders, customOptions))
                .subscribe(
                    (response: HttpResponse<T>) => {
                        resolve(response.body);
                    },
                    (err: any) => {
                        reject(this.handleError(err));
                    }
                );
        });
    }

    async put<T>(path: string, data: any, customHeaders?: HttpHeaders): Promise<T> {
        return new Promise((resolve, reject) => {
            this.http.put(HttpService.getUrl(path), data, this.getOptions(customHeaders)).subscribe(
                (response: HttpResponse<T>) => {
                    resolve(response.body);
                },
                (err: any) => {
                    reject(this.handleError(err));
                }
            );
        });
    }

    async patch<T>(path: string, data: any, customHeaders?: HttpHeaders): Promise<T> {
        return new Promise((resolve, reject) => {
            this.http
                .patch(HttpService.getUrl(path), data, this.getOptions(customHeaders))
                .subscribe(
                    (response: HttpResponse<T>) => {
                        resolve(response.body);
                    },
                    (err: any) => {
                        reject(this.handleError(err));
                    }
                );
        });
    }

    async delete<T>(path: string, customHeaders?: HttpHeaders): Promise<T> {
        return new Promise((resolve, reject) => {
            this.http.delete(HttpService.getUrl(path), this.getOptions(customHeaders)).subscribe(
                (response: HttpResponse<any>) => {
                    resolve(response.body);
                },
                (err: any) => {
                    reject(this.handleError(err));
                }
            );
        });
    }
}
