import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { captureMessage } from '@sentry/browser/esm';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OPTIONS } from '../tokens';
import { SentryModuleOptions } from '../interfaces';

/**
 * Injectable HTTP interceptor for Sentry.
 */
@Injectable()
export class SentryErrorInterceptor implements HttpInterceptor {
    public constructor(@Inject(OPTIONS) private options: SentryModuleOptions) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request.clone()).pipe(
            catchError(response => {
                // log to Sentry
                if (response instanceof HttpErrorResponse && this.filter(response)) {
                    captureMessage(this.getMessage(request, response));
                }

                // re-throw error
                return throwError(() => response);
            }),
        );
    }

    private filter(response: HttpErrorResponse): boolean {
        if (!this.options.enabled || !this.options.http?.enabled) {
            return false;
        }

        // apply whitelist
        if (this.options.http.whitelist && this.options.http.whitelist.length > 0) {
            return !this.options.http.whitelist.includes(response.status);
        }

        // apply blacklist
        if (this.options.http.blacklist && this.options.http.blacklist.length > 0) {
            return this.options.http.blacklist.includes(response.status);
        }

        return true;
    }

    private getMessage(request: HttpRequest<any>, response: HttpErrorResponse): string {
        const replace = (msg: string) => {
            const map = {
                method: request.method,
                url: request.url,
                status: response.status,
                message: response.message,
                error: JSON.stringify(response.error.errors[0]),
            };

            // replace all keys with their values
            Object.keys(map).forEach((key) => {
                msg = msg.replace(new RegExp(`{${key}}`, 'g'), map[key]);
            });

            return msg;
        };

        if (this.options.http.customMessage) {
            return replace(this.options.http.customMessage);
        }

        // use default message
        return replace(this.options.http.message);
    }
}
