import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Form } from '../../classes';
import { FormGroup } from '@angular/forms';
import { ErrorTextService } from '../../services/error-text.service';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter } from '../../custom-date-adapter';

@Component({
    selector: 'med-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css'],
    providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class FormComponent implements OnInit, OnChanges {
    @Input() form: Form;
    @Input() actionButtonText?: string;
    @Output() clickIcon = new EventEmitter<string>();
    @Output() actionButtonClick = new EventEmitter();
    formGroup: FormGroup;
    formProcessing: boolean;

    constructor(public errorTextService: ErrorTextService) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.form.previousValue !== changes.form.currentValue) {
            this.initForm();
        }
    }

    async submit() {
        this.formProcessing = true;
        await this.form.submit();
        this.formProcessing = false;
    }

    public onClickActionIcon(controlName: string) {
        this.clickIcon.emit(controlName);
    }

    public handleActionButtonClick() {
        this.actionButtonClick.emit();
    }

    private initForm() {
        this.formProcessing = false;
        this.form.buildFormGroup();
        this.formGroup = this.form.getFormGroup();
    }
}
