import { IChartListItem } from '@doctor/app/pages/patient/medical-records-v2/new-chart-form/models/chart.interface';
import { ListFilter } from '../../med-list/interfaces';
import { AppointmentResolution, IAppointment, IFieldConfig } from './';

export interface IAppointmentListParams {
    search?: string;
    page?: number;
    limit?: number;
    filters?: IAppointmentFilter;
}

export interface IAppointmentStatsResponse {
    upcoming: number;
    unresolved: number;
    archive: number;
}

export interface IAppointmentListResponse {
    appointments: IAppointment[];
    total: number;
}

export interface IAppointmentFilter {
    date?: Date;
    stateId?: number;
    serviceId?: number;
}

export interface IDoctorAreaAppointmentChangeStatusResponse {
    change: boolean;
}

export interface IDoctorAreaAppointmentGetTokenResponse {
    token: string;
}

export interface IDoctorAreaGetProcessNotesResponse {
    display: boolean;
    config: IFieldConfig[];
    values?: Record<string, string | number | boolean | Date>;
    url?: string;
    fileName?: string;
    createdAt?: Date;
    creator?: {
        id: number;
        fullName: string;
    };
    appointmentChart: IChartListItem;
}

export interface IDoctorAppointmentResolutionParams {
    resolution: AppointmentResolution;
    medication?: string;
    pharmacyName?: string;
    additionalComments?: string;
}

export interface IDoctorClosePharmacyTaskParams {
    comment: string;
    taskId: number;
}

export enum DoctorTaskCode {
    UPDATE_PHARMACY = 'update_pharmacy',
    CREATE_PRESCRIPTION = 'create_prescription',
}

// TODO: move in another place
export const enum PharmacyIssueResolutions {
    NEED_DOCTOR_SIGNATURE = 'Prescription has been drafted. Need doctor’s signature',
    NEED_DOCTOR_REVIEW = 'Prescription has been created. Need doctor’s review',
}

export type UpdatePharmacyDoctorTaskMetadata = {
    medicationName: string;
    oldPharmacy: string;
    newPharmacy: string;
    prescriptionDosespotId: number;
    relatedPharmacyIssueTaskId: number;
    relatedPharmacyIssueResolution?: PharmacyIssueResolutions;
    eRxLink: string;
};

export type CreatePrescriptionDoctorTaskMetadata = {
    medication: string;
    pharmacyName: string;
    additionalComments: string;
    appointmentTime: Date;
    eRxLink: string;
};

export type DoctorTaskMetadata =
    | UpdatePharmacyDoctorTaskMetadata
    | CreatePrescriptionDoctorTaskMetadata;

export interface DoctorTask {
    id: number;
    appointmentId: number;
    lead: ILeadPrescriptionFormat;
    code: DoctorTaskCode;
    metadata: DoctorTaskMetadata;
    createdAt: string;
    updatedAt: string;
}

export interface IPeriodAppointmentsSchedule {
    appointments: IAppointment[];
    reservedAppointments: IAppointment[];
}

export interface IReservedAppointment {
    id: number;
    firstName: string;
    lastName: string;
    serviceName: string;
    time: string;
    doctorTimezone: string;
    servicePlanName: string;
    servicePlanPrice: number;
    createdAt: string;
    leadId: number;
    doctorName: string;
    phone: string;
    status: number;
    email: string;
    clientTimezone: string;
}

export interface ILeadPrescriptionFormat {
    id: number;
    clientId: number;
    dosespotId: number;
    firstName: string;
    lastName: string;
    serviceName: string;
    dateOfBirth?: string;
}

/**
 * field: patientFullName, value: part of 'clientFullName'
 * field: code, value: DoctorTaskCode
 * field: serviceName, value: part of 'serviceName'
 */
export interface IDoctorTaskListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
}

// Labs
export enum EAppointmentResolutionByLab {
    NOT_ORDERED = 'notOrdered',
    NOT_NEEDED = 'notNeeded',
}

export interface IGetLabOrdersParams {
    clientId: number;
}

export interface ICreateLabOrderParams {
    leadId: number;
    doctorId: number;
    details: string;
}

export interface ILabOrder {
    id: number;
    leadId: number;
    doctor: { id: number; fullName: string };
    details: string;
    createdAt: string;
}

export interface IResolutionByLabParams {
    leadId: number;
    doctorId: number;
    labResolution: EAppointmentResolutionByLab;
    labDetails: string;
}
