import { ListResponse } from '../../med-list/interfaces';
import { DoctorTaskCode, IServiceAccountChannel, IUserGroup, LeadStatus, Task, TaskType } from './';

export enum LeadEventRefModel {
    SQUARE_UP_TRANSACTION = 'squareUpTransaction',
    CASH_TRANSACTION = 'cashTransaction',
    STRIPE_TRANSACTION = 'stripeTransaction',
    AUTHORIZE_TRANSACTION = 'authorizeTransaction',
}

export enum StripePaymentMethod {
    CARD = 'CARD',
    APPLE_PAY = 'APPLE_PAY',
}

export interface ILeadEvent {
    id: number;
    leadId: number;
    type: LeadEventType;
    metadata?: LeadEventMetadata;
    refModel: string;
    refId: number;
    createdAt: Date;
    updatedAt: Date;
    /* refObjects */
    task?: Task;
}

export interface ILeadEventListParams {
    page: number;
    limit: number;
    filters?: {
        leadId?: number;
    };
}

export interface ILeadEventLeadUpdatedField {
    field: string;
    from: string;
    to: string;
}

export interface ILeadEventListResponse extends ListResponse {
    items: ILeadEvent[];
}

export enum LeadEventType {
    LEAD_CREATED = 0,
    SERVICE_SELECTED = 1,
    SERVICE_PLAN_SELECTED = 2,
    APPOINTMENT_TIME_SELECTED = 3,
    STATUS_CHANGED = 4,
    PAYMENT_COMPLETED = 5,
    PAYMENT_FAILED = 6,
    INTAKE_COMPLETED = 7,
    APPOINTMENT_CONFIRMED = 8,
    APPOINTMENT_STARTED = 9,
    APPOINTMENT_FINISHED = 10,
    INCOMING_MESSAGE = 11,
    OUTGOING_MESSAGE = 12,
    TASK_CREATED = 13,
    TASK_ACTIVATED = 14,
    TASK_ATTACHED = 15,
    TASK_OVERDUE = 16,
    TASK_COMPLETED = 17,
    NOTE_CREATED = 18,
    MOBILE_SCREEN_COMPLETE_PAYMENT_VIEWED = 19,
    MOBILE_SCREEN_INTAKE_FORM_VIEWED = 20,
    STATE_SELECTED = 21,
    LETTER_SENT = 22,
    APPOINTMENT_TIME_WAS_CHANGED_BY_SUB = 23,
    CALL_SKIPPED = 24,
    NOTE_UPDATED = 25,
    NOTE_DELETED = 26,
    DOCUMENT_UPLOADED = 27,
    LEAD_UPDATED = 28,
    SUBSCRIPTION_CANCELLED = 29,
    TERMS_ACCEPTED = 30,
    SUBSCRIPTION_REACTIVATED = 31,
    INCOMING_CALL = 32,
    OUTGOING_CALL = 33,
    APPOINTMENT_TIME_RESCHEDULED = 34,
    INSURANCE_SELECTED = 35,
    ELIGIBILITY_CHECK_RESULT = 36,
    INSURANCE_UPDATED = 37,
    PRESCRIPTION = 38,
    PARENT_LEAD_CLOSED = 39,
    NO_ERX_APPOINTMENT_SUCCESSFULLY = 40,
    TASK_POSTPONED = 41,
    CALL_RESOLUTION = 42,
    CONTACT_PATIENT_TO_CLARIFY_PRESCRIPTION_CLOSED = 43,
    DOCTOR_DECISION = 44,
    TWILIO_PIPELINE_MANUAL_RECALLED = 45,
    TASK_SKIPPED = 46,
    CALL_REDIRECTED = 47,
    PAYMENT_ONSITE = 48,
    PROMO_CODE_APPLIED = 49,
    STRIPE_CHARGE_DISPUTE = 50,
    STRIPE_REFUNDED_UPDATE = 51,
    APPOINTMENT_CANCELLED_BY_LEAD = 52,
    APPOINTMENT_RESCHEDULED_BEFORE_CONFIRMATION_BY_LEAD = 53,
    APPOINTMENT_RESCHEDULED_AFTER_CONFIRMATION_BY_LEAD = 54,
    RESCHEDULE_APPOINTMENT_SUBMITED = 56,
    APPOINTMENT_TIME_RESCHEDULED_BY_MANAGER = 57,
    PROVIDER_CONFIRMED_RESCHEDULE_REQUEST = 58,
    CALL_ON_HOLD = 59,
    CALL_RESUMED = 60,
    RESCHEDULE_APPOINTMENT_REFUND_REQUESTED = 61,
    RESCHEDULE_APPOINTMENT_RESCHEDULED = 62,
    AUTHORIZE_REFUND_CREATED = 63,
    LEAD_REMINDER_APPOINTMENT_IN_ONE_DAY = 64,
    MESSAGE_ANSWERED = 65,
    MESSAGE_ANSWER_NO_NEEDED = 66,
    MESSAGE_ASSIGN_TO_ANOTHER_DPT = 67,
    MANUAL_LEAD_CREATION_REASON = 68,
    MANUAL_LEAD_CREATION_COMMENT = 69,
    UNTRUSTED_PHARMACY_SELECTED = 70,
    INTAKE_COMPLETED_AUTOMATICALLY = 71,
    DOCTOR_PHONE_CALL_COMPLETED = 72,
    DOCTOR_PHONE_CALL_MISSED = 73,
    PARENT_LEAD_PHARMACY_ISSUE_CREATED = 74,
    CANCEL_AUTO_CONFIRM_APPOINTMENT = 75,
    SUCCESS_AUTO_CONFIRM_APPOINTMENT = 76,
    APPOINTMENT_AUTO_ASSIGNED = 77,
    APPOINTMENT_MISSED = 78,
    THIRD_PARTY_SYSTEM_APPOINTMENT = 79,
    RED_FLAG_MARK = 80,
    BLACK_LIST_MARK = 81,
    APPOINTMENT_EXPERIENCE_RATED = 82,
    APPOINTMENT_PROVIDER_RATED = 83,
    APPOINTMENT_SATISFACTION_COMMENTED = 84,
    ADDED_TO_DOSESPOT = 85,
    DOCTOR_HAS_COMPLETED_TASK = 86,
    NOT_ADDED_AUTOMATICALLY_TO_DOSESPOT = 87,
    INTERCOM_INCOMING_PHONE_CALL = 88,
    INTERCOM_OUTGOING_PHONE_CALL = 89,
    INTERCOM_MESSAGES_TRIGGERED = 90,
    LAB_IS_ORDERED = 92,
    ORDER_LAB_RESOLUTION = 93,
    PAYMENT_FREE = 94,
    PROGRESS_NOTES_CREATED = 95,
    PROGRESS_NOTES_UPDATED = 96,
    DOCTOR_RECORD_ADDED = 97,
    MANAGER_MARK_AS_DELETED = 98,
    APPOINTMENT_CHART_LOCKED_AND_SUBMITTED = 99,
    // before adding enum number please check the list
    // https://www.notion.so/IDs-reservation-95caf33042224e53bffcc10dea3cb2da
}

type LeadCreatedMetadata = {
    createdFrom: string;
    userFullName?: string;
    websiteUrl?: string;
    stateName: string;
};

type ServiceSelectedMetadata = {
    serviceName: string;
};

type ServicePlanSelectedMetadata = {
    servicePlanTitle: string;
    price: number;
    selectedBySystem?: boolean;
};

type AppointmentTimeSelectedMetadata = {
    appointmentTime: Date;
};

type StatusChangedMetadata = {
    prevStatus: LeadStatus;
    nextStatus: LeadStatus;
    userFullName?: string;
    closeComment?: string;
};

type PaymentSuccessfulMetadata = {
    gatewayTransactionId: string;
    gatewayLocationId: string;
};

type PaymentFailedMetadata = {
    gatewayResponse: any;
};

type AppointmentConfirmedMetadata = {
    appointmentTime: Date;
    doctorFullName: string;
};

type MessageCreatedMetadata = {
    leadCode: string;
    serviceAccountCode: string;
    serviceAccountName: string;
    channel: IServiceAccountChannel;
};

type TaskEventMetadata = {
    taskName: string;
    taskType: TaskType;
};

type TaskAttachedMetadata = TaskEventMetadata & {
    userId: number;
    userFullName: string;
};

type TaskCompletedMetadata = TaskAttachedMetadata & {
    closedAutomatically: boolean;
    closeComment: string;
};

type NoteCreatedMetadata = {
    noteTitle: string;
    noteDescription: string;
    userId: number;
    userFullName: string;
};

type NoteUpdatedMetadata = {
    noteTitle: string;
    noteDescription: string;
    userId: number;
    userFullName: string;
};

type NoteDeletedMetadata = {
    noteTitle: string;
    noteDescription: string;
    userId: number;
    userFullName: string;
};

type StateSelectedMetadata = {
    serviceName: string;
    serviceCode: string;
};

type LeadUpdatedMetadata = {
    updatedFields: ILeadEventLeadUpdatedField[];
};

export type LeadPaymentMethodMetadata = {
    paymentMethod?: StripePaymentMethod;
};

export type DocumentUploadedMetadata = {
    userId: number;
    userFullName: string;
    file: {
        name: string;
        url: string;
    };
};

export type SubscriptionCancelledMetadata = {
    userFullName: string;
    subscriptionId: number;
    cancelledAt: Date;
    paidTo: Date;
};

export type TermsAcceptedMetadata = {
    time: Date;
};

export type SubscriptionReactivatedMetadata = {
    userFullName: string;
    subscriptionId: number;
    reactivatedAt: Date;
    paidTo: Date;
};

export enum RefundType {
    PARTIAL = 'Partial refund',
    FULL = 'Full refund',
    NO = 'No refund',
}

export interface IRefundUpdateData {
    id: string;
    amount: number;
    status: string;
    type: RefundType;
    reason: string;
}

export type RefundedMetadata = {
    refund: IRefundUpdateData;
};

export type RescheduleAppointmentTimeMetadata = {
    prevTime: Date;
    newTime: Date;
    clientTimezone: string;
};

export type InsuranceSelectedMetadata = {
    insuranceId: number;
    insuranceName: string;
};

export type CallResolutionMetadata = {
    request?: string;
    subRequest?: string;
    result?: string;
};

export enum StripeChargeDisputeEventTypes {
    CREATED = 'charge.dispute.created',
    CLOSED = 'charge.dispute.closed',
    FUNDS_REINSTATED = 'charge.dispute.funds_reinstated',
    FUNDS_WITHDRAWN = 'charge.dispute.funds_withdrawn',
    UPDATED = 'charge.dispute.updated',
}

export enum ELeadAppointmentReminderEventTypes {
    NOTIFICATION_IS_SENT = 1,
    CONFIRMED_APPOINTMENT,
    REFUSED_APPOINTMENT,
    RESCHEDULED_APPOINTMENT,
}

export type StripeChargeDisputeMetadata = {
    eventType: string;
    disputeId: string;
    amount?: number;
    chargeId?: string;
    reason?: string;
};

export type AppointmentCancelledByLeadMetadata = {
    comment: string;
};

export type RescheduleAppointmentSubmitedMetadata = {
    managerFullName: string;
};

export type AppointmentTimeRescheduledByManagerMetadata = {
    managerFullName: string;
    newTime: Date;
    clientTimezone: string;
};

export type AuthorizeRefundCreatedMetadata = {
    id: string;
    amount: number;
    type: RefundType;
};

export type AppointmentRescheduled = {
    date: Date;
    doctorFullName: string;
};

export type CallOnHoldMetadata = {
    managerId: number;
};

export type CallResumedMetadata = {
    managerId: number;
};

export type ManualLeadCreationReason = {
    fullName: string;
    reason: string;
    linkToLead: string;
};

export type ManualLeadCreationComment = {
    comment: string;
};

export type LeadReminderAppointmentInOneDay = {
    caseNumber: number;
};

export type DoctorPhoneCallCompletedMetadata = {
    callStartTime: Date;
    callDuration: number;
    clientTimezone?: string;
    doctorFullName: string;
};

export type DoctorPhoneCallMissedMetadata = {
    callStartTime: Date;
    clientTimezone?: string;
    doctorFullName: string;
};

export type ParentLeadPharmacyIssueCreatedMetadata = {
    parentLeadId: number;
};

export type CancelAutoConfirmAppointmentMetadata = {
    appointmentTime?: Date;
    providerName?: string;
    reason?: string;
};

export type SuccessAutoConfirmAppointmentMetadata = {
    appointmentTime: Date;
    providerName: string;
};

export type AppointmentAutoAssignedMetadata = {
    appointmentTime: Date;
    providerName: string;
};

export type DoctorHasCompletetTaskMetadata = {
    taskId: number;
    taskCode: DoctorTaskCode;
    commentOnClose?: string;
};

export type ThirdPartySystemAppointmentMetadata = {
    thirdPartySystemComment: string;
};

export type BlackListLeadMarkMetadata = {
    userRoleString: string;
    createdBy: string;
    blacklisted: boolean;
    userGroups: IUserGroup[];
};

export type RedFlagLeadMarkMetadata = {
    userRoleString: string;
    createdBy: string;
    redFlag: boolean;
    userGroups: IUserGroup[];
};

export type AppointmentRatingMetadata = {
    rating: string;
    providerName?: string;
    comment?: string;
};

export type IntercomMessageMetadata = {
    intercomEventId: number;
    messageTemplateCode: string;
};

export type LabsMetadata = {
    description: string;
};

export type LeadEventMetadata =
    | LeadCreatedMetadata
    | ServiceSelectedMetadata
    | ServicePlanSelectedMetadata
    | AppointmentTimeSelectedMetadata
    | StatusChangedMetadata
    | PaymentSuccessfulMetadata
    | PaymentFailedMetadata
    | AppointmentConfirmedMetadata
    | MessageCreatedMetadata
    | TaskEventMetadata
    | TaskAttachedMetadata
    | TaskCompletedMetadata
    | NoteCreatedMetadata
    | NoteUpdatedMetadata
    | NoteDeletedMetadata
    | StateSelectedMetadata
    | DocumentUploadedMetadata
    | LeadUpdatedMetadata
    | SubscriptionCancelledMetadata
    | TermsAcceptedMetadata
    | SubscriptionReactivatedMetadata
    | RescheduleAppointmentTimeMetadata
    | InsuranceSelectedMetadata
    | CallResolutionMetadata
    | StripeChargeDisputeMetadata
    | RefundedMetadata
    | AppointmentCancelledByLeadMetadata
    | RescheduleAppointmentSubmitedMetadata
    | AppointmentTimeRescheduledByManagerMetadata
    | AuthorizeRefundCreatedMetadata
    | ManualLeadCreationReason
    | ManualLeadCreationComment
    | AppointmentRescheduled
    | CallOnHoldMetadata
    | CallResumedMetadata
    | LeadReminderAppointmentInOneDay
    | LeadPaymentMethodMetadata
    | DoctorPhoneCallCompletedMetadata
    | DoctorPhoneCallMissedMetadata
    | ParentLeadPharmacyIssueCreatedMetadata
    | CancelAutoConfirmAppointmentMetadata
    | SuccessAutoConfirmAppointmentMetadata
    | AppointmentAutoAssignedMetadata
    | DoctorHasCompletetTaskMetadata
    | ThirdPartySystemAppointmentMetadata
    | BlackListLeadMarkMetadata
    | RedFlagLeadMarkMetadata
    | AppointmentRatingMetadata
    | IntercomMessageMetadata
    | LabsMetadata
    | null;
