import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, MasterAuthGuard, NotAuthGuard } from 'medvidi';

import { LoginComponent } from './login/login.component';
import { MasterLoginComponent } from './master-login/master-login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
    { path: 'login', canActivate: [NotAuthGuard], component: LoginComponent },
    { path: 'auth/google/callback', canActivate: [NotAuthGuard], component: LoginComponent },
    { path: 'master-login', canActivate: [MasterAuthGuard], component: MasterLoginComponent },
    { path: 'change-password', canActivate: [AuthGuard], component: ChangePasswordComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
