import { ListFilter } from '../../med-list/interfaces';
import {
    AppointmentMethodType,
    IApplication,
    IDoctor,
    ILead,
    ILeadDocuments,
    ILetter,
    IMedicalRecord,
    ISubSymptoms,
    ILabOrder,
} from '../interfaces';

export interface IAppointment {
    id: number;
    leadId: number;
    leadAmocrmId: number;
    firstName: string;
    lastName: string;
    phone: string;
    serviceName: string;
    stateName: string;
    doctorName: string;
    time: Date;
    doctorTimezone: string;
    doctorMomentTimezone: string;
    status: string;
    fitsDoctorSchedule: boolean;
    isInitial: boolean;
    hasInsurance?: boolean;
    appointmentMethod?: AppointmentMethodType;
    startTime: Date;
    endTime: Date;
    isCallSkipped: boolean;
}

export interface IAppointmentFull {
    id: number;
    lead: ILead;
    leadLabOrders: ILabOrder[];
    doctor: IDoctor;
    peerId: string;
    time: Date;
    endTime: Date;
    doctorTimezone: string;
    doctorMomentTimezone: string;
    status: string;
    applicationData: any;
    startTime: Date;
    paymentType: number;
    notes: string;
    needLetter: boolean;
    needResolution: boolean;
    doctorSalary: number;
    hasDoctorDecision: boolean;
    linkToPatientDosespot: string | null;
    linkToLabProfile: string | null;
}

export interface IPublicAppointment {
    id: number;
    peerId: string;
    currentDate: Date;
    scheduledFor: Date;
    clientAppointmentTime: Date;
    startedAt: Date;
    endedAt: Date;
    createdAt: Date;
    updatedAt: Date;
    doctorId?: number;
    firstName?: string;
    lastName?: string;
    serviceName?: string;
    stateName?: string;
    doctorName?: string;
    offline?: boolean;
    applicationData?: any;
    letters?: ILetter[];
    application?: IApplication;
    medicalRecords?: IMedicalRecord[];
    documents?: ILeadDocuments[];
    symptoms?: ISubSymptoms;
    leadId?: number;
}

// TODO удалить после того, как выйдет в стейдж таска где добавляются эти интерфейсы

export interface IPatientCalendarAppointment extends IAppointment {
    selected?: boolean;
    isAppointmentReserved?: boolean;
}

export interface IAppointmentsCount {
    today: number;
    pending: number;
    letters: number;
}

export interface AppointmentListResponse {
    items: IAppointmentFull[];
    total: number;
}

export interface AppointmentListParams {
    filters: ListFilter[];
    page?: number;
    limit?: number;
}

export enum AppointmentListStatuses {
    AWAITING_APPOINTMENT = 'Awaiting appointment',
    AWAITING_DOCTOR = 'Awaiting doctor',
    AWAITING_PATIENT = 'Awaiting patient',
    ON_CALL = 'On call',
    FINISHED = 'Finished',
    APPROVED = 'Approved',
    DENIED = 'Denied',
    AWAITING_LETTER = 'Awaiting letter',
    LETTER_SEND = 'Letter send',
    NO_SHOW = 'No show',
    RESCHEDULED_AWAITING_APPOINTMENT = 'Rescheduled. Awaiting appointment.',
}

export enum AppointmentLogType {
    // -- BEFORE CALL --
    DOCTOR_STARTED_CALL = 'DOCTOR_STARTED_CALL',
    // patient opened appointment page, but hasn't joined yet
    PATIENT_OPENED_APPOINTMENT_PAGE = 'PATIENT_OPENED_APPOINTMENT_PAGE',
    // hit join button
    PATIENT_JOINED_CALL = 'PATIENT_JOINED_CALL',

    // -- AFTER CALL --
    CALL_FINISHED = 'CALL_FINISHED',

    // -- DURING CALL --
    PATIENT_ERROR = 'PATIENT_ERROR',
    DOCTOR_ERROR = 'DOCTOR_ERROR',
    PATIENT_DISCONNECTED = 'PATIENT_DISCONNECTED',
    DOCTOR_DISCONNECTED = 'DOCTOR_DISCONNECTED',
    PATIENT_RECONNECTED = 'PATIENT_RECONNECTED',
    DOCTOR_RECONNECTED = 'DOCTOR_RECONNECTED',

    PATIENT_REPORT_PROBLEM = 'PATIENT_REPORT_PROBLEM',
}

export interface AppointmentLogsResponse {
    items: AppointmentLog[];
    total: number;
}

export interface AppointmentLog {
    id: number;
    type: AppointmentLogType;
    metadata: any;
    createdAt: string;
}

export enum AppointmentResolution {
    NO_ERX = 0,
    ERX_WILL_BE_CREATED_BY_MANAGER = 1,
    ERX_WILL_BE_CREATED_LATER = 2,
    ERX_ALREADY_CREATED = 3,
}

export const enum AppointmentMissedBy {
    DOCTOR = 'doctor',
    PATIENT = 'patient',
}

export interface IFinishAppointmentParams {
    isCallSkipped?: boolean;
    resolutionComment?: string;
    thirdPartySystemComment?: string;
    missedBy?: AppointmentMissedBy;
}

export interface ICompleteAppointmentParams {
    isCallSkipped?: boolean;
}
