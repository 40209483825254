import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    IGetConversationEventsResponse,
    IGetConversationsResponse,
} from '../interfaces/intercom-history';

@Injectable({ providedIn: 'root' })
export class IntercomHistoryService {
    constructor(private http: HttpService) {}

    public async getConversations(contactId: number): Promise<IGetConversationsResponse> {
        return this.http.post('intercom/conversations/list', {
            contactId,
        });
    }

    public async getConversationEvents(
        conversationId: number
    ): Promise<IGetConversationEventsResponse> {
        return this.http.get(`intercom/conversations/${conversationId}/events`);
    }
}
