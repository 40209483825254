import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io } from 'socket.io-client';

import { HttpService } from '../../med-common/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private static socketUrl: string = 'http://localhost:3001';
    //private expiresIn: Moment;
    public socket = null;
    public onReconnect = new Subject();

    constructor(private http: HttpService) {}

    static setSocketUrl(url: string) {
        SocketService.socketUrl = url;
    }

    init(): void {
        this.socket = io(SocketService.socketUrl);

        this.socket.on('connect', () => {
            this.emit('auth', this.getAPIAuthToken());
        });

        this.socket.on('reconnect', async () => {
            console.log('Reconnect socket connection');
            //await this.checkSocketToken();
            this.onReconnect.next(true);
        });
    }

    async joinRoom(params: object): Promise<void> {
        this.initSocketIfNotExists();
        //await this.checkSocketToken();
        this.socket.emit('joinRoom', { token: this.getAPIAuthToken(), ...params });
    }

    leaveRoom(name: string): void {
        this.initSocketIfNotExists();
        this.socket.emit('leaveRoom', name);
    }

    subscribe(name: string, cb: () => void): void {
        this.initSocketIfNotExists();
        this.socket.on(name, cb);
    }

    unsubscribe(name: string, cb: () => void): void {
        this.initSocketIfNotExists();
        this.socket.removeListener(name, cb);
    }

    emit(eventName: string, data) {
        this.initSocketIfNotExists();
        this.socket.emit(eventName, data);
    }

    close(): void {
        this.socket && this.socket.close();
        this.socket = null;
    }

    /*
    private async getToken(): Promise<void> {
        const { token, expiresIn } = await this.http.post(`app/message/socket-login`, {});

        this.token = token;
        this.expiresIn = moment().add(expiresIn, 'seconds');
    }
    */

    initSocketIfNotExists(): void {
        if (this.socket === null) {
            this.init();
        }
    }

    /*
    async checkSocketToken(): Promise<void> {
        if (!this.token || moment().isAfter(this.expiresIn)) {
            await this.getToken();
        }

        // Auth socket-client
        this.emit('auth', this.token);
    }
    */

    private getAPIAuthToken(): string {
        return localStorage.getItem('apiAuthToken');
    }
}
