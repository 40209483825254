<div class="container" *ngIf="loaded">
    <h1>Create dosespot patient</h1>
    <med-form [form]="patientForm"></med-form>
    <div class="custom-error" *ngIf="dosespotValidationError">
        {{ dosespotValidationError }}
    </div>
    <div class="actions-container">
        <button mat-stroked-button (click)="closeDialog()">Cancel</button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="!patientForm.getFormGroup().valid"
            (click)="addPatient()"
            [attr.data-test-id]="'Save patient button'"
        >
            Save
        </button>
    </div>
</div>
<med-loader *ngIf="!loaded"></med-loader>
