import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    IUser,
    IProfileEditParams,
    IProfileChangePasswordParams,
    IProfileChangePasswordRespose,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiProfileService {
    constructor(private http: HttpService) {}

    async edit(params: IProfileEditParams): Promise<IUser> {
        return this.http.patch<IUser>(`profile`, params);
    }

    async changePassword(
        params: IProfileChangePasswordParams
    ): Promise<IProfileChangePasswordRespose> {
        return this.http.patch<IProfileChangePasswordRespose>(`profile/change-password`, params);
    }
}
