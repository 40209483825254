<button
    mat-raised-button
    color="primary"
    class="container"
    [disabled]="disabled"
    [style.width.px]="width"
    (click)="onClick()"
>
    <span *ngIf="loading" class="spinner">
        <mat-spinner [diameter]="20" color="accent"></mat-spinner>
    </span>
    {{ text }}
</button>
