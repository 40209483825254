<div class="container-1">
    <app-sidebar *ngIf="authService.user"></app-sidebar>
    <div
        [class.additionalMenuOpened]="appStorageService.getAdditionalMenuOpen()"
        [class.comboOpened]="appStorageService.getAdditionalMenuOpen()"
        class="container-2"
    >
        <div
            *ngIf="activatedRoute.snapshot.data.displayTasksComponent"
            [ngClass]="{ 'tasks-container-hidden': !taskService.taskBarVisible }"
            class="tasks-container"
        >
            <app-my-tasks-list [attr.data-test-id]="'Lead task list'"></app-my-tasks-list>
        </div>
        <div
            [class.fullHeight]="activatedRoute.snapshot.data.fullHeight"
            class="content scroll-list-container"
        >
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
