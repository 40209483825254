import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { MaterialModule } from '../material.module';
import { MedCommonModule } from '../med-common/med-common.module';
import { MedFormModule } from '../med-form/med-form.module';
import { LetterGenerationComponent } from './components/letter-generation/letter-generation.component';
import { IntakeComponent } from './components/intake/intake.component';
import { MedicalRecordsComponent } from './components/medical-records/medical-records.component';

@NgModule({
    declarations: [LetterGenerationComponent, IntakeComponent, MedicalRecordsComponent],
    imports: [
        CommonModule,
        MedCommonModule,
        MedFormModule,
        MaterialModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        PdfViewerModule,
    ],
})
export class MedLettersModule {}

export { LetterGenerationComponent } from './components/letter-generation/letter-generation.component';
export { IntakeComponent } from './components/intake/intake.component';
export { MedicalRecordsComponent } from './components/medical-records/medical-records.component';
export * from './interfaces';
