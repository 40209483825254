import { Directive, HostListener } from '@angular/core';
import { SnackbarService } from '@shared/components';
import { PhoneClientService } from '../services/phone-client.service';

@Directive({ selector: '[twilioActiveChecking]', standalone: true })
export class TwilioActiveBeforeUnloadDirective {
    constructor(private twilioPhoneClient: PhoneClientService, private snackbarService: SnackbarService) {}

    @HostListener('window:beforeunload', ['$event'])
    public unloadTwilioNotification($event: WindowEventMap['beforeunload']): any {
        const isIncomingCallsActive =
            this.twilioPhoneClient.isActive && this.twilioPhoneClient.isReceiveCallsActive;

        if (isIncomingCallsActive) {
            const userMessage = `«Incoming Calls» feature is active on the current tab.
            Please disable it before closing the page or finish your working day to avoid missed calls.`;

            this.snackbarService.showSnackInfo({ message: userMessage });
            return ($event.returnValue = true);
        }
    }
}
