import { FormFieldParams } from '../interfaces/form-field-params';
import { Validators, ValidatorFn } from '@angular/forms';

export class FormField {
    controlName: string;
    icon: string;
    actionIcon: string;
    label: string;
    value: any;
    validators: ValidatorFn[];
    min: string | number | Date;
    max: string | number | Date;
    disabled: boolean;
    startView: string;
    format: string;

    constructor(params: FormFieldParams) {
        this.controlName = params.controlName;
        this.icon = params.icon;
        this.actionIcon = params.actionIcon;
        this.label = params.label;
        this.value = params.value;
        this.validators = [];
        this.min = params.min;
        this.max = params.max;
        this.disabled = params.disabled || false;
        this.startView = params.startView;
        this.format = params.format;
        if (params.validators) {
            for (const validatorType of params.validators) {
                if (typeof validatorType === 'function') {
                    this.validators.push(validatorType);
                } else if (typeof validatorType === 'string') {
                    this.validators.push(Validators[validatorType]);
                } else {
                    this.validators.push(Validators[validatorType.type](validatorType.params));
                }
            }
        }
    }

    public setProperty(name: string, value: any): void {
        this[name] = value;
    }

    public getProperty(name: string): any {
        return this[name];
    }
}
