import { FormField } from '../form-field';
import { FormInputParams } from '../../interfaces/form-input-params';

export class FormInputField extends FormField {
    type: string;
    mask: string;
    prefix: string;
    patterns: any;
    dropMaskSymbols: boolean;

    constructor(params: FormInputParams) {
        super(params);
        this.type = params.type;
        this.mask = params.mask;
        this.prefix = params.prefix;
        this.patterns = params.patterns;
        this.dropMaskSymbols = params.dropMaskSymbols ?? true;
    }
}
