import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole, SystemType } from 'medvidi';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent {
    public readonly availableRoles = [
        UserRole.ADMIN,
        UserRole.MANAGER,
        UserRole.SUPERVISOR,
        UserRole.SYSTEM_ADMIN,
    ];
    public readonly googleRedirectUri: string = new URL('auth/google/callback', location.origin)
        .href;
    public readonly systemType: SystemType = SystemType.CRM;

    constructor(private router: Router) {}

    public onLogged() {
        this.router.navigate(['/dashboard']);
    }
}
