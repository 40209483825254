import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    IScheduleConfig,
    IScheduleGetParams,
    IScheduleUpdateParams,
    IScheduleUpdateResponse,
    IScheduleListParams,
    IScheduleListResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiScheduleService {
    constructor(private http: HttpService) {}

    getMySchedule(params: IScheduleGetParams): Promise<Record<string, IScheduleConfig[]>> {
        return this.http.post<Record<string, IScheduleConfig[]>>(`schedule/list/doctor/my`, params);
    }

    getScheduleByDoctorId(
        id: number,
        params: IScheduleGetParams
    ): Promise<Record<string, IScheduleConfig[]>> {
        return this.http.post<Record<string, IScheduleConfig[]>>(
            `schedule/list/doctor/${id}`,
            params
        );
    }

    updateMySchedule(params: IScheduleUpdateParams): Promise<IScheduleUpdateResponse> {
        return this.http.post<IScheduleUpdateResponse>(`schedule/edit/doctor/my`, params);
    }

    updateScheduleByDoctorId(
        id: number,
        params: IScheduleUpdateParams
    ): Promise<IScheduleUpdateResponse> {
        return this.http.post<IScheduleUpdateResponse>(`schedule/edit/doctor/${id}`, params);
    }

    list(params: IScheduleListParams): Promise<IScheduleListResponse> {
        return this.http.post<IScheduleListResponse>(`crm/schedule-table`, params);
    }
}
