import { UserRole } from 'medvidi';

interface ContentLink {
    label: string;
    icon: string;
    path: string;
    roles: string[];
    disabled?: boolean;
}

interface ContentSection {
    label: string;
    children: ContentLink[];
}

export const crmContent: ContentSection[] = [
    {
        label: 'Main',
        children: [
            {
                label: 'Leads',
                icon: 'attach_money',
                path: '/lead/list',
                roles: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.MANAGER],
            },
            {
                label: 'Tasks dashboard',
                icon: 'dashboard',
                path: '/task/dashboard',
                roles: [UserRole.ADMIN, UserRole.SUPERVISOR, UserRole.MANAGER],
            },
            {
                label: 'Tasks list',
                icon: 'assignment',
                path: '/task/list',
                roles: [UserRole.ADMIN],
            },
            {
                label: 'User Activities',
                icon: 'dashboard',
                path: '/user/dashboard',
                roles: [UserRole.ADMIN, UserRole.SUPERVISOR],
            },
        ],
    },
    {
        label: 'Messages',
        children: [
            {
                label: 'Calls',
                icon: 'call',
                path: '/call/list',
                roles: [UserRole.ADMIN],
            },
        ],
    },
];
