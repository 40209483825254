import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { PhoneClientService } from '../../../../services/phone-client.service';
import { CommonDataService, IServiceAccountChannel, ServiceAccountTwilioAccount } from 'medvidi';

@Component({
    selector: 'app-dialpad',
    templateUrl: './dialpad.component.html',
    styleUrls: ['./dialpad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialpadComponent implements AfterViewInit {
    @Input() dialPadControl = new FormControl('');

    @ViewChild('dialpadInput') dialpadInput: ElementRef;

    constructor(
        private phoneClientService: PhoneClientService,
        private commonDataService: CommonDataService
    ) {}

    ngAfterViewInit() {
        this.dialpadInput.nativeElement?.focus();
    }

    public deleteLastSymbolFromNumber(): void {
        const value = this.dialPadControl.value;
        this.dialPadControl.setValue(value.slice(0, -1));
    }

    public async onMakeCallClicked() {
        if (!this.dialPadControl.value) return;

        const to = '+1' + this.dialPadControl.value;

        const from = this.commonDataService.serviceAccounts.find(
            ({ website, channel, twilioAccount }) =>
                website?.code === 'ezcareclinic' &&
                channel === IServiceAccountChannel.PHONE &&
                twilioAccount === ServiceAccountTwilioAccount.MAIN
        )?.code;

        await this.phoneClientService.makeCall(to, from);
    }
}
