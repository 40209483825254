<mat-form-field appearance="outline" [formGroup]="formGroup" class="form-field multi-input-field">
    <mat-label>{{ field.label }}</mat-label>
    <mat-chip-list #stringsList aria-label="Services selection">
        <mat-chip
            *ngFor="let string of field.strings"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(string)"
        >
            {{ string }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
            matInput
            formControlName="{{ field.controlName }}"
            [matChipInputFor]="stringsList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
    <mat-icon matSuffix class="icon" fontSet="material-icons-outlined">{{ field.icon }}</mat-icon>
    <mat-error *ngIf="formGroup.get(field.controlName)?.invalid">{{
        errorTextService.getMessage(formGroup.get(field.controlName))
    }}</mat-error>
</mat-form-field>
