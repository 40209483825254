import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'medvidi';
import { DashboardComponent } from './crm/dashboard/dashboard.component';
import { ProfileComponent } from './crm/profile/profile.component';
import { CrmPageTemplateComponent } from './elements/crm-page-template/crm-page-template.component';
import { PageNotFoundComponent } from './errors/page-not-found/page-not-found.component';

const DEFAULT_ROUTE_DATA = {
    displayTasksComponent: true,
    fullHeight: true,
};

const WITHOUT_TASKS_ROUTE_DATA = {
    displayTasksComponent: false,
    fullHeight: false,
};

const WITH_TASKS_ROUTE_DATA = {
    displayTasksComponent: true,
    fullHeight: false,
};

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: CrmPageTemplateComponent,
        children: [{ path: '', component: DashboardComponent }],
        data: WITHOUT_TASKS_ROUTE_DATA,
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        component: CrmPageTemplateComponent,
        children: [{ path: '', component: ProfileComponent }],
        data: WITHOUT_TASKS_ROUTE_DATA,
    },
    {
        path: '',
        pathMatch: 'prefix',
        component: CrmPageTemplateComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'call',
                loadChildren: () => import('./crm/call/call.module'),
                data: WITH_TASKS_ROUTE_DATA,
            },
            {
                path: 'lead',
                loadChildren: () => import('./crm/lead/lead.module'),
                data: WITH_TASKS_ROUTE_DATA,
            },
            {
                path: 'task',
                loadChildren: () => import('./crm/task/task.module'),
                data: DEFAULT_ROUTE_DATA,
            },
            {
                path: 'calendar',
                loadChildren: () => import('./crm/calendar/calendar.module'),
                data: DEFAULT_ROUTE_DATA,
            },
            {
                path: 'e-fax',
                loadChildren: () => import('./crm/e-fax/e-fax.module'),
                data: WITH_TASKS_ROUTE_DATA,
            },
            {
                path: 'activity',
                loadChildren: () => import('./crm/manager-activity/manager-activity.module'),
                data: WITH_TASKS_ROUTE_DATA,
            },
            {
                path: 'salary',
                loadChildren: () => import('./crm/salary/salary.module'),
                data: DEFAULT_ROUTE_DATA,
            },
            {
                path: 'appointments',
                loadChildren: () => import('./crm/appointments/appointment-list.module'),
                data: WITH_TASKS_ROUTE_DATA,
            },
            {
                path: 'letter',
                loadChildren: () => import('./crm/letter/letter.module'),
                data: DEFAULT_ROUTE_DATA,
            },
            {
                path: 'refunds',
                loadChildren: () => import('./crm/extended-refund/extended-refund.module'),
                data: DEFAULT_ROUTE_DATA,
            },
            {
                path: 'financial-reports',
                loadChildren: () => import('./crm/financial-reports/financial-reports.module'),
                data: DEFAULT_ROUTE_DATA,
            },
            {
                path: 'online-monitor',
                loadChildren: () => import('./crm/online-monitor/online-monitor.module'),
                data: WITHOUT_TASKS_ROUTE_DATA,
            },
        ],
        data: DEFAULT_ROUTE_DATA,
    },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
