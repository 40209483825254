import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ILead, IPublicAppointment } from 'medvidi';
import * as moment from 'moment';

const MILLS_IN_HALF_DAY: number = 12 * 60 * 60 * 1000;

@Component({
    selector: 'app-schedule-appointment-calendar',
    templateUrl: './schedule-appointment-calendar.component.html',
    styleUrls: ['./schedule-appointment-calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleAppointmentCalendarComponent implements OnChanges {
    @Input() lead: ILead;
    @Input() appointment?: IPublicAppointment;
    @Input() doctorId?: number;
    @Input() selectedDate: Date;
    @Input() momentTimezone: string;

    @Output() updateDate = new EventEmitter();

    @ViewChild('calendar') calendar: MatCalendar<Date>;

    public disablePrevDates: (date: Date | null) => boolean;

    public get isTimeRequested(): boolean {
        return this.clientRequestedTime && !this.appointment?.doctorId;
    }

    public get isTimeConfirmed(): boolean {
        return !!this.appointment?.scheduledFor && !!this.appointment?.doctorId;
    }

    public get clientRequestedTime(): Date {
        return this.lead?.clientAppointmentTime;
    }

    public get confirmedTime(): Date {
        return new Date(this.appointment?.scheduledFor);
    }

    ngOnChanges(): void {
        if (this.selectedDate) this.updateCalendarDate();
        this.setDisabledDates();
    }

    public handleDateChange(date: Date): void {
        const selectDate = new Date(date.getTime() + MILLS_IN_HALF_DAY);
        this.updateDate.emit(selectDate);
    }

    private updateCalendarDate(): void {
        this.calendar._goToDateInView(this.selectedDate, 'month');
        this.calendar.updateTodaysDate();
    }

    private setDisabledDates(): void {
        this.disablePrevDates = (date: Date | null) => {
            const formattedDate = moment(date).tz(this.momentTimezone, true).utc();
            const today = moment().tz(this.momentTimezone).utc();

            return formattedDate.isAfter(today) || formattedDate.isSame(today, 'day');
        };
    }
}
