import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { SnackbarService } from '@shared/components';
import {
    ApiCrmAppointmentService,
    ApiLeadService,
    AuthService,
    IAppointmentSchedule,
    ICrmAppointmentGetByLeadIdResponse,
    IDoctor,
    ILead,
    IPublicAppointment,
    ServicePlanType,
    TIMEZONES,
    UserRole,
} from 'medvidi';
import * as moment from 'moment-timezone';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DEFAULT_TIMEZONE } from '../../shared/constants';

export interface ITime {
    hours: number;
    minutes: number;
}

interface IDialogData {
    doctorIds: number[];
    isRescheduleMode: boolean;
    time: string;
    lead: ILead;
}

interface DoctorScheduleInterface {
    doctor: IDoctor;
    slots: string[];
}

@Component({
    selector: 'app-appointment-confirmation',
    templateUrl: './appointment-confirmation.component.html',
    styleUrls: ['./appointment-confirmation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentConfirmationComponent implements OnInit, OnDestroy {
    @Output() confirmAppointment = new EventEmitter();

    public loaded = false;

    public searchDoctorId?: number;
    public selectedDoctorId?: number;

    public lead: ILead;
    public appointment: IPublicAppointment;
    public schedule: DoctorScheduleInterface[] = [];

    public dateForCalendar: Date;

    public displayedDate: Date;
    public selectedDate: Date;
    public momentTimezone: string;

    public selectedTime: ITime = null;

    private destroy$ = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private cdr: ChangeDetectorRef,
        private apiLeadService: ApiLeadService,
        private apiCrmAppointmentService: ApiCrmAppointmentService,
        private appSnackBar: SnackbarService,
        public dialogRef: MatDialogRef<AppointmentConfirmationComponent>,
        public authService: AuthService
    ) {}

    public get servicePlanType(): string {
        return this.lead.servicePlan?.type === ServicePlanType.INITIAL ? 'Initial' : 'Follow-Up';
    }

    public get leadTimezone(): string {
        return this.lead.state?.timezone || DEFAULT_TIMEZONE;
    }

    public get clientRequestedTime(): Date {
        return this.lead?.clientAppointmentTime;
    }

    public get confirmedTime(): string {
        return this.appointment ? String(this.appointment.scheduledFor) : '';
    }

    public get isChangeButtonDisabled(): boolean {
        return !this.selectedDoctorId || !this.selectedTime || !this.selectedDate;
    }

    public get isConfirmedDateOpened(): boolean {
        return (
            this.confirmAppointment &&
            moment(this.dateForCalendar)
                .tz(this.momentTimezone, true)
                .isSame(moment(this.confirmedTime).tz(this.momentTimezone), 'days')
        );
    }

    ngOnInit(): void {
        this.initDefaultState();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initDefaultState(): void {
        this.lead = this.data.lead;
        this.setMomentTimezone(this.data.lead.state?.timezone || DEFAULT_TIMEZONE);

        this.loadAppointment()
            .pipe(
                tap(() => this.setDefaults()),
                switchMap(() => this.loadSchedule())
            )
            .subscribe();
    }

    private setDefaults(): void {
        const confirmedTimeIsValid =
            this.confirmedTime && moment(this.confirmedTime).isAfter(moment(new Date()));

        this.selectedDoctorId = this.appointment?.doctorId;
        this.selectedDate = confirmedTimeIsValid ? new Date(this.confirmedTime) : new Date();
        this.displayedDate = moment(this.confirmedTime || this.clientRequestedTime || new Date())
            .tz(this.momentTimezone)
            .toDate();

        // need in case if manager and patient have different days (11/15/2023 -- 11/14/2023)
        const selectedMoment = confirmedTimeIsValid ? moment(this.confirmedTime) : moment();
        this.dateForCalendar = new Date(
            selectedMoment
                .tz(this.momentTimezone)
                .set({ hours: 12, minutes: 0 })
                .format('YYYY-MM-DD HH:mm:ss')
        );

        if (this.confirmedTime) this.setConfirmedTime();
        this.dateForCalendar.setDate(selectedMoment.tz(this.momentTimezone).date());
    }

    public handleDoctorSearchChange(doctorId?: number): void {
        this.searchDoctorId = doctorId;
        this.loadSchedule().subscribe();
    }

    public handleDateChange(date: Date): void {
        this.selectedTime = null;

        this.schedule = [];

        this.dateForCalendar = date;
        this.selectedDate = date;

        if (this.isConfirmedDateOpened) this.setConfirmedTime();

        this.loadSchedule().subscribe();
    }

    public handleTimeChange({ doctorId, time }: { doctorId: number; time: ITime }): void {
        this.selectedDoctorId = doctorId;
        this.selectedTime = time;
    }

    private setConfirmedTime(): void {
        const hours = moment(this.confirmedTime).tz(this.momentTimezone).hours();
        const minutes = moment(this.confirmedTime).tz(this.momentTimezone).minutes();

        this.dateForCalendar.setHours(hours);
        this.dateForCalendar.setMinutes(minutes);
    }

    public sendConfirmRequest(): void {
        this.loaded = false;

        const selectedTimeMoment = moment(this.selectedTime);

        const date = moment(this.dateForCalendar)
            .tz(this.momentTimezone, true)
            .set({
                hours: selectedTimeMoment.hours(),
                minutes: selectedTimeMoment.minutes(),
            })
            .toLocaleString();

        from(
            this.apiCrmAppointmentService.confirmAppointment({
                leadId: this.lead.id,
                doctorId: this.selectedDoctorId,
                date,
            })
        )
            .pipe(
                takeUntil(this.destroy$),
                tap(() => {
                    this.confirmAppointment.emit();
                    this.dialogRef.close();
                }),
                finalize(() => this.finalizePipe()),
                catchError((err) => {
                    console.warn('Appointment confirmation error: ', err);

                    if (Array.isArray(err.errors)) {
                        err.errors.forEach((error) =>
                            this.appSnackBar.showSnackError({ message: error.message })
                        );
                    }

                    return of(null);
                })
            )
            .subscribe();
    }

    private loadSchedule(): Observable<IAppointmentSchedule[]> {
        this.loaded = false;

        const date = moment(this.dateForCalendar)
            .tz(this.momentTimezone, true)
            .set({ hours: 12, minutes: 0 })
            .toLocaleString();

        return from(
            this.apiLeadService.getDoctorsSchedule(this.lead.id, this.searchDoctorId, date)
        ).pipe(
            takeUntil(this.destroy$),
            tap(this.setLoadedSchedule),
            finalize(() => this.finalizePipe()),
            catchError((err) => {
                console.warn('[APPOINTMENT CONFIRMATION] - load schedule: ', err.message);
                return of(null);
            })
        );
    }

    private setLoadedSchedule = (res: IAppointmentSchedule[]) => {
        const isManager = this.authService.user.role === UserRole.MANAGER;
        const currentManagerId = this.authService.user.managerId;

        if (isManager && currentManagerId) {
            // sort by personal assistance
            res = res.sort((a, b) => {
                const isAssistantA = a.doctor.managerIds?.includes(currentManagerId);
                const isAssistantB = b.doctor.managerIds?.includes(currentManagerId);
                if (isAssistantA && !isAssistantB) return -1;
                if (!isAssistantA && isAssistantB) return 1;
                return 0;
            });
        }

        this.schedule = res;
    };

    private loadAppointment(): Observable<ICrmAppointmentGetByLeadIdResponse> {
        return from(this.apiCrmAppointmentService.getByLeadId(this.lead.id)).pipe(
            takeUntil(this.destroy$),
            tap((res) => {
                this.appointment = res.appointment || null;
            }),
            catchError((err) => {
                console.warn('[APPOINTMENT CONFIRMATION] - load appointment: ', err.message);
                return of(null);
            })
        );
    }

    private setMomentTimezone(clientTimezone: string): void {
        this.momentTimezone = TIMEZONES[clientTimezone];
    }

    private finalizePipe(): void {
        this.loaded = true;
        this.cdr.markForCheck();
    }
}
