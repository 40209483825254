<mat-form-field appearance="outline" [formGroup]="formGroup" class="form-field">
    <mat-label>{{ field.label }}</mat-label>
    <mat-select
        formControlName="{{ field.controlName }}"
        panelClass="custom-select-panel"
        [multiple]="field.multiple"
        [attr.data-test-id]="field.label + ' select'"
    >
        <mat-option *ngIf="field.withFilter">
            <ngx-mat-select-search
                [formControl]="filterCtrl"
                placeholderLabel="Find..."
                noEntriesFoundLabel="No options found"
            ></ngx-mat-select-search>
        </mat-option>

        <mat-select-trigger *ngIf="field.multiple">
            {{ findTextValue() }}
            <span
                class="example-additional-selection"
                *ngIf="formGroup.controls[field.controlName].value?.length > 1"
            >
                (+{{ formGroup.controls[field.controlName].value.length - 1 }} more)
            </span>
        </mat-select-trigger>

        <mat-option *ngIf="field.nullable && !field.multiple"></mat-option>
        <mat-option
            #selectAll
            *ngIf="field.allOption"
            [value]="-1"
            [attr.data-test-id]="-1"
            (click)="handleAllOptionClick()"
            >All
        </mat-option>
        <mat-option
            *ngFor="let op of options"
            [value]="op.value !== undefined ? op.value : op"
            [attr.data-test-id]="op.value !== undefined ? op.value : op"
            (click)="handleOptionClick()"
            [title]="field.hint ? op.text : ''"
            >{{ op.text || op }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="formGroup.get(field.controlName)?.invalid"
        >{{ errorTextService.getMessage(formGroup.get(field.controlName)) }}
    </mat-error>
</mat-form-field>
