<div *ngIf="appStateService.isAppLoading()" class="css-loader-main">
    <div class="css-loader-wrapper">
        <div class="css-loader"></div>
    </div>
</div>

<router-outlet *ngIf="appStateService.isAppDefault()" twilioActiveChecking></router-outlet>

<app-something-broken *ngIf="appStateService.isAppBroken()"></app-something-broken>
<app-session-expired *ngIf="appStateService.isAppNeedAuth()"></app-session-expired>
<app-page-forbidden *ngIf="appStateService.isAppForbidden()"></app-page-forbidden>
