<div
    (click)="onMouseClick($event)"
    [class.completed]="completed"
    [class.disabled]="disabled"
    [matTooltip]="tooltip"
    class="icon-button"
    matTooltipClass="tooltip-custom-font-lg"
>
    <mat-icon class="icon-button__icon">{{ matIconRef }}</mat-icon>
</div>
