<div
    (mouseenter)="onMouseenter()"
    (mouseleave)="onMouseLeave()"
    [ngClass]="{ disabled: !!disabled }"
    class="container"
>
    <div (click)="showOptions = !showOptions" class="info">
        <p *ngIf="field" [class.fullWidth]="fieldFullWidth" class="field">{{ field }}:</p>
        <p *ngIf="!selected.length" class="placeholder">{{ placeholder }}</p>
        <p *ngIf="!multi && selected.length" class="selected-text">{{ selected[0]?.text }}</p>
        <mat-chip-list (click)="showOptions = !showOptions" *ngIf="multi && selected.length">
            <mat-chip (click)="showOptions = !showOptions">
                {{ selected[0].text }}
            </mat-chip>
            <mat-chip (click)="showOptions = !showOptions" *ngIf="selected.length > 1">
                +{{ selected.length - 1 }}
            </mat-chip>
        </mat-chip-list>
        <div *ngIf="!multi" [class.on]="showOptions" class="arrow">
            <svg
                fill="none"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity="0.3">
                    <path
                        d="M14 5L8 11L2 5"
                        stroke="#081D33"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                    />
                </g>
            </svg>
        </div>
    </div>
    <div *ngIf="showOptions" class="list-block" [ngClass]="listPosition">
        <div *ngFor="let group of groups" class="group-block" [attr.data-test-id]="'select group ' + group.name">
            <p *ngIf="group.name" class="group-name">{{ group.name }}</p>
            <div
                (click)="choiseOption(option)"
                *ngFor="let option of group.options"
                [class.select]="isSelectOption(option)"
                [attr.data-test-id]="'select option ' + option.value"
                class="option"
            >
                <img *ngIf="option.iconPath" [src]="option.iconPath" class="option-icon" />
                <span>{{ option.text }}</span>
            </div>
        </div>
    </div>
</div>
