<mat-form-field appearance="outline" class="form-field">
    <mat-label>{{ field.label }}</mat-label>
    <input
        [matAutocomplete]="auto"
        [formControl]="formControl"
        type="text"
        matInput
        (change)="setValue()"
        [attr.data-test-id]="field.controlName + 'autocomplete input'"
    />
    <mat-autocomplete [displayWith]="displayFunction" #auto="matAutocomplete">
        <mat-option *ngIf="isLoading">
            <mat-spinner diameter="20"></mat-spinner>
        </mat-option>
        <mat-option *ngFor="let option of filteredOptions" [value]="option" [title]="option.text">
            {{ option.text }}
        </mat-option>
    </mat-autocomplete>
    <mat-error>{{ errorTextService.getMessage(formControl) }}</mat-error>
</mat-form-field>
