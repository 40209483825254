import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { ApiLeadService, ICrmLead } from 'medvidi';
import { from, Subject, Subscription } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { PhoneClientService } from '../../../../services/phone-client.service';
import { CallTypes, IVRTreeReadable } from '../../../../twilio-voice/twilio-voice.interfaces';

@Component({
    selector: 'app-call-panel',
    templateUrl: './call-panel.component.html',
    styleUrls: ['./call-panel.component.scss'],
})
export class CallPanelComponent implements OnChanges, OnDestroy {
    lead: ICrmLead = null;
    @Input() data: any;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    public isCallResumedInProgress = false;
    public isCallOnHoldInProgress = false;

    extensionNumbersValue: string = '';

    private savedLeadByNumber: string = null;
    public callTypes = CallTypes;
    private destroy$ = new Subject<void>();
    private getLeadByPhoneSubscription: Subscription = null;
    public isLeadLoading: boolean = false;

    public get ivrTree(): string | null {
        if (!this.data?.reservation?.task?.attributes) return null;

        const { level, goal } = this.data?.reservation?.task?.attributes;
        if (!level || !goal) return null;

        const levelOptions = IVRTreeReadable[level];

        return (levelOptions && levelOptions[goal]) || null;
    }

    constructor(
        public phoneClientService: PhoneClientService,
        private apiLeadService: ApiLeadService,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private router: Router
    ) {}

    ngOnChanges(): void {
        // при измении входящей даты ставим в фолс все ожидания
        this.isCallResumedInProgress = false;
        this.isCallOnHoldInProgress = false;

        console.log('[app-call-panel] data => ', this.data);
        this.loadLead();
    }

    public onCallOnHold(): void {
        if (!this.data.onHold) return;

        this.isCallOnHoldInProgress = true;
        this.data.onHold();
    }

    public onCallResume(): void {
        if (!this.data.onContinue) return;

        this.isCallResumedInProgress = true;
        this.data.onContinue();
    }

    private loadLead() {
        if (!this.data?.phone) {
            this.savedLeadByNumber = null;
            this.lead = null;
            return;
        }

        if (this.savedLeadByNumber !== this.data.phone) {
            this.getLeadByPhoneSubscription?.unsubscribe();
            this.isLeadLoading = true;
            this.lead = null;
            this.cdr.detectChanges();
            console.log('[app-call-panel] search for a lead by number => ', this.data.phone);

            this.getLeadByPhoneSubscription = from(this.apiLeadService.getByPhone(this.data.phone))
                .pipe(
                    takeUntil(this.destroy$),
                    finalize(() => {
                        this.isLeadLoading = false;
                        this.getNewExtensionNumbers();
                        this.cdr.markForCheck();
                    })
                )
                .subscribe(
                    (res) => {
                        this.savedLeadByNumber = this.data.phone;
                        this.lead = res.lead ? res.lead : null;
                        console.log('[app-call-panel] found lead => ', this.lead);
                    },
                    (error) => {
                        this.savedLeadByNumber = null;
                        this.lead = null;
                        console.warn('[ERROR][app-call-panel] found lead => ', error);
                    }
                );
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getFullName(): string {
        return this.lead.fullName || 'New Lead';
    }

    public acceptCall(): void {
        this.data.onAccept();
        if (this.lead) {
            this.router.navigate([`/lead/view/${this.lead.id}`]);
        } else {
            this.router.navigate([
                '/lead/new',
                {
                    queryParams: {
                        phone: this.data.phone,
                    },
                },
            ]);
        }
    }

    public onHangUp(): void {
        this.data.onHangUp();
    }

    private getNewExtensionNumbers(): void {
        this.phoneClientService
            .sendDigitsListener$()
            .pipe(
                takeUntil(this.destroy$),
                tap((character) => {
                    this.extensionNumbersValue = this.extensionNumbersValue.concat(` ${character}`);
                })
            )
            .subscribe();
    }
}
