import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
    @Input() matIconRef = '';
    @Input() disabled = false;
    @Input() completed = false;
    @Input() tooltip = '';
    @Output() onClick = new EventEmitter<MouseEvent>();

    public onMouseClick($event: MouseEvent): void {
        if (this.disabled) return;
        this.onClick.emit($event);
    }
}
