<div class="container" *ngIf="loaded">
    <div class="header">
        <h1>Connect patient</h1>
    </div>
    <div class="body">
        <div class="patients-header-container">
            <div class="patients-header-data-container">
                <div class="table-column">Patient</div>
                <div class="table-column">Phone</div>
                <div class="table-column">Date of Birth</div>
            </div>
        </div>
        <div class="table-row" *ngFor="let patient of dosespotPatients">
            <div class="patient-data-container">
                <div class="table-column">{{ patient.firstName }} {{ patient.lastName }}</div>
                <div class="table-column">
                    {{ patient.phone }}
                </div>
                <div class="table-column">
                    {{ patient.dob | date: 'MM-dd-yyyy' }}
                </div>
            </div>
            <div class="patient-actions-container">
                <button
                    mat-flat-button
                    color="primary"
                    class="connect-patient-button"
                    (click)="connectPatient(patient)"
                >
                    Select
                </button>
            </div>
        </div>
    </div>
    <div class="actions-container">
        <button mat-stroked-button (click)="closeDialog()">Cancel</button>
        <button mat-flat-button color="primary" (click)="openPatientCreationDialog()">
            Create new
        </button>
    </div>
</div>
<med-loader *ngIf="!loaded"></med-loader>
