import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    ManagerActivityListParams,
    ManagerActivityListResponse,
    ManagerStatusesResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiManagerActivityService {
    constructor(private http: HttpService) {}

    async list(params: ManagerActivityListParams): Promise<ManagerActivityListResponse> {
        return this.http.post<ManagerActivityListResponse>(`activity/list`, params);
    }

    async getManagerStatuses(): Promise<ManagerStatusesResponse> {
        return this.http.get<ManagerStatusesResponse>('activity/manager-statuses');
    }
}
