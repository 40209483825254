export enum LeadDocumentType {
    INTAKE = 'INTAKE',
    PDMP = 'PDMP',
    PHARMACY_ISSUE = 'PHARMACY_ISSUE',
    CL_LETTER = 'CL_LETTER',
    MEDICAL_RECORDS = 'MEDICAL_RECORDS',
    RESIDENCY_PROOFS = 'RESIDENCY_PROOFS',
    ID = 'ID',
    TC_FORM = 'TC_FORM',
    DISCHARGE_LETTER = 'DISCHARGE_LETTER',
    BY_PATIENT = 'BY_PATIENT'
}

export const LEAD_DOCUMENT_TYPE_OPTIONS = [
    { value: LeadDocumentType.PDMP, text: 'Pdmp reports' },
    { value: LeadDocumentType.CL_LETTER, text: 'CL letters' },
    { value: LeadDocumentType.INTAKE, text: 'Manual intake forms' },
    {
        value: LeadDocumentType.MEDICAL_RECORDS,
        text: 'Any specific document that provider`s might need for the appointment such as past medical records from other providers',
    },
    {
        value: LeadDocumentType.RESIDENCY_PROOFS,
        text: 'Residency proofs such as electricity bills',
    },
    { value: LeadDocumentType.ID, text: 'IDs that patients sent to us through emails' },
    { value: LeadDocumentType.TC_FORM, text: 'T&C form for patients who filled disputes' },
    {
        value: LeadDocumentType.DISCHARGE_LETTER,
        text: 'Discharge letter for patients who have been discharged from the clinic',
    },
];

export interface IUploadFilesParams {
    leadId: number;
    leadDocumentType: LeadDocumentType;
    files: File | File[];
    createdByDoctor?: boolean;
    taskId?: number;
    isAllowedToPatient?: boolean
}

export interface ILeadDocumentsParams {
    leadId: number;
}

export interface ILeadDocumentsResponse {
    items: ILeadDocuments[];
    total?: number;
}

export interface ILeadDocuments {
    id: number;
    type: LeadDocumentType;
    fileName: string;
    fileSize?: number;
    url: {
        medvidi: string;
        dctrApp: string;
    };
    createdByDoctor: boolean;
    author?: {
        id: number;
        fullName: string;
    };
    createdAt: Date;
    updatedAt: Date;
}
