<div class="audio-settings">
    <ng-container *ngIf="!isPermissionsPending && !isComponentPending">
        <!--  Input Device Select  -->
        <mat-form-field class="input-device">
            <mat-label>Input Device</mat-label>
            <mat-select [formControl]="inputDeviceControl">
                <mat-option *ngFor="let device of availableInputDevices" [value]="device.value">
                    {{ device.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!--  Micro Volume  -->
        <div class="micro-volume">
            <div class="description" *ngIf="!inputDeviceControl.value">
                Select input device to check volume
            </div>
            <div class="description" *ngIf="inputDeviceControl.value">Volume of input device</div>
            <mat-progress-bar
                *ngIf="inputDeviceControl.value"
                mode="determinate"
                [value]="inputVolume$ | async"
            ></mat-progress-bar>
        </div>

        <!--  Output Device Select  -->
        <mat-form-field class="output-device">
            <mat-label>Output Device</mat-label>
            <mat-select [formControl]="outputDeviceControl">
                <mat-option *ngFor="let device of availableOutputDevices" [value]="device.value">
                    {{ device.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!--  Test sound -->
        <div class="test-sound" (click)="testCurrentSpeaker()">
            <mat-icon>play_arrow</mat-icon>
            Play test sound
        </div>
    </ng-container>

    <div class="placeholder" *ngIf="isPermissionsPending && !isComponentPending">
        You must give audio permissions to have access to this feature.
    </div>

    <div class="placeholder" *ngIf="isComponentPending">
        <app-content-loader [size]="20"></app-content-loader>
    </div>
</div>
