import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule, MaterialModule],
    exports: [],
})
export class MedTaskModule {}

export * from './classes';
export * from './services';
