import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService, SocketService, TaskService } from 'medvidi';
import { Task } from 'medvidi';
import { from, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PhoneClientDialogComponent } from '../../../elements/phone-client-dialog/phone-client-dialog.component';

import { PhoneClientService } from '../../../services/phone-client.service';
import { WorkControlService } from 'projects/crm/src/app/services/work-control.service';
import { Platform } from '@angular/cdk/platform';

@Component({
    selector: 'app-my-tasks-list',
    templateUrl: './my-tasks-list.component.html',
    styleUrls: ['./my-tasks-list.component.scss'],
})
export class MyTasksListComponent implements OnInit, OnDestroy {
    loaded = true;

    public isRefreshLoading = false;

    isTodoTaskCollapsed = false;
    isInProgressTaskCollapsed = false;
    isOnHoldTaskCollapsed = false;
    isCompletedTaskCollapsed = true;

    showDialpad: boolean = false;
    showAudioSettings: boolean = false;

    public isDayStarted$ = this.workControlService.isDayStarted$;
    public isWorkSwitchLoading$ = this.workControlService.isWorkSwitchLoading$;
    public isManager = this.workControlService.isManager;

    private destroy$ = new Subject<void>();

    public get isDialPadVisible(): boolean {
        return (
            this.showDialpad &&
            !this.phoneClientService.callPanelData &&
            this.phoneClientService.isActive
        );
    }

    public get isSettingsButtonVisible(): boolean {
        return !this.platform.SAFARI && !this.platform.FIREFOX;
    }

    public get isDisplayPhoneClientNotification(): boolean {
        return (
            this.phoneClientService.isActive &&
            this.authService.user.canReceiveCalls &&
            !this.phoneClientService.isPhoneClientTogglingInProgress &&
            !this.phoneClientService.isResolutionDialogActive &&
            !this.phoneClientService.callPanelData &&
            !this.phoneClientService.isReceiveCallsActive
        );
    }

    public get isDisplayAudioSettingsPanel(): boolean {
        return this.showAudioSettings && this.phoneClientService.isActive;
    }

    public get isDisabledCallButton(): boolean {
        return !this.authService.user?.phoneClientIdentity && !this.authService.user.allowCall;
    }

    public get isActiveClient(): boolean {
        return (
            this.phoneClientService.isActive &&
            !!(this.authService.user?.phoneClientIdentity || this.authService.user.allowCall)
        );
    }

    constructor(
        public taskService: TaskService,
        public authService: AuthService,
        public phoneClientService: PhoneClientService,
        public workControlService: WorkControlService,
        private phoneClientDialog: MatDialog,
        private socketService: SocketService,
        private platform: Platform
    ) {}

    ngOnInit(): void {
        this.subscribeOnUpdateBoard();

        from(this.taskService.loadTasks())
            .pipe(
                takeUntil(this.destroy$),
                switchMap(() => this.socketService.onReconnect),
                switchMap(() => this.taskService.loadTasks())
            )
            .subscribe();
    }

    public toggleAudioSettings(): void {
        this.showAudioSettings = !this.showAudioSettings;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public toggleTodoTasksCollapse(): void {
        this.isTodoTaskCollapsed = !this.isTodoTaskCollapsed;
    }

    public toggleInProgressTasksCollapse(): void {
        this.isInProgressTaskCollapsed = !this.isInProgressTaskCollapsed;
    }

    public toggleOnHoldCollapse(): void {
        this.isOnHoldTaskCollapsed = !this.isOnHoldTaskCollapsed;
    }

    public toggleCompletedTasksCollapse(): void {
        this.isCompletedTaskCollapsed = !this.isCompletedTaskCollapsed;
    }

    public hideTaskList(): void {
        this.taskService.setTaskBarVisibility(false);
    }

    public updateBoard(): void {
        if (this.isRefreshLoading) return;

        this.taskService.refreshMyTasks$.next();
    }

    private subscribeOnUpdateBoard(): void {
        this.taskService.refreshMyTasks$
            .pipe(
                tap(() => (this.isRefreshLoading = true)),
                tap(() => this.taskService.refreshTaskDashboard$.next()),
                switchMap(() => from(this.taskService.loadTasks())),
                takeUntil(this.destroy$)
            )
            .subscribe(
                () => (this.isRefreshLoading = false),
                () => (this.isRefreshLoading = false)
            );
    }

    public onStartOrFinishDay(): void {
        if (!this.workControlService.isDayStarted$.getValue()) {
            this.workControlService.startDay();
            this.updateBoard();
            return;
        }

        this.workControlService
            .finishDay$()
            .pipe(
                takeUntil(this.destroy$),
                tap(() => this.updateBoard()),
                tap(() => this.phoneClientService.deActivatePhoneClient())
            )
            .subscribe();
    }

    public openPhoneClientDialog(): void {
        this.phoneClientDialog.open(PhoneClientDialogComponent, {
            width: '600px',
            data: {},
            panelClass: 'crm-dialog',
        });
    }

    public toggleDialpad(): void {
        this.showDialpad = !this.showDialpad;
    }

    public identify(index: number, item: Task): number {
        return item.id;
    }
}
