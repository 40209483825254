import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SnackbarService } from '@shared/components';
import { environment as env } from 'environments/environment';
import {
    ApiCallService,
    AuthService,
    ManagerCallState,
    ManagerNotificationService,
    SocketService,
    UserRole,
} from 'medvidi';
import { fromEvent, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, tap } from 'rxjs/operators';
import { Connection as TwilioConnection, Device as TwilioDevice } from 'twilio-client';
import { RequestReasonDialogComponent } from '../shared/components/request-reason-dialog/request-reason-dialog.component';
import { RequestReasonService } from '../shared/components/request-reason-dialog/request-reason.service';
import { TwilioVoiceLoggingService } from '../twilio-voice/twilio-voice-logging.service';
import {
    ApiTwilioVoiceLogsEnum,
    CallTypes,
    PhoneClientLogs,
} from '../twilio-voice/twilio-voice.interfaces';

declare const Twilio: any;

@Injectable({ providedIn: 'root' })
export class PhoneClientService {
    public device: TwilioDevice = new TwilioDevice();
    private isDevicePending = false;
    private isWorkerPending = false;

    private isTokenReceivingInProgress = false;

    private connection: TwilioConnection;

    public isActive: boolean = false;
    public isReceiveCallsActive: boolean = false;
    private managerCallStatus: ManagerCallState;

    public isResolutionDialogActive = false;

    public timerString: string = '00:00';
    public timer: number = 0;
    public timerInterval;
    public lastFromCommunicationMember: string;
    public isCallOnHold: boolean = false;
    public reservation: any;
    public callTimeoutId;
    public callPanelData: any;

    private worker: any;
    private intervalHandler;
    private onHoldCallSid: string;

    public get isPhoneClientTogglingInProgress(): boolean {
        return this.isDevicePending || this.isWorkerPending || this.isTokenReceivingInProgress;
    }

    public get isMuted(): boolean {
        return this.connection?.isMuted() ?? false;
    }

    private get isRoleAbleAllowsCall(): boolean {
        return [UserRole.MANAGER, UserRole.ADMIN, UserRole.SUPERVISOR].includes(
            this.authService.user?.role
        );
    }

    constructor(
        public authService: AuthService,
        public managerNotificationService: ManagerNotificationService,
        private apiCallService: ApiCallService,
        private socketService: SocketService,
        private requestReasonService: RequestReasonService,
        private dialog: MatDialog,
        private twilioLoggingService: TwilioVoiceLoggingService,
        private snackbarService: SnackbarService
    ) {
        // set manager status if socket reconnected
        this.configureReconnectingActions();
    }

    // used to respond to the pharmacy call robot
    public sendDigitsListener$(): Observable<string> {
        return fromEvent<KeyboardEvent>(document, 'keypress').pipe(
            map((event) => event.key),
            filter((character) => !!character.match('^[0-9#*]$')),
            debounceTime(400),
            filter(() => !!this.connection),
            tap((val) => this.connection.sendDigits(val))
        );
    }

    private configureReconnectingActions(): void {
        this.socketService.onReconnect.subscribe(() => {
            this.setManagerCallStatus(this.managerCallStatus || ManagerCallState.UNAVAILABLE);
        });
    }

    public async deActivatePhoneClient(): Promise<void> {
        if (!this.isActive || !this.isRoleAbleAllowsCall) return;
        this.isDevicePending = true;

        if (this.reservation) {
            await this.onDeclineCall();
        }

        if (this.connection) {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.CONNECTION_DESTROYING,
            });
            this.connection.ignore();
            this.connection.disconnect();
        }

        if (this.device) {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.DEVICE_DESTROYING,
            });
            this.device.destroy();
        }

        await this.setDisconnectActivityToWorker();

        this.handlePhoneEvent(null);

        this.worker = null;
        this.reservation = null;
        this.connection = null;
        this.device = null;
        this.isDevicePending = false;
    }

    public async activatePhoneClient(): Promise<void> {
        if (this.isActive || !this.isRoleAbleAllowsCall) return;

        this.twilioLoggingService.storeUIEvent({ type: PhoneClientLogs.TOKEN_REQUESTING });

        try {
            this.isTokenReceivingInProgress = true;
            const { workerToken, clientToken } = await this.apiCallService.getClientTokens();
            this.isTokenReceivingInProgress = false;

            const isManager = this.authService.user?.role === UserRole.MANAGER;
            const isManagerCallUnavailable = (!clientToken || !workerToken) && isManager;
            const isAdminSupervisorCallUnavailable = !clientToken && !isManager;

            if (isManagerCallUnavailable || isAdminSupervisorCallUnavailable) {
                throw new Error('Token not received'); // -> catch block
            }

            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.TOKEN_REQUESTING_SUCCESS,
            });

            this.initDevice(clientToken);
            this.initWorker(workerToken);
        } catch (error) {
            this.isActive = false;
            this.isDevicePending = false;
            this.isWorkerPending = false;
            this.isTokenReceivingInProgress = false;

            this.twilioLoggingService.storeUIErrorEvent({
                type: PhoneClientLogs.TOKEN_REQUESTING_ERROR,
                error,
            });
        }
    }

    private async setConnectActivityToWorker(): Promise<void> {
        await this.setWorkerActivity(env.connectActivitySid);
    }

    private async setDisconnectActivityToWorker(): Promise<void> {
        await this.setWorkerActivity(env.disconnectActivitySid);
    }

    private async setWorkerActivity(sid: string): Promise<void> {
        if (!this.authService?.user?.canReceiveCalls || !this.worker) {
            return;
        }

        try {
            this.isWorkerPending = true;
            await this.updateWorkerAsync(sid);
            this.isWorkerPending = false;
        } catch (error) {
            this.isWorkerPending = false;
            this.setWorkerActivityError(error);
        }
    }

    private setWorkerActivityError(error: string | Error): void {
        const userMessage = "The system couldn't update worker status - please refresh your page.";

        this.twilioLoggingService.storeUIErrorEvent({
            type: PhoneClientLogs.SET_WORKER_ACTIVITY_ERROR,
            error,
            userMessage,
        });

        this.snackbarService.showSnackError({ message: userMessage });
    }

    private initWorker(workerToken: string): void {
        // if the user cannot receive calls, then there will be no worker
        if (!this.authService?.user?.canReceiveCalls || !this.isReceiveCallsActive) {
            return;
        }

        this.isWorkerPending = true;

        this.worker = new Twilio.TaskRouter.Worker(
            workerToken,
            true,
            env.connectActivitySid,
            env.disconnectActivitySid,
            true // closeExistingSessions
        );

        this.worker.on('ready', (worker) => {
            this.isWorkerPending = false;

            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.WORKER_READY,
            });
        });

        this.worker.on('activity.update', (worker) => {
            this.isWorkerPending = false;
            this.isReceiveCallsActive = worker.available;

            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.SET_WORKER_ACTIVITY_SUCCESS,
                userMessage: `Status: ${worker.activityName}`,
            });
        });

        this.worker.on('token.expired', async () => {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.TOKEN_EXPIRED,
            });

            const { workerToken: newToken } = await this.apiCallService.getClientTokens();
            this.worker.updateToken(newToken);
        });

        this.worker.on('reservation.created', (reservation) => {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.RESERVATION_CREATED,
                extraLog: reservation,
            });

            this.reservation = reservation;
            this.reservation.dequeue();

            this.twilioLoggingService.sendCallLogToApi({
                id: ApiTwilioVoiceLogsEnum.CALL_DELIVERED,
                reservation,
            });
        });

        this.worker.on('reservation.canceled', (reservation) => {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.RESERVATION_CANCELED,
                extraLog: reservation,
            });

            if (this.reservation?.task?.sid !== reservation?.task?.sid) {
                return;
            }

            this.onHoldCallSid = this.reservation.task.attributes?.call_sid;

            this.resetTwilioClientToReadyForCall();
        });

        this.worker.on('reservation.timeout', (reservation) => {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.RESERVATION_TIMEOUT,
                extraLog: reservation,
            });

            if (this.reservation?.task?.sid !== reservation?.task?.sid) {
                return;
            }

            reservation.task.update(
                'Attributes',
                JSON.stringify({
                    ...reservation?.task?.attributes,
                    rejectedManagersSids: [
                        ...(reservation?.task?.attributes?.rejectedManagersSids || []),
                        this.authService.user.phoneClientIdentity,
                    ],
                })
            );

            this.twilioLoggingService.sendCallLogToApi({
                id: ApiTwilioVoiceLogsEnum.CALL_EXPIRED,
                reservation,
            });

            this.onHoldCallSid = this.reservation.task.attributes?.call_sid;

            this.resetTwilioClientToReadyForCall();
        });

        this.worker.on('task.wrapup', (task) => {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.TASK_WRAP_UP,
                extraLog: task,
            });
            task.complete();
        });

        this.worker.on('error', () => {
            this.isWorkerPending = false;
        });
    }

    private resetTwilioClientToReadyForCall(): void {
        this.connection?.ignore();
        this.device.disconnectAll();
        this.reservation = null;
        this.connection = null;
        this.handlePhoneEvent(null);

        void this.setConnectActivityToWorker();
        this.setManagerCallStatus(ManagerCallState.READY_FOR_CALL);
    }

    public setReceiveCalls(value: boolean): void {
        this.isReceiveCallsActive = value;
    }

    private setManagerCallStatus(status: ManagerCallState): void {
        this.managerCallStatus = status;
        this.managerNotificationService.setCallState(status);
    }

    private hangUp(): void {
        if (!this.isActive) return;

        this.twilioLoggingService.storeUIEvent({ type: PhoneClientLogs.END_CALL });
        this.device.disconnectAll(); // disconnect all active connections
    }

    public toggleMute(): void {
        if (!this.connection) return;

        this.connection.mute(!this.isMuted);
    }

    public testSpeaker(): void {
        void this.device.audio.speakerDevices.test();
    }

    private async openCallResolutionDialog(phone: string, callSid: string): Promise<void> {
        if (this.authService.user.role === UserRole.ADMIN) return;

        this.isResolutionDialogActive = true;

        // create empty record about call resolution
        this.requestReasonService.createEmptyCallResult({ callSid, phone }).subscribe();

        // avoid incoming calls while resolution filling
        await this.setDisconnectActivityToWorker();

        const resolutionDialog = this.dialog.open(RequestReasonDialogComponent, {
            width: '480px',
            data: { callSid, phone },
            autoFocus: false,
            panelClass: 'custom-dialog-container',
            disableClose: true,
        });

        resolutionDialog.afterClosed().subscribe(() => {
            this.isResolutionDialogActive = false;
            // activate incoming call again
            void this.setConnectActivityToWorker();
        });
    }

    public async makeCall(to: string, from: string = null): Promise<void> {
        if (!this.isActive) {
            this.twilioLoggingService.storeUIErrorEvent({
                type: PhoneClientLogs.MAKE_CALL_ERROR,
                userMessage: 'Make Call Unavailable. Device is not ready.',
            });
            return;
        }

        if (this.reservation) {
            this.twilioLoggingService.storeUIErrorEvent({
                type: PhoneClientLogs.MAKE_CALL_ERROR,
                userMessage: `Outgoing call to ${to} was interrupted due to an incoming call`,
            });
            return;
        }

        // to avoid incoming calls during the call
        await this.setDisconnectActivityToWorker();

        const params: any = {
            To: to,
            From: from || this.lastFromCommunicationMember,
            userId: this.authService.user.id,
        };

        this.twilioLoggingService.storeUIEvent({
            type: PhoneClientLogs.MAKING_CALL,
            userMessage: `(to: ${params.To} ${params.From ? ` from: ${params.From})` : ')'}`,
        });

        try {
            const outgoingConnection = this.device.connect(params);

            outgoingConnection.on('ringing', () => {
                this.twilioLoggingService.storeUIEvent({
                    type: PhoneClientLogs.RINGING,
                });

                this.initOutgoingCallPanel(params.To);
            });
        } catch (error) {
            this.twilioLoggingService.storeUIErrorEvent({
                type: PhoneClientLogs.MAKE_CALL_ERROR,
                error,
            });
        }
    }

    private initLastCallPanel = (from: string, to: string): void => {
        this.handlePhoneEvent(
            {
                type: CallTypes.LAST_CALL,
                phone: from,
                onCall: () => this.makeCall(from, to),
            },
            5000
        );
    };

    private initOutgoingCallPanel = (callToPhone: string): void => {
        this.handlePhoneEvent({
            type: CallTypes.OUTGOING_CALL,
            phone: callToPhone,
            onHangUp: () => this.hangUp(),
        });
    };

    private initActiveCallPanel = (
        connection: TwilioConnection,
        phone: string,
        incoming: boolean
    ): void => {
        this.handlePhoneEvent({
            type: CallTypes.ACTIVE_CALL,
            phone,
            incoming,
            onHangUp: () => {
                if (incoming) {
                    this.reservation.task.update(
                        'Attributes',
                        JSON.stringify({
                            ...this.reservation?.task?.attributes,
                            isCallAbortedByLead: false,
                        })
                    );
                }

                this.isCallOnHold = false;
                this.hangUp();
            },
            onHold: async () => {
                const taskAttributes = JSON.stringify({
                    ...this.reservation?.task?.attributes,
                    isOnHold: true,
                    isCallAbortedByLead: false,
                    onHoldContactUri: this.worker.attributes.contact_uri,
                });

                const workerAttributes = JSON.stringify({
                    ...this.worker.attributes,
                    isOnHold: true,
                });

                this.twilioLoggingService.storeUIEvent({
                    type: PhoneClientLogs.CALL_ON_HOLD,
                });

                this.twilioLoggingService.sendCallLogToApi({
                    id: ApiTwilioVoiceLogsEnum.CALL_ON_HOLD,
                    reservation: this.reservation,
                    from: connection.customParameters.get('From'),
                });

                this.reservation.task.update('Attributes', taskAttributes, (taskErr) => {
                    if (taskErr) return;

                    this.worker.update('Attributes', workerAttributes, (workerErr) => {
                        if (workerErr) return;

                        this.isCallOnHold = true;
                        this.hangUp();
                    });
                });
            },
            onContinue: () => {
                this.apiCallService
                    .twilioCallContinue(this.worker.attributes.contact_uri)
                    .pipe(
                        catchError(() => {
                            this.openCallResolutionDialog(
                                this.callPanelData.phone,
                                this.onHoldCallSid
                            );

                            this.onHoldCallSid = null;
                            this.callPanelData = null;
                            this.callTimeoutId = null;
                            return of(null);
                        })
                    )
                    .subscribe();
            },
        });
    };

    public handlePhoneEvent(data: any, duration: number = null): void {
        if (this.callTimeoutId) {
            clearTimeout(this.callTimeoutId);
            this.callTimeoutId = null;
        }

        if (
            data?.type === CallTypes.LAST_CALL &&
            this.callPanelData?.type === CallTypes.ACTIVE_CALL &&
            !this.isCallOnHold
        ) {
            let callSid;
            if (this.connection) {
                callSid =
                    this.connection.direction === 'INCOMING'
                        ? this.reservation.task.attributes.call_sid
                        : this.connection.parameters.CallSid;
            } else {
                callSid = this.onHoldCallSid;
                this.onHoldCallSid = null;
            }

            this.openCallResolutionDialog(this.callPanelData.phone, callSid);
        }

        this.callPanelData = data !== null ? { ...data, reservation: this.reservation } : null;

        if (duration && !this.isCallOnHold) {
            this.callTimeoutId = setTimeout(() => {
                this.callPanelData = null;
                this.callTimeoutId = null;
            }, duration);
        }
    }

    private initDevice(clientToken: string): void {
        this.isDevicePending = true;

        this.device = new TwilioDevice();

        this.device.setup(clientToken, {
            codecPreferences: [TwilioConnection.Codec.Opus, TwilioConnection.Codec.PCMU],
            fakeLocalDTMF: true,
            enableRingingState: true,
            debug: true,
        });

        this.device.on('ready', () => {
            this.isActive = true;
            this.isDevicePending = false;
            this.setManagerCallStatus(ManagerCallState.READY_FOR_CALL);

            this.twilioLoggingService.storeUIEvent({ type: PhoneClientLogs.DEVICE_READY });
            this.twilioLoggingService.sendActivityLogToApi({
                id: ApiTwilioVoiceLogsEnum.DEVICE_READY,
            });
        });

        this.device.on('offline', () => {
            this.isActive = false;
            this.isDevicePending = false;
            this.setManagerCallStatus(ManagerCallState.UNAVAILABLE);

            this.twilioLoggingService.storeUIEvent({ type: PhoneClientLogs.DEVICE_DESTROYED });
            this.twilioLoggingService.sendActivityLogToApi({
                id: ApiTwilioVoiceLogsEnum.DEVICE_DESTROYED,
            });
        });

        this.device.on('error', (error) => {
            this.isDevicePending = false;

            this.twilioLoggingService.storeUIErrorEvent({
                type: PhoneClientLogs.DEVICE_ERROR,
                userMessage: error.message,
                error,
            });

            this.twilioLoggingService.sendDeviceLogToApi({
                id: ApiTwilioVoiceLogsEnum.DEVICE_ERROR,
                taskSid: this.reservation?.task?.sid,
                callSid: error?.connection?.parameters?.CallSid,
                error,
            });

            this.snackbarService.showSnackError({ message: error.message });
        });

        this.device.on('cancel', (connection: TwilioConnection) => {
            // This is triggered when an incoming connection is canceled by the caller
            // before it is accepted by the Twilio Client device.
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.DEVICE_CANCEL,
            });

            this.twilioLoggingService.sendDeviceLogToApi({
                id: ApiTwilioVoiceLogsEnum.DEVICE_CANCEL,
                callSid: connection?.parameters?.CallSid,
                taskSid: this.reservation?.task?.sid,
            });
        });

        this.device.on('connect', (conn: TwilioConnection) => {
            this.connection = conn;

            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.CONNECTED_ESTABLISHED,
                extraLog: conn,
            });

            this.managerNotificationService.subscribeOnTwilioOnHoldCallMissed(
                { managerId: this.authService.user.managerId },
                this.handleClientDiscardCallIssue.bind(this)
            );

            let phone, incoming;
            if (this.reservation) {
                incoming = true;
                phone = this.reservation.task.attributes.from;
            } else {
                incoming = false;
                phone = conn.customParameters.get('To');
            }

            this.initActiveCallPanel(conn, phone, incoming);

            this.initCallDurationInterval();

            this.setManagerCallStatus(ManagerCallState.ON_CALL);

            this.twilioLoggingService.storeUIEvent({ type: PhoneClientLogs.CONNECTION_OPENED });
            this.twilioLoggingService.sendConnectionLogToApi({
                type: ApiTwilioVoiceLogsEnum.CONNECTION_OPENED,
                from: conn.customParameters.get('From'),
                to: conn.customParameters.get('To'),
                incoming,
                callSid: conn?.parameters?.CallSid,
                taskSid: this.reservation?.task?.sid,
            });
        });

        this.device.on('disconnect', async (conn: TwilioConnection) => {
            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.CALL_ENDED,
                extraLog: conn,
            });

            let from;
            let to;
            let incoming;

            if (this.reservation) {
                incoming = true;
                from = this.reservation.task.attributes.from;
                to = this.reservation.task.attributes.to;
            } else {
                incoming = false;
                from = conn.customParameters.get('From');
                to = conn.customParameters.get('To');
            }

            if (!this.isCallOnHold) {
                this.initLastCallPanel(incoming ? from : to, incoming ? to : from);

                clearInterval(this.timerInterval);

                this.timerInterval = null;

                this.managerNotificationService.unsubscribeOnTwilioOnHoldCallMissed(
                    { managerId: this.authService.user.managerId },
                    this.handleClientDiscardCallIssue.bind(this)
                );
            }

            this.connection = null;

            this.setManagerCallStatus(ManagerCallState.READY_FOR_CALL);

            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.CONNECTION_CLOSED,
            });
            this.twilioLoggingService.sendConnectionLogToApi({
                type: ApiTwilioVoiceLogsEnum.CONNECTION_CLOSED,
                from,
                to,
                incoming,
                callSid: conn?.parameters?.CallSid,
                taskSid: this.reservation?.task?.sid,
            });

            if (this.reservation) {
                this.reservation.task.complete();
                this.onHoldCallSid = this.reservation.task.attributes?.call_sid;
                this.reservation = null;
            }

            if (!this.isResolutionDialogActive) {
                void this.setConnectActivityToWorker();
            }
        });

        this.device.on('incoming', (conn: TwilioConnection) => {
            this.connection = conn;
            const from = this.reservation.task.attributes.from;
            const to = this.reservation.task.attributes.to;

            this.twilioLoggingService.storeUIEvent({
                type: PhoneClientLogs.INCOMING_CONNECTION,
                userMessage: '(from: ' + from + ')',
                extraLog: this.connection,
            });

            const onAcceptHandler = () => {
                const attributes = {
                    ...this.reservation.task.attributes,
                    acceptedUserId: this.authService.user.id,
                };
                this.reservation.task.update('Attributes', JSON.stringify(attributes));
                conn.accept();

                this.twilioLoggingService.storeUIEvent({
                    type: PhoneClientLogs.CALL_ACCEPTED,
                    userMessage: 'from: ' + from,
                });

                this.twilioLoggingService.sendCallLogToApi({
                    id: ApiTwilioVoiceLogsEnum.CALL_ACCEPTED,
                    reservation: this.reservation,
                });
            };

            if (this.intervalHandler) {
                clearInterval(this.intervalHandler);
            }

            this.intervalHandler = setInterval(() => {
                if (conn.status() === 'closed' && !this.isCallOnHold) {
                    this.initLastCallPanel(from, to);

                    clearInterval(this.intervalHandler);
                    this.intervalHandler = null;
                    this.connection = null;
                }
            }, 500);

            if (!this.isCallOnHold) {
                this.handlePhoneEvent({
                    type: CallTypes.INCOMING_CALL,
                    phone: from,
                    onAccept: onAcceptHandler,
                    onDecline: async () => {
                        await this.onDeclineCall();
                        this.resetTwilioClientToReadyForCall();
                    },
                });
            } else {
                onAcceptHandler();
                this.isCallOnHold = false;
            }
        });
    }

    private async onDeclineCall(): Promise<void> {
        // это сиды менеджеров отклонивших вызов
        // нужны для того чтобы звонок опять не пришел на них после реджекта
        const rejectedManagersSids = [
            ...(this.reservation?.task?.attributes?.rejectedManagersSids || []),
            this.authService.user.phoneClientIdentity,
        ];

        const updatedAttributes = { ...this.reservation?.task?.attributes, rejectedManagersSids };

        await this.reservation?.task.update('Attributes', JSON.stringify(updatedAttributes));
        await this.reservation?.reject();

        this.twilioLoggingService.storeUIEvent({
            type: PhoneClientLogs.CALL_REJECTED,
        });

        this.twilioLoggingService.sendCallLogToApi({
            id: ApiTwilioVoiceLogsEnum.CALL_REJECTED,
            reservation: this.reservation,
        });
    }

    private async updateWorkerAsync(sid: string, attempt: number = 0): Promise<any> {
        return new Promise((resolve, reject) => {
            const retryUpdateIfFail = (error: any) => {
                if (attempt < 5) {
                    setTimeout(
                        () =>
                            this.updateWorkerAsync(sid, attempt + 1)
                                .then(resolve)
                                .catch(reject),
                        1500
                    );
                } else reject(error);
            };

            this.worker.update('ActivitySid', sid, (error, worker) => {
                if (!error) resolve(worker);
                else retryUpdateIfFail(error);
            });
        });
    }

    private initCallDurationInterval(): void {
        if (this.timerInterval) return;

        this.timer = 0;
        this.timerString = '00:00';
        this.timerInterval = setInterval(() => {
            this.timer++;
            let min = Math.trunc(this.timer / 60).toString();
            let sec = (this.timer % 60).toString();
            if (min.length < 2) min = '0' + min;
            if (sec.length < 2) sec = '0' + sec;
            this.timerString = `${min}:${sec}`;
        }, 1000);
    }

    private handleClientDiscardCallIssue(data: { callSid: string }): void {
        if (!this.isCallOnHold) return;

        this.twilioLoggingService.storeUIEvent({
            type: PhoneClientLogs.CLIENT_DISCARD_CALL,
        });

        this.isCallOnHold = false;
        this.onHoldCallSid = data.callSid || this.onHoldCallSid;
        this.hangUp();
    }
}
