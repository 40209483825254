<div *ngIf="loaded" class="container">
    <h2 *ngIf="step === letterGenerationStep.INIT">Choose a template</h2>
    <h2 *ngIf="step === letterGenerationStep.PREVIEW_PDF">Preview PDF</h2>
    <h2 *ngIf="step === letterGenerationStep.PREVIEW_EMAIL">Preview Email</h2>
    <h2 *ngIf="step === letterGenerationStep.EDIT_PDF_HTML">Edit PDF</h2>
    <h2 *ngIf="step === letterGenerationStep.EDIT_PDF_FORM">Edit Form</h2>
    <h2 *ngIf="step === letterGenerationStep.EDIT_EMAIL">Edit Email</h2>
    <div class="content-block">
        <div *ngIf="step === letterGenerationStep.INIT" class="step-block">
            <med-form [form]="chooseTemplateForm"></med-form>
        </div>
        <div
            *ngIf="
                step === letterGenerationStep.PREVIEW_PDF ||
                step === letterGenerationStep.PREVIEW_EMAIL
            "
            class="step-block"
        >
            <mat-tab-group
                [selectedIndex]="step === 'PREVIEW_PDF' ? 0 : 1"
                (selectedTabChange)="onTabChange($event)"
            >
                <mat-tab label="PDF">
                    <iframe [src]="pdfInfo.safeLink" style="width: 100%; height: 99%"></iframe>
                    <!-- <pdf-viewer [src]="pdfInfo.link"></pdf-viewer> -->
                </mat-tab>
                <mat-tab label="Email">
                    <iframe [srcdoc]="pdfInfo.emailHtml" style="width: 100%; height: 99%"></iframe>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div *ngIf="step === letterGenerationStep.EDIT_PDF_HTML" class="step-block">
            <iframe #pdfEditContent></iframe>
        </div>
        <div *ngIf="step === letterGenerationStep.EDIT_PDF_FORM" class="step-block">
            <div class="form-container">
                <form id="input-form" [formGroup]="pdfForm" novalidate>
                    <div *ngFor="let input of inputList; let i = index" [ngSwitch]="input.type">
                        <input
                            *ngSwitchCase="'checkbox'"
                            [name]="input.name"
                            type="checkbox"
                            [formControlName]="input.name"
                            [ngStyle]="getInputPosition(input)"
                        />
                        <input
                            *ngSwitchCase="'radio'"
                            [name]="input.name"
                            type="radio"
                            [formControlName]="input.name"
                            [value]="input.value"
                            [ngStyle]="getInputPosition(input)"
                        />
                        <input
                            *ngSwitchDefault
                            [name]="input.name"
                            type="text"
                            [formControlName]="input.name"
                            [ngStyle]="getInputPosition(input)"
                        />
                    </div>
                </form>
            </div>
            <pdf-viewer
                id="viewer"
                [src]="pdfInfo.link"
                (after-load-complete)="loadComplete($event)"
            ></pdf-viewer>
        </div>
        <div *ngIf="step === letterGenerationStep.EDIT_EMAIL" class="step-block">
            <iframe #emailEditContent></iframe>
        </div>
        <div *ngIf="step === letterGenerationStep.RESULT" class="step-block">
            {{ getResultMessage() }}
        </div>
    </div>
    <div class="control-block">
        <button
            *ngIf="step !== letterGenerationStep.RESULT"
            mat-button
            (click)="closeDialog()"
            data-test-id="Cancel button"
        >
            Cancel
        </button>
        <button
            *ngIf="step === letterGenerationStep.INIT"
            mat-button
            color="primary"
            [disabled]="!isActiveButtonNext()"
            (click)="render()"
            data-test-id="Next button"
        >
            Next
        </button>
        <button
            *ngIf="step === letterGenerationStep.PREVIEW_PDF"
            mat-button
            color="primary"
            (click)="onEditPdf()"
            data-test-id="Edit PDF button"
        >
            Edit PDF
        </button>
        <button
            *ngIf="step === letterGenerationStep.PREVIEW_EMAIL"
            mat-button
            color="primary"
            (click)="onEditEmail()"
            data-test-id="Edit Email button"
        >
            Edit Email
        </button>
        <button
            *ngIf="
                step === letterGenerationStep.PREVIEW_PDF ||
                step === letterGenerationStep.PREVIEW_EMAIL
            "
            mat-button
            color="primary"
            (click)="sendLetter()"
            data-test-id="Send button"
        >
            Send
        </button>
        <button
            *ngIf="step === letterGenerationStep.EDIT_PDF_HTML"
            mat-button
            color="primary"
            (click)="onSavePdfHtml()"
            data-test-id="Save & Next button"
        >
            Save & Next
        </button>
        <button
            *ngIf="step === letterGenerationStep.EDIT_PDF_FORM"
            mat-button
            color="primary"
            (click)="onSavePdfForm()"
            data-test-id="Save & Next button"
        >
            Save & Next
        </button>
        <button
            *ngIf="step === letterGenerationStep.EDIT_EMAIL"
            mat-button
            color="primary"
            (click)="onSaveEmailHtml()"
            data-test-id="Save & Next button"
        >
            Save & Next
        </button>
        <button
            *ngIf="step === letterGenerationStep.RESULT"
            mat-button
            color="primary"
            (click)="closeDialog()"
            data-test-id="Ok button"
        >
            Ok
        </button>
    </div>
</div>
<med-loader *ngIf="!loaded"></med-loader>
