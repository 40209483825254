<!-- Only Value -->
<div *ngIf="onlyValue" class="priority-select__trigger">
    <img class="priority-image" [src]="activeIconPath" />
    <span *ngIf="!hideLabel">{{ activeLabel }}</span>
</div>
<!--  Select -->
<mat-select
    *ngIf="!onlyValue"
    class="priority-select"
    [formControl]="priorityFormControl"
    (valueChange)="selectValueChange($event)"
>
    <mat-select-trigger class="priority-select__trigger">
        <img class="priority-image" [src]="activeIconPath" />
        <span>{{ activeLabel }}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let priority of priorityOptions" [value]="priority.value">
        <div class="priority-select__option">
            <img class="priority-image" [src]="iconPaths[priority.value]" />
            <span>{{ priority.text }}</span>
        </div>
    </mat-option>
</mat-select>
