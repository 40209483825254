import { Injectable } from '@angular/core';

enum AppState {
    LOADING = -1,
    DEFAULT = 0,
    BROKEN = 1,
    NEED_AUTH = 2,
    FORBIDDEN = 3,
}

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    private state = AppState.DEFAULT;

    constructor() {}

    setAppLoading() {
        this.state = AppState.LOADING;
    }

    isAppLoading(): boolean {
        return this.state === AppState.LOADING;
    }

    setAppDefault() {
        this.state = AppState.DEFAULT;
    }

    isAppDefault(): boolean {
        return this.state === AppState.DEFAULT;
    }

    setAppBroken() {
        this.state = AppState.BROKEN;
    }

    isAppBroken(): boolean {
        return this.state === AppState.BROKEN;
    }

    setAppNeedAuth() {
        this.state = AppState.NEED_AUTH;
    }

    isAppNeedAuth(): boolean {
        return this.state === AppState.NEED_AUTH;
    }

    setAppForbidden() {
        this.state = AppState.FORBIDDEN;
    }

    isAppForbidden(): boolean {
        return this.state === AppState.FORBIDDEN;
    }
}
