import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUserService, UserRole } from 'medvidi';

import { crmContent } from '../../../consts/crm-content.const';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    dashboardContent = crmContent;
    role = null;

    constructor(private apiUserService: ApiUserService, private router: Router) {}

    async ngOnInit() {
        const user = await this.apiUserService.viewByToken();
        this.role = user?.role || UserRole.MANAGER;

        if (this.role === UserRole.MANAGER) {
            this.router.navigate(['/task/dashboard']);
        }
    }

    canDisplaySection(section) {
        if (
            this.role &&
            section &&
            section.children &&
            section.children.findIndex((menuItem) => menuItem.roles.includes(this.role)) !== -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    canDisplayMenuItem(menuItem) {
        if (this.role && menuItem && menuItem.roles && menuItem.roles.includes(this.role)) {
            return true;
        } else {
            return false;
        }
    }
}
