import { ListResponse } from '../../med-list/interfaces';
import { IServiceAccount } from './';

export enum WebsiteCode {
    MOBILE = 'mobile',
    MMJDOCTOR = 'mmjdoctor',
    ESACARE = 'esacare',
    TPD = 'tpd',
    EZCARECLINIC = 'ezcareclinic',
    MMJDOCTORS = 'mmjdoctors',
    MDBERRY = 'mdberry',
    MEDVIDI = 'medvidi',
    ADHDDOCTORS = 'adhddoctors',
    MANGOCLINIC = 'mangoclinic',
    START_MEDVIDI = 'start_medvidi',
}

export interface IWebsite {
    id: number;
    code: string;
    name: string;
    serviceCodes: string[];
    url: string;
    logoUrl: string;
    formUrl: string;
    phone: string;
    fax: string;
    address: string;
    email: string;
    defaultFacebook: IServiceAccount;
    defaultPhone: IServiceAccount;
    defaultPhoneForSms: IServiceAccount;
    defaultEmail: IServiceAccount;
    basicEmailTemplate: string;
    terms: string;
    refundPolicy: string;
    applicationUrl?: string;
    officeHours: string;
    availableForCalendar?: boolean;
    termsUrl?: string;
    privacyUrl?: string;
    telehealthServiceAgreementUrl?: string;
    telehealthConsentUrl?: string;
    refundPolicyUrl?: string;
    healthTermsAndConditionsUrl?: string;
    isHiddenForManagers?: string;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;
    reservedPhoneForSms: IServiceAccount;
    userGroupId: number;
}

export interface IWebsiteSmall {
    id: number;
    name: string;
    code: string;
    url: string;
    logoUrl: string;
    formUrl: string;
}

export interface ICommonDataWebsite {
    id: number;
    code?: string;
    name: string;
    serviceCodes: string[];
    availableForCalendar: boolean;
    isHiddenForManagers: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface IWebsiteListParams {
    page: number;
    limit: number;
}

export interface IWebsiteListResponse extends ListResponse {
    items: IWebsite[];
}

export interface IWebsiteCreateParams {
    code: string;
    name: string;
    serviceCodes: string[];
    url?: string;
    logoUrl?: string;
    formUrl?: string;
    phone?: string;
    fax?: string;
    address?: string;
    email?: string;
    defaultFacebookId?: string;
    defaultPhoneId?: string;
    defaultPhoneForSmsId?: string;
    defaultEmailId?: string;
    applicationUrl?: string;
    officeHours?: string;
    availableForCalendar?: boolean;
    termsUrl?: string;
    privacyUrl?: string;
    telehealthServiceAgreementUrl?: string;
    telehealthConsentUrl?: string;
    refundPolicyUrl?: string;
    healthTermsAndConditionsUrl?: string;
    isHiddenForManagers?: boolean;
    reservedPhoneForSmsId?: number;
}

export interface IWebsiteEditParams {
    code?: string;
    name?: string;
    serviceCodes?: string[];
    url?: string;
    logoUrl?: string;
    formUrl?: string;
    phone?: string;
    fax?: string;
    address?: string;
    email?: string;
    basicEmailTemplate?: string;
    terms?: string;
    refundPolicy?: string;
    defaultFacebookId?: string;
    defaultPhoneId?: string;
    defaultPhoneForSmsId?: string;
    defaultEmailId?: string;
    applicationUrl?: string;
    officeHours?: string;
    availableForCalendar?: boolean;
    termsUrl?: string;
    privacyUrl?: string;
    telehealthServiceAgreementUrl?: string;
    telehealthConsentUrl?: string;
    refundPolicyUrl?: string;
    healthTermsAndConditionsUrl?: string;
    isHiddenForManagers?: boolean;
    reservedPhoneForSmsId?: number;
}

export interface IWebsiteCloneParams {
    service?: string;
    states?: string;
    websites?: string;
    title?: string;
    code?: string;
    price?: string;
    description?: string;
    priority?: string;
    type?: string;
    appointmentMethod?: string;
}
