import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
    IAppointment,
    IGlobalSchedule,
    IPatientCalendarAppointment,
} from '../../med-api/interfaces';
import { IGlobalScheduleOfTheWeek, ILeadInfo, RoundMethod } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class AppScheduleService {
    public daysWeek = [
        {
            key: 'sunday',
            label: 'Sunday',
        },
        {
            key: 'monday',
            label: 'Monday',
        },
        {
            key: 'tuesday',
            label: 'Tuesday',
        },
        {
            key: 'wednesday',
            label: 'Wednesday',
        },
        {
            key: 'thursday',
            label: 'Thursday',
        },
        {
            key: 'friday',
            label: 'Friday',
        },
        {
            key: 'saturday',
            label: 'Saturday',
        },
    ];

    public convertGlobalScheduleFormatFromAPI(data: IGlobalSchedule[]): IGlobalScheduleOfTheWeek {
        const schedules: IGlobalScheduleOfTheWeek = this.initGlobalScheduleOfTheWeek();

        for (let i = 0, length = data.length; i < length; i++) {
            schedules[this.getDayString(data[i].day)].push(<IGlobalSchedule>{
                startHours: data[i].startHours,
                startMinutes: data[i].startMinutes,
                endHours: data[i].endHours,
                endMinutes: data[i].endMinutes,
                day: data[i].day,
            });
        }

        return schedules;
    }

    public convertGlobalScheduleFormatInAPI(data: IGlobalScheduleOfTheWeek): IGlobalSchedule[] {
        const schedules: IGlobalSchedule[] = [];
        const schedulesKeys: string[] = Object.keys(data);

        for (let i = 0; i < schedulesKeys.length; i++) {
            for (let j = 0; j < data[schedulesKeys[i]].length; j++) {
                schedules.push(<IGlobalSchedule>{
                    startHours: data[schedulesKeys[i]][j].startHours,
                    startMinutes: data[schedulesKeys[i]][j].startMinutes,
                    endHours: data[schedulesKeys[i]][j].endHours,
                    endMinutes: data[schedulesKeys[i]][j].endMinutes,
                    day: this.getDayNumber(schedulesKeys[i]),
                });
            }
        }

        return schedules;
    }

    public initGlobalScheduleOfTheWeek(): IGlobalScheduleOfTheWeek {
        return {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        };
    }

    public getDayString(day: number): string {
        switch (day) {
            case 0:
                return 'sunday';
            case 1:
                return 'monday';
            case 2:
                return 'tuesday';
            case 3:
                return 'wednesday';
            case 4:
                return 'thursday';
            case 5:
                return 'friday';
            case 6:
                return 'saturday';
        }
    }

    public getDayNumber(day: string): number {
        switch (day) {
            case 'sunday':
                return 0;
            case 'monday':
                return 1;
            case 'tuesday':
                return 2;
            case 'wednesday':
                return 3;
            case 'thursday':
                return 4;
            case 'friday':
                return 5;
            case 'saturday':
                return 6;
        }
    }

    public isAppointmentInactive(appointment: IPatientCalendarAppointment): boolean {
        switch (appointment.status) {
            case 'closed_lost':
            case 'closed_won':
                return true;
            default:
                return false;
        }
    }

    /*
        duration - period to round in minutes(e.g. 10, 15, 30, 60 mins);
    */
    public getRoundedHoursAndMinutes(date: DateTime, duration: number, method: RoundMethod) {
        const minutes = date.hour * 60 + date.minute;
        const roundedMinutes = Math[method](minutes / duration) * duration;

        return {
            hours: Math.floor(roundedMinutes / 60),
            minutes: roundedMinutes % 60,
        };
    }

    public formatAppointmentsToCalendarView(
        appointments: IPatientCalendarAppointment[],
        span: number,
        applyTimezone: boolean = false
    ): Record<string, ILeadInfo[]> {
        const formattedAppointments = {};
        appointments.forEach((appointment) => {
            let luxonTime: DateTime;
            if (applyTimezone && appointment.doctorMomentTimezone) {
                luxonTime = DateTime.fromISO(appointment.time.toLocaleString()).setZone(
                    appointment.doctorMomentTimezone
                );
            } else {
                luxonTime = DateTime.fromISO(appointment.time.toLocaleString());
            }

            const roundedTime = this.getRoundedHoursAndMinutes(luxonTime, span, RoundMethod.FLOOR);

            const key = `${roundedTime.hours}:${roundedTime.minutes}`;
            const leadData = {
                id: appointment.id,
                firstName: appointment.firstName,
                lastName: appointment.lastName,
                inactive: this.isAppointmentInactive(appointment),
                selected: !!appointment.selected,
                isInitial: appointment.isInitial,
                hasInsurance: appointment.hasInsurance,
                appointmentMethod: appointment.appointmentMethod,
                isAppointmentReserved: appointment.isAppointmentReserved,
            };

            if (formattedAppointments[key]) {
                formattedAppointments[key].push(leadData);
            } else {
                formattedAppointments[key] = [leadData];
            }
        });

        return formattedAppointments;
    }

    public generateWeekRange(startDate?: Date): Date[] {
        const weekRange = [];

        for (let i = 0; i < 7; i++) {
            const date = startDate ? new Date(startDate) : new Date();
            date.setHours(12);
            date.setDate(date.getDate() + i);
            weekRange.push(date);
        }

        return weekRange;
    }

    public divideAppointmentsByDays(
        appointments: IAppointment[],
        daysRange: Date[],
        timezone?: string
    ) {
        const result = [];

        daysRange.map((day) => {
            const date = new Date(day);
            const matchedAppointments = appointments.filter(
                (x) =>
                    DateTime.fromISO(x.time.toLocaleString())
                        .setZone(timezone || x.doctorMomentTimezone)
                        .get('day') === date.getDate()
            );
            result.push({ date, appointments: matchedAppointments });
        });

        return result;
    }
}
