import { ListColumn } from './list-column';
import { TemplateRef } from '@angular/core';
import { ListResponse, ListFilter, ListSortParams, SortOrder } from '../interfaces';

export class List {
    private columns: Map<string, ListColumn>;
    private dataProvider: Function;
    private filters: ListFilter[];
    private cellTemplate: TemplateRef<any>;
    private pageSize: number = 25;
    private sortParams: ListSortParams = { id: SortOrder.DESC };

    constructor() {
        this.columns = new Map<string, ListColumn>();
        this.filters = [];
    }

    public setPageSize(value: number): void {
        this.pageSize = value;
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setSortParams(value: ListSortParams): void {
        this.sortParams = value;
    }

    public getSortParams(): ListSortParams {
        return this.sortParams;
    }

    public addColumn(key: string, field: ListColumn) {
        this.columns.set(key, field);
    }

    public setDataProvider(func: Function) {
        this.dataProvider = func;
    }

    public setFilters(filters: ListFilter[]) {
        this.filters = filters;
    }

    public getFilters(): ListFilter[] {
        return this.filters;
    }

    public async loadData(
        page: number,
        limit: number,
        filters: ListFilter[],
        sort: ListSortParams
    ): Promise<ListResponse> {
        return this.dataProvider(page, limit, filters, sort);
    }

    public getColumnCodes(): string[] {
        return [...this.columns.keys()];
    }

    public getColumns(): ListColumn[] {
        return [...this.columns.values()];
    }

    public setCellTemplate(ref: TemplateRef<any>) {
        this.cellTemplate = ref;
    }

    public getCellTemplate(): TemplateRef<any> {
        return this.cellTemplate;
    }
}
