import { FormField } from '../form-field';
import { FormSelectParams, IOptionsItem } from '../../interfaces/form-select-params';

export class FormSelectField extends FormField {
    options: Array<IOptionsItem | string | number>;
    nullable: boolean;
    multiple: boolean;
    withFilter: boolean;
    allOption: boolean;
    hint: boolean;

    constructor(params: FormSelectParams) {
        super(params);
        this.options = params.options;
        this.nullable = params.nullable;
        this.multiple = params.multiple;
        this.withFilter = params.withFilter;
        this.allOption = params.allOption;
        this.hint = params.hint;
    }
}
