import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@shared/components';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent {
    constructor(private matSnackBar: SnackbarService, private router: Router) {}

    public onChangePasswordError({ error }): void {
        const message =
            error?.error?.message ||
            error?.errors[0]?.message ||
            'Something went wrong during password changing';
        this.matSnackBar.showSnackError({ message });
    }

    public onChangePasswordSuccess(): void {
        this.matSnackBar.showSnackSuccess({ message: 'Your password has been changed.' });
        this.router.navigate(['dashboard']);
    }
}
