import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

import { SidebarComponent } from './components/sidebar/sidebar.component';

@NgModule({
    declarations: [SidebarComponent],
    imports: [CommonModule, RouterModule, MaterialModule],
    exports: [SidebarComponent],
})
export class MedSidebarModule {}

export { SidebarComponent } from './components/sidebar/sidebar.component';

export * from './classes';
export * from './services/sidebar-storage.service';
export * from './interfaces';
