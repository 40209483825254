import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@shared/components';
import {
    ApiProfileService,
    AuthService,
    Form,
    FormBuilder,
    FormEvent,
    FormInputField,
    FormSelectField,
    Timezone,
} from 'medvidi';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    public profileForm: Form;
    public passwordForm: Form;

    constructor(
        private authService: AuthService,
        private apiProfileService: ApiProfileService,
        private appSnackBar: SnackbarService
    ) {}

    ngOnInit() {
        this.initProfileForm();
        this.initPasswordForm();
    }

    private initProfileForm(): void {
        const formBuilder = new FormBuilder();
        formBuilder.addField(FormInputField, 'email', {
            label: 'Email',
            validators: ['required', 'email'],
            value: this.authService.user.email,
        });
        formBuilder.addField(FormInputField, 'firstName', {
            label: 'First Name',
            validators: ['required'],
            value: this.authService.user.firstName,
        });
        formBuilder.addField(FormInputField, 'lastName', {
            label: 'Last Name',
            validators: ['required'],
            value: this.authService.user.lastName,
        });
        formBuilder.addField(FormInputField, 'middleName', {
            label: 'Middle Name',
            value: this.authService.user.middleName,
        });
        formBuilder.addField(FormSelectField, 'timezone', {
            label: 'Timezone',
            validators: ['required'],
            options: [
                Timezone.PST,
                Timezone.MST,
                Timezone.MST_Arizona,
                Timezone.EST,
                Timezone.CST,
                Timezone.AKST,
                Timezone.HST,
                Timezone.CET,
                Timezone.MSK,
                Timezone.IST,
            ],
            value: this.authService.user.timezone,
        });
        formBuilder.addSubmitButton('SUBMIT');
        formBuilder.setHandler(FormEvent.SUBMIT, this.onProfileSubmit.bind(this));
        this.profileForm = formBuilder.getForm();
    }

    private initPasswordForm(): void {
        const formBuilder = new FormBuilder();
        formBuilder.addField(FormInputField, 'currentPassword', {
            label: 'Current Password',
            validators: ['required', { type: 'minLength', params: 6 }],
            type: 'password',
        });
        formBuilder.addField(FormInputField, 'newPassword', {
            label: 'New Password',
            validators: ['required', { type: 'minLength', params: 6 }],
            type: 'password',
        });

        formBuilder.addSubmitButton('SUBMIT');
        formBuilder.setHandler(FormEvent.SUBMIT, this.onPasswordSubmit.bind(this));

        this.passwordForm = formBuilder.getForm();
    }

    private async onProfileSubmit(formValue): Promise<void> {
        try {
            const res = await this.apiProfileService.edit({
                email: formValue.email,
                firstName: formValue.firstName,
                lastName: formValue.lastName,
                middleName: formValue.middleName || null,
                timezone: formValue.timezone,
            });

            this.authService.user.email = res.email;
            this.authService.user.firstName = res.firstName;
            this.authService.user.lastName = res.lastName;
            this.authService.user.middleName = res.middleName;
            this.authService.user.timezone = res.timezone;
            this.authService.user.momentTimezone = res.momentTimezone;

            this.appSnackBar.showSnackSuccess({ message: 'Saved successfully' });
        } catch (err) {
            console.warn('[PROFILE] - update error: ', err.message);
            this.appSnackBar.showSnackError({ message: 'Save error' });
        }
    }

    private async onPasswordSubmit(formValue): Promise<void> {
        try {
            await this.apiProfileService.changePassword({
                currentPassword: formValue.currentPassword,
                newPassword: formValue.newPassword,
            });

            this.appSnackBar.showSnackSuccess({ message: 'Saved successfully' });
        } catch (err) {
            const message = err.errors?.[0]?.message || err.error?.message || 'Password change error';
            console.warn('[PROFILE] - update password error: ', err);
            this.appSnackBar.showSnackError({ message });
        }
    }
}
