import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TaskPriority, TaskPriorityReadable } from 'medvidi';
import { FormControl } from '@angular/forms';
import { TASK_PRIORITY_ICON_PATHS, TASK_PRIORITY_OPTIONS } from './task-priorities.const';

@Component({
    selector: 'app-priority-select',
    templateUrl: './priority-select.component.html',
    styleUrls: ['./priority-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrioritySelectComponent {
    public readonly priorityOptions = TASK_PRIORITY_OPTIONS;
    public readonly iconPaths = TASK_PRIORITY_ICON_PATHS;

    @Input() onlyValue: boolean = false;
    @Input() hideLabel: boolean = false;

    @Input() set priority(p: TaskPriority) {
        this.priorityFormControl.setValue(p);
    }

    @Output() priorityChanged = new EventEmitter<number>();

    public get activeLabel(): string {
        return TaskPriorityReadable[this.priorityFormControl.value] || '';
    }

    public get activeIconPath(): string {
        return this.iconPaths[this.priorityFormControl.value];
    }

    public priorityFormControl = new FormControl();

    public selectValueChange($event: number): void {
        this.priorityChanged.next($event);
    }
}
