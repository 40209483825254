import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AutotestsUtilsService } from 'medvidi';
import * as moment from 'moment';
import { DEFAULT_TIMEZONE } from '../../../../shared/constants';

@Component({
    selector: 'app-time-slots',
    templateUrl: './time-slots.component.html',
    styleUrls: ['./time-slots.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSlotsComponent {
    @Input() loaded: boolean = true;
    @Input() schedule: string[] = [];
    @Input() timezone: string = DEFAULT_TIMEZONE;
    @Input() selectedTime?: string;
    @Input() doctorId!: number;

    @Output() selectTime = new EventEmitter<string>();

    constructor(public autoTestUtilsService: AutotestsUtilsService) {}

    public isSelected(time: string): boolean {
        const timeMoment = moment(time);
        const selectedTimeMoment = moment(this.selectedTime);

        return (
            timeMoment.hours() === selectedTimeMoment.hours() &&
            timeMoment.minutes() === selectedTimeMoment.minutes()
        );
    }

    public select(time: string): void {
        this.selectTime.emit(time);
    }
}
