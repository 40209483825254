import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class UserEventService {
    constructor(private http: HttpService) {}

    async createActivatePhoneClientEvent(): Promise<void> {
        return this.http.post('user-event/activate-phone-client', {});
    }

    async createDeactivatePhoneClientEvent(): Promise<void> {
        return this.http.post('user-event/deactivate-phone-client', {});
    }
}
