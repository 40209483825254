import { Injectable, Inject, ErrorHandler } from '@angular/core';
import { captureException } from '@sentry/browser/esm';
import { OPTIONS } from '../tokens';
import { SentryModuleOptions } from '../interfaces';

/**
 * Injectable error handler for Sentry.
 */
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    public constructor(@Inject(OPTIONS) private options: SentryModuleOptions) {}

    public handleError(error: any): void {
        // log to Sentry
        if (this.options.enabled) {
            captureException(error.originalError || error);
        }

        // re-throw error
        throw error;
    }
}
