import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestReasonDialogComponent } from './request-reason-dialog.component';
import { ElementsModule } from '../../../elements/elements.module';
import { MedCommonModule } from 'medvidi';
import { RequestReasonService } from './request-reason.service';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
    declarations: [RequestReasonDialogComponent],
    imports: [CommonModule, ElementsModule, MedCommonModule, MatProgressSpinnerModule],
    providers: [RequestReasonService],
    exports: [RequestReasonDialogComponent],
})
export class RequestReasonDialogModule {}
