import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { ArrowButtonComponent } from './components/arrow-button/arrow-button.component';
import { AttachFileButtonComponent } from './components/attach-file-button/attach-file-button.component';
import { ButtonComponent } from './components/button/button.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { UploadsButtonComponent } from './components/uploads-button/uploads-button.component';
import {
    LeadStatusPipe,
    MomentDatePipe,
    PhonePipe,
    RomanNumeralPipe,
    ValueFormatPipe,
} from './pipes';

@NgModule({
    declarations: [
        ActionButtonComponent,
        ButtonComponent,
        LoaderComponent,
        PaginatorComponent,
        UploadsButtonComponent,
        PrintButtonComponent,
        MomentDatePipe,
        PhonePipe,
        LeadStatusPipe,
        AttachFileButtonComponent,
        ArrowButtonComponent,
        ValueFormatPipe,
        RomanNumeralPipe,
    ],
    imports: [CommonModule, MaterialModule],
    exports: [
        ActionButtonComponent,
        ButtonComponent,
        LoaderComponent,
        PaginatorComponent,
        UploadsButtonComponent,
        PrintButtonComponent,
        MomentDatePipe,
        PhonePipe,
        LeadStatusPipe,
        AttachFileButtonComponent,
        ArrowButtonComponent,
        ValueFormatPipe,
        RomanNumeralPipe,
    ],
})
export class MedCommonModule {}

export * from './components/action-button/action-button.component';
export * from './components/arrow-button/arrow-button.component';
export * from './components/attach-file-button/attach-file-button.component';
export * from './components/button/button.component';
export * from './components/loader/loader.component';
export * from './components/paginator/paginator.component';
export * from './components/print-button/print-button.component';
export * from './components/uploads-button/uploads-button.component';
export * from './consts/error-texts.const';
export * from './consts/timezones.const';
export * from './interfaces';
export * from './pipes';
export * from './pipes/valueFormat.pipe';
export * from './services/date-time.service';
export * from './services/app-emitter.service';
export * from './services/app-file.service';
export * from './services/app-phone.service';
export * from './services/app-query-params.service';
export * from './services/app-state.service';
export * from './services/autotests-utils.service';
export * from './services/common-data.service';
export * from './services/date-time.service';
export * from './services/event-config.service';
export * from './services/http.service';
export * from './services/lead.service';
export * from './services/promo-code.service';
export * from './services/socket.service';

