<div class="container">
    <div class="header">Prescription information</div>
    <div class="body">
        <div class="field-container">
            <div class="field-name">Id:</div>
            <div class="field-value">{{ data.prescription.id }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Name:</div>
            <div class="field-value">{{ data.prescription.displayName }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Generic Drug Name:</div>
            <div class="field-value">{{ data.prescription.genericDrugName }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Generic Product Name:</div>
            <div class="field-value">{{ data.prescription.genericProductName }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Drug Classification:</div>
            <div class="field-value">{{ data.prescription.drugClassification }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Prescription Status:</div>
            <div class="field-value">
                {{ data.prescription.status }}
            </div>
        </div>
        <div class="field-container">
            <div class="field-name">Medication Status:</div>
            <div class="field-value">
                {{ data.prescription.medicationStatus}}
            </div>
        </div>
        <div class="field-container">
            <div class="field-name">Written Date:</div>
            <div class="field-value">
                {{ data.prescription.writtenDate | date : 'MM-dd-yyyy' }}
            </div>
        </div>
        <div class="field-container">
            <div class="field-name">Effective Date:</div>
            <div class="field-value">
                {{ data.prescription.effectiveDate | date : 'MM-dd-yyyy' }}
            </div>
        </div>
        <div class="field-container">
            <div class="field-name">Date Inactive:</div>
            <div class="field-value">
                {{ data.prescription.dateInactive | date : 'MM-dd-yyyy' }}
            </div>
        </div>
        <div class="field-container">
            <div class="field-name">Directions:</div>
            <div class="field-value">{{ data.prescription.directions }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Strength:</div>
            <div class="field-value">{{ data.prescription.strength }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Refills:</div>
            <div class="field-value">{{ data.prescription.refills }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Quantity:</div>
            <div class="field-value">{{ data.prescription.quantity }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Days Supply:</div>
            <div class="field-value">{{ data.prescription.daysSupply }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Urgent:</div>
            <div class="field-value">{{ data.prescription.isUrgent ? 'Yes' : 'No' }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Pharmacy Notes:</div>
            <div class="field-value">{{ data.prescription.pharmacyNotes }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">No Substitutions:</div>
            <div class="field-value">{{ data.prescription.noSubstitutions ? 'Yes' : 'No' }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Prescriber:</div>
            <div class="field-value">{{ prescriberValue }}</div>
        </div>
        <div class="field-container">
            <div class="field-name">Pharmacy:</div>
            <div class="field-value">{{ pharmacyValue }}</div>
        </div>
    </div>
    <div class="actions-container">
        <button mat-stroked-button (click)="closeDialog()">Close</button>
    </div>
</div>
