import { SidebarItem } from './sidebar-item';
import { SafeHtml } from '@angular/platform-browser';

export class Sidebar {
    private logoIcon?: SafeHtml;
    private items: SidebarItem[];

    constructor() {
        this.items = [];
    }

    public addItem(item: SidebarItem): void {
        this.items.push(item);
    }

    public getItems(): SidebarItem[] {
        return this.items;
    }

    public setLogo(logo: SafeHtml): void {
        this.logoIcon = logo;
    }

    public getLogo(): SafeHtml {
        return this.logoIcon;
    }
}
