import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IAuthenticatedUser } from '../../med-api/interfaces';
import { AppStateService } from '../../med-common/services/app-state.service';
import { CommonDataService } from '../../med-common/services/common-data.service';
import { SocketService } from '../../med-common/services/socket.service';

import {
    ApiAuthService,
    AuthGoogleLoginParams,
    AuthLoginParams,
    AuthLoginResponse,
} from '../api/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _isLoggedIn: boolean;
    private onLoginFunc: () => any;
    public isLoggedIn$ = new BehaviorSubject<boolean>(false);
    public user$ = new BehaviorSubject<IAuthenticatedUser>(null);
    public redirectUrl = '/';

    public set user(u: IAuthenticatedUser) {
        this.user$.next(u);
    }

    public get user(): IAuthenticatedUser {
        return this.user$.getValue();
    }

    constructor(
        private apiAuthService: ApiAuthService,
        private appStateService: AppStateService,
        private commonDataService: CommonDataService,
        private socketService: SocketService,
        private router: Router
    ) {}

    public setOnLoginFunction(cb: () => void): void {
        this.onLoginFunc = cb;
    }

    async loadInitialData() {
        await this.commonDataService.loadData();
        this.socketService.initSocketIfNotExists();
        if (typeof this.onLoginFunc === 'function') {
            await this.onLoginFunc();
        }
    }

    async isLoggedIn(): Promise<boolean> {
        if (this._isLoggedIn !== undefined) {
            return this._isLoggedIn;
        }

        try {
            this.appStateService.setAppLoading();
            const response = await this.apiAuthService.checkAuthStatus();
            this._isLoggedIn = response.authenticated;
            this.isLoggedIn$.next(response.authenticated);

            if (this._isLoggedIn) {
                this.user = response.user;
                localStorage.setItem('apiAuthToken', response.token);
                if (response.user.isPasswordExpired) {
                    void this.router.navigate(['/change-password']);
                    this.appStateService.setAppDefault();
                    return true;
                }
                await this.loadInitialData();
            }
            this.appStateService.setAppDefault();

            return this._isLoggedIn;
        } catch (error) {
            console.error('[AuthService]', error);
        }

        this._isLoggedIn = null;
        throw new Error('Cannot check AuthState');
    }

    async masterLogin(userId: string, sessionId: string): Promise<void> {
        try {
            this.appStateService.setAppLoading();
            const loginResponse = await this.apiAuthService.masterLogin(userId, sessionId);
            await this.setDataAfterLogin(loginResponse);
            this.appStateService.setAppDefault();
        } catch (error) {
            console.error('[AuthService]', error);
        }
    }

    async login(params: AuthLoginParams): Promise<void> {
        try {
            const loginResponse = await this.apiAuthService.login(params);
            await this.setDataAfterLogin(loginResponse);
            await this.router.navigate([this.redirectUrl]);
            this.redirectUrl = '/';
        } catch (err) {
            throw err;
        }
    }

    async googleLogin(params: AuthGoogleLoginParams): Promise<void> {
        try {
            const loginResponse = await this.apiAuthService.googleLogin(params);
            await this.setDataAfterLogin(loginResponse);
            await this.router.navigate([this.redirectUrl]);
            this.redirectUrl = '/';
        } catch (err) {
            throw err;
        }
    }

    private async setDataAfterLogin(loginResponse: AuthLoginResponse): Promise<void> {
        localStorage.setItem('apiAuthKey', loginResponse.authKey);
        localStorage.setItem('apiAuthToken', loginResponse.token);
        this._isLoggedIn = true;
        this.isLoggedIn$.next(true);
        this.user = loginResponse.user;
        await this.loadInitialData();
    }

    async logout(redirectUrl: string = '/login'): Promise<void> {
        try {
            this.appStateService.setAppLoading();
            await this.apiAuthService.logout();
            this.socketService.close();
            localStorage.removeItem('apiAuthKey');
            localStorage.removeItem('apiAuthToken');
            this._isLoggedIn = false;
            this.user = null;
            window.location.href = redirectUrl;
            this.isLoggedIn$.next(false);
        } catch (err) {
            throw err;
        }
    }

    public checkAcl(code: string): boolean {
        return this.user?.acl && this.user?.acl[code];
    }
}
