import { Component, OnInit } from '@angular/core';

import { AppStateService } from 'medvidi';

@Component({
    selector: 'app-page-forbidden',
    templateUrl: './page-forbidden.component.html',
    styleUrls: ['./page-forbidden.component.scss'],
})
export class PageForbiddenComponent implements OnInit {
    constructor(private appStateService: AppStateService) {}

    ngOnInit() {}

    initAppStateDefault() {
        this.appStateService.setAppDefault();
    }
}
