import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { TaskNames } from '../../../../../consts/task-names';
import { ApiTaskService, AppointmentMethodType, Task, TaskItem, TaskService } from 'medvidi';
import { from, Subject } from 'rxjs';

@Component({
    selector: 'app-task-card',
    templateUrl: './task-card.component.html',
    styleUrls: ['./task-card.component.scss'],
})
export class TaskCardComponent implements OnInit, OnDestroy {
    @Input() task: Task;
    @Input() resolved = false;

    public taskItem: TaskItem;
    public queryParams: Params;
    public link: string;

    private destroy$ = new Subject<void>();

    constructor(
        public taskService: TaskService,
        private router: Router,
        private apiTaskService: ApiTaskService
    ) {}

    public get isInsuranceLead(): boolean {
        return this.task?.lead?.hasInsurance;
    }

    public get isBlacklisted(): boolean {
        return this.task?.lead?.blacklisted;
    }

    public get isRedFlagAdded(): boolean {
        return this.task?.lead?.redFlag;
    }

    public get isOfflineVisit(): boolean {
        return this.task.appointmentMethod === AppointmentMethodType.OFFLINE;
    }

    public get isHidePDMPLink(): boolean {
        const isPDMPTask =
            this?.task?.name === TaskNames.UPDATE_PDMP ||
            this?.task?.name === TaskNames.PDMP_VERIFICATION;

        return isPDMPTask && !this.task.closedAt;
    }

    ngOnInit(): void {
        this.taskItem = new TaskItem(this.task);
        this.queryParams = {
            ...this.taskItem.getRelatedLinkData()?.queryParams,
            taskId: this.task.id,
        };

        this.link = this.taskItem.getRelatedLinkData()?.link;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public openDescriptionLink() {
        window.open(this.task.descriptionLink, '_blank');
    }

    public selectTask(): void {
        if (!this.link) return;
        this.router.navigate([this.link], { queryParams: this.queryParams });
    }

    public getFullName(): string {
        let fullName: string = '';

        if (this.task?.lead?.firstName) {
            fullName += this.task.lead.firstName;
        }

        fullName += fullName ? ' ' : '';

        if (this.task?.lead?.lastName) {
            fullName += this.task.lead.lastName;
        }

        return fullName || 'New Lead';
    }

    public onPriorityOfTaskChanged(priority: number) {
        from(this.apiTaskService.changePriorityById(this.task.id, priority)).subscribe((res) => {
            this.taskService.refreshMyTasks$.next();
            this.taskService.tasksChanged$.next(res.task);
        });
    }
}
