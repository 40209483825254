import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FinishDayDialogComponent } from './finish-day-dialog.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
    declarations: [FinishDayDialogComponent],
    exports: [FinishDayDialogComponent],
    imports: [MatDialogModule, MatButtonModule],
})
export class FinishDayDialogModule {}
