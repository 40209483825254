import { FormField } from '../form-field';
import { Observable } from 'rxjs';
import {
    FormAsyncAutocompleteParams,
    IAutocompleteOptionsItem,
} from '../../interfaces/autocomplete-params';

export class FormAutocompleteField extends FormField {
    optionsFunction: (a: string) => Observable<IAutocompleteOptionsItem[]>;
    options: IAutocompleteOptionsItem[];
    isAsyncOptions: boolean;
    minSearchLength: number;

    constructor(params: FormAsyncAutocompleteParams) {
        super(params);
        this.optionsFunction = params.optionsFunction;
        this.options = params.options;
        this.isAsyncOptions = params.isAsyncOptions ?? false;
        this.minSearchLength = params.minSearchLength ?? 0;
    }
}
