<div
    [class.selected]="task.id === taskService.selectedTask?.id"
    [class.resolved]="resolved"
    [routerLink]="[link]"
    [queryParams]="queryParams"
    (click)="selectTask()"
    class="container"
>
    <div class="card-header-container">
        <div class="lead-info">
            <div class="lead-name" [title]="getFullName()">
                {{ getFullName() }}
            </div>
        </div>
        <div class="task-timestamp">
            {{ taskItem.getAttachedAtTimestamp() }}
        </div>
    </div>

    <div class="card-body-container">
        <div
            class="task-type-badge"
            [class.selected]="task.id === taskService.selectedTask?.id"
            [title]="task.name"
        >
            {{ task.name }}
        </div>
        <div class="icons-list">
            <mat-icon *ngIf="isBlacklisted" class="blacklist"> bookmark</mat-icon>
            <mat-icon *ngIf="isRedFlagAdded" class="special-attention"> bookmark</mat-icon>
            <mat-icon *ngIf="isInsuranceLead" [title]="'Insurance'" class="insurance-badge">
                health_and_safety
            </mat-icon>
            <mat-icon *ngIf="isOfflineVisit" class="offline">error</mat-icon>
        </div>
    </div>

    <app-priority-select
        *ngIf="!task.closedAt"
        class="priority-select"
        [priority]="taskItem.task.priority"
        (priorityChanged)="onPriorityOfTaskChanged($event)"
        stopClick
    ></app-priority-select>

    <div *ngIf="task.description && !task.descriptionLink && !isHidePDMPLink" class="card-desc">
        {{ task.description }}
    </div>

    <a
        (click)="openDescriptionLink()"
        *ngIf="task.description && task.descriptionLink"
        class="card-desc"
    >
        {{ task.description }}
    </a>
</div>
