<div class="finish-day-dialog"></div>
<mat-dialog-content>
    <div class="finish-day-dialog__text">
        We will finish your "working day" when you log out of account.<br />
        Don't forget to start it again the next time you log in.
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button [color]="'primary'" [mat-dialog-close]="true">Log out</button>
</mat-dialog-actions>
