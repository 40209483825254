export const TIMEZONES = {
    PST: 'America/Los_Angeles',
    MST: 'America/Denver',
    'MST (Arizona)': 'America/Phoenix',
    CST: 'America/Chicago',
    EST: 'America/New_York',
    AKST: 'America/Anchorage',
    HST: 'Pacific/Honolulu',
    CET: 'Europe/Berlin',
    MSK: 'Europe/Moscow',
    IST: 'Asia/Kolkata',
};
