import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    ISalaryListParams,
    ISalaryListResponse,
    ISalaryDownloadParams,
    IPayoutsSalaryParams,
    IPayoutsSalaryResponse,
} from '../interfaces';
import { AppFileService } from '../../med-common/services/app-file.service';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class ApiSalaryService {
    constructor(private http: HttpService, private appFileService: AppFileService) {}

    async list(params: ISalaryListParams): Promise<ISalaryListResponse> {
        return this.http.post<ISalaryListResponse>(`crm/salary/list`, params);
    }

    async listDownload(params: ISalaryDownloadParams): Promise<void> {
        const data = await this.http.post<any>(`crm/salary/list/download`, params, null, {
            responseType: 'arraybuffer',
        });

        return this.appFileService.downloadFileFromBlob(
            new Blob([data]),
            `salary_${DateTime.now().toFormat('yyyy_LLL_dd')}.csv`
        );
    }

    async payouts(params: IPayoutsSalaryParams): Promise<IPayoutsSalaryResponse> {
        return this.http.post<IPayoutsSalaryResponse>(`crm/salary/payouts`, params);
    }

    async summaryDownload(params: IPayoutsSalaryParams): Promise<void> {
        const data = await this.http.post<any>(`crm/salary/summary/download`, params, null, {
            responseType: 'arraybuffer',
        });

        return this.appFileService.downloadFileFromBlob(
            new Blob([data]),
            `summary_${DateTime.now().toFormat('yyyy_LLL_dd')}.csv`
        );
    }
}
