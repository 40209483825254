import { Form, FormEvent } from './form';
import { FormField } from './form-field';
import { FormFieldParams } from '../interfaces/form-field-params';
import { FormInputParams } from '../interfaces/form-input-params';
import { FormSelectParams } from '../interfaces/form-select-params';
import { FormMultiInputParams } from '../interfaces/form-multi-input-params';
import { FormMultiInputAutoParams } from '../interfaces/form-multi-input-auto-params';
import { ValidatorFn } from '@angular/forms';
import { FormAsyncAutocompleteParams } from '../interfaces/autocomplete-params';

export class FormBuilder {
    private form: Form;

    constructor() {
        this.form = new Form();
    }

    public addField(
        FieldType: typeof FormField,
        controlName: string,
        fieldParams:
            | FormFieldParams
            | FormMultiInputParams
            | FormMultiInputAutoParams
            | FormInputParams
            | FormAsyncAutocompleteParams
            | FormSelectParams = {}
    ) {
        this.form.addField(
            controlName,
            new FieldType({ ...fieldParams, ...{ controlName: controlName } })
        );
    }

    public addSubmitButton(label: string = 'SUBMIT') {
        this.form.setSubmitButton(label);
    }

    public setHandler(event: FormEvent, func: Function) {
        this.form.setHandler(event, func);
    }

    public setFormValidators(validators: ValidatorFn[]): void {
        this.form.setFormGroupValidators(validators);
    }

    public getForm(): Form {
        return this.form;
    }
}
