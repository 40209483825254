import { Injectable } from '@angular/core';

import { HttpService } from '../../med-common/med-common.module';
import {
    IElectronicPrescriptionsCreatePatientResponse,
    IElectronicPrescriptionsLinkPatientResponse,
    IElectronicPrescriptionsPatient,
    IElectronicPrescriptionsSearchPatientRequest,
    INotificationsDataResponse,
    IPrescriptionsDataResponse,
    IPrescriptionsPatientProfileData,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ElectronicPrescriptionsService {
    constructor(private http: HttpService) {}

    async getElectronicPrescriptionsData(
        leadId: number,
        params: {
            pageNumber: number;
        }
    ): Promise<IPrescriptionsDataResponse> {
        return this.http.get<IPrescriptionsDataResponse>(
            `electronic-prescriptions?leadId=${leadId}&pageNumber=${params.pageNumber}`
        );
    }

    async getNotificationsData(): Promise<INotificationsDataResponse> {
        return this.http.get<INotificationsDataResponse>(`electronic-prescriptions/notifications`);
    }

    async getPatientProfileData(leadId: number): Promise<IPrescriptionsPatientProfileData> {
        return this.http.get<IPrescriptionsPatientProfileData>(
            `electronic-prescriptions/patient/profile-data?leadId=${leadId}`
        );
    }

    async createPatient(
        leadId: number,
        leadData: IPrescriptionsPatientProfileData
    ): Promise<IElectronicPrescriptionsCreatePatientResponse> {
        return this.http.post<IElectronicPrescriptionsCreatePatientResponse>(
            `electronic-prescriptions/patient`,
            { leadData, leadId }
        );
    }

    async searchPatient(
        params: IElectronicPrescriptionsSearchPatientRequest
    ): Promise<IElectronicPrescriptionsPatient[]> {
        const query = `firstName=${params.firstName}&lastName=${params.lastName}&dob=${params.dob}`;

        return this.http.get<IElectronicPrescriptionsPatient[]>(
            `electronic-prescriptions/patient/search?${query}`
        );
    }

    async linkPatient(
        leadId: number,
        dosespotId: number
    ): Promise<IElectronicPrescriptionsLinkPatientResponse> {
        return this.http.post<IElectronicPrescriptionsLinkPatientResponse>(
            `electronic-prescriptions/patient/link`,
            { leadId, dosespotId }
        );
    }

    async getPatientPrescriptionsLink(
        leadId: number
    ): Promise<IElectronicPrescriptionsLinkPatientResponse> {
        return this.http.get<IElectronicPrescriptionsLinkPatientResponse>(
            `electronic-prescriptions/patient/prescriptions-link?leadId=${leadId}`
        );
    }
}
