import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EScrollShadowPositions } from './scroll-shadow.enum';
import { LINEAR_GRADIENT_STYLES } from './scroll-shadow.config';

@Directive({ selector: '[scrollShadow]' })
export class ScrollShadowDirective implements AfterViewInit, OnDestroy {
    @Input('scrollShadow') elementClass: string = '';

    private lastScrollShadowPosition: EScrollShadowPositions | null = null;

    private element: HTMLElement | null = null;

    private observer: MutationObserver;
    private destroy$ = new Subject<void>();

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.startTrackingScroll();
    }

    ngOnDestroy(): void {
        this.observer?.disconnect();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initElement(): void {
        if (!this.elementClass) {
            this.element = this.el.nativeElement;
            return;
        }

        this.element = this.el.nativeElement.querySelector(this.elementClass) as HTMLElement;
    }

    private startTrackingScroll(): void {
        this.initElement();
        this.updateScrollShadow();

        fromEvent(this.element, 'scroll')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.updateScrollShadow());

        this.observer = new MutationObserver(() => this.updateScrollShadow());
        this.observer.observe(this.element, { childList: true, subtree: true });
    }

    private updateScrollShadow(): void {
        if (!this.element) return;

        const isContentFullyDisplayed = this.element.scrollHeight === this.element.clientHeight;

        if (isContentFullyDisplayed) {
            this.removeScrollShadowStyles();
            return;
        }

        const scrollTop = this.element.scrollTop + 5;
        const scrollHeight = this.element.scrollHeight;
        const clientHeight = this.element.clientHeight;

        if (scrollTop === 5) {
            this.setScrollShadowStyles(EScrollShadowPositions.BOTTOM);
        } else if (scrollTop >= scrollHeight - clientHeight) {
            this.setScrollShadowStyles(EScrollShadowPositions.TOP);
        } else {
            this.setScrollShadowStyles(EScrollShadowPositions.TOP_AND_BOTTOM);
        }
    }

    private setScrollShadowStyles(position: EScrollShadowPositions): void {
        if (this.lastScrollShadowPosition === position) return;

        this.lastScrollShadowPosition = position;

        this.renderer.setStyle(this.element, 'mask-image', LINEAR_GRADIENT_STYLES[position]);

        this.renderer.setStyle(
            this.element,
            '-webkit-mask-image',
            LINEAR_GRADIENT_STYLES[position]
        );
    }

    private removeScrollShadowStyles(): void {
        this.renderer.removeStyle(this.element, 'mask-image');
        this.renderer.removeStyle(this.element, '-webkit-mask-image');
    }
}
