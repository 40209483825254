<div class="crm-dialog-title" mat-dialog-title>
    <!--  Can Receive Calls  -->
    <div *ngIf="isUserCanReceiveCalls" class="incoming-calls-info">
        Incoming calls worker:
        <span
            class="incoming-calls-info__status"
            [class.online]="isIncomingCallsOnline"
            [class.offline]="!isIncomingCallsOnline"
            >{{ isIncomingCallsOnline ? 'Online' : 'Offline' }}</span
        >
    </div>
    <!--  For Users who can't receives calls  -->
    <div *ngIf="!isUserCanReceiveCalls">Phone Client</div>

    <!--  Help / Logs toggle  -->
    <div *ngIf="isUserCanReceiveCalls" class="help-block" (click)="isShowHelp = !isShowHelp">
        <mat-icon>{{ isShowHelp ? 'manage_search' : 'help_outline' }}</mat-icon>
        <a>{{ isShowHelp ? 'Show logs' : 'Need help?' }}</a>
    </div>
</div>
<div class="crm-dialog-content" mat-dialog-content>
    <code
        #scrollContainer
        class="logs"
        *ngIf="phoneClientLogService.phoneClientLogMessages.length && !isShowHelp"
    >
        <mat-icon
            *ngIf="phoneClientLogService.phoneClientLogMessages.length > 1"
            class="logs__copy"
            [matTooltip]="'Download logs'"
            (click)="downloadLogs()"
            >download</mat-icon
        >
        <p
            *ngFor="
                let m of phoneClientLogService.reversedPhoneClientLogMessages;
                trackBy: trackByFn
            "
            class="log-line"
        >
            <span class="log-time">{{ m.date | date : 'hh:mm:ss a' }}</span
            >&nbsp;&bull;&nbsp;{{ m.message }}
        </p>
    </code>
    <div
        class="instruction"
        *ngIf="!phoneClientLogService.phoneClientLogMessages.length && !isShowHelp"
    >
        <div>This window controls the Phone Client feature.</div>
        <div>You can activate the functionality you need using buttons below.</div>
    </div>

    <div *ngIf="isShowHelp" class="help-block instruction">
        <div>This window controls the Phone Client feature.</div>
        <br />
        <div>
            If the worker status is <b class="ref online">"Online"</b>, it means that you are
            visible in our calls system and we are prepared to route incoming calls to you.
        </div>
        <br />
        <div>
            If the status is <b class="ref offline">"Offline"</b>, this indicates that you are
            currently not available for incoming calls. This could be because you didn't enable the
            feature to receive incoming calls, the system has turned you off due to inactivity or
            poor internet connection, or there were technical issues on any side.
        </div>
        <br />
        <div>
            To fix most issues with receiving calls, you simply need to restart the service by
            clicking the buttons in the following sequence:
            <ol>
                <li><b class="ref deactivate">[De-Activate]</b></li>
                <li><b class="ref activate-incoming">[Activate Outgoing & Incoming]</b></li>
            </ol>
        </div>
        <div>
            If you are still having issues, you can download the log file by clicking on the
            <mat-icon class="download-icon">download</mat-icon>
            icon next to the logs, and provide it to the developers for precise problem
            determination.
        </div>
    </div>
</div>

<div class="crm-dialog-actions" mat-dialog-actions>
    <button (click)="onClose()" class="close-button" mat-raised-button>Close</button>
    <div class="button-group">
        <button
            *ngIf="phoneClientService.isActive"
            [disabled]="phoneClientService.isPhoneClientTogglingInProgress"
            (click)="phoneClientService.deActivatePhoneClient()"
            color="warn"
            mat-raised-button
        >
            De-Activate
        </button>
        <button
            *ngIf="!phoneClientService.isActive"
            [disabled]="phoneClientService.isPhoneClientTogglingInProgress"
            (click)="phoneClientService.activatePhoneClient()"
            color="primary"
            mat-raised-button
        >
            Activate Outgoing
        </button>

        <button
            *ngIf="!phoneClientService.isActive && isUserCanReceiveCalls"
            [disabled]="phoneClientService.isPhoneClientTogglingInProgress"
            (click)="this.activateWithIncomingCalls()"
            color="primary"
            mat-raised-button
        >
            Activate Outgoing & Incoming
        </button>
    </div>
</div>
