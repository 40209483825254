import { Injectable } from '@angular/core';
import { ApiManagerService, AuthService, UserRole } from 'medvidi';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class WorkControlService {
    public isDayStarted$ = new BehaviorSubject<boolean>(false);
    public isWorkSwitchLoading$ = new BehaviorSubject<boolean>(false);

    public get isManager(): boolean {
        return this.authService.user.role === UserRole.MANAGER;
    }

    constructor(private apiManagerService: ApiManagerService, private authService: AuthService) {
        this.init();
    }

    public init(): void {
        if (!this.isManager) return;

        this.isDayStarted$.next(this.authService.user.isDayStarted);
    }

    public startDay(): void {
        if (this.isWorkSwitchLoading$.getValue()) return;

        this.isWorkSwitchLoading$.next(true);

        from(this.apiManagerService.startDay())
            .pipe(finalize(() => this.isWorkSwitchLoading$.next(false)))
            .subscribe(() => this.isDayStarted$.next(true));
    }

    public finishDay$(): Observable<{}> {
        if (this.isWorkSwitchLoading$.getValue()) return;

        this.isWorkSwitchLoading$.next(true);

        return from(this.apiManagerService.finishDay()).pipe(
            finalize(() => this.isWorkSwitchLoading$.next(false)),
            tap(() => this.isDayStarted$.next(false))
        );
    }
}
