<div class="container">
    <div class="header">
        <p class="title">Appointment details</p>
        <p class="subtitle">
            <span class="subtitle_bold">{{ servicePlanType }}</span>
            <span class="subtitle_bold">•</span>
            <span>Select the date, time and provider for the appointment</span>
        </p>
        <app-close-button
            class="close-dialog-button"
            (handleClick)="dialogRef.close()"
        ></app-close-button>
    </div>
    <div class="body">
        <div [class.dropdown-open]="search.isOpen" class="schedules-container">
            <span
                *ngIf="lead?.finalPaymentDate && clientRequestedTime"
                class="two-payments-lead-info"
            >
                Slots available after chosen time:
                {{ lead.clientAppointmentTime | momentPipe: momentTimezone:'LLL':true }}
            </span>
            <app-doctor-search
                #search
                (searchDoctor)="handleDoctorSearchChange($event)"
            ></app-doctor-search>
            <div class="doctor-schedules">
                <app-doctor-schedule
                    *ngFor="let item of schedule"
                    [currentUser]="authService.user"
                    [doctor]="item.doctor"
                    [selectedDoctorId]="selectedDoctorId"
                    [schedule]="item.slots"
                    [displayedDate]="displayedDate"
                    [selectedDate]="dateForCalendar"
                    [momentTimezone]="momentTimezone"
                    [isConfirmedDateOpened]="isConfirmedDateOpened"
                    [confirmedTime]="confirmedTime"
                    [selectedTime]="selectedTime"
                    (selectTime)="handleTimeChange($event)"
                ></app-doctor-schedule>
                <p *ngIf="!schedule?.length">There are no doctors matching lead requirements.</p>
            </div>
        </div>
        <app-schedule-appointment-calendar
            [doctorId]="selectedDoctorId"
            [lead]="lead"
            [appointment]="appointment"
            [selectedDate]="dateForCalendar"
            [momentTimezone]="momentTimezone"
            (updateDate)="handleDateChange($event)"
        ></app-schedule-appointment-calendar>
    </div>
    <app-appointment-confirmation-footer
        (closeDialog)="dialogRef.close()"
        (confirm)="sendConfirmRequest()"
        [isDisabled]="isChangeButtonDisabled"
        [timezone]="leadTimezone"
    ></app-appointment-confirmation-footer>
</div>
<div *ngIf="!loaded" class="loader">
    <mat-spinner [diameter]="100" color="primary" class="spinner"></mat-spinner>
</div>
