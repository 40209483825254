export enum RequestReasons {
    PRESCRIPTION_RELATED_ISSUES = 'Prescription Related Issues',
    VERIFICATION_AND_CONFIRMATION = 'Verification and Confirmation',
    REFUND = 'Refund',
    GENERAL_QUERIES = 'General Queries',
    PA_AND_INSURANCE = 'PA and Insurance',
}

export const SubRequestReasons: {
    [key: string]: string[];
} = {
    [RequestReasons.PRESCRIPTION_RELATED_ISSUES]: [
        'Status of Prescription',
        'Change of Pharmacy',
        'Dosage Change',
        'Not happy with prescription want to change',
        'Pharmacy Verification Calls',
        'Resend my prescription after PA approval',
        'Request to send the prescription to a different state',
        'Requesting to talk to the doctor about medication',
        'Need a letter or document signed by the provider',
        'Release of medical records',
    ],
    [RequestReasons.VERIFICATION_AND_CONFIRMATION]: [
        'Doc unavailable status of the website',
        'Unable to make payment',
        'Intake form not working or taking back to Payment',
        'Need new intake link',
        'Is the appointment confirmed',
        'Awaiting Appointment Link (Top of the list nowadays in this category)',
        'Chargeback dispute pt waiting for the appointment',
        'Change of appointment time and date/ reschedule expired appointment',
        'Use of credit for the new appointment',
    ],
    [RequestReasons.REFUND]: [
        'Request for Refund',
        'Status of Refund',
        "Haven't received the refund, clarity about the processing time",
    ],
    [RequestReasons.GENERAL_QUERIES]: [
        'Services and availability in a particular state',
        'Guidance with booking and other processes',
        'Request for promo or discount',
        'Request about the change of email or number',
        'Resend the last text or email message',
        'Doctor change request',
        'Confirmation of payment',
        'Follow-up call',
    ],
    [RequestReasons.PA_AND_INSURANCE]: [
        'Information about the process',
        'Where to send documents',
        'Is PA or Insurance approved',
        'Is my PA sent to the pharmacy',
    ],
};

export const Reasons: string[] = [
    'Resolved',
    'Not Resolved',
    'Call dropped',
    'Created task to ACS team',
    'Created task to Scheduling Team',
    'Created task to Refund&Disputes Team',
    'Created task to Billing Team',
];
