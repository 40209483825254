import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SidebarStorageService {
    private menuLabel: string = null;
    public additionalMenuOpen: boolean = false;
    public additionalMenuLabel: string = null;
    public additionalMenu: any[] = [];

    constructor() {}

    public setActiveMenu(label: string) {
        this.menuLabel = label;
    }

    public getActiveMenu(): string {
        return this.menuLabel;
    }
}
