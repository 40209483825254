import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { PhoneClientService } from '../../../../services/phone-client.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-phone-settings',
    templateUrl: './phone-settings.component.html',
    styleUrls: ['./phone-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneSettingsComponent implements OnInit, AfterViewInit, OnDestroy {
    public inputDeviceControl = new FormControl('');
    public outputDeviceControl = new FormControl('');

    public isComponentPending = true;
    public isPermissionsPending = true;

    public availableInputDevices: { label: string; value: string }[] = [];
    public availableOutputDevices: { label: string; value: string }[] = [];

    private _inputVolume$ = new BehaviorSubject<number>(0);

    public inputVolume$ = this._inputVolume$.asObservable().pipe(
        map((volume: number) => volume * 100),
        map((volume) => Math.round(volume)),
        distinctUntilChanged()
    );

    private destroy$ = new Subject<void>();

    constructor(private phoneClientService: PhoneClientService, private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.isPermissionsPending = true;

        void navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                this.isPermissionsPending = false;
                this.cd.detectChanges();

                const audioHelper = this.phoneClientService.device.audio;
                this.availableInputDevices = this.generateDeviceOptions(
                    audioHelper.availableInputDevices
                );
                this.availableOutputDevices = this.generateDeviceOptions(
                    audioHelper.availableOutputDevices
                );
                // form control subscriptions
                this.initDevicesSubscription();

                this.initCurrentInputDevice();
                this.initCurrentOutputDevice();
            })
            .catch(() => {
                this.isComponentPending = false;
                this.cd.markForCheck();
            });
    }

    ngAfterViewInit() {
        this.isComponentPending = false;
    }

    private initCurrentInputDevice(): void {
        const deviceId =
            this.phoneClientService.device.audio.inputDevice?.deviceId ||
            this.availableInputDevices[0]?.value;

        this.inputDeviceControl.setValue(deviceId, { emitEvent: true });
    }

    private initCurrentOutputDevice(): void {
        const speakerDevices = this.phoneClientService.device.audio.speakerDevices.get();
        speakerDevices.forEach((spkDev) => {
            this.outputDeviceControl.setValue(spkDev.deviceId);
            return;
        });
    }

    private generateDeviceOptions(devices: any) {
        const result = [];
        devices.forEach((device, id) => {
            const deviceOption = { label: device.label, value: id };
            result.push(deviceOption);
        });
        return result;
    }

    private initDevicesSubscription(): void {
        this.outputDeviceControl.valueChanges
            .pipe(takeUntil(this.destroy$), filter(Boolean))
            .subscribe((deviceId) => {
                void this.phoneClientService.device.audio.speakerDevices.set(deviceId as string);
            });

        this.inputDeviceControl.valueChanges
            .pipe(takeUntil(this.destroy$), filter(Boolean), tap(this.removeVolumeListeners))
            .subscribe((deviceId) => {
                void this.phoneClientService.device.audio.setInputDevice(deviceId as string);

                this.phoneClientService.device.audio.on('inputVolume', (volume) => {
                    this._inputVolume$.next(volume);
                });
            });
    }

    private removeVolumeListeners = () => {
        const listeners = this.phoneClientService.device?.audio?.listeners('inputVolume');
        if (!listeners?.length) return;

        listeners.forEach((fn: (...args: any[]) => void) => {
            this.phoneClientService.device?.audio?.removeListener('inputVolume', fn);
        });
    };

    public testCurrentSpeaker(): void {
        this.phoneClientService.testSpeaker();
    }

    ngOnDestroy() {
        this.removeVolumeListeners();
        this.destroy$.next();
        this.destroy$.complete();
    }
}
