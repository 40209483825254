export enum LeadTabList {
    ACTIVITY = 'Activity',
    PHONE = 'Phone',
    EMAIL = 'Email',
    INTERCOM_HISTORY = 'Intercom',
    NOTES = 'Notes',
    APPOINTMENTS = 'Appointments',
    INSURANCE = 'Insurance',
    MEDICAL_HISTORY = 'Medical History',
    ELECTRONIC_PRESCRIPTIONS = 'eRx',
}
