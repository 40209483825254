<div class="container">
    <div class="header">
        <div class="header-column-left">
            <div class="header-text">My tasks</div>
            <div class="header-description">
                {{ taskService.inProgressTasks?.length || 0 }} active
            </div>
        </div>
        <div class="tasks-actions">
            <!-- Audio Settings -->
            <button
                *ngIf="isSettingsButtonVisible"
                class="tasks-action-button settings"
                matTooltip="Audio settings"
                [class.disabled]="!phoneClientService.isActive"
                [class.active]="phoneClientService.isActive && showAudioSettings"
                (click)="toggleAudioSettings()"
            >
                <mat-icon>settings</mat-icon>
            </button>
            <!-- Dialpad -->
            <button
                (click)="toggleDialpad()"
                *ngIf="authService.user?.id"
                [class.disabled]="!phoneClientService.isActive"
                [class.active]="phoneClientService.isActive && showDialpad"
                class="tasks-action-button"
                matTooltip="Dialpad"
            >
                <img src="assets/img/svg/dialpad.svg" />
            </button>
            <button
                (click)="openPhoneClientDialog()"
                *ngIf="authService.user.id"
                [ngClass]="{
                    'active-client': isActiveClient,
                    disabled: isDisabledCallButton || (isManager && !(isDayStarted$ | async))
                }"
                class="tasks-action-button"
                matTooltip="Phone Client"
            >
                <mat-icon>phone</mat-icon>
                <div class="circle" [class.active]="phoneClientService.isActive"></div>
            </button>
            <app-icon-button
                (onClick)="hideTaskList()"
                [matIconRef]="'chevron_left'"
                [tooltip]="'Hide'"
                class="toggle-button"
            >
            </app-icon-button>
        </div>
    </div>
    <div class="body">
        <div class="phone-client-notification" *ngIf="isDisplayPhoneClientNotification">
            <mat-icon class="notification-icon">error_outline</mat-icon>
            <div class="notification-text">
                <span class="notification-text__sentence"
                    >The function to receive incoming calls is currently deactivated.</span
                >
                <span class="notification-text__sentence"
                    >You can activate it <a (click)="openPhoneClientDialog()">here</a>.</span
                >
            </div>
        </div>

        <app-phone-settings *ngIf="isDisplayAudioSettingsPanel"></app-phone-settings>

        <div class="call-container">
            <!--  DialPad  -->
            <app-dialpad *ngIf="isDialPadVisible"></app-dialpad>
            <!--  Call panel  -->
            <app-call-panel
                *ngIf="phoneClientService.callPanelData"
                [data]="phoneClientService.callPanelData"
            ></app-call-panel>
        </div>

        <div class="actions">
            <!-- START AND FINISH DAY -->
            <button
                *ngIf="isManager"
                class="action working-day"
                matTooltipClass="tooltip-custom-font-lg"
                data-test-id="Start and Finish day"
                [disabled]="isWorkSwitchLoading$ | async"
                [class.loading]="isWorkSwitchLoading$ | async"
                [class.start-day]="!(isDayStarted$ | async)"
                [class.finish-day]="isDayStarted$ | async"
                [matTooltip]="!(isDayStarted$ | async) ? 'Start day' : 'Finish day'"
                (click)="onStartOrFinishDay()"
            >
                <ng-container *ngIf="isWorkSwitchLoading$ | async; else playAndPauseIcons">
                    <img src="assets/img/svg/progress_activity.svg" alt="progress icon" />
                </ng-container>
                <ng-template #playAndPauseIcons [ngSwitch]="isDayStarted$ | async">
                    <img *ngSwitchCase="false" src="assets/img/svg/play.svg" alt="play icon" />
                    <img *ngSwitchCase="true" src="assets/img/svg/pause.svg" alt="pause icon" />
                </ng-template>
            </button>
            <!-- UPDATE BOARD -->
            <button
                class="action update-board"
                [class.loading]="isRefreshLoading"
                matTooltipClass="tooltip-custom-font-lg"
                data-test-id="Update board"
                [matTooltip]="'Refresh board'"
                [disabled]="isRefreshLoading"
                (click)="updateBoard()"
            >
                <img src="assets/img/svg/refresh.svg" alt="refresh icon" />
            </button>
        </div>

        <!--  TO DO TASKS  -->
        <div class="task-container">
            <div (click)="toggleTodoTasksCollapse()" class="block-title">
                <span class="task-title">To Do</span>
                <mat-icon class="tasks-arrow"
                    >{{ isTodoTaskCollapsed ? 'expand_less' : 'expand_more' }}
                </mat-icon>
            </div>
            <div [class.hidden]="isTodoTaskCollapsed" class="task-data-container">
                <app-task-card
                    *ngFor="let task of taskService.todoTasks"
                    [task]="task"
                    [attr.data-test-id]="task.id"
                ></app-task-card>
                <div *ngIf="!taskService.todoTasks?.length" class="no-tasks-desc">
                    No to do tasks
                </div>
            </div>
        </div>

        <!--  In Progress TASKS  -->
        <div class="task-container">
            <div (click)="toggleInProgressTasksCollapse()" class="block-title">
                <span class="task-title">In progress</span>
                <mat-icon class="tasks-arrow"
                    >{{ isInProgressTaskCollapsed ? 'expand_less' : 'expand_more' }}
                </mat-icon>
            </div>
            <div [ngClass]="{ hidden: isInProgressTaskCollapsed }" class="task-data-container">
                <ng-container *ngFor="let task of taskService.inProgressTasks">
                    <app-task-card [task]="task" [attr.data-test-id]="task.id"></app-task-card>
                </ng-container>

                <div *ngIf="!taskService.inProgressTasks?.length" class="no-tasks-desc">
                    No active tasks
                </div>
            </div>
        </div>

        <!--  ON HOLD TASKS  -->
        <div class="task-container">
            <div (click)="toggleOnHoldCollapse()" class="block-title">
                <span class="task-title">On hold</span>
                <mat-icon class="tasks-arrow"
                    >{{ isOnHoldTaskCollapsed ? 'expand_less' : 'expand_more' }}
                </mat-icon>
            </div>
            <div [class.hidden]="isOnHoldTaskCollapsed" class="task-data-container">
                <app-task-card
                    *ngFor="let task of taskService.onHoldTasks"
                    [task]="task"
                    [attr.data-test-id]="task.id"
                ></app-task-card>
                <div *ngIf="!taskService.onHoldTasks?.length" class="no-tasks-desc">
                    No tasks on hold
                </div>
            </div>
        </div>

        <!--  COMPLETED TASKS  -->
        <div class="task-container">
            <div (click)="toggleCompletedTasksCollapse()" class="block-title">
                <span class="task-title">Completed</span>
                <mat-icon class="tasks-arrow"
                    >{{ isCompletedTaskCollapsed ? 'expand_less' : 'expand_more' }}
                </mat-icon>
            </div>
            <div [class.hidden]="isCompletedTaskCollapsed" class="task-data-container">
                <app-task-card
                    *ngFor="let task of taskService.completedTasks"
                    [resolved]="true"
                    [task]="task"
                    [attr.data-test-id]="task.id"
                ></app-task-card>
                <div *ngIf="!taskService.completedTasks?.length" class="no-tasks-desc">
                    No completed tasks
                </div>
            </div>
        </div>
    </div>
</div>
