<app-middle-container>
    <div class="error-container">
        <h1 class="error-title">Forbidden</h1>
        <div class="error-code">403</div>
        <div class="error-message">
            Sorry, but we were unable to go to the page you are looking for.
        </div>
        <a (click)="initAppStateDefault()" [routerLink]="['/']" class="back-link"
            >Go back to dashboard</a
        >
    </div>
</app-middle-container>
