<form class="form-container" [formGroup]="formGroup" (ngSubmit)="submit()">
    <med-form-field
        *ngFor="let field of form.getFields()"
        [field]="field"
        [formGroup]="formGroup"
        (clickIcon)="onClickActionIcon($event)"
    ></med-form-field>

    <ng-content></ng-content>

    <mat-error *ngIf="!formGroup.valid && form.hasGeneralError()" class="general-error">{{
        errorTextService.getMessage(formGroup)
    }}</mat-error>
    <mat-error
        *ngIf="
            !formGroup.valid &&
            formGroup.hasError(errorTextService.getFormGroupErrorCodes().INVALID_DATE_RANGE)
        "
        class="general-error"
    >
        {{
            errorTextService.getFormGroupErrorText(
                errorTextService.getFormGroupErrorCodes().INVALID_DATE_RANGE
            )
        }}
    </mat-error>

    <div class="buttons">
        <button
            mat-raised-button
            color="primary"
            type="submit"
            [attr.data-test-id]="form.getSubmitButton().label"
            [disabled]="formProcessing || !formGroup.valid"
            *ngIf="form.getSubmitButton()"
        >
            <span *ngIf="formProcessing" class="spinner">
                <mat-spinner [diameter]="20" color="accent"></mat-spinner>
            </span>
            {{ form.getSubmitButton().label }}
        </button>
        <button
            mat-raised-button
            color="primary"
            class="action-button"
            [disabled]="formProcessing || !formGroup.valid"
            *ngIf="actionButtonText"
            (click)="handleActionButtonClick()"
            [attr.data-test-id]="actionButtonText"
        >
            {{ actionButtonText }}
        </button>
    </div>
</form>
