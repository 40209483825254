<med-form-input-field
    *ngIf="getFieldType() === 'input'"
    [field]="field"
    [formGroup]="formGroup"
    (clickIcon)="onClickActionIcon($event)"
    [attr.data-test-id]="field.label"
></med-form-input-field>

<med-autocomplete-field
    *ngIf="getFieldType() === 'autocomplete'"
    [field]="field"
    [formGroup]="formGroup"
    [attr.data-test-id]="field.label"
></med-autocomplete-field>

<med-form-select-field
    *ngIf="getFieldType() === 'select'"
    [field]="field"
    [formGroup]="formGroup"
    [attr.data-test-id]="field.label"
></med-form-select-field>

<med-form-checkbox-field
    *ngIf="getFieldType() === 'checkbox'"
    [field]="field"
    [formGroup]="formGroup"
    [attr.data-test-id]="field.label"
>
</med-form-checkbox-field>

<med-form-multi-input-field
    *ngIf="getFieldType() === 'multi-input'"
    [field]="field"
    [formGroup]="formGroup"
    [attr.data-test-id]="field.label"
>
</med-form-multi-input-field>

<med-form-multi-input-auto-field
    *ngIf="getFieldType() === 'multi-input-auto'"
    [field]="field"
    [formGroup]="formGroup"
    [attr.data-test-id]="field.label"
></med-form-multi-input-auto-field>

<med-date-input-field
    *ngIf="getFieldType() === 'date-input'"
    [field]="field"
    [formGroup]="formGroup"
    [attr.data-test-id]="field.label"
>
</med-date-input-field>
