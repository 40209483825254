import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import { ICRMLetterListParams, ICRMLetterListResponse, ICRMLetter } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiCRMLetterService {
    constructor(private http: HttpService) {}

    list(params: ICRMLetterListParams): Promise<ICRMLetterListResponse> {
        return this.http.post<ICRMLetterListResponse>(`crm/letter/list`, params);
    }

    changeActivation(id: number): Promise<ICRMLetter> {
        return this.http.patch<ICRMLetter>(`crm/letter/${id}/change-activation`, {});
    }
}
