<med-base-login
    *ngIf="loaded && needBaseAuth()"
    [availableRoles]="availableRoles"
    (onLogged)="onLogged.emit()"
></med-base-login>
<med-google-login
    *ngIf="loaded && needGoogleAuth()"
    [availableRoles]="availableRoles"
    [redirectUri]="googleRedirectUri"
    (onLogged)="onLogged.emit()"
></med-google-login>
<med-loader *ngIf="!loaded"></med-loader>
