import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDateInputField } from '../../../classes';
import { ErrorTextService } from '../../../services/error-text.service';
import { fromEvent, Subscription } from 'rxjs';
import { StartViewParameter } from '../../../interfaces/form-field-params';

@Component({
    selector: 'med-date-input-field',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.css'],
})
export class FormDateInputFieldComponent implements OnInit, OnDestroy {
    @ViewChild('input') input: ElementRef;
    @Input() field: FormDateInputField;
    @Input() formGroup: FormGroup;

    constructor(public errorTextService: ErrorTextService) {}

    private active: boolean = false;
    public showCalendar: boolean = false;
    private saveValue: Date;
    private subscription: Subscription;

    ngOnInit(): void {
        this.saveValue = this.field.value;
        this.subscription = fromEvent(document, 'click').subscribe(() => {
            if (!this.active) {
                this.showCalendar = false;

                if (this.saveValue !== this.field.value) {
                    this.field.value = this.saveValue;
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public isActiveClearButton(): boolean {
        return !!this.formGroup.value[this.field.controlName];
    }

    public clearValue(): void {
        this.formGroup.patchValue({
            [this.field.controlName]: null,
        });
        this.input.nativeElement.value = null;
    }

    public onMouseenter(): void {
        this.active = true;
    }

    public onMouseLeave(): void {
        this.active = false;
    }

    public handleDateChange(date: Date): void {
        this.formGroup.patchValue({
            [this.field.controlName]: date,
        });
        this.field.value = date;
        this.saveValue = date;

        this.showCalendar = false;
    }

    public chosenYearHandler(date: Date): void {
        if (this.field.startView === StartViewParameter.MULTI_YEAR) {
            this.field.value = date;
        }
    }

    public chosenMonthHandler(date: Date): void {
        if (this.field.startView === StartViewParameter.MULTI_YEAR) {
            this.handleDateChange(date);
        }
    }
}
