import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    ILetterTemplate,
    ILetterTemplateListParams,
    ILetterTemplateListResponse,
    ILetterTemplateCreateParams,
    ILetterTemplateEditParams,
    ILetterTemplateRenderParams,
    ILetterTemplateRenderResponse,
    ILetterTemplateUpdateRenderParams,
    ILetterTemplateUpdateRenderResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiLetterTemplateService {
    constructor(private http: HttpService) {}

    async list(params: ILetterTemplateListParams): Promise<ILetterTemplateListResponse> {
        return this.http.post<ILetterTemplateListResponse>(`letter-template/list`, params);
    }

    async create(params: ILetterTemplateCreateParams): Promise<ILetterTemplate> {
        return this.http.post<ILetterTemplate>(`letter-template`, params);
    }

    async view(id: number): Promise<ILetterTemplate> {
        return this.http.get<ILetterTemplate>(`letter-template/${id}`);
    }

    async edit(id: number, params: ILetterTemplateEditParams | FormData): Promise<ILetterTemplate> {
        return this.http.patch<ILetterTemplate>(`letter-template/${id}`, params);
    }

    async delete(id: number): Promise<void> {
        return this.http.delete(`letter-template/${id}`);
    }

    async render(params: ILetterTemplateRenderParams): Promise<ILetterTemplateRenderResponse> {
        return this.http.post<ILetterTemplateRenderResponse>(`letter-template/render`, params);
    }

    async updateRender(
        uuid: string,
        params: ILetterTemplateUpdateRenderParams
    ): Promise<ILetterTemplateUpdateRenderResponse> {
        return this.http.patch<ILetterTemplateUpdateRenderResponse>(
            `letter-template/render/${uuid}`,
            params
        );
    }
}
