import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FormInputField, Form, FormBuilder, FormEvent } from '../../../med-form/classes';
import { UserRole } from '../../../med-api/interfaces';
import { Router } from '@angular/router';

@Component({
    selector: 'med-base-login',
    templateUrl: './base-login.component.html',
    styleUrls: ['./base-login.component.css'],
})
export class BaseLoginComponent implements OnInit {
    @Input() availableRoles: UserRole[];
    @Output() onLogged = new EventEmitter();

    form: Form;

    constructor(private authService: AuthService, private router: Router) {}

    private initForm() {
        const formBuilder = new FormBuilder();
        formBuilder.addField(FormInputField, 'email', {
            label: 'Email',
            icon: 'mail',
            validators: ['required', 'email'],
        });
        formBuilder.addField(FormInputField, 'password', {
            label: 'Password',
            icon: 'vpn_key',
            validators: ['required'],
            type: 'password',
        });
        formBuilder.addSubmitButton('Login');
        formBuilder.setHandler(FormEvent.SUBMIT, this.onSubmit.bind(this));
        formBuilder.setHandler(FormEvent.SUCCESS, this.onSuccess.bind(this));
        formBuilder.setHandler(FormEvent.ERROR, this.onError.bind(this));
        formBuilder.setHandler(FormEvent.CHANGE, this.onChange.bind(this));
        this.form = formBuilder.getForm();
    }

    ngOnInit() {
        this.initForm();
    }

    async onSubmit(formValue: any) {
        await this.authService.login({
            roles: this.availableRoles,
            email: formValue.email,
            password: formValue.password,
        });
    }

    async onSuccess() {
        if (this.authService.user.isPasswordExpired) {
            void this.router.navigate(['/change-password']);
        } else {
            this.onLogged.emit();
        }
    }

    async onError(errors: any[]) {
        this.form.setGeneralError(false);

        for (let err of errors) {
            if (err.code === 'auth_incorrect_credentials') {
                this.form
                    .getFormGroup()
                    .get('email')
                    .setErrors({ auth_incorrect_credentials: true });
                this.form
                    .getFormGroup()
                    .get('password')
                    .setErrors({ auth_incorrect_credentials: true });
                this.form.getFormGroup().setErrors({ auth_incorrect_credentials: true });
                this.form.setGeneralError(true);
            }

            if (err.code === 'auth_account_disabled') {
                this.form.getFormGroup().setErrors({ auth_account_disabled: true });
                this.form.setGeneralError(true);
            }
        }
    }

    async onChange() {
        if (this.form.getFormGroup().get('email').hasError('auth_incorrect_credentials')) {
            this.form.getFormGroup().get('email').setErrors(null);
        }
        if (this.form.getFormGroup().get('password').hasError('auth_incorrect_credentials')) {
            this.form.getFormGroup().get('password').setErrors(null);
        }
    }
}
