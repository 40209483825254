import { LeadService } from '../../med-common/services/lead.service';
import { Task } from '../../med-api/interfaces';
import { DateTime } from 'luxon';

export class TaskItem {
    public task: Task;
    private leadService: LeadService = new LeadService();

    constructor(task: Task) {
        this.task = task;
    }

    public fromFormat(): string {
        let res = '';

        if (this.task?.leadCode) {
            res += this.task.leadCode;
        }

        if (this.task?.lead) {
            res += ` (${this.getFullName()})`;
        }

        return res.trim() || '...';
    }

    public getRelatedLinkData(): { link: string; queryParams: object } {
        return this.leadService.getRelatedLinkData(
            this.task.refObjectClass,
            this.task.leadId,
            this.task.leadCode
        );
    }

    public getAttachedAtTimestamp(): string {
        if (!this.task.attachedAt) return '';

        return DateTime.fromISO(this.task.attachedAt.toLocaleString()).toFormat('hh:mm a');
    }

    private getFullName(): string {
        let fullName: string = '';

        if (this.task?.lead?.firstName) {
            fullName += this.task.lead.firstName;
        }

        fullName += fullName ? ' ' : '';

        if (this.task?.lead?.lastName) {
            fullName += this.task.lead.lastName;
        }

        return fullName || 'New Lead';
    }
}
