import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { ScrollShadowModule } from '@shared/directives';
import { MedCommonModule, MedFormModule, MedScheduleModule, MedSidebarModule, MedTaskModule, } from 'medvidi';
import { NgxMaskModule } from 'ngx-mask';
import { PipesModule } from '../pipes/pipes.module';
import { ClickOutsideModule } from '../shared-v2/directives/click-outside/click-outside.module';
import { IconButtonModule } from '../shared/components/icon-button/icon-button.module';
import { MyTasksListModule } from '../shared/components/my-tasks-list/my-tasks-list.module';
import { ErrorTextPipeModule } from '../shared/services/error-text/error-text-pipe.module';
import {
    AppointmentConfirmationFooterComponent
} from './appointment-confirmation/appointment-confirmation-footer/appointment-confirmation-footer.component';
import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';
import { DoctorScheduleComponent } from './appointment-confirmation/doctor-schedule/doctor-schedule.component';
import { TimeSlotsComponent } from './appointment-confirmation/doctor-schedule/time-slots/time-slots.component';
import { DoctorSearchComponent } from './appointment-confirmation/doctor-search/doctor-search.component';
import {
    ScheduleAppointmentCalendarComponent
} from './appointment-confirmation/schedule-appointment-calendar/schedule-appointment-calendar.component';
import { CallMessageItemComponent } from './call-message-item/call-message-item.component';
import { CallRecordDialogComponent } from './call-record-dialog/call-record-dialog.component';
import { ChatComponent } from './chat/chat.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { CloseLeadDialogComponent } from './close-lead-dialog/close-lead-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CrmPageTemplateComponent } from './crm-page-template/crm-page-template.component';
import { DateInputComponent } from './date-input/date-input.component';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { FileUploadComponent } from './eligibility-upload/file-upload.component';
import { FormBaseComponent } from './form-base/form-base.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormSubmitButtonComponent } from './form-submit-button/form-submit-button.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { InlineInputComponent } from './inline-input/inline-input.component';
import { InlineSelectComponent } from './inline-select/inline-select.component';
import { InputComponent } from './input/input.component';
import { LeadBlacklistDialogComponent } from './lead-blacklist-dialog/lead-blacklist-dialog.component';
import { LeadDocumentComponent } from './lead-document/lead-document.component';
import { LetterCardComponent } from './letter-card/letter-card.component';
import { MiddleContainerComponent } from './middle-container/middle-container.component';
import { PhoneClientDialogComponent } from './phone-client-dialog/phone-client-dialog.component';
import { SelectComponent } from './select/select.component';
import { SendButtonComponent } from './send-button/send-button.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SuperBillDialogComponent } from './super-bill-dialog/super-bill-dialog.component';
import { TaskBlacklistDialogComponent } from './task-blacklist-dialog/task-blacklist-dialog.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TimeInputComponent } from './time-input/time-input.component';

@NgModule({
    declarations: [
        MiddleContainerComponent,
        FormFieldComponent,
        FormSubmitButtonComponent,
        CrmPageTemplateComponent,
        SidebarComponent,
        CallRecordDialogComponent,
        FormBaseComponent,
        DeleteButtonComponent,
        PhoneClientDialogComponent,
        AppointmentConfirmationComponent,
        InputComponent,
        InlineInputComponent,
        TextAreaComponent,
        SendButtonComponent,
        CallMessageItemComponent,
        LetterCardComponent,
        CloseButtonComponent,
        TaskBlacklistDialogComponent,
        ConfirmDialogComponent,
        SelectComponent,
        InlineSelectComponent,
        LeadDocumentComponent,
        CloseLeadDialogComponent,
        DateInputComponent,
        ChatComponent,
        SuperBillDialogComponent,
        LeadBlacklistDialogComponent,
        ImageViewerComponent,
        FileUploadComponent,
        DoctorScheduleComponent,
        AppointmentConfirmationFooterComponent,
        ScheduleAppointmentCalendarComponent,
        DoctorSearchComponent,
        TimeSlotsComponent,
        TimeInputComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        RouterModule,
        MedScheduleModule,
        MedCommonModule,
        MedFormModule,
        MedSidebarModule,
        FormsModule,
        MedTaskModule,
        MatMenuModule,
        PipesModule,
        MatAutocompleteModule,
        IconButtonModule,
        MyTasksListModule,
        MatFormFieldModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatCheckboxModule,
        MatSelectModule,
        MatChipsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTooltipModule,
        MatProgressBarModule,
        ErrorTextPipeModule,
        ScrollShadowModule,
        ClickOutsideModule,
    ],
    exports: [
        MiddleContainerComponent,
        CrmPageTemplateComponent,
        FormBaseComponent,
        AppointmentConfirmationComponent,
        InputComponent,
        InlineInputComponent,
        TextAreaComponent,
        SendButtonComponent,
        CallMessageItemComponent,
        LetterCardComponent,
        TaskBlacklistDialogComponent,
        SelectComponent,
        InlineSelectComponent,
        LeadDocumentComponent,
        DateInputComponent,
        ChatComponent,
        SuperBillDialogComponent,
        ImageViewerComponent,
        FileUploadComponent,
        CloseButtonComponent,
        FormFieldComponent,
        TimeInputComponent,
    ],
})
export class ElementsModule {}
