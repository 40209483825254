import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppStateService, AuthService } from 'medvidi';

@Component({
    selector: 'app-session-expired',
    templateUrl: './session-expired.component.html',
    styleUrls: ['./session-expired.component.scss'],
})
export class SessionExpiredComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private appStateService: AppStateService,
        private router: Router
    ) {}

    ngOnInit() {}

    async toSignIn() {
        await this.authService.logout();
        this.appStateService.setAppDefault();
        this.router.navigate(['/login']);
    }
}
