import { ModuleWithProviders, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { init, showReportDialog } from '@sentry/browser/esm';
import { SentryErrorHandler } from './handler/sentry';
import { SentryErrorInterceptor } from './interceptor/sentry';
import { INITIALIZER, OPTIONS } from './tokens';
import { SentryModuleOptions } from './interfaces';

export function initializer(options: SentryModuleOptions): void {
    if (options.enabled) {
        if (options.dialog) {
            options.sentry.beforeSend = (event) => {
                if (event.exception) {
                    showReportDialog(
                        typeof options.dialog === 'object' ? options.dialog : undefined
                    );
                }
                return event;
            };
        }

        init(options.sentry);
    }
}

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
})
export class SentryModule {
    public static forRoot(options: SentryModuleOptions): ModuleWithProviders<SentryModule> {
        return {
            ngModule: SentryModule,
            providers: [
                {
                    provide: OPTIONS,
                    useValue: options,
                },
                {
                    provide: INITIALIZER,
                    useFactory: initializer,
                    deps: [OPTIONS],
                },
                {
                    provide: ErrorHandler,
                    useClass: SentryErrorHandler,
                    deps: [OPTIONS, INITIALIZER],
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: SentryErrorInterceptor,
                    deps: [OPTIONS, INITIALIZER],
                    multi: true,
                },
            ],
        };
    }
}

export * from './interfaces';
export * from './handler/sentry';
export * from './interceptor/sentry';
