<div class="sidebar-container unselectable">
    <div class="sidebar-left-container">
        <div *ngIf="logoIcon" [innerHTML]="logoIcon" class="logo-icon"></div>
        <div class="sidebar-left-block">
            <div class="sidebar-content-top">
                <div *ngFor="let menu of sidebarContentTop">
                    <a
                        *ngIf="!menu.children"
                        class="sidebar-link"
                        routerLink="{{ menu.path }}"
                        routerLinkActive="active"
                        (click)="
                            setMenuLabel(menu.label); additionalMenuHide(); menu.func && menu.func()
                        "
                        [class.isDisabled]="menu.disabled"
                        [class.isActive]="isActiveMenuItem(menu.label)"
                        [attr.data-test-id]="menu.label"
                    >
                        <mat-icon
                            fontSet="material-icons-outlined"
                            class="link-icon"
                            *ngIf="menu.icon"
                            >{{ menu.icon }}</mat-icon
                        >
                        <div
                            class="link-icon"
                            [innerHTML]="menu.customIcon"
                            *ngIf="menu.customIcon"
                        ></div>
                        <span class="link-title">{{ menu.label }}</span>
                    </a>
                    <div
                        *ngIf="menu.children"
                        class="sidebar-link"
                        routerLinkActive="active"
                        (click)="onClickMenuItem(menu); menu.func && menu.func()"
                        [class.isDisabled]="menu.disabled"
                        [class.isActive]="isActiveMenuItem(menu.label)"
                        [attr.data-test-id]="menu.label"
                    >
                        <mat-icon
                            fontSet="material-icons-outlined"
                            class="link-icon"
                            *ngIf="menu.icon"
                            >{{ menu.icon }}</mat-icon
                        >
                        <div
                            class="link-icon"
                            [innerHTML]="menu.customIcon"
                            *ngIf="menu.customIcon"
                        ></div>
                        <span class="link-title">{{ menu.label }}</span>
                    </div>
                </div>
            </div>
            <div class="sidebar-content-bottom">
                <div *ngFor="let menu of sidebarContentBottom">
                    <a
                        *ngIf="!menu.children"
                        class="sidebar-link"
                        routerLink="{{ menu.path }}"
                        routerLinkActive="active"
                        (click)="
                            setMenuLabel(menu.label); additionalMenuHide(); menu.func && menu.func()
                        "
                        [class.isDisabled]="menu.disabled"
                        [class.isActive]="isActiveMenuItem(menu.label)"
                        [attr.data-test-id]="menu.label"
                    >
                        <mat-icon
                            fontSet="material-icons-outlined"
                            class="link-icon"
                            *ngIf="menu.icon"
                            >{{ menu.icon }}</mat-icon
                        >
                        <div
                            class="link-icon"
                            [innerHTML]="menu.customIcon"
                            *ngIf="menu.customIcon"
                        ></div>
                        <span class="link-title">{{ menu.label }}</span>
                    </a>
                    <div
                        *ngIf="menu.children"
                        class="sidebar-link"
                        routerLinkActive="active"
                        (click)="onClickMenuItem(menu); menu.func && menu.func()"
                        [class.isDisabled]="menu.disabled"
                        [class.isActive]="isActiveMenuItem(menu.label)"
                    >
                        <mat-icon
                            fontSet="material-icons-outlined"
                            class="link-icon"
                            *ngIf="menu.icon"
                            >{{ menu.icon }}</mat-icon
                        >
                        <div
                            class="link-icon"
                            [innerHTML]="menu.customIcon"
                            *ngIf="menu.customIcon"
                        ></div>
                        <span class="link-title">{{ menu.label }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="sidebarStorageService.additionalMenuOpen" class="sidebar-right-container">
        <div class="additional-menu-list-block">
            <a
                *ngFor="let menu of sidebarStorageService.additionalMenu"
                class="list-item"
                [class.isDisabled]="menu.disabled"
                [class.isActive]="isActiveAdditionalMenuItem(menu.label)"
                [attr.data-test-id]="autotestsUtilsService.getDataTestId('Submenu', menu.label)"
                routerLink="{{ menu.path }}"
                routerLinkActive="active"
                (click)="setAdditionalMenuLabel(menu.label); menu.func && menu.func()"
            >
                <mat-icon
                    style="margin-right: 5px"
                    fontSet="material-icons-outlined"
                    class="link-icon"
                    >{{ menu.icon }}
                </mat-icon>
                <div>{{ menu.label }}</div>
            </a>
        </div>
        <div class="additional-menu-hide-block" (click)="additionalMenuHide(false)">
            <div class="additional-menu-hide-content">Hide menu</div>
        </div>
    </div>
</div>
