import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    IUser,
    IUserListParams,
    IUserListResponse,
    IUserCreateParams,
    IUserChangePasswordParams,
    IUserChangePasswordRespose,
    IUserChangePermissionGroupsParams,
    IUserChangePermissionGroupsResponse,
    IUserEditBaseParams,
    IUserEditAccessParams,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiUserService {
    constructor(private http: HttpService) {}

    async list(params: IUserListParams): Promise<IUserListResponse> {
        return this.http.post<IUserListResponse>(`user/list`, params);
    }

    async create(params: IUserCreateParams): Promise<IUser> {
        return this.http.post<IUser>(`user`, params);
    }

    async view(id: number): Promise<IUser> {
        return this.http.get<IUser>(`user/${id}`);
    }

    async editBase(id: number, params: IUserEditBaseParams): Promise<IUser> {
        return this.http.patch<IUser>(`user/${id}`, params);
    }

    async editAccesss(id: number, params: IUserEditAccessParams): Promise<IUser> {
        return this.http.patch<IUser>(`user/${id}/change-access-fields`, params);
    }

    /* @TODO: legacy route. Used in CRM - replace and clear */
    async viewByToken(): Promise<IUser> {
        return this.http.get<IUser>(`user/view-by-token`);
    }
    /* end */

    async changePassword(
        id: number,
        params: IUserChangePasswordParams
    ): Promise<IUserChangePasswordRespose> {
        return this.http.patch<IUserChangePasswordRespose>(`user/${id}/change-password`, params);
    }

    async changePermissionGroups(
        id: number,
        params: IUserChangePermissionGroupsParams
    ): Promise<IUserChangePermissionGroupsResponse> {
        return this.http.patch<IUserChangePermissionGroupsResponse>(
            `user/${id}/change-permission-groups`,
            params
        );
    }

    async delete(id: number): Promise<void> {
        return this.http.delete(`user/${id}`);
    }
}
