import { ListFilter, ListResponse, ListSortParams } from '../../med-list/interfaces';
import { IService, IState, IWebsite } from '../interfaces';

export const enum ServicePlanType {
    INITIAL = 1,
    RETURNING = 2,
}

export const ServicePlanTypeReadable = {
    [ServicePlanType.INITIAL]: 'Initial',
    [ServicePlanType.RETURNING]: 'Follow-up',
};

export const enum AppointmentMethodType {
    ONLINE = 1,
    OFFLINE = 2,
}

export const AppointmentMethodTypeReadable = {
    [AppointmentMethodType.ONLINE]: 'Online',
    [AppointmentMethodType.OFFLINE]: 'Offline',
};

export interface IServicePlan {
    id: number;
    serviceId: number;
    service: IService;
    states: IState[];
    websites: IWebsite[];
    title: string;
    code: string;
    price: number;
    description: string;
    priority: number;
    type: ServicePlanType;
    appointmentMethod: AppointmentMethodType;
    createdAt: Date;
    updatedAt: Date;
}

export interface IServicePlanListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
    sort?: ListSortParams;
}

export interface IServicePlanListResponse extends ListResponse {
    items: IServicePlan[];
}

export interface IServicePlanCreateParams {
    serviceId: number;
    states: number[];
    websites: number[];
    title: string;
    code: string;
    price: number;
    description: string;
    priority?: number;
    type?: ServicePlanType;
    appointmentMethod: AppointmentMethodType;
}

export interface IServicePlanEditParams {
    serviceId?: number;
    states?: number[];
    websites?: number[];
    title?: string;
    code?: string;
    price?: number;
    description?: string;
    priority?: number;
    type?: ServicePlanType;
    appointmentMethod?: AppointmentMethodType;
}
