import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-appointment-confirmation-footer',
    templateUrl: './appointment-confirmation-footer.component.html',
    styleUrls: ['./appointment-confirmation-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentConfirmationFooterComponent {
    @Input() isDisabled!: boolean;
    @Input() timezone!: string;

    @Output() closeDialog = new EventEmitter<void>();
    @Output() confirm = new EventEmitter<void>();
}
