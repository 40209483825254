import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrioritySelectComponent } from './priority-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
    declarations: [PrioritySelectComponent],
    imports: [CommonModule, ReactiveFormsModule, MatSelectModule, MatProgressSpinnerModule],
    exports: [PrioritySelectComponent],
})
export class PrioritySelectModule {}
