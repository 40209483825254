import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { CommonDataService, IOptionsItem } from 'medvidi';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-doctor-search',
    templateUrl: './doctor-search.component.html',
    styleUrls: ['./doctor-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorSearchComponent implements OnInit, AfterViewInit, OnDestroy {
    public doctorSearchControl = new FormControl('');
    public placeholder: string = '';
    public filteredOptionsArray = [];
    public doctorId: number;

    public isOpen: boolean = false;

    private destroy$ = new Subject<void>();

    @Output() searchDoctor = new EventEmitter<number | undefined>();

    @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
    autoComplete: MatAutocompleteTrigger;
    @ViewChild('autocompleteDropDown', { read: MatAutocomplete }) autocompleteDropdown;

    private get doctorOptions(): IOptionsItem[] {
        return this.commonDataService.doctors
            .filter((doctor) => doctor.isTest === false && doctor.isEnabled === true)
            .map((doctor) => ({
                text: doctor.fullName,
                value: doctor.id,
            }));
    }

    constructor(private commonDataService: CommonDataService) {}

    ngOnInit(): void {
        this.handleDoctorSearchControl();
        this.doctorSearchControl.setValue('');
    }

    ngAfterViewInit(): void {
        this.handleDropdownVisibility();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public displayFn(doctor: IOptionsItem): string {
        return doctor?.text ? `${doctor.text}` : '';
    }

    public clearInput(): void {
        this.doctorId = undefined;
        this.doctorSearchControl.setValue('');

        this.searchDoctor.emit(this.doctorId);
    }

    public handleDoctorChange(doctorId: number): void {
        this.doctorId = doctorId;
        this.placeholder = null;

        this.searchDoctor.emit(this.doctorId);
    }

    private handleDropdownVisibility(): void {
        this.autocompleteDropdown.opened
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => (this.isOpen = true));
        this.autocompleteDropdown.closed
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => (this.isOpen = false));
    }

    private handleDoctorSearchControl(): void {
        this.doctorSearchControl.valueChanges.subscribe((inputValue: any) => {
            if (typeof inputValue !== 'string' || inputValue?.length > 25) {
                if (!inputValue.text) return;

                inputValue = inputValue.text;
                this.placeholder = null;
            }

            const loweredInputValue = inputValue.toLowerCase();
            // sort array ("start with" options will be the first then just the presence of a substring)
            this.filteredOptionsArray = this.doctorOptions
                .filter((item) => `${item.text}`.toLowerCase().indexOf(loweredInputValue) !== -1)
                .sort((a, b) => {
                    const ai = `${a.text}`.toLowerCase().indexOf(loweredInputValue);
                    const bi = `${b.text}`.toLowerCase().indexOf(loweredInputValue);
                    return ai - bi;
                });
            this.handleInputChange(inputValue);
        });
    }

    private handleInputChange(inputValue: string): void {
        const firstOptionText = this.filteredOptionsArray[0]?.text;
        const escapedString = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const isEntry = new RegExp('^' + escapedString, 'gi').test(firstOptionText);

        if (!firstOptionText || !isEntry || !inputValue?.length) {
            this.placeholder = null;
            return;
        }

        this.placeholder = this.transformPlaceholder(inputValue, firstOptionText);
    }

    private transformPlaceholder(inputValue: string, placeholder: string): string {
        if (!placeholder) return inputValue;

        const regEx = new RegExp(inputValue, 'i');
        return placeholder.replace(regEx, inputValue);
    }
}
