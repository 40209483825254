import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
    Gender,
    GenderReadable,
    IPrescriptionsPatientProfileData,
    PrescriptionsPhoneTypeReadable,
} from '../../../med-api/interfaces';

import { ElectronicPrescriptionsService } from '../../../med-api/services';
import { DoctorEventType } from '../../../med-common/interfaces/event.type';
import { EventAnalyticsService } from '../../../med-common/services/event-analytics.service';
import {
    Form,
    FormBuilder,
    FormDateInputField,
    FormEvent,
    FormInputField,
    FormSelectField,
} from '../../../med-form/classes';
import { englishFormatValidator, phoneNumberValidator, zipcodeValidator } from '../../validators';
import { DateTime } from 'luxon';

interface IDialogData {
    leadId: number;
}

@Component({
    selector: 'med-electronic-prescriptions-patient-dialog',
    templateUrl: './electronic-prescriptions-patient-dialog.component.html',
    styleUrls: ['./electronic-prescriptions-patient-dialog.component.css'],
})
export class ElectronicPrescriptionsPatientDialogComponent implements OnInit, AfterViewInit {
    @Output() onPatientAdd = new EventEmitter<string>();

    public patientForm: Form;
    public leadData: IPrescriptionsPatientProfileData;
    public dosespotValidationError: string;
    public loaded: boolean = false;

    constructor(
        private eventAnalyticsService: EventAnalyticsService,
        public dialogRef: MatDialogRef<ElectronicPrescriptionsPatientDialogComponent>,
        private electronicPrescriptionsService: ElectronicPrescriptionsService,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData
    ) {}

    public ngOnInit(): void {
        this.initComponentData();
    }

    ngAfterViewInit(): void {
        this.emitEvent(DoctorEventType.POPUP_LOADED, {});
    }

    public closeDialog() {
        this.dialogRef.close();
    }

    public async addPatient(): Promise<void> {
        const formValues = this.patientForm.getFormGroup().value;

        const jsDate = new Date(formValues.dob);
        const dobFormatted = DateTime.fromJSDate(jsDate).set({ hour: 12 }).toJSDate();

        this.emitEvent(DoctorEventType.BUTTON_TAPPED, {
            element_name: 'create_new_patient_dosespot_profile',
            patient_name: `${formValues.firstName} ${formValues.lastName}`,
            patient_phone: formValues.phone,
            patient_date_of_birth: dobFormatted,
        });

        try {
            this.loaded = false;

            const { dob, ...rest } = formValues;

            const patientFields = {
                ...rest,
                dob: dobFormatted,
            };

            const response = await this.electronicPrescriptionsService.createPatient(
                this.data.leadId,
                patientFields
            );
            this.emitEvent(DoctorEventType.DOSESPOT_CREATION, {
                element_name: 'new_dosespot_profile_created',
                patient_name: `${formValues.firstName} ${formValues.lastName}`,
                patient_phone: formValues.phone,
                patient_date_of_birth: dobFormatted,
                new_dosespot_profile_created: response.link,
            });
            this.onPatientAdd.emit(response.link);
            this.closeDialog();

            this.loaded = true;
        } catch (err) {
            this.loaded = true;
            this.dosespotValidationError = err?.errors?.length && err?.errors[0]?.message;
            this.eventAnalyticsService.emitError('[ePrescriptions] - Add patient error: ' + err);
            console.warn('[ePrescriptions] - Add patient error: ', err);
        }
    }

    private async initComponentData(): Promise<void> {
        this.loaded = false;

        await this.loadLeadData();
        this.initPatientForm();

        this.loaded = true;
    }

    private async loadLeadData(): Promise<void> {
        try {
            this.leadData = await this.electronicPrescriptionsService.getPatientProfileData(
                this.data.leadId
            );
        } catch (err) {
            console.warn('[ePrescriptions] - Load Lead Data error: ', err);
        }
    }

    private initPatientForm(): void {
        const formBuilder = new FormBuilder();

        formBuilder.addField(FormInputField, 'firstName', {
            label: 'First Name',
            value: this.leadData?.firstName,
            validators: [Validators.required, englishFormatValidator()],
        });
        formBuilder.addField(FormInputField, 'lastName', {
            label: 'Last Name',
            value: this.leadData?.lastName,
            validators: [Validators.required, englishFormatValidator()],
        });
        formBuilder.addField(FormDateInputField, 'dob', {
            label: 'Date of Birth',
            value: this.leadData?.dob
                ? DateTime.fromISO(this.leadData.dob.split('T')[0]).toJSDate()
                : null,
            validators: [Validators.required],
        });
        formBuilder.addField(FormSelectField, 'gender', {
            label: 'Gender',
            value: this.leadData?.gender,
            validators: [Validators.required],
            options: [
                GenderReadable[Gender.MALE],
                GenderReadable[Gender.FEMALE],
                GenderReadable[Gender.OTHER],
            ],
        });
        formBuilder.addField(FormInputField, 'address', {
            label: 'Address',
            value: this.leadData?.address,
            validators: [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(35),
                englishFormatValidator(),
            ],
        });
        formBuilder.addField(FormInputField, 'city', {
            label: 'City',
            value: this.leadData?.city,
            validators: [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(35),
                englishFormatValidator(),
            ],
        });
        formBuilder.addField(FormInputField, 'state', {
            label: 'State',
            value: this.leadData?.state,
            validators: [Validators.required, englishFormatValidator()],
        });
        formBuilder.addField(FormInputField, 'zip', {
            label: 'Zip Code',
            value: this.leadData?.zip,
            validators: [Validators.required, zipcodeValidator(), englishFormatValidator()],
        });
        formBuilder.addField(FormInputField, 'phone', {
            label: 'Primary Phone',
            value: this.leadData?.phone,
            validators: [Validators.required, englishFormatValidator(), phoneNumberValidator()],
        });
        formBuilder.addField(FormSelectField, 'phoneType', {
            label: 'Primary Phone Type',
            value: this.leadData?.phoneType,
            validators: [Validators.required],
            options: Object.keys(PrescriptionsPhoneTypeReadable).map((key) => ({
                text: PrescriptionsPhoneTypeReadable[key],
                value: key,
            })),
        });
        formBuilder.setHandler(FormEvent.CHANGE, this.onFormChange.bind(this));

        this.patientForm = formBuilder.getForm();
    }

    private onFormChange(): void {
        this.dosespotValidationError = null;
    }

    private emitEvent(type: DoctorEventType, meta: Record<string, any>): void {
        this.eventAnalyticsService.emitEvent({
            type,
            metadata: {
                lead_id: this.data.leadId,
                page: 'patient/profile',
                pop_up_title: 'new_dosespot_patient_adding_details',
                ...meta,
            },
        });
    }
}
