import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function zipcodeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || typeof control.value !== 'string') {
            return null;
        }

        return [5, 9].includes(control.value.length)
            ? null
            : { invalidZipcode: { value: control.value } };
    };
}

export function englishFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const regexp = /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]*$/;

        if (!control.value) {
            return null;
        }

        return regexp.test(control.value) ? null : { invalidLanguage: { value: control.value } };
    };
}

export function phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }

        const invalidPhoneResponse = { invalidPhoneNumber: { value: control.value } };
        const phoneFormatted = getPrescriptionsPhoneFormat(control.value);

        if (!phoneFormatted) {
            return invalidPhoneResponse;
        }

        const numbersAndSeparatorsRegexp = /^[0-9()-]*$/;
        if (!numbersAndSeparatorsRegexp.test(phoneFormatted)) {
            return invalidPhoneResponse;
        }

        if (phoneFormatted.slice(3, 6) === '555') {
            return invalidPhoneResponse;
        }

        if (!phoneFormatted.split('').some((x) => x !== phoneFormatted[0])) {
            return invalidPhoneResponse;
        }

        return null;
    };
}

function getPrescriptionsPhoneFormat(phone: string): string {
    if (!phone) {
        return null;
    }

    const separatorsRegexp = /^[()\-\s]*$/;
    const formattedPhone = phone.replace(separatorsRegexp, '');

    if (formattedPhone.length === 10) {
        return phone;
    }

    if (formattedPhone.length === 12 && formattedPhone.startsWith('+1')) {
        return formattedPhone.slice(2);
    }

    if (formattedPhone.length === 11 && formattedPhone.startsWith('1')) {
        return formattedPhone.slice(1);
    }

    return null;
}
