import { ListFilter, ListResponse } from '../../med-list/interfaces';
import { IUser, IState, AppointmentNotificationType } from '../interfaces';

export enum DoctorAppointmentNotificationTime {
    AFTER_CONFIRMATION = 'after_confirmation',
    FIVE_MINUTES_BEFORE = 'five_minutes_before',
    TEN_MINUTES_BEFORE = 'ten_minutes_before',
    FIFTEEN_MINUTES_BEFORE = 'fifteen_minutes_before',
    THIRTY_MINUTES_BEFORE = 'thirty_minutes_before',
    SIXTY_MINUTES_BEFORE = 'sixty_minutes_before',
}

export interface IDoctorAppointmentsNotification {
    type: AppointmentNotificationType;
    time: DoctorAppointmentNotificationTime;
}

export const enum DoctorAppointmentMethodType {
    ONLINE = 1,
    OFFLINE = 2,
}

export enum DoctorAppointmentVisitType {
    INITIAL_VISIT = 1,
    FOLLOW_UP = 2,
}

export const DoctorAppointmentMethodTypeReadable = {
    [DoctorAppointmentMethodType.ONLINE]: 'Online',
    [DoctorAppointmentMethodType.OFFLINE]: 'Offline',
};

export interface IDoctorSmallFormat {
    id: number;
    fullName: string;
}

export interface IDoctorAppointmentsCount {
    today: number;
    letters: number;
    pending: number;
}

export interface IDoctor {
    id: number;
    userId: number;
    user: IUser;
    appointmentPrice: number;
    returningAppointmentPrice?: number;
    licenseNumber: string;
    licenseStartDate: Date;
    licenseEndDate: Date;
    signature: string;
    npi: string;
    licenseType: string;
    licenseStateId: number;
    licenseState: IState;
    initials: string;
    nameOfPractice: string;
    officeAddress: string;
    appointmentNotificationsConfig: IDoctorAppointmentsNotification[];
    allowToMakeCalls: boolean;
    allowSkipCall: boolean;
    isTest: boolean;
    allowReschedule: boolean;
    isDosespotEnabled: boolean;
    appointmentMethod: DoctorAppointmentMethodType[];
    appointmentsCount: IDoctorAppointmentsCount;
    createdAt: Date;
    updatedAt: Date;
    schedulePreferences: string;
    isShownOnTheManageAppPage: boolean;
    dosespotPin: string;
    visitType: DoctorAppointmentVisitType[];
    managerIds: number[];
    priorityAppointment: boolean;
    assistantsOnline: string[];
    allowAppointmentChartsV2: boolean;
}

export interface ICommonDataDoctor {
    id: number;
    fullName: string;
    isTest: boolean;
    isEnabled: boolean;
}

export interface IDoctorListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
}

export interface IDoctorListResponse extends ListResponse {
    items: IDoctor[];
}

export interface IDoctorEditParams {
    appointmentPrice?: number;
    returningAppointmentPrice?: number;
    licenseNumber?: string;
    licenseStartDate?: Date;
    licenseEndDate?: Date;
    signature?: string;
    npi?: string;
    licenseType?: string;
    licenseStateId?: number;
    initials?: string;
    nameOfPractice?: string;
    officeAddress?: string;
    appointmentNotificationsConfig?: IDoctorAppointmentsNotification[];
    allowToMakeCalls?: boolean;
    allowSkipCall?: boolean;
    isTest?: boolean;
    appointmentMethod?: DoctorAppointmentMethodType[];
    allowReschedule?: boolean;
    isShownOnTheManageAppPage?: boolean;
    dosespotPin?: number;
}

export interface IPreferencesBody {
    schedulePreferences: string;
    visitType: DoctorAppointmentVisitType[];
}

export interface IUpdatePreferencesResponse {
    success: boolean;
}

export enum AllergyStatusType {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3,
}
