import { ListFilter, ListResponse, ListSortParams } from '../../med-list/interfaces';
import { IServiceSmall } from './service';
import { IState } from './state';
import { IWebsiteSmall } from './website';

export enum PromoCodeType {
    PERCENT = 0,
    AMOUNT = 1,
    SUBSCRIPTION_AMOUNT = 2,
}

export interface IPromoCode {
    id: number;
    code: string;
    type: number;
    rate: number;
    multipleRate?: string;
    services?: IServiceSmall[];
    states?: IState[];
    websites?: IWebsiteSmall[];
    createdAt: Date;
    updatedAt: Date;
    availableForFollowUp: boolean;
    availableForInitial: boolean;
}

export interface IPromoCodeListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
    sort?: ListSortParams;
}

export interface IPromoCodeListResponse extends ListResponse {
    items: IPromoCode[];
}

export interface IPromoCodeCreateParams {
    code: string;
    type: number;
    rate: number;
    availableForInitial: boolean;
    availableForFollowUp: boolean;
}

export interface IPromoCodeEditParams {
    code: string;
    rate: number;
    multipleRate?: string;
    services: number[];
    states: number[];
    websites: number[];
    availableForFollowUp: boolean;
    availableForInitial: boolean;
}
