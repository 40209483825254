<ng-template #contactInfo>
    <div *ngIf="isLeadLoading">{{ data.phone }}</div>
    <ng-container *ngIf="!isLeadLoading">
        <ng-container *ngIf="lead">
            <div class="lead-link">
                <a [routerLink]="['/lead/view', lead.id]">{{ getFullName() }}</a>
            </div>
            <div class="lead-phone">{{ data.phone }}</div>
        </ng-container>
        <a *ngIf="!lead" [queryParams]="{ phone: data.phone }" [routerLink]="['/lead/new']">
            {{ data.phone }}
        </a>
    </ng-container>
</ng-template>

<ng-template #ivrSelectedInfo>
    <div *ngIf="ivrTree" class="ivr-tree">
        {{ ivrTree }}
    </div>
</ng-template>

<div>
    <div class="call-panel-container">
        <div *ngIf="data.type === callTypes.INCOMING_CALL">
            <div class="row">
                <div class="info">
                    <div class="call-to">
                        <ng-container *ngTemplateOutlet="contactInfo"></ng-container>
                    </div>
                    <div class="call-status">Calling...</div>
                    <ng-container *ngTemplateOutlet="ivrSelectedInfo"></ng-container>
                </div>
            </div>
            <div class="row">
                <div class="call-actions-container">
                    <button (click)="acceptCall()" class="call-action-button success-action">
                        <img src="assets/img/svg/start-call.svg" />
                    </button>
                    <button (click)="data.onDecline()" class="call-action-button error-action">
                        <img src="assets/img/svg/end-call.svg" />
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="data.type === callTypes.OUTGOING_CALL">
            <div class="row">
                <div class="info">
                    <div class="call-to">
                        <ng-container *ngTemplateOutlet="contactInfo"></ng-container>
                    </div>
                    <div class="call-status">Ringing...</div>
                </div>
                <div class="buttons">
                    <button class="call-action-button error-action" (click)="onHangUp()">
                        <img src="assets/img/svg/end-call.svg" />
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="data.type === callTypes.ACTIVE_CALL || phoneClientService.isCallOnHold">
            <div class="row">
                <div *ngIf="extensionNumbersValue" class="extension-numbers">
                    {{ extensionNumbersValue }}
                </div>
                <div *ngIf="!extensionNumbersValue" class="info">
                    <div class="call-to">
                        <ng-container *ngTemplateOutlet="contactInfo"></ng-container>
                    </div>
                    <div class="call-status">
                        {{ phoneClientService.timerString }}&nbsp;({{
                            data.incoming ? 'Incoming' : 'Outgoing'
                        }})
                    </div>
                </div>
            </div>
            <div class="row">
                <ng-container *ngTemplateOutlet="ivrSelectedInfo"></ng-container>
            </div>
            <div class="row">
                <div class="call-actions-container">
                    <ng-container *ngIf="!phoneClientService.isCallOnHold">
                        <button
                            (click)="phoneClientService.toggleMute()"
                            *ngIf="!phoneClientService.isMuted"
                            class="call-action-button neutral-action"
                        >
                            <mat-icon>mic</mat-icon>
                        </button>
                        <button
                            (click)="phoneClientService.toggleMute()"
                            *ngIf="phoneClientService.isMuted"
                            class="call-action-button neutral-action"
                        >
                            <mat-icon>mic_off</mat-icon>
                        </button>
                        <button (click)="data.onHangUp()" class="call-action-button error-action">
                            <img src="assets/img/svg/end-call.svg" />
                        </button>
                    </ng-container>
                    <button
                        *ngIf="!phoneClientService.isCallOnHold && data.incoming"
                        class="call-action-button on-hold-call"
                        [disabled]="isCallOnHoldInProgress"
                        (click)="onCallOnHold()"
                    >
                        <mat-spinner *ngIf="isCallOnHoldInProgress" [diameter]="20"></mat-spinner>
                        <img
                            *ngIf="!isCallOnHoldInProgress"
                            src="assets/img/svg/on-hold-call.svg"
                        />
                    </button>
                    <button
                        *ngIf="phoneClientService.isCallOnHold && data.incoming"
                        class="call-action-button in-progress-call"
                        [disabled]="isCallResumedInProgress"
                        (click)="onCallResume()"
                    >
                        <mat-spinner *ngIf="isCallResumedInProgress" [diameter]="20"></mat-spinner>
                        <img
                            *ngIf="!isCallResumedInProgress"
                            src="assets/img/svg/in-progress-call.svg"
                        />
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="data.type === callTypes.LAST_CALL && !phoneClientService.isCallOnHold">
            <div class="row">
                <div class="info">
                    <div class="call-to">
                        <ng-container *ngTemplateOutlet="contactInfo"></ng-container>
                    </div>
                    <div class="call-status">Finished</div>
                </div>
                <div class="buttons">
                    <button
                        *ngIf="phoneClientService.isActive"
                        (click)="data.onCall()"
                        class="call-action-button success-action"
                    >
                        <img src="../../../../../assets/img/svg/start-call.svg" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
