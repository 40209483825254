import {
    Component,
    OnInit,
    Input,
    ViewChild,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { FormSelectField } from '../../../classes';
import { FormGroup, FormControl } from '@angular/forms';
import { ErrorTextService } from '../../../services/error-text.service';
import { IOptionsItem } from '../../../interfaces/form-select-params';

@Component({
    selector: 'med-form-select-field',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
})
export class FormSelectFieldComponent implements OnInit, OnChanges {
    @Input() field: FormSelectField;
    @Input() formGroup: FormGroup;
    @ViewChild('selectAll') public selectAll;

    filterCtrl: FormControl = new FormControl();
    options: Array<IOptionsItem | string | number>;

    constructor(public errorTextService: ErrorTextService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.field.withFilter) {
            this.filterCtrl.valueChanges.subscribe(() => {
                this.filterOptions();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.field?.currentValue) {
            this.options = this.field.options;
        }
    }

    public findTextValue() {
        const firstSelectedValue = this.formGroup.controls[this.field.controlName].value
            ? this.formGroup.controls[this.field.controlName].value[0]
            : null;

        if (firstSelectedValue !== null) {
            const opt = this.field.options.find(
                (opt: IOptionsItem) => opt.value === firstSelectedValue
            );
            // @ts-ignore
            return opt?.text;
        } else {
            return '';
        }
    }

    private filterOptions(): void {
        let search = this.filterCtrl.value;
        if (!search) {
            this.options = this.field.options;
        } else {
            search = search.toLowerCase();
        }

        this.options = this.field.options.filter((op) => {
            const opObject: IOptionsItem = op !== null && typeof op === 'object' ? op : null;
            if (
                opObject &&
                opObject.text &&
                opObject.text.toString().toLowerCase().indexOf(search) > -1
            ) {
                return true;
            }
            if (!opObject && op.toString().toLowerCase().indexOf(search) > -1) {
                return true;
            }

            return op === '';
        });
        this.cdr.detectChanges();
    }

    public handleOptionClick() {
        if (this.field.allOption) {
            if (this.selectAll.selected) {
                this.selectAll.deselect();
                return;
            }
            if (
                this.formGroup.controls[this.field.controlName].value?.length ===
                this.field.options.length
            ) {
                this.selectAll.select();
            }
        }
    }

    public handleAllOptionClick() {
        if (this.selectAll.selected) {
            this.formGroup.controls[this.field.controlName].patchValue([
                ...this.options.map((option: IOptionsItem) => option.value),
                -1,
            ]);
        } else {
            this.formGroup.controls[this.field.controlName].patchValue([]);
        }
    }
}
