import { FormField } from '../form-field';
import { FormMultiInputAutoParams } from '../../interfaces/form-multi-input-auto-params';

export class FormMultiInputAutoField extends FormField {
  strings: string[];
  allStrings: string[];

  constructor(params: FormMultiInputAutoParams) {
    super(params);

    this.strings = params.strings || [];
    this.allStrings = params.allStrings || [];
  }
}
