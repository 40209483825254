import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    IService,
    IServiceListParams,
    IServiceListResponse,
    IServiceCreateParams,
    IServiceEditParams,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiServiceService {
    constructor(private http: HttpService) {}

    async list(params: IServiceListParams): Promise<IServiceListResponse> {
        return this.http.post<IServiceListResponse>(`service/list`, params);
    }

    async create(params: IServiceCreateParams): Promise<IService> {
        return this.http.post<IService>(`service`, params);
    }

    async view(id: number): Promise<IService> {
        return this.http.get<IService>(`service/${id}`);
    }

    async edit(id: number, params: IServiceEditParams | FormData): Promise<IService> {
        return this.http.patch<IService>(`service/${id}`, params);
    }

    async delete(id: number): Promise<void> {
        return this.http.delete(`service/${id}`);
    }
}
