export enum IVRTreeLevel {
    SCHEDULE_APPOINTMENT = 'schedule_appointment',
    RESCHEDULE_APPOINTMENT = 'reschedule_appointment',
    PRESCRIPTION_OR_PHARMACY_ISSUES = 'prescription_or_pharmacy_issues',
    MEDICAL_RECORDS = 'medical_records',
    TECHNICAL_ISSUES = 'technical_issues',
    GENERAL_QUESTIONS = 'general_questions',
}

export enum IVRTreeGoal {
    SCHEDULE_APPOINTMENT = 'schedule_appointment',
    END_CALL = 'end_call',
    SPEAK_WITH_CARE_TEAM = 'speak_with_care_team',
    RETURN_TO_MAIN_MENU = 'return_to_main_menu',
    APPOINTMENT_STATUS = 'appointment_status',
    RESCHEDULE_APPOINTMENT = 'reschedule_appointment',
    INTAKE_OR_PDMP = 'intake_or_pdmp',
    CHANGE_PHARMACY_ADDRESS = 'change_pharmacy_address',
    DOSAGE_CHANGES = 'dosage_changes',
    PRESCRIPTION_INACCURACIES = 'prescription_inaccuracies',
    PRESCRIPTIONS_GENERAL = 'prescriptions_general',
    MEDICAL_RECORDS = 'medical_records',
    CONFIRMATION_LETTER = 'confirmation_letter',
    AUTHORIZATION_OF_MEDICATIONS = 'authorization_of_medications',
    GENERAL_MEDICAL_RECORDS = 'general_medical_records',
    PAYMENT_ISSUES = 'payment_issues',
    APPOINTMENT_ISSUES = 'appointment_issues',
    INTAKE_FILLING_ISSUES = 'intake_filling_issues',
    LOGIN_ISSUES = 'login_issues',
    OTHER_ISSUES = 'other_issues',
    ADVANCE_AUTHORIZATION_OF_MEDICATIONS = 'advance_authorization_of_medications',
}

export const IVRTreeReadable = {
    [IVRTreeLevel.SCHEDULE_APPOINTMENT]: {
        [IVRTreeGoal.SCHEDULE_APPOINTMENT]: 'Schedule appointment => Schedule a new appointment',
        [IVRTreeGoal.SPEAK_WITH_CARE_TEAM]: 'Schedule appointment => Speak with the care team',
    },
    [IVRTreeLevel.RESCHEDULE_APPOINTMENT]: {
        [IVRTreeGoal.APPOINTMENT_STATUS]: 'Status of your upcoming => Speak with the care team',
        [IVRTreeGoal.SPEAK_WITH_CARE_TEAM]: 'Reschedule appointment => Speak with the care team',
        [IVRTreeGoal.RESCHEDULE_APPOINTMENT]: 'Reschedule appointment => Speak with the care team',
        [IVRTreeGoal.INTAKE_OR_PDMP]: 'Intake or PDMP => Speak with the care team',
    },
    [IVRTreeLevel.PRESCRIPTION_OR_PHARMACY_ISSUES]: {
        [IVRTreeGoal.CHANGE_PHARMACY_ADDRESS]:
            'Prescription or pharmacy issues => Change pharmacy address',
        [IVRTreeGoal.DOSAGE_CHANGES]: 'Prescription or pharmacy issues => Dosage changes',
        [IVRTreeGoal.PRESCRIPTION_INACCURACIES]:
            'Prescription or pharmacy issues => Prescriptions inaccuracies',
        [IVRTreeGoal.PRESCRIPTIONS_GENERAL]:
            'Prescription or pharmacy issues => Speak with the care team',
    },
    [IVRTreeLevel.MEDICAL_RECORDS]: {
        [IVRTreeGoal.MEDICAL_RECORDS]: 'Medical records => Letter for insurance company',
        [IVRTreeGoal.CONFIRMATION_LETTER]: 'Medical records => Confirmation letter',
        [IVRTreeGoal.AUTHORIZATION_OF_MEDICATIONS]:
            'Medical records => Authorization of medications',
        [IVRTreeGoal.GENERAL_MEDICAL_RECORDS]: 'Medical records => Speak with the care team',
        [IVRTreeGoal.ADVANCE_AUTHORIZATION_OF_MEDICATIONS]:
            'Medical records => Prior authorization of medications',
    },
    [IVRTreeLevel.TECHNICAL_ISSUES]: {
        [IVRTreeGoal.PAYMENT_ISSUES]: 'Technical issues => Payment issues',
        [IVRTreeGoal.APPOINTMENT_ISSUES]: 'Technical issues => Appointment issues',
        [IVRTreeGoal.INTAKE_FILLING_ISSUES]: 'Technical issues => Intake filling issues',
        [IVRTreeGoal.LOGIN_ISSUES]: 'Technical issues => Login issues',
        [IVRTreeGoal.OTHER_ISSUES]: 'Technical issues => Other issues',
    },
    [IVRTreeLevel.GENERAL_QUESTIONS]: {
        [IVRTreeGoal.SPEAK_WITH_CARE_TEAM]: 'General questions => Speak with the care team',
    },
};

export enum CallTypes {
    LAST_CALL = 'last_call',
    INCOMING_CALL = 'incoming_call',
    OUTGOING_CALL = 'outgoing_call',
    ACTIVE_CALL = 'active_call',
}

export enum PhoneClientLogs {
    TOKEN_REQUESTING = 'Requesting Capability Token...',
    TOKEN_EXPIRED = 'Token expired - Receiving a new token...',
    TOKEN_REQUESTING_SUCCESS = 'The token has been received.',
    TOKEN_REQUESTING_ERROR = 'Could not get a token from server!',
    CONNECTION_DESTROYING = 'Connection closing...',
    DEVICE_READY = 'Twilio.Device Ready!',
    DEVICE_DESTROYING = 'Twilio.Device destroying...',
    DEVICE_DESTROYED = 'Twilio.Device Destroyed!',
    DEVICE_ERROR = 'Twilio.Device Error.',
    DEVICE_CANCEL = 'Twilio.Device Cancel.',
    CALL_ACCEPTED = 'Call accepted.',
    CALL_ON_HOLD = 'Call was put on hold.',
    CALL_REJECTED = 'Call rejected',
    CALL_ENDED = 'Call ended.',
    INCOMING_CONNECTION = 'Incoming connection.',
    CONNECTION_OPENED = 'Connection opened',
    CONNECTED_ESTABLISHED = 'Successfully established call!',
    CONNECTION_CLOSED = 'Connection closed.',
    RESERVATION_CREATED = 'Reservation created',
    RESERVATION_CANCELED = 'Reservation canceled',
    RESERVATION_TIMEOUT = 'Reservation timeout',
    MAKING_CALL = 'Making a call...',
    RINGING = 'Ringing...',
    MAKE_CALL_ERROR = 'Make call Error.',
    END_CALL = 'Ending the call...',
    WORKER_READY = 'Twilio.Worker Ready!',
    SET_WORKER_ACTIVITY_ERROR = 'Worker activity updating error.',
    SET_WORKER_ACTIVITY_SUCCESS = 'Worker activity changed.',
    WORKER_ERROR = 'Worker error.',
    TASK_WRAP_UP = 'Task wrap up',
    CLIENT_DISCARD_CALL = 'Client discard call.',
}

export enum ApiTwilioVoiceLogsEnum {
    DEVICE_READY,
    DEVICE_DESTROYED,
    DEVICE_ERROR,
    DEVICE_CANCEL,
    CALL_DELIVERED,
    CALL_EXPIRED,
    CALL_ON_HOLD,
    CALL_REJECTED,
    CALL_ACCEPTED,
    CONNECTION_OPENED,
    CONNECTION_CLOSED,
}
