import { IService } from './service';
import { IServicePlan } from './service-plan';
import { IWebsite } from './website';
import { IClient } from './client';

export enum SubStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    EXPIRED = 'expired',
    CANCELLED = 'cancelled',
}

export const SubStatusReadable = {
    [SubStatus.ACTIVE]: 'Active',
    [SubStatus.INACTIVE]: 'Inactive',
    [SubStatus.EXPIRED]: 'Expired',
    [SubStatus.CANCELLED]: 'Cancelled',
};

// Transfer from crm-web
export interface ISubscription {
    id: number;
    client?: IClient;
    service?: IService;
    servicePlan?: IServicePlan;
    website?: IWebsite;
    originalPrice?: number;
    originalTerm?: number;
    status?: SubStatus;
    activatedAt?: Date;
    paidTo?: Date;
    symptoms?: string;
    createdAt: Date;
    updatedAt: Date;
    countOfLeads: number;
    cancelledAt: Date;
}

export interface ISubSymptoms {
    assesment_answers: Record<string, IAssessmentAnswer[]>;
    assesment_score_sum: Record<string, number>;
}

export interface IAssessmentAnswer {
    answer: string;
    question: string;
    score: string;
}
