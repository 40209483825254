import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MyTasksListComponent } from './my-tasks-list.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { CallPanelComponent } from './call-panel/call-panel.component';
import { RouterModule } from '@angular/router';
import { TaskCardComponent } from './task-card/task-card.component';
import { IconButtonModule } from '../icon-button/icon-button.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ReactiveFormsModule } from '@angular/forms';
import { PhoneSettingsComponent } from './phone-settings/phone-settings.component';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { ContentLoaderModule } from '../../../shared-v2/components/content-loader/content-loader.module';
import { DialpadComponent } from './dialpad/dialpad.component';
import { PrioritySelectModule } from '../../../shared-v2/components/priority-select/priority-select.module';
import { StopClickDirectiveModule } from '../../../shared-v2/directives/stop-click/stop-click.module';

@NgModule({
    declarations: [
        MyTasksListComponent,
        CallPanelComponent,
        TaskCardComponent,
        PhoneSettingsComponent,
        DialpadComponent,
    ],
    imports: [
        CommonModule,
        NgxMaskModule,
        MatIconModule,
        RouterModule,
        IconButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        ContentLoaderModule,
        PrioritySelectModule,
        StopClickDirectiveModule,
    ],
    exports: [MyTasksListComponent],
})
export class MyTasksListModule {}
