<mat-form-field appearance="outline" [formGroup]="formGroup" class="form-field">
    <mat-label>{{ field.label }}</mat-label>
    <input
        *ngIf="field.type !== 'textarea'"
        matInput
        type="{{ field.type }}"
        formControlName="{{ field.controlName }}"
        prefix="{{ field.prefix }}"
        mask="{{ field.mask }}"
        [patterns]="field.patterns"
        [dropSpecialCharacters]="field.dropMaskSymbols"
        [attr.data-test-id]="field.controlName + 'input'"
    />
    <textarea
        *ngIf="field.type === 'textarea'"
        matInput
        formControlName="{{ field.controlName }}"
        [rows]="3"
        [cdkTextareaAutosize]="false"
        [attr.data-test-id]="field.controlName + 'textarea'"
    ></textarea>
    <mat-icon *ngIf="field.icon" matSuffix class="icon" fontSet="material-icons-outlined">{{
        field.icon
    }}</mat-icon>
    <mat-icon
        *ngIf="field.actionIcon"
        matSuffix
        class="icon"
        [class.action]="true"
        fontSet="material-icons-outlined"
        (click)="onClickActionIcon()"
        >{{ field.actionIcon }}</mat-icon
    >
    <mat-error *ngIf="formGroup.get(field.controlName)?.invalid">{{
        errorTextService.getMessage(formGroup.get(field.controlName))
    }}</mat-error>
</mat-form-field>
