import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import { IAuthenticatedUser, UserRole } from '../../med-api/med-api.module';

@Injectable({
    providedIn: 'root',
})
export class ApiAuthService {
    constructor(private http: HttpService) {}

    async checkAuthStatus(): Promise<AuthStatusResponse> {
        return this.http.get<AuthStatusResponse>('auth/status');
    }

    async login(params: AuthLoginParams): Promise<AuthLoginResponse> {
        return this.http.post<AuthLoginResponse>('auth/login', params);
    }

    async logout(): Promise<AuthLogoutResponse> {
        return this.http.get<AuthLogoutResponse>('auth/logout');
    }

    async masterLogin(userId: string, sessionId): Promise<AuthLoginResponse> {
        return this.http.post<AuthLoginResponse>(`auth/master-login`, {
            userId,
            sessionId,
        });
    }

    async getAuthType(systemType: SystemType): Promise<GetAuthTypeResponse> {
        return this.http.get<GetAuthTypeResponse>(`auth/type?systemType=${systemType}`);
    }

    async googleGetLoginURL(redirectUri: string): Promise<AuthGoogleGetLoginURLResponse> {
        return this.http.get<AuthGoogleGetLoginURLResponse>(
            `auth/google/url?redirectUri=${redirectUri}`
        );
    }

    async googleLogin(params: AuthGoogleLoginParams): Promise<AuthLoginResponse> {
        return this.http.post<AuthLoginResponse>(`auth/google/login`, params);
    }
}

interface AuthStatusResponse {
    authenticated: boolean;
    user?: IAuthenticatedUser;
    token?: string;
}

export interface AuthLoginParams {
    roles: UserRole[];
    email: string;
    password: string;
}

export interface AuthLoginResponse {
    user: IAuthenticatedUser;
    authKey: string;
    token: string;
}

interface AuthLogoutResponse {
    authenticated: boolean;
}

interface AuthGoogleGetLoginURLResponse {
    url: string;
}

export interface AuthGoogleLoginParams {
    roles: UserRole[];
    code: string;
    redirectUri: string;
}

export enum AuthType {
    BASE = 'base',
    GOOGLE = 'google',
}

export enum SystemType {
    CRM = 'CRM',
    ADMIN = 'ADMIN',
    DOCTOR = 'DOCTOR',
}

export interface GetAuthTypeResponse {
    authType: AuthType;
}
