import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';
import { SnackbarService } from '@shared/components';
import { SentryModule, SentryModuleOptions } from '@shared/services/sentry';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CrmModule } from './crm/crm.module';
import { ElementsModule } from './elements/elements.module';
import { ErrorsModule } from './errors/errors.module';
import { FinishDayDialogModule } from './shared/components/finish-day-dialog/finish-day-dialog.module';
import { RequestReasonDialogModule } from './shared/components/request-reason-dialog/request-reason-dialog.module';
import { TwilioActiveBeforeUnloadDirective } from './twilio-voice/twilio-active-before-unload.directive';

const sentryConfig: SentryModuleOptions = {
    enabled: environment.environment === 'development' ? false : environment.crmSentry.enabled,
    sentry: {
        dsn: environment.crmSentry.dsn,
        environment: environment.environment,
        tracesSampleRate: environment.crmSentry.tracesSampleRate,
        integrations: (integrations) => {
            return (
                integrations
                    // Integration breaks app-inline-select component click events
                    .filter((integration) => integration.name !== 'Breadcrumbs')
            );
        },
        ignoreErrors: environment.crmSentry.ignoreErrors,
        beforeSend(event: Sentry.Event) {
            if (['fatal', 'error'].includes(event.level)) {
                return event;
            }
            return null;
        },
    },
    http: {
        enabled: environment.crmSentry.http.enabled,
        message: environment.crmSentry.http.defaultErrorHttpMessage,
    },
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        ErrorsModule,
        AuthModule,
        AppRoutingModule,
        CrmModule,
        BrowserAnimationsModule,
        RequestReasonDialogModule,
        FinishDayDialogModule,
        ElementsModule,
        SentryModule.forRoot(sentryConfig),
        TwilioActiveBeforeUnloadDirective,
    ],
    providers: [CookieService, SnackbarService],
    bootstrap: [AppComponent],
})
export class AppModule {}
