import { Component, OnInit, Input } from '@angular/core';
import { FormCheckboxField } from '../../../classes';
import { FormGroup } from '@angular/forms';
import { ErrorTextService } from '../../../services/error-text.service';

@Component({
    selector: 'med-form-checkbox-field',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css'],
})
export class FormCheckboxFieldComponent implements OnInit {
    @Input() field: FormCheckboxField;
    @Input() formGroup: FormGroup;

    constructor(public errorTextService: ErrorTextService) {}

    ngOnInit(): void {}
}
