import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import {
    PrescriptionWithDoctorAndPharmacyFormatted,
} from '../../../med-api/interfaces';

interface IDialogData {
    prescription: PrescriptionWithDoctorAndPharmacyFormatted;
}

@Component({
    selector: 'med-electronic-prescription-preview-dialog',
    templateUrl: './electronic-prescription-preview-dialog.component.html',
    styleUrls: ['./electronic-prescription-preview-dialog.component.css'],
})
export class ElectronicPrescriptionPreviewDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ElectronicPrescriptionPreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData
    ) {}

    get prescriberValue(): string {
        const doctor = this.data.prescription.doctor;
        return doctor
            ? `${doctor.firstName || ''} ${doctor.middleName || ''} ${doctor.lastName || ''}`
            : '-';
    }

    get pharmacyValue(): string {
        const pharmacy = this.data.prescription.pharmacy;
        return pharmacy
            ? [pharmacy.storeName, pharmacy.address1, pharmacy.address2, pharmacy.city].join(', ')
            : '-';
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
