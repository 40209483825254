import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material.module';
import { PatientCalendarLinearComponent } from './components/patient-calendar-linear/patient-calendar-linear.component';
import { PatientCalendarComponent } from './components/patient-calendar/patient-calendar.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TimingComponent } from './components/timing/timing.component';
import { WeekSwitcherComponent } from './components/week-switcher/week-switcher.component';

@NgModule({
    declarations: [
        WeekSwitcherComponent,
        TimingComponent,
        PatientCalendarLinearComponent,
        PatientCalendarComponent,
    ],
    imports: [CommonModule, MaterialModule, RouterModule, MatTooltipModule],
    exports: [
        WeekSwitcherComponent,
        TimingComponent,
        PatientCalendarLinearComponent,
        PatientCalendarComponent,
    ],
})
export class MedScheduleModule {}

export { WeekSwitcherComponent } from './components/week-switcher/week-switcher.component';
export { TimingComponent } from './components/timing/timing.component';
export { PatientCalendarLinearComponent } from './components/patient-calendar-linear/patient-calendar-linear.component';
export { PatientCalendarComponent } from './components/patient-calendar/patient-calendar.component';
export * from './classes';
export * from './interfaces';
export * from './services';
