import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpService } from '../../med-common/services/http.service';
import {
    AppointmentListParams,
    AppointmentListResponse,
    AppointmentLogsResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiAppointmentService {
    constructor(private http: HttpService) {}

    async list(params: AppointmentListParams): Promise<AppointmentListResponse> {
        return this.http.post<AppointmentListResponse>(`crm/appointment/list`, params);
    }

    async getAppointmentLogs(appointmentId: number): Promise<AppointmentLogsResponse> {
        return this.http.get(`crm/appointment/${appointmentId}/events`);
    }

    public requestReschedule(id: number, params: { timeDescription: string }): Observable<{}> {
        return from(this.http.post(`crm/appointment/${id}/reschedule`, params));
    }

    public updateTime(id: number, time: string): Observable<{}> {
        return from(
            this.http.patch(`public/crm-lead/${id}/update-time`, {
                time,
            })
        );
    }

    public markAsDeleted(leadId: number): Observable<{}> {
        return from(this.http.patch(`public/crm-lead/${leadId}/mark-as-deleted`, {}));
    }
}
