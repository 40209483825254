import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    LeadListParams,
    LeadListResponse,
    LeadGetResponse,
    ILeadCreateParams,
    ILeadCreateResponse,
    ILead,
    ILeadCloseParams,
    ILeadCloseResponse,
    IApplication,
    ILeadSendLinkParams,
    ILeadDetailsEditParams,
    ICrmLead,
    ICheckPromoCodeParams,
    ICheckPromoCodeResponse,
    ILeadRefundParams,
    ILeadRefundResponse,
    IEligibilityResultsParams,
    IEligibilityResult,
    IEligibilityResultFileResponse,
} from '../interfaces';
import { IAppointmentSchedule } from '../../med-schedule/interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiLeadService {
    constructor(private http: HttpService) {}

    async list(params: LeadListParams): Promise<LeadListResponse> {
        return this.http.post<LeadListResponse>(`public/crm-lead/list`, params);
    }

    async refundList(params: LeadListParams): Promise<LeadListResponse> {
        return this.http.post<LeadListResponse>(`public/crm-lead/refund-list`, params);
    }

    async get(id: number): Promise<ILead> {
        return this.http.get<ILead>(`public/crm-lead?id=${id}`);
    }

    async getByPhone(phone: string): Promise<LeadGetResponse> {
        return this.http.post<LeadGetResponse>(`public/crm-lead/getByPhone`, {
            type: 'phone',
            phone,
        });
    }

    async updateLeadTestFlag(leadId: number, isTest: boolean): Promise<any> {
        return this.http.post(`public/crm-lead/update-test-flag`, { leadId, isTest });
    }

    async editLeadDetails(leadId: number, leadDetails: ILeadDetailsEditParams): Promise<ICrmLead> {
        return this.http.post(`public/crm-lead/edit-details/${leadId}`, leadDetails);
    }

    async editLeadStatus(leadId: number, status: number): Promise<ICrmLead> {
        return this.http.post(`public/crm-lead/status/${leadId}`, { status });
    }

    async getDoctorsSchedule(
        leadId: number,
        doctorId: number,
        date: string
    ): Promise<Array<IAppointmentSchedule>> {
        return this.http.post(`public/crm-schedule/v2`, {
            leadId,
            doctorId,
            date,
        });
    }

    async create(params: ILeadCreateParams): Promise<ILeadCreateResponse> {
        return this.http.post(`public/crm-lead`, params);
    }

    async checkPromoCode(params: ICheckPromoCodeParams): Promise<ICheckPromoCodeResponse> {
        return this.http.post<ICheckPromoCodeResponse>(`crm/payment/codes/check-promocode`, params);
    }

    async getMedicalRecords(leadId: number): Promise<any> {
        return this.http.get<any>(`public/crm-lead/${leadId}/medical-records`);
    }

    async getApplicationData(leadId: number): Promise<IApplication> {
        return this.http.get<any>(`public/crm-lead/${leadId}/application`);
    }

    async closeLost(params: ILeadCloseParams): Promise<ILeadCloseResponse> {
        return this.http.post<ILeadCloseResponse>(`public/crm-lead/close-lost`, params);
    }

    async closeWon(params: ILeadCloseParams): Promise<ILeadCloseResponse> {
        return this.http.post<ILeadCloseResponse>(`public/crm-lead/close-won`, params);
    }

    async sendApplicationLink(params: ILeadSendLinkParams): Promise<void> {
        return this.http.post<void>('public/crm-lead/application-link', params);
    }

    async sendCallLink(params: ILeadSendLinkParams): Promise<void> {
        return this.http.post<void>('public/crm-lead/call-link', params);
    }

    async refund(params: ILeadRefundParams): Promise<ILeadRefundResponse> {
        return this.http.post<ILeadRefundResponse>(`public/crm-lead/refund`, params);
    }

    async setBlacklisted(leadId: number): Promise<any> {
        return this.http.post(`public/crm-lead/${leadId}/blacklist`, {});
    }

    async removeBlacklisted(leadId: number): Promise<any> {
        return this.http.delete(`public/crm-lead/${leadId}/blacklist`);
    }

    async sendEligibilityResults(body: IEligibilityResultsParams): Promise<IEligibilityResult> {
        return this.http.post('eligibility/save-result', body);
    }

    async uploadEligibilityFile(file: File): Promise<IEligibilityResultFileResponse> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post('eligibility/upload-eligibility-result', formData);
    }
}
