import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { FormComponent } from './components/form/form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { FormInputFieldComponent } from './components/fields/input/input.component';
import { MaterialModule } from '../material.module';
import { FormSelectFieldComponent } from './components/fields/select/select.component';
import { FormCheckboxFieldComponent } from './components/fields/checkbox/checkbox.component';
import { FormMultiInputFieldComponent } from './components/fields/multi-input/multi-input.component';
import { FormMultiInputAutoFieldComponent } from './components/fields/multi-input-auto/multi-input-auto.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CUSTOM_DATE_FORMATS } from './custom-date-adapter';
import { FormDateInputFieldComponent } from './components/fields/date-input/date-input.component';
import { AutocompleteFieldComponent } from './components/fields/autocomplete/autocomplete.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

@NgModule({
  declarations: [
    FormComponent,
    FormFieldComponent,
    FormInputFieldComponent,
    FormSelectFieldComponent,
    FormCheckboxFieldComponent,
    FormMultiInputFieldComponent,
    FormMultiInputAutoFieldComponent,
    FormDateInputFieldComponent,
    AutocompleteFieldComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [FormComponent],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],
})
export class MedFormModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-en');
  }
}

export { FormComponent } from './components/form/form.component';
export * from './classes';
export * from './services/error-text.service';
export * from './validators';
export * from './interfaces';
