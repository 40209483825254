<mat-form-field
    *ngIf="!field.startView"
    appearance="outline"
    [formGroup]="formGroup"
    class="form-field custom-datepicker"
>
    <mat-label>{{ field.label }}</mat-label>
    <input
        #input
        matInput
        [matDatepicker]="picker"
        [min]="field.min"
        [max]="field.max"
        [formControlName]="field.controlName"
        class="custom-datepicker-input"
        autocomplete="off"
        (click)="picker.open()"
        (dateChange)="handleDateChange($event.target.value)"
        [attr.data-test-id]="field.label + ' input'"
    />
    <mat-icon
        *ngIf="isActiveClearButton()"
        (click)="clearValue()"
        matSuffix
        fontSet="material-icons-outlined"
        class="clear"
    >
        clear
    </mat-icon>
    <mat-datepicker-toggle
        *ngIf="!isActiveClearButton()"
        matSuffix
        [for]="picker"
        class="custom-datepicker-toggle"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="formGroup.get(field.controlName)?.invalid">{{
        errorTextService.getMessage(formGroup.get(field.controlName))
    }}</mat-error>
</mat-form-field>

<div
    *ngIf="field.startView"
    class="container"
    (mouseenter)="onMouseenter()"
    (mouseleave)="onMouseLeave()"
>
    <div class="info" (click)="showCalendar = !showCalendar">
        <div class="description">
            <div class="icon">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g opacity="0.3">
                        <path
                            d="M3.09253 9.40445H20.9165"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M16.442 13.3097H16.4512"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12.0047 13.3097H12.014"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.55793 13.3097H7.5672"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M16.442 17.1964H16.4512"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12.0047 17.1964H12.014"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.55793 17.1964H7.5672"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M16.0438 2V5.29078"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M7.9654 2V5.29078"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                            stroke="#081D33"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                </svg>
            </div>
            <p>{{ field.label }}:</p>
        </div>
        <div class="value">{{ formGroup.value[field.controlName] | date: field.format }}</div>
    </div>
    <div class="calendar-block" *ngIf="showCalendar">
        <mat-calendar
            startView="{{ field.startView }}"
            [selected]="formGroup.value[field.controlName]"
            (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event)"
            (selectedChange)="handleDateChange($event)"
        ></mat-calendar>
    </div>
</div>
