import { SidebarItemParams, SidebarItemChildParams } from '../interfaces';
import { SafeHtml } from '@angular/platform-browser';

export class SidebarItem {
    public path: string;
    public label: string;
    public icon?: string;
    public customIcon?: SafeHtml;
    public isTopPosition: boolean;
    public disabled: boolean;
    public children: SidebarItemChildParams[];
    public func: Function;

    constructor(props: SidebarItemParams) {
        this.path = props.path;
        this.label = props.label;
        this.icon = props.icon;
        this.customIcon = props.customIcon;
        this.isTopPosition = props.isTopPosition;
        this.disabled = props.disabled;
        this.func = props.func;
    }

    public addChild(props: SidebarItemChildParams): void {
        if (this.children) {
            this.children.push({
                path: props.path,
                label: props.label,
                icon: props.icon,
                disabled: props.disabled,
                func: props.func,
            });
        } else {
            this.children = [
                {
                    path: props.path,
                    label: props.label,
                    icon: props.icon,
                    disabled: props.disabled,
                    func: props.func,
                },
            ];
        }
    }
}
