import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    INote,
    INoteListParams,
    INoteListResponse,
    INoteCreateParams,
    INoteEditParams,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class NoteService {
    constructor(private http: HttpService) {}

    async list(params: INoteListParams): Promise<INoteListResponse> {
        return this.http.post<INoteListResponse>(`note/list`, params);
    }

    async create(params: INoteCreateParams): Promise<INote> {
        return this.http.post<INote>(`note`, params);
    }

    async edit(id: number, params: INoteEditParams): Promise<INote> {
        return this.http.patch<INote>(`note/${id}`, params);
    }

    async delete(id: number): Promise<void> {
        return this.http.delete(`note/${id}`);
    }
}
