<div class="make-call-panel">
    <div class="make-call-panel-container">
        <input
            class="make-call-panel__input"
            [formControl]="dialPadControl"
            [mask]="' 000 0000 000'"
            prefix="+1"
            #dialpadInput
            placeholder="+1 000 0000 000"
            type="tel"
        />
        <div
            *ngIf="dialPadControl.value"
            (click)="deleteLastSymbolFromNumber()"
            class="make-call-panel__delete"
        >
            <mat-icon fontSet="material-icons-outlined">backspace</mat-icon>
        </div>
    </div>
    <div class="make-call-panel-buttons">
        <div (click)="onMakeCallClicked()" class="make-call-panel-buttons__button">
            <mat-icon>phone</mat-icon>
        </div>
    </div>
</div>
