import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    ICRMSuperBillGetAppointmentsParams,
    ICRMSuperBillGetAppointmentsResponse,
    ICRMSuperBillRenderSuperBillParams,
    ICRMSuperBillRenderSuperBillResponse,
    ICRMSuperBillUpdateRenderSuperBillParams,
    ICRMSuperBillUpdateRenderSuperBillResponse,
    ICRMSuperBillSendRenderSuperBillParams,
    ICRMSuperBillSendRenderSuperBillResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiCRMSuperBillService {
    constructor(private http: HttpService) {}

    getAppointments(
        params: ICRMSuperBillGetAppointmentsParams
    ): Promise<ICRMSuperBillGetAppointmentsResponse> {
        return this.http.post<ICRMSuperBillGetAppointmentsResponse>(
            `crm/super-bill/appointments`,
            params
        );
    }

    renderSuperBill(
        params: ICRMSuperBillRenderSuperBillParams
    ): Promise<ICRMSuperBillRenderSuperBillResponse> {
        return this.http.post<ICRMSuperBillRenderSuperBillResponse>(
            `crm/super-bill/render`,
            params
        );
    }

    updateRenderSuperBill(
        uuid: string,
        params: ICRMSuperBillUpdateRenderSuperBillParams
    ): Promise<ICRMSuperBillUpdateRenderSuperBillResponse> {
        return this.http.put<ICRMSuperBillUpdateRenderSuperBillResponse>(
            `crm/super-bill/render/${uuid}`,
            params
        );
    }

    sendRenderSuperBill(
        uuid: string,
        params: ICRMSuperBillSendRenderSuperBillParams
    ): Promise<ICRMSuperBillSendRenderSuperBillResponse> {
        return this.http.post<ICRMSuperBillSendRenderSuperBillResponse>(
            `crm/super-bill/render/${uuid}/send`,
            params
        );
    }
}
