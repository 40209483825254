import { Component, OnInit, Input } from '@angular/core';

import { Sidebar, SidebarItem } from '../../classes';
import { SidebarStorageService } from '../../services/sidebar-storage.service';
import { SafeHtml } from '@angular/platform-browser';
import { AutotestsUtilsService } from '../../../med-common/services/autotests-utils.service';

@Component({
    selector: 'med-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
    @Input() sidebar: Sidebar;
    public logoIcon?: SafeHtml;
    public sidebarContent: SidebarItem[];
    public sidebarContentTop: SidebarItem[];
    public sidebarContentBottom: SidebarItem[];

    constructor(
        public sidebarStorageService: SidebarStorageService,
        public autotestsUtilsService: AutotestsUtilsService
    ) {}

    ngOnInit() {
        this.initSidebar();
    }

    initSidebar(): void {
        this.logoIcon = this.sidebar.getLogo();
        this.sidebarContent = this.sidebar.getItems();
        this.sidebarContentTop = this.sidebar.getItems().filter((item) => item.isTopPosition);
        this.sidebarContentBottom = this.sidebar.getItems().filter((item) => !item.isTopPosition);
    }

    onClickMenuItem(menu: SidebarItem): void {
        if (!this.sidebarStorageService.additionalMenuOpen) {
            this.additionalMenuShow(menu);
        } else {
            if (this.sidebarStorageService.getActiveMenu() === menu.label) {
                this.additionalMenuHide(false);
            } else {
                this.additionalMenuShow(menu);
            }
        }

        this.setMenuLabel(menu.label);
    }

    additionalMenuShow(menu: SidebarItem): void {
        if (menu && menu.children) {
            this.sidebarStorageService.additionalMenu = menu.children;
            this.sidebarStorageService.additionalMenuOpen = true;
        }
    }

    additionalMenuHide(remove: boolean = true): void {
        if (remove) {
            this.sidebarStorageService.additionalMenu = [];
            this.sidebarStorageService.additionalMenuLabel = null;
        }

        this.sidebarStorageService.additionalMenuOpen = false;
    }

    setAdditionalMenuLabel(label: string): void {
        this.sidebarStorageService.additionalMenuLabel = label;
    }

    setMenuLabel(label: string): void {
        this.sidebarStorageService.setActiveMenu(label);
    }

    isActiveAdditionalMenuItem(label: string): boolean {
        return this.sidebarStorageService.additionalMenuLabel === label;
    }

    isActiveMenuItem(label: string): boolean {
        return this.sidebarStorageService.getActiveMenu() === label;
    }
}
