import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
    FormField,
    FormInputField,
    FormSelectField,
    FormCheckboxField,
    FormMultiInputField,
    FormMultiInputAutoField,
    FormDateInputField,
    FormAutocompleteField,
} from '../../classes';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'med-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.css'],
})
export class FormFieldComponent implements OnInit {
    @Input() field: FormField;
    @Input() formGroup: FormGroup;
    @Output() clickIcon = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}

    getFieldType(): string {
        if (this.field instanceof FormAutocompleteField) {
            return 'autocomplete';
        }
        if (this.field instanceof FormInputField) {
            return 'input';
        }
        if (this.field instanceof FormSelectField) {
            return 'select';
        }
        if (this.field instanceof FormCheckboxField) {
            return 'checkbox';
        }
        if (this.field instanceof FormMultiInputField) {
            return 'multi-input';
        }
        if (this.field instanceof FormMultiInputAutoField) {
            return 'multi-input-auto';
        }
        if (this.field instanceof FormDateInputField) {
            return 'date-input';
        }
        return null;
    }

    public onClickActionIcon(controlName: string) {
        this.clickIcon.emit(controlName);
    }
}
