import { Component, OnInit } from '@angular/core';
import { TraceClassDecorator } from '@sentry/angular';
import { showBuildData } from '@shared/utils/show-build-data/show-build-data.utility';
import { AppStateService, CommonDataFilterType, CommonDataService } from 'medvidi';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
@TraceClassDecorator()
export class AppComponent implements OnInit {
    constructor(
        public appStateService: AppStateService,
        private commonDataService: CommonDataService
    ) {
        showBuildData();
    }

    ngOnInit(): void {
        this.setCommonDataFilters();
    }

    private setCommonDataFilters() {
        const filters = [
            CommonDataFilterType.STATES,
            CommonDataFilterType.WEBSITES,
            CommonDataFilterType.SERVICES,
            CommonDataFilterType.DOCTORS,
            CommonDataFilterType.MANAGERS,
            CommonDataFilterType.ADMINS,
            CommonDataFilterType.SERVICE_ACCOUNTS,
            CommonDataFilterType.EMAIL_TEMPLATES,
            CommonDataFilterType.LETTER_TEMPLATES,
            CommonDataFilterType.INSURANCE_COMPANIES,
            CommonDataFilterType.USER_GROUPS,
            CommonDataFilterType.TASK_ATTRIBUTES,
        ];

        this.commonDataService.setCommonDataFilters(filters);
    }
}
