import { Injectable } from '@angular/core';
import { DoctorEventType } from '../interfaces/event.type';
import { RestApiService } from './rest-api.service';

export interface IEventAnalyticsData {
    type: DoctorEventType;
    metadata?: Record<string, unknown>;
}

@Injectable({
    providedIn: 'root',
})
export class EventAnalyticsService {
    public isDoctorProfile = false;

    private _isProd: boolean;

    public get isProd(): boolean {
        return this._isProd;
    }

    public set isProd(value: boolean) {
        this._isProd = value;
    }

    constructor(private restApiService: RestApiService) {}

    public emitEvent(data: IEventAnalyticsData): void {
        if (!this.isDoctorProfile) return;

        this.restApiService.post('doctor-area/doctor-event/', data).subscribe(() => {
            if (!this.isProd) console.table({ type: data.type, ...data.metadata });
        });
    }

    public emitError(message: string): void {
        if (!this.isDoctorProfile) return;

        this.restApiService.post('doctor-area/doctor-event/error', { message }).subscribe(() => {
            if (!this.isProd) console.table({ type: 'error', message });
        });
    }
}
