import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { ApiAuthService } from '../../api/auth.service';
import { UserRole } from '../../../med-api/interfaces';

@Component({
    selector: 'med-google-login',
    templateUrl: './google-login.component.html',
})
export class GoogleLoginComponent implements OnInit {
    @Input() redirectUri: string;
    @Input() availableRoles: UserRole[];
    @Output() onLogged = new EventEmitter();

    constructor(
        private apiAuthService: ApiAuthService,
        private authService: AuthService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(async (params) => {
            try {
                if (params.code) {
                    await this.authService.googleLogin({
                        roles: this.availableRoles,
                        code: params.code,
                        redirectUri: this.redirectUri,
                    });
                    this.onLogged.emit();
                } else {
                    const { url } = await this.apiAuthService.googleGetLoginURL(this.redirectUri);
                    window.location.replace(url);
                }
            } catch (err) {
                console.warn('[MEDVIDI GOOGLE LOGIN] - error auth: ', err);
            }
        });
    }
}
