import { IPageResult } from '../../med-common/interfaces/page-result';
import { FirstPrescriptionDiagnosis } from './dosespot-erx';

export enum PrescriptionsPhoneType {
    BEEPER = 1,
    CELL = 2,
    FAX = 3,
    HOME = 4,
    WORK = 5,
    NIGHT = 6,
}

export const PrescriptionsPhoneTypeReadable = {
    [PrescriptionsPhoneType.BEEPER]: 'Beeper',
    [PrescriptionsPhoneType.CELL]: 'Cell',
    [PrescriptionsPhoneType.FAX]: 'Fax',
    [PrescriptionsPhoneType.HOME]: 'Home',
    [PrescriptionsPhoneType.WORK]: 'Work',
    [PrescriptionsPhoneType.NIGHT]: 'Night',
};

export interface IPrescriptionsDataResponse {
    link: string;
    prescriptions: PrescriptionWithDoctorAndPharmacyFormatted[];
    pageResult: IPageResult;
}

export interface INotificationsDataResponse {
    link: string;
}

export enum ElectronicPrescriptionStatusType {
    ENTERED = 1,
    PRINTED = 2,
    SENDING = 3,
    E_RX_SENT = 4,
    FAX_SENT = 5,
    ERROR = 6,
    DELETED = 7,
    REQUESTED = 8,
    EDITED = 9,
    EPCS_ERROR = 10,
    EPCS_SIGNED = 11,
    READY_TO_SIGN = 12,
    PHARMACY_VERIFIED = 13,
}

export enum ElectronicPrescriptionStatus {
    ENTERED = 'Entered',
    PRINTED = 'Printed',
    SENDING = 'Sending',
    E_RX_SENT = 'eRxSent',
    FAX_SENT = 'FaxSent',
    ERROR = 'Error',
    DELETED = 'Deleted',
    REQUESTED = 'Requested',
    EDITED = 'Edited',
    EPCS_ERROR = 'EpcsError',
    EPCS_SIGNED = 'EpcsSigned',
    READY_TO_SIGN = 'ReadyToSign',
    PHARMACY_VERIFIED = 'PharmacyVerified',
}

export const electronicPrescriptionStatusTypeReadable = {
    [ElectronicPrescriptionStatus.ENTERED]: 'Entered',
    [ElectronicPrescriptionStatus.PRINTED]: 'Printed',
    [ElectronicPrescriptionStatus.SENDING]: 'Sending',
    [ElectronicPrescriptionStatus.E_RX_SENT]: 'eRx Sent',
    [ElectronicPrescriptionStatus.FAX_SENT]: 'Fax Sent',
    [ElectronicPrescriptionStatus.ERROR]: 'Error',
    [ElectronicPrescriptionStatus.DELETED]: 'Deleted',
    [ElectronicPrescriptionStatus.REQUESTED]: 'Requested',
    [ElectronicPrescriptionStatus.EDITED]: 'Edited',
    [ElectronicPrescriptionStatus.EPCS_ERROR]: 'EPCS Error',
    [ElectronicPrescriptionStatus.EPCS_SIGNED]: 'EPCS Signed',
    [ElectronicPrescriptionStatus.READY_TO_SIGN]: 'Ready to Sign',
    [ElectronicPrescriptionStatus.PHARMACY_VERIFIED]: 'Pharmacy Verified',
};

export enum DoseSpotMedicationStatuses {
    FULL_FILL = 'FullFill',
    PARTIAL_FILL = 'PartialFill',
    NO_FILL = 'NoFill',
    ACTIVE = 'Active',
    DISCONTINUED = 'Discontinued',
    DELETED = 'Deleted',
    COMPLETED = 'Completed',
    CANCEL_REQUESTED = 'CancelRequested',
    CANCEL_PENDING = 'CancelPending',
    CANCELLED = 'Cancelled',
    CANCEL_DENIED = 'CancelDenied',
    CHANGED = 'Changed',
}

export interface IPrescriptionsPatientProfileData {
    firstName: string;
    lastName: string;
    dob: string;
    gender: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone: string;
    phoneType: PrescriptionsPhoneType;
    dosespotId?: number;
}

export interface IElectronicPrescriptionsPatient {
    dosespotId: number;
    active: boolean;
    address: string;
    city: string;
    state: string;
    zip: string;
    dob: Date;
    email?: string;
    firstName: string;
    lastName: string;
    gender: string;
    phone: string;
    phoneType: PrescriptionsPhoneType;
}

export interface PrescriptionWithDoctorAndPharmacyFormatted
    extends Omit<PrescriptionFormatted, 'prescriberid' | 'pharmacyId'> {
    doctor: PrescriptionDoctorFormatted | null;
    pharmacy: PrescriptionPharmacyFormatted | null;
}

export interface PrescriptionPharmacyFormatted {
    id: number;
    storeName: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zipcode: string;
    primaryPhone: string;
    primaryFax: string;
}

export interface PrescriptionDoctorFormatted {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    prefix?: string;
}

export interface PrescriptionFormatted {
    id: number;
    displayName: string;
    prescriberId: number;
    pharmacyId: number;
    firstPrescriptionDiagnosis: FirstPrescriptionDiagnosis;
    genericDrugName: string;
    genericProductName: string;
    drugClassification: string;
    status: ElectronicPrescriptionStatus;
    medicationStatus: String;
    writtenDate: Date;
    effectiveDate: Date;
    dateInactive: Date;
    directions: string;
    strength: string;
    refills: string;
    quantity: string;
    daysSupply: number;
    isUrgent: boolean;
    pharmacyNotes: string;
    dispenseUnitDescription: string;
    noSubstitutions: boolean;
    schedule: string;
    lexiDrugSynId: number;
    lexiSynonymTypeId: number;
    dispenseUnitId: number;
    lexiGenProductId: number;
    ndc: string;
}

export interface IPrescriptionsNotificationsData {
    pendingPrescriptionCount: number;
    transmissionErrorCount: number;
    refillRequestCount: number;
    changeRequestCount: number;
    erxTasksCount: number;
}

export interface IElectronicPrescriptionsCreatePatientResponse {
    link: string;
}

export interface IElectronicPrescriptionsLinkPatientResponse {
    link: string;
}

export interface IElectronicPrescriptionsSearchPatientRequest {
    firstName: string;
    lastName: string;
    dob: Date;
}

export enum IPrescriptionEventType {
    PRESCRIPTION_CREATED = 'PrescriptionCreated',
    PRESCRIPTION_UPDATED = 'PrescriptionUpdated',
    MEDICATION_STATUS_UPDATED = 'MedicationStatusUpdated',
}
