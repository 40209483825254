import { ListResponse } from '../../med-list/interfaces';
import { IWebsite } from './website';

export interface IServiceAccount {
    id: number;
    code: string;
    name: string;
    website?: IWebsite;
    channel: IServiceAccountChannel;
    allowedForDoctors: boolean;
    defaultUtmSource?: string;
    ignoringEvents: boolean;
    serviceChannel: boolean;
    twilioAccount?: ServiceAccountTwilioAccount;
    createdAt: Date;
    updatedAt?: Date;
    deletedAt?: Date;
    reservedForCustomerIo: boolean;
}

export interface ICommonDataServiceAccount {
    id: number;
    name: string;
    code: string;
    website?: {
        id: number;
        code: string;
        name: string;
    };
    channel: string;
    allowedForDoctors: boolean;
    serviceChannel: boolean;
    twilioAccount: number;
    createdAt: Date;
    updatedAt: Date;
    reservedForCustomerIo: boolean;
}

export const enum ServiceAccountTwilioAccount {
    MAIN = 1,
    SPARE = 2,
}

export enum IServiceAccountChannel {
    PHONE = 'phone',
    FACEBOOK = 'facebook',
    EMAIL = 'email',
}

export interface IServiceAccountListParams {
    page: number;
    limit: number;
}

export interface IServiceAccountListResponse extends ListResponse {
    items: IServiceAccount[];
}

export interface IServiceAccountCreateParams {
    code: string;
    name: string;
    channel: IServiceAccountChannel;
    websiteId?: string;
    allowedForDoctors: boolean;
    defaultUtmSource?: string;
    ignoringEvents?: boolean;
    serviceChannel?: boolean;
    twilioAccount?: ServiceAccountTwilioAccount;
    reservedForCustomerIo: boolean;
    userGroupId: number;
}

export interface IServiceAccountEditParams {
    code: string;
    name: string;
    channel: IServiceAccountChannel;
    websiteId?: string;
    allowedForDoctors: boolean;
    defaultUtmSource?: string;
    ignoringEvents?: boolean;
    serviceChannel?: boolean;
    twilioAccount?: ServiceAccountTwilioAccount;
    reservedForCustomerIo: boolean;
    userGroupId?: number;
}
