import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-content-loader',
    templateUrl: './content-loader.component.html',
    styleUrls: ['./content-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentLoaderComponent {
    @Input() size: 20 | 40 = 40;
    @Input() label: string = '';

    get fontSize(): number {
        return 10 + this.size / 10;
    }

    get borderWidth(): number {
        return this.size / 10;
    }
}
