<div class="container">
    <mat-tab-group>
        <mat-tab label="Profile Info">
            <med-form [form]="profileForm"></med-form>
        </mat-tab>
        <mat-tab label="Change password">
            <med-form [form]="passwordForm"></med-form>
        </mat-tab>
    </mat-tab-group>
</div>
