<div class="loader-container">
    <div
        class="loader"
        [style.border-width.px]="borderWidth"
        [style.width.px]="size"
        [style.height.px]="size"
    ></div>

    <div *ngIf="label" [style.font-size.px]="fontSize" class="loader-label">
        {{ label }}
    </div>
</div>
