<div class="container">
    <h1>Search dosespot patient</h1>
    <med-form [form]="patientDataForm"></med-form>
    <div class="actions-container">
        <button mat-stroked-button (click)="closeDialog()">Cancel</button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="!patientDataForm.getFormGroup().valid"
            (click)="searchPatient()"
            [attr.data-test-id]="'Search doseSpot patient button'"
        >
            Search patient
        </button>
    </div>
</div>
