import { Injectable } from '@angular/core';
import { FormControl, AbstractControl, FormGroup } from '@angular/forms';
import {
    formFieldErrors,
    FormGroupErrorCodeTypes,
    formGroupErrors,
} from '../consts/error-texts.const';

@Injectable({
    providedIn: 'root',
})
export class ErrorTextService {
    constructor() {}

    public getMessage(el: FormControl | AbstractControl | FormGroup): string {
        const errorTexts = el instanceof FormGroup ? formGroupErrors : formFieldErrors;
        for (let er of errorTexts) {
            if (el.hasError(er.code)) {
                if (['minlength', 'maxlength'].includes(er.code)) {
                    return er.text.replace('{X}', el.getError(er.code).requiredLength);
                }

                if ('min' === er.code) {
                    return er.text.replace('{X}', el.getError(er.code).min);
                }

                if ('max' === er.code) {
                    return er.text.replace('{X}', el.getError(er.code).max);
                }

                return er.text;
            }
        }
        return '';
    }

    public getFormGroupErrorCodes() {
        return FormGroupErrorCodeTypes;
    }

    public getFormGroupErrorText(type: FormGroupErrorCodeTypes): string {
        return formGroupErrors.find((e) => e.code === type)?.text;
    }
}
