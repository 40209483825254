<form class="choose-doctor-form">
    <mat-form-field appearance="outline" class="example-full-width">
        <mat-label>Choose a doctor</mat-label>
        <label class="placeholder" for="choose-doctor">
            {{ placeholder }}
        </label>
        <input
            #autoCompleteInput
            [formControl]="doctorSearchControl"
            [matAutocomplete]="auto"
            id="choose-doctor"
            matInput
            data-test-id="Choose Doctor Input"
        />
        <button
            (click)="clearInput()"
            *ngIf="doctorSearchControl.value"
            aria-label="Clear"
            mat-icon-button
            matSuffix
        >
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete
            #auto="matAutocomplete"
            #autocompleteDropDown
            (optionSelected)="handleDoctorChange($event.option.value.value)"
            [autoActiveFirstOption]="true"
            [displayWith]="displayFn"
        >
            <mat-option
                *ngFor="let option of filteredOptionsArray"
                [value]="option"
                [attr.data-test-id]="option.value"
            >
                {{ option.text }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
