import { FormField } from '../form-field';
import { FormMultiInputParams } from '../../interfaces/form-multi-input-params';

export class FormMultiInputField extends FormField {
  strings: string[];

  constructor(params: FormMultiInputParams) {
    super(params);
    this.strings = params.strings || [];
  }
}
