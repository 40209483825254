<p class="title">Calendar shows the patient's time zone: ({{ timezone }})</p>
<div class="body">
    <div class="legend">
        <div class="legend__item">
            <div class="legend__indicator legend_selected"></div>
            <span class="legend__description">Selected</span>
        </div>
        <div class="legend__item">
            <div class="legend__indicator legend_available"></div>
            <span class="legend__description">Available</span>
        </div>
    </div>
    <div class="button-container">
        <med-button
            (click)="closeDialog.emit()"
            [width]="100"
            data-test-id="Cancel button"
            text="Cancel"
            class="transparent cancel-button"
        ></med-button>
        <med-button
            (click)="confirm.emit()"
            [ngClass]="{ disabled: isDisabled }"
            [width]="100"
            data-test-id="Save button"
            text="Confirm"
            class="secondary"
        ></med-button>
    </div>
</div>
