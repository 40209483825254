import { Pipe, PipeTransform } from '@angular/core';
import { LeadStatus, LeadStatusReadable } from '../../med-api/interfaces';

@Pipe({
    name: 'leadStatusPipe',
})
export class LeadStatusPipe implements PipeTransform {
    transform(value: LeadStatus): string {
        return LeadStatusReadable[value] || '...';
    }
}
