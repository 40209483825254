import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { PDFDocumentProxy, PDFPageProxy } from 'ng2-pdf-viewer';
import { ICommonDataLetterTemplate } from '../../../med-api/interfaces';
import { ApiLetterService, ApiLetterTemplateService } from '../../../med-api/services';
import { AppFileService } from '../../../med-common/services/app-file.service';
import { CommonDataService } from '../../../med-common/services/common-data.service';
import { Form, FormBuilder, FormDateInputField, FormSelectField } from '../../../med-form/classes';
import {
    IDialogData,
    ILetterInput,
    IPdfInfo,
    LetterGenerationStep,
    LetterResult,
    LetterResultMessages,
} from '../../interfaces';

const DEFAULT_LETTER_TEMPLATE: string = 'esa_house_and_travel_2';
const DEFAULT_EMAIL_TEMPLATE: string = 'esa_letter';
const DEFAULT_SERVICE: string = 'esa';

@Component({
    selector: 'med-letter-generation',
    templateUrl: './letter-generation.component.html',
    styleUrls: ['./letter-generation.component.css'],
})
export class LetterGenerationComponent implements OnInit, AfterViewInit {
    @ViewChild('pdfEditContent') pdfEditContent: ElementRef;
    @ViewChild('emailEditContent') emailEditContent: ElementRef;

    public loaded: boolean = true;
    public chooseTemplateForm: Form;
    public step: LetterGenerationStep = LetterGenerationStep.INIT;
    public pdfInfo: IPdfInfo;
    public pdfForm: FormGroup = new FormGroup({});
    public inputList: ILetterInput[] = [];
    public letterSendResult: string;
    public letterGenerationStep = LetterGenerationStep;

    constructor(
        private dialogRef: MatDialogRef<LetterGenerationComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IDialogData,
        private sanitizer: DomSanitizer,
        private apiLetterTemplateService: ApiLetterTemplateService,
        private apiLetterService: ApiLetterService,
        private commonDataService: CommonDataService,
        private appFileService: AppFileService
    ) {}

    public ngOnInit(): void {
        this.init();
    }

    public ngAfterViewInit(): void {
        this.chooseTemplateForm
            .getFormGroup()
            .get('letterTemplate')
            .valueChanges.subscribe((id) => {
                const letterTemplate: ICommonDataLetterTemplate =
                    this.commonDataService.letterTemplates.find((l) => l.id === id);
                const validFromValue = this.chooseTemplateForm
                    .getFormGroup()
                    .get('validFrom').value;

                const validFrom = validFromValue
                    ? DateTime.fromJSDate(validFromValue)
                    : DateTime.local();

                const validityMonths = letterTemplate.validity || 12;
                const validUntil = validFrom.plus({ months: validityMonths });

                const updatedValues = {
                    validUntil: validUntil.toJSDate(),
                    validFrom: validFrom.toJSDate(),
                };

                this.chooseTemplateForm.getFormGroup().patchValue(updatedValues);
            });
    }

    private async init(): Promise<void> {
        try {
            this.loaded = false;
            this.initChooseTemplateForm();
            this.loaded = true;
        } catch (err) {
            console.warn('[PROFILE LETTER GENERATION] - init: ', err.message);
            this.dialogRef.close();
        }
    }

    private initChooseTemplateForm(): void {
        const formBuilder: FormBuilder = new FormBuilder();
        const defaultLetterTemplate: ICommonDataLetterTemplate =
            this.commonDataService.letterTemplates.find((x) => x.code === DEFAULT_LETTER_TEMPLATE);

        formBuilder.addField(FormSelectField, 'letterTemplate', {
            label: 'Letter Template',
            options: this.commonDataService.letterTemplates
                ? this.commonDataService.letterTemplates.map((x) => ({ text: x.name, value: x.id }))
                : [],
            value: defaultLetterTemplate?.id,
        });
        formBuilder.addField(FormSelectField, 'emailTemplate', {
            label: 'Email Template',
            options: this.commonDataService.emailTemplates
                ? this.commonDataService.emailTemplates.map((x) => ({ text: x.name, value: x.id }))
                : [],
            value: this.commonDataService.emailTemplates.find(
                (x) => x.code === DEFAULT_EMAIL_TEMPLATE
            )?.id,
        });
        formBuilder.addField(FormSelectField, 'service', {
            label: 'Service',
            options: this.commonDataService.services
                ? this.commonDataService.services.map((x) => ({ text: x.name, value: x.id }))
                : [],
            value: this.commonDataService.services.find((x) => x.code === DEFAULT_SERVICE)?.id,
        });
        formBuilder.addField(FormDateInputField, 'validFrom', {
            label: 'Valid From',
            value: new Date(
                this.data?.appointmentTime ? this.data?.appointmentTime.toFormat('MM/d/y') : ''
            ),
        });
        formBuilder.addField(FormDateInputField, 'validUntil', {
            label: 'Valid Until',
            value: DateTime.fromJSDate(
                new Date(
                    this.data?.appointmentTime?.year,
                    this.data?.appointmentTime?.month,
                    this.data?.appointmentTime?.day
                )
            )
                .plus({ month: defaultLetterTemplate?.validity || 12 })
                .toJSDate(),
        });
        this.chooseTemplateForm = formBuilder.getForm();
    }

    public async render(): Promise<void> {
        try {
            this.loaded = false;
            const formValue = this.chooseTemplateForm.getFormGroup().value;
            const res = await this.apiLetterTemplateService.render({
                leadId: this.data.leadId,
                emailTemplateId: formValue.emailTemplate,
                letterTemplateId: formValue.letterTemplate,
                serviceId: formValue.service,
                outputs: ['form', 'html', 'pdf', 'email_html'],
                createdByDoctor: !!this.data.createdByDoctor,
                validFrom: DateTime.fromJSDate(formValue.validFrom).toISODate(),
                validUntil: DateTime.fromJSDate(formValue.validUntil).toISODate(),
            });
            this.pdfInfo = {
                id: res.pdf.uuid,
                fileName: res.pdf.fileName,
                link: this.appFileService.getFileAuthURL(res.pdf.url),
                safeLink: this.sanitizer.bypassSecurityTrustResourceUrl(
                    this.appFileService.getFileAuthURL(res.pdf.url)
                ),
                html: res.html,
                emailHtml: res.emailHTML,
                form: res.form,
            };
            this.setStep(LetterGenerationStep.PREVIEW_PDF);
            this.dialogRef.updateSize('90%', '90%');
            this.loaded = true;
        } catch (err) {
            console.warn('[PROFILE LETTER GENERATION] - render: ', err.message);
            this.closeDialog();
            this.loaded = true;
        }
    }

    public async onSavePdfHtml(): Promise<void> {
        try {
            this.loaded = false;
            const html =
                '<!DOCTYPE html><html>' +
                this.pdfEditContent.nativeElement.contentDocument.documentElement.innerHTML +
                '</html>';
            await this.apiLetterTemplateService.updateRender(this.pdfInfo.id, {
                customHTML: html,
                outputs: ['form', 'html', 'pdf'],
            });
            this.pdfInfo.html = html;
            this.setStep(LetterGenerationStep.PREVIEW_PDF);
            this.loaded = true;
        } catch (err) {
            this.loaded = false;
            console.warn('[PROFILE LETTER GENERATION] - save pdf html: ', err.message);
        }
    }

    public async onSavePdfForm(): Promise<void> {
        try {
            this.loaded = false;

            const values = Object.assign(this.pdfInfo.form, this.pdfForm.value);
            for (let key of Object.keys(values)) {
                if (typeof values[key] === 'boolean') {
                    values[key] = values[key] ? 'Yes' : 'No';
                }
            }

            await this.apiLetterTemplateService.updateRender(this.pdfInfo.id, {
                customValues: values,
                outputs: ['form', 'html', 'pdf'],
            });

            this.pdfInfo.form = values;
            this.setStep(LetterGenerationStep.PREVIEW_PDF);
            this.loaded = true;
        } catch (err) {
            this.loaded = false;
            console.warn('[PROFILE LETTER GENERATION] - save pdf form: ', err.message);
        }
    }

    public onSaveEmailHtml(): void {
        this.loaded = false;
        this.pdfInfo.emailHtml =
            '<!DOCTYPE html><html>' +
            this.emailEditContent.nativeElement.contentDocument.documentElement.innerHTML +
            '</html>';
        this.setStep(LetterGenerationStep.PREVIEW_EMAIL);
        this.loaded = true;
    }

    public async sendLetter(): Promise<void> {
        try {
            this.loaded = false;
            const formValue = this.chooseTemplateForm.getFormGroup().value;
            await this.apiLetterService.send(this.pdfInfo.id, {
                emailTemplateId: formValue.emailTemplate,
                customEmailHTML: this.pdfInfo.emailHtml,
            });

            this.dialogRef.updateSize('30%', '16%');
            this.setStep(LetterGenerationStep.RESULT);
            this.letterSendResult = LetterResult.SUCCESS;

            this.loaded = true;
        } catch (err) {
            this.dialogRef.updateSize('30%', '16%');
            this.setStep(LetterGenerationStep.RESULT);
            this.letterSendResult = LetterResult.FAIL;
            this.loaded = true;
            console.warn('[PROFILE LETTER GENERATION] - send letter: ', err.message);
        }
    }

    public isActiveButtonNext(): boolean {
        const formValue = this.chooseTemplateForm?.getFormGroup()?.value;
        if (!formValue) return false;

        const { letterTemplate, emailTemplate, service, validFrom, validUntil } = formValue;

        const isDateValid = DateTime.fromJSDate(validFrom) < DateTime.fromJSDate(validUntil);

        return letterTemplate && emailTemplate && service && isDateValid;
    }

    public onEditPdf(): void {
        if (this.pdfInfo.html) {
            this.loadPdfHtmlInEditIframe();
            this.setStep(LetterGenerationStep.EDIT_PDF_HTML);
        } else {
            this.setStep(LetterGenerationStep.EDIT_PDF_FORM);
        }
    }

    public onEditEmail(): void {
        this.loadEmailHtmlInEditIframe();
        this.setStep(LetterGenerationStep.EDIT_EMAIL);
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    private loadPdfHtmlInEditIframe(): void {
        if (this.pdfEditContent && this.pdfEditContent.nativeElement) {
            const doc = this.pdfEditContent.nativeElement.contentDocument;
            doc.open();
            doc.writeln(this.pdfInfo.html);
            doc.close();
            doc.body.contentEditable = true;
        } else {
            setTimeout(() => this.loadPdfHtmlInEditIframe(), 500);
        }
    }

    private loadEmailHtmlInEditIframe(): void {
        if (this.emailEditContent && this.emailEditContent.nativeElement) {
            const doc = this.emailEditContent.nativeElement.contentDocument;
            doc.open();
            doc.writeln(this.pdfInfo.emailHtml);
            doc.close();
            doc.body.contentEditable = true;
        } else {
            setTimeout(() => this.loadEmailHtmlInEditIframe(), 500);
        }
    }

    public onTabChange(event: any): void {
        switch (event.index) {
            case 0:
                this.setStep(LetterGenerationStep.PREVIEW_PDF);
                break;
            case 1:
                this.setStep(LetterGenerationStep.PREVIEW_EMAIL);
                break;
        }
    }

    private setStep(step: LetterGenerationStep): void {
        this.step = step;
    }

    public async loadComplete(pdf: PDFDocumentProxy): Promise<void> {
        let yShift: number = 0;

        for (let i = 1; i <= pdf.numPages; i++) {
            const page: PDFPageProxy = await pdf.getPage(i);
            const annotations: any = await page.getAnnotations();
            const viewPort = page.getViewport({
                scale:
                    document.getElementsByClassName('page')[0]?.clientWidth /
                    page.getViewport({ scale: 1 }).width,
            });
            annotations
                .filter((annotation) => annotation.subtype === 'Widget' && annotation.fieldName)
                .forEach((annotation) => {
                    const fieldRect = viewPort.convertToViewportRectangle(annotation.rect);
                    this.addInput(annotation, fieldRect, yShift);
                });

            yShift += viewPort.height + 10;
        }
    }

    private addInput(annotation, rect: number[], yShift: number): void {
        if (this.pdfInfo?.form[annotation.fieldName] === undefined) {
            return;
        }

        const input: ILetterInput = {
            name: annotation.fieldName,
            value: this.pdfInfo?.form[annotation.fieldName],
        };

        if (annotation.fieldType === 'Tx') {
            input.type = 'text';
            this.pdfForm.addControl(input.name, new FormControl(input.value));
        }

        if (annotation.radioButton) {
            input.type = 'radio';
            input.value = annotation?.buttonValue;
            if (!this.pdfForm.controls[annotation.fieldName]) {
                this.pdfForm.addControl(input.name, new FormControl());
            }
        }

        if (annotation.checkBox) {
            input.type = 'checkbox';
            input.value = this.pdfInfo?.form[annotation.fieldName] === 'Yes' ? true : false;
            this.pdfForm.addControl(input.name, new FormControl(input.value));
        }

        const generalWidth: number = document.getElementById('viewer').clientWidth;
        const pdfWidth: number = document.getElementsByClassName('page')[0]?.clientWidth;
        const diff: number =
            generalWidth && pdfWidth && generalWidth > pdfWidth ? (generalWidth - pdfWidth) / 2 : 0;

        if (rect) {
            input.top = rect[1] - (rect[1] - rect[3]) + yShift;
            input.left = rect[0] + diff;
            input.height = (rect[1] - rect[3]) * 0.9;
            input.width = rect[2] - rect[0];
        }

        this.inputList.push(input);
    }

    public getInputPosition(input: ILetterInput) {
        return {
            top: `${input.top}px`,
            left: `${input.left}px`,
            height: `${input.height}px`,
            width: `${input.width}px`,
        };
    }

    public getResultMessage(): string {
        return this.letterSendResult && this.letterSendResult === LetterResult.SUCCESS
            ? LetterResultMessages[LetterResult.SUCCESS]
            : LetterResultMessages[LetterResult.FAIL];
    }
}
