import { ListFilter, ListResponse } from '../../med-list/interfaces';
import { AclType } from './permission';
import { IPermissionGroupSmall } from './permission-group';
import { SortValue } from './sorting';
import { Timezone } from './timezone';

export interface IUser {
    id: number;
    email: string;
    phone?: string;
    role: UserRole;
    timezone: string;
    momentTimezone: string;
    fullName?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    isEnabled?: boolean;
    dateOfBirth?: string;
    createdAt: Date;
    updatedAt: Date;
    taskProcessing?: string[];
    phoneClientIdentity?: string;
    canReceiveCalls: boolean;
    dosespotId: number;
    canProcessSubscriptions?: boolean;
    userGroups: UserGroup[];
    isPasswordExpired?: boolean;
    permissionGroups?: IPermissionGroupSmall[];
    allowCall: boolean | undefined;
    isMasterLoginEnabled?: boolean;
    isDayStarted: boolean | null;
    // only manager fields
    managerId?: number;
    personalProvidersIds?: number[];
}

export interface IAuthenticatedUser extends IUser {
    acl: AclType;
}

export interface ICommonDataUser {
    id: number;
    fullName: string;
}

export interface UserGroup {
    name: string;
    code: string;
}

export enum UserRole {
    SYSTEM_ADMIN = 'system_admin',
    ADMIN = 'admin',
    DOCTOR = 'doctor',
    MANAGER = 'manager',
    SUPERVISOR = 'supervisor',
}

export interface IUserListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
    sort?: {
        firstName?: SortValue;
        lastName?: SortValue;
    };
}

export interface IUserListResponse extends ListResponse {
    items: IUser[];
}

export interface IUserCreateParams {
    email: string;
    password: string;
    role: UserRole;
    firstName: string;
    middleName: string;
    lastName?: string;
    timezone: Timezone;
    phoneClientIdentity?: string;
    phone?: string;
    dosespotId?: number;
    dateOfBirth?: string;
}

export interface IUserEditBaseParams {
    firstName: string;
    middleName: string;
    lastName?: string;
    timezone: Timezone;
    phoneClientIdentity?: string;
    phone?: string;
    dosespotId?: number;
    dateOfBirth?: string;
}

export interface IUserEditAccessParams {
    email: string;
    role: UserRole;
    isEnabled: boolean;
    isMasterLoginEnabled?: boolean;
    allowCall?: boolean;
}

export interface IUserChangePasswordParams {
    password: string;
}

export interface IUserChangePasswordRespose {
    changed: boolean;
}

export interface IUserChangePermissionGroupsParams {
    permissionGroups: number[];
}

export interface IUserChangePermissionGroupsResponse {
    changed: boolean;
}
