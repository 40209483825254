import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DateTime } from 'luxon';
import { ILead } from '../../../med-api/interfaces';
import { DoctorEventType } from '../../../med-common/interfaces/event.type';
import { EventAnalyticsService } from '../../../med-common/services/event-analytics.service';

import { Form, FormBuilder, FormDateInputField, FormInputField } from '../../../med-form/classes';
import { englishFormatValidator } from '../../validators';
import { ElectronicPrescriptionsSearchPatientDialogComponent } from '../electronic-prescriptions-search-patient-dialog/electronic-prescriptions-search-patient-dialog.component';

interface IDialogData {
    lead: ILead;
    isDoctorProfile?: boolean;
}

@Component({
    selector: 'med-electronic-prescriptions-search-patient-data-dialog',
    templateUrl: './electronic-prescriptions-search-patient-data-dialog.component.html',
    styleUrls: ['./electronic-prescriptions-search-patient-data-dialog.component.css'],
})
export class ElectronicPrescriptionsSearchPatientDataDialogComponent
    implements OnInit, AfterViewInit
{
    @Output() onPatientAdd = new EventEmitter<string>();

    public patientDataForm: Form;

    constructor(
        public dialogRef: MatDialogRef<ElectronicPrescriptionsSearchPatientDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDialogData,
        private dialog: MatDialog,
        private eventAnalyticsService: EventAnalyticsService
    ) {}

    public ngOnInit(): void {
        this.initComponentData();

        this.eventAnalyticsService.isDoctorProfile = this.data.isDoctorProfile || false;
    }

    ngAfterViewInit(): void {
        this.emitEvent(DoctorEventType.POPUP_LOADED, {});
    }

    public closeDialog() {
        this.dialogRef.close();
    }

    public searchPatient(): void {
        const { firstName, lastName, dob } = this.patientDataForm.getFormGroup().value;

        this.emitEvent(DoctorEventType.BUTTON_TAPPED, { element_name: 'search_patient' });

        const dialogRef = this.dialog.open(ElectronicPrescriptionsSearchPatientDialogComponent, {
            width: '550px',
            height: '400px',
            data: {
                leadId: this.data.lead.id,
                firstName,
                lastName,
                dob,
            },
        });

        dialogRef.componentInstance.onPatientAdd.subscribe((link: string) => {
            this.onPatientAdd.emit(link);
            this.closeDialog();
        });

        dialogRef
            .afterClosed()
            .subscribe(() =>
                this.emitEvent(DoctorEventType.POPUP_CLOSED, { pop_up_title: 'connect_patient' })
            );

        this.closeDialog();
    }

    private async initComponentData(): Promise<void> {
        this.initPatientDataForm();
    }

    private initPatientDataForm(): void {
        const formBuilder = new FormBuilder();

        formBuilder.addField(FormInputField, 'firstName', {
            label: 'First Name',
            value: this.data.lead?.firstName,
            validators: [Validators.required, englishFormatValidator()],
        });
        formBuilder.addField(FormInputField, 'lastName', {
            label: 'Last Name',
            value: this.data.lead?.lastName,
            validators: [Validators.required, englishFormatValidator()],
        });
        formBuilder.addField(FormDateInputField, 'dob', {
            label: 'Date of Birth',
            value: this.data.lead?.dateOfBirth
                ? DateTime.fromFormat(this.data.lead.dateOfBirth, 'MM/dd/yyyy').toJSDate()
                : null,
            validators: [Validators.required],
        });

        this.patientDataForm = formBuilder.getForm();
    }

    private emitEvent(type: DoctorEventType, meta: Record<string, any>): void {
        this.eventAnalyticsService.emitEvent({
            type,
            metadata: {
                lead_id: this.data.lead.id,
                page: 'patient/profile',
                pop_up_title: 'search_dosespot_patient',
            },
        });
    }
}
