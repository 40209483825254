import { Injectable } from '@angular/core';
import { PromoCodeType } from '../../med-api/interfaces';

interface IOption {
    value: number;
    text: string;
}

@Injectable({
    providedIn: 'root',
})
export class PromoCodeService {
    constructor() {}

    public promoCodeTypeText = {
        [PromoCodeType.PERCENT]: 'percent',
        [PromoCodeType.AMOUNT]: 'amount',
        [PromoCodeType.SUBSCRIPTION_AMOUNT]: 'subscription amount',
    };

    public getPromoCodeTypeOptions(): IOption[] {
        return Object.keys(this.promoCodeTypeText).map((key) => ({
            value: Number(key),
            text: this.promoCodeTypeText[key],
        }));
    }
}
