import { Injectable } from '@angular/core';

import { HttpService } from '../../med-common/services/http.service';
import {
    IUserGroup,
    IUserGroupCreateParams,
    IUserGroupListParams,
    IUserGroupListResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiUserGroupService {
    constructor(private http: HttpService) {}

    async list(params: IUserGroupListParams): Promise<IUserGroupListResponse> {
        return this.http.post<IUserGroupListResponse>(`user-group/list`, params);
    }

    async view(id: number): Promise<IUserGroup> {
        return this.http.get<IUserGroup>(`user-group/${id}`);
    }

    create(params: IUserGroupCreateParams | FormData): Promise<IUserGroup> {
        return this.http.post<IUserGroup>(`user-group/`, params);
    }

    update(params: IUserGroupCreateParams | FormData, id: number): Promise<IUserGroup> {
        return this.http.put<IUserGroup>(`user-group/${id}`, params);
    }

    delete(id: number): Promise<void> {
        return this.http.delete<void>(`user-group/${id}`);
    }
}
