import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import { SocketService } from '../../med-common/services/socket.service';
import {
    IManagerMessage,
    IGetManagerMessagesParams,
    IManagerMessageCreateParams,
    IEmailMessagesList,
    IFacebookMessagesList,
    ISubscribeParams,
    SocketIoMessageType,
    IManagerPhoneMessagesResponse,
    IManagerEmailMessageCreateParams,
    IManagerSupportMessagesResponse,
    IGetManagerEmailMessagesParams,
    IGetManagerFacebookMessagesParams,
} from '../interfaces';
import { IServiceAccount } from '../../med-api/interfaces';

@Injectable({
    providedIn: 'root',
})
export class ManagerChatService {
    constructor(private http: HttpService, private socket: SocketService) {}

    async createSmsMessage(params: IManagerMessageCreateParams): Promise<IManagerMessage> {
        return this.http.post<IManagerMessage>(`public/crm-communication/sms/send`, params);
    }

    async createSupportMessage(params: IManagerMessageCreateParams): Promise<IManagerMessage> {
        return this.http.post<IManagerMessage>(`public/crm-communication/support/send`, params);
    }

    async getPhoneCommunicationRecords(
        params: IGetManagerMessagesParams
    ): Promise<IManagerPhoneMessagesResponse> {
        return this.http.post<IManagerPhoneMessagesResponse>(
            `public/crm-communication/phone/records-list`,
            params
        );
    }

    async getSupportCommunicationRecords(
        params: IGetManagerMessagesParams
    ): Promise<IManagerSupportMessagesResponse> {
        return this.http.post<IManagerSupportMessagesResponse>(
            `public/crm-communication/support/records-list`,
            params
        );
    }

    async getEmailMessages(params: IGetManagerEmailMessagesParams): Promise<IEmailMessagesList> {
        return this.http.post<IEmailMessagesList>(`public/crm-communication/email/list`, params);
    }

    async createEmailMessage(
        params: IManagerEmailMessageCreateParams | FormData
    ): Promise<IManagerMessage> {
        return this.http.post<IManagerMessage>(`public/crm-communication/email/send`, params);
    }

    async getFacebookMessages(
        params: IGetManagerFacebookMessagesParams
    ): Promise<IFacebookMessagesList> {
        return this.http.post<IFacebookMessagesList>(
            `public/crm-communication/facebook/list`,
            params
        );
    }

    async createFacebookMessage(params: IManagerMessageCreateParams): Promise<IManagerMessage> {
        return this.http.post<IManagerMessage>(`public/crm-communication/facebook/send`, params);
    }

    subscribeOnIncomingFacebookMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.INCOMING_FACEBOOK, cb);
    }

    unsubscribeFromIncomingFacebookMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.unsubscribe(SocketIoMessageType.INCOMING_FACEBOOK, cb);
        this.socket.leaveRoom(roomName);
    }

    subscribeOnOutgoingFacebookMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.OUTGOING_FACEBOOK, cb);
    }

    unsubscribeFromOutgoingFacebookMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.unsubscribe(SocketIoMessageType.OUTGOING_FACEBOOK, cb);
        this.socket.leaveRoom(roomName);
    }

    subscribeOnIncomingEmailMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.INCOMING_EMAIL, cb);
    }

    unsubscribeFromIncomingEmailMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.unsubscribe(SocketIoMessageType.INCOMING_EMAIL, cb);
        this.socket.leaveRoom(roomName);
    }

    subscribeOnOutgoingEmailMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.OUTGOING_EMAIL, cb);
    }

    unsubscribeFromOutgoingEmailMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.unsubscribe(SocketIoMessageType.OUTGOING_EMAIL, cb);
        this.socket.leaveRoom(roomName);
    }

    subscribeOnIncomingSmsMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.INCOMING_SMS, cb);
    }

    unsubscribeFromIncomingSmsMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.unsubscribe(SocketIoMessageType.INCOMING_SMS, cb);
        this.socket.leaveRoom(roomName);
    }

    subscribeOnSupportMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.SUPPORT_MESSAGE, cb);
    }

    unsubscribeFromSupportMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.unsubscribe(SocketIoMessageType.SUPPORT_MESSAGE, cb);
        this.socket.leaveRoom(roomName);
    }

    subscribeOnOutgoingSmsMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.OUTGOING_SMS, cb);
    }

    unsubscribeFromOutgoingSmsMessages(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Manager-${params.serviceAccountId}-${params.leadCode}`;
        this.socket.unsubscribe(SocketIoMessageType.OUTGOING_SMS, cb);
        this.socket.leaveRoom(roomName);
    }

    getMobileServiceAccount(): Promise<IServiceAccount> {
        return this.http.get(`public/crm-communication/service-account?channel=mobile`);
    }
}
