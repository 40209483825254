<div class="container">
    <div class="header-container">
        <div class="row">
            <div class="item-container left-header-item-container">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="12"
                        cy="12"
                        r="11.25"
                        fill="white"
                        stroke="#BDBDBD"
                        stroke-width="1.5"
                    />
                    <path
                        d="M12 7V12H17"
                        stroke="#BDBDBD"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
            <div
                class="item-container top-header-item-container"
                *ngFor="let dailyData of appointmentsData"
                [ngClass]="{ 'day-selected': isDaySelected(dailyData) }"
            >
                <div class="day-number">{{ getDayNumber(dailyData) }}</div>
                <div class="day-name" [class.dayOff]="isDayOff(dailyData)">
                    {{ getDayName(dailyData) }}
                </div>
            </div>
        </div>
    </div>
    <div class="content-container">
        <div class="row" *ngFor="let timingItem of timing.getItems()">
            <div
                class="item-container left-header-item-container"
                [ngClass]="{ 'timing-item-selected': isTimingItemSelected(timingItem) }"
            >
                {{ timingItem.getOutput() }}
            </div>
            <div
                class="item-container content-item-container"
                *ngFor="let dailyData of appointmentsData"
                (mouseenter)="handleElementHover(dailyData, timingItem)"
                (mouseleave)="handleLeaveElement()"
                [attr.data-test-id]="getTimeSlotTestId(dailyData, timingItem)"
            >
                <div
                    class="leads-data-item"
                    [ngClass]="{
                        'leads-data-item-highlighted': isItemHighlighted(dailyData, timingItem)
                    }"
                >
                    <span
                        *ngFor="
                            let appointmentData of getDailyAppointments(
                                dailyData,
                                timingItem
                            )?.slice(0, 2)
                        "
                        class="lead-item"
                        [ngClass]="{
                            inactive: appointmentData.inactive,
                            initial: appointmentData.isInitial
                        }"
                        [attr.data-test-id]="getTestId(appointmentData)"
                    >
                        {{ getLeadName(appointmentData) }}
                        <mat-icon *ngIf="appointmentData.hasInsurance" class="lead-badge insurance">
                            health_and_safety
                        </mat-icon>
                        <mat-icon
                            *ngIf="appointmentData.isAppointmentReserved"
                            class="lead-badge reserved"
                            matTooltipClass="tooltip"
                            matTooltip="This patient is not fully confirmed yet, and may be rescheduled to another day or time."
                        >
                            query_builder
                        </mat-icon>
                        <mat-icon
                            *ngIf="isOfflineVisit(appointmentData)"
                            class="lead-badge offline"
                        >
                            error
                        </mat-icon>
                    </span>
                    <span
                        class="lead-item hidden-appointments-number"
                        *ngIf="getDailyAppointments(dailyData, timingItem)?.length > 2"
                    >
                        +{{ getDailyAppointments(dailyData, timingItem)?.length - 2 }}
                    </span>
                </div>
                <div
                    class="leads-data-item hovered-container"
                    [ngClass]="{ 'day-selected': isDaySelected(dailyData) }"
                    *ngIf="isElementHovered(dailyData, timingItem)"
                >
                    <a
                        *ngFor="let appointmentData of getDailyAppointments(dailyData, timingItem)"
                        class="lead-item hovered-item"
                        [ngClass]="{
                            inactive: appointmentData.inactive,
                            initial: appointmentData.isInitial
                        }"
                        [routerLink]="
                            appointmentLinkPrefix
                                ? appointmentLinkPrefix + appointmentData.id
                                : null
                        "
                        (click)="onClickLead(appointmentData)"
                    >
                        {{ getLeadName(appointmentData) }}
                        <mat-icon *ngIf="appointmentData.hasInsurance" class="lead-badge insurance">
                            health_and_safety
                        </mat-icon>
                        <mat-icon
                            *ngIf="appointmentData.isAppointmentReserved"
                            class="lead-badge reserved"
                            matTooltipClass="tooltip"
                            matTooltip="This patient is not fully confirmed yet, and may be rescheduled to another day or time."
                        >
                            query_builder
                        </mat-icon>
                        <mat-icon
                            *ngIf="isOfflineVisit(appointmentData)"
                            class="lead-badge offline"
                        >
                            error
                        </mat-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
