import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiTaskService } from '../../med-api/services';
import { Task } from '../../med-api/interfaces';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TaskService {
    public todoTasks: Task[] = [];
    public inProgressTasks: Task[] = [];
    public onHoldTasks: Task[] = [];
    public completedTasks: Task[] = [];
    public selectedTask: Task;

    public taskBarVisible = true;
    public loaded = false;
    public onChangeVisible = new BehaviorSubject<boolean>(true);

    public refreshTaskDashboard$ = new Subject<void>();
    public refreshMyTasks$ = new Subject<void>();

    public tasksChanged$ = new Subject<Task | null>();

    constructor(
        private apiTaskService: ApiTaskService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.route.queryParams
            .pipe(
                map((params) => params['taskId']),
                distinctUntilChanged(),
                switchMap((selectedTaskId: string) => this.getSelectedTask(+selectedTaskId)),
                tap((task) => this.setSelectedTask(task))
            )
            .subscribe();
    }

    public resetTasks() {
        this.todoTasks = [];
        this.inProgressTasks = [];
        this.onHoldTasks = [];
        this.completedTasks = [];
        this.selectedTask = null;
        this.taskBarVisible = true;
        this.loaded = false;
        this.tasksChanged$.next(null);
    }

    public async loadTasks() {
        try {
            this.loaded = false;

            const res = await this.apiTaskService.getMyTasks();

            this.todoTasks = res.tasks.toDo;
            this.inProgressTasks = res.tasks.inProgress;
            this.onHoldTasks = res.tasks.onHold;
            this.completedTasks = res.tasks.completed;

            this.loaded = true;
        } catch (err) {
            console.error(`[TASK SERVICE] - load tasks error: `, err);
        }
    }

    public setSelectedTask(task: Task): void {
        this.selectedTask = task;
        this.tasksChanged$.next(task);
    }

    public resetSelectedTask(): void {
        this.selectedTask = null;
        this.tasksChanged$.next(null);
    }

    public setTaskBarVisibility(isVisible: boolean): void {
        this.taskBarVisible = isVisible;

        this.onChangeVisible.next(isVisible);
    }

    private getSelectedTask(selectedTaskId: number): Observable<Task> {
        if (!selectedTaskId) return of(null);

        const allTasks = [
            ...this.todoTasks,
            ...this.inProgressTasks,
            ...this.onHoldTasks,
            ...this.completedTasks,
        ];

        const selectedTask = allTasks.find((task) => task.id === selectedTaskId) || null;

        if (!selectedTask) {
            return from(this.apiTaskService.getTaskById(selectedTaskId)).pipe(
                map((x) => x.task),
                catchError((err) => {
                    this.router.navigate(['/dashboard']);
                    return of(null);
                })
            );
        }

        return of(selectedTask);
    }
}
