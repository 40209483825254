import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import { ISearchClientsParams, ISearchClientsResponse } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class ApiClientService {
    constructor(private http: HttpService) {}

    searchClients({
        phone,
        email,
        dateOfBirth,
    }: ISearchClientsParams): Promise<ISearchClientsResponse> {
        let queryParams = `phone=${phone}&email=${email}&dateOfBirth=${dateOfBirth}`;
        return this.http.get<ISearchClientsResponse>(
            `app/client/search?${queryParams}`
        );
    }
}
