import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AppQueryParamsService {
    constructor(private router: Router, private route: ActivatedRoute) {}

    public setQueryParams(queryParams = {}): void {
        this.router.navigate([], { queryParams });
    }

    public getStringValueByKey(key: string): string {
        const value = this.getValueByKey(key);

        return value || undefined;
    }

    public getNumberValueByKey(key: string): number {
        const value = this.getValueByKey(key);

        if (value) {
            if (!isNaN(+value)) {
                return +value;
            }
        }

        return undefined;
    }

    public getDateValueByKey(key: string): Date {
        const value = this.getValueByKey(key);

        if (value) {
            if (!isNaN(new Date(value).valueOf())) {
                return new Date(value);
            }

            if (!isNaN(new Date(+value).valueOf())) {
                return new Date(+value);
            }
        }

        return undefined;
    }

    public getValueByKey(key: string): any {
        return this.route.snapshot.queryParams[key];
    }
}
