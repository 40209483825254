import { Injectable } from '@angular/core';

import { HttpService } from '../../med-common/services/http.service';
import {
    ITaskAttributes,
    ITaskAttributesCreateParams,
    ITaskAttributesListParams,
    ITaskAttributesListResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiTaskAttributesService {
    constructor(private http: HttpService) {}

    async list(params: ITaskAttributesListParams): Promise<ITaskAttributesListResponse> {
        return this.http.post<ITaskAttributesListResponse>(`task-attributes/list`, params);
    }

    async create(params: ITaskAttributesCreateParams | FormData): Promise<ITaskAttributes> {
        return this.http.post<ITaskAttributes>(`task-attributes/`, params);
    }

    async view(id: number): Promise<ITaskAttributes> {
        return this.http.get<ITaskAttributes>(`task-attributes/${id}`);
    }

    async edit(id: number, params: ITaskAttributesCreateParams): Promise<ITaskAttributes> {
        return this.http.patch<ITaskAttributes>(`task-attributes/${id}`, params);
    }

    async delete(id: number): Promise<void> {
        return this.http.delete<void>(`task-attributes/${id}`);
    }
}
