import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
} from '@angular/core';
import { TimingRange, ICalendarDailyData, ILeadInfo } from '../../interfaces';
import { Timing, TimingItem } from '../../classes';
import { AppScheduleService } from '../../services';
import { Router } from '@angular/router';
import { AppointmentMethodType } from '../../../med-api/interfaces';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'med-patient-calendar',
    templateUrl: './patient-calendar.component.html',
    styleUrls: ['./patient-calendar.component.css'],
})
export class PatientCalendarComponent implements OnInit {
    @Input() startDate?: string;
    @Input() startHours?: number = 9;
    @Input() endHours?: number = 21;
    @Input() timeFullFormat?: boolean = false;
    @Input() span?: number = 30;
    @Input() filling?: TimingRange[] = [];
    @Input() appointmentsData?: ICalendarDailyData[] = [];
    @Input() appointmentLinkPrefix?: string;
    @Output() onClick = new EventEmitter<any>();

    timing: Timing = new Timing();
    hoveredItem: any;

    constructor(private appScheduleService: AppScheduleService, private router: Router) {}

    ngOnInit(): void {
        if (this.startHours > 24 || this.startHours < 0) {
            this.startHours = 0;
        }
        if (this.endHours > 24 || this.endHours < 0) {
            this.endHours = 24;
        }
        if (this.startHours >= this.endHours) {
            this.startHours = 0;
            this.endHours = 24;
        }
        if (this.span < 0) {
            this.span = 30;
        }

        this.timing.generateItems(this.startHours, this.endHours, this.span, this.timeFullFormat);
        this.timing.refreshFilling(this.filling);
    }

    public isOfflineVisit(appointment: ILeadInfo): boolean {
        return appointment?.appointmentMethod === AppointmentMethodType.OFFLINE;
    }

    public getDayNumber(dailyData: ICalendarDailyData): number {
        return new Date(dailyData.date).getDate();
    }

    public getDayName(dailyData: ICalendarDailyData): string {
        const key = this.appScheduleService.getDayString(new Date(dailyData.date).getDay());
        const day = this.appScheduleService.daysWeek.find((x) => x.key === key);
        return day.label;
    }

    public isDayOff(dailyData: ICalendarDailyData): boolean {
        const key = this.appScheduleService.getDayString(new Date(dailyData.date).getDay());

        return key === 'sunday' || key === 'saturday';
    }

    public getDailyAppointments(dailyData: ICalendarDailyData, timingItem: TimingItem) {
        return dailyData.appointments[
            `${timingItem.getHourStart()}:${timingItem.getMinuteStart()}`
        ];
    }

    public getLeadName(appointmentData: ILeadInfo): string {
        return `${appointmentData.firstName} ${appointmentData.lastName}`;
    }

    public handleElementHover(dailyData: ICalendarDailyData, timingItem: TimingItem): void {
        const appointments =
            dailyData.appointments[`${timingItem.getHourStart()}:${timingItem.getMinuteStart()}`];

        if (appointments?.length) {
            this.hoveredItem = { date: dailyData.date, time: timingItem.getOutput() };
        }
    }

    public isElementHovered(dailyData: ICalendarDailyData, timingItem: TimingItem): boolean {
        return (
            this.hoveredItem?.date === dailyData.date &&
            this.hoveredItem?.time === timingItem.getOutput()
        );
    }

    public handleLeaveElement(): void {
        this.hoveredItem = null;
    }

    public isTimingItemSelected(timingItem: TimingItem): boolean {
        return this.hoveredItem?.time === timingItem.getOutput();
    }

    public isDaySelected(dailyData: ICalendarDailyData): boolean {
        return this.hoveredItem?.date === dailyData.date;
    }

    public isItemHighlighted(dailyData: ICalendarDailyData, timingItem: TimingItem): boolean {
        return (
            this.hoveredItem?.date === dailyData.date ||
            this.hoveredItem?.time === timingItem.getOutput()
        );
    }

    public onClickLead(appointmentData: ILeadInfo): void {
        this.onClick.emit(appointmentData);
    }

    public getTimeSlotTestId(dailyData: ICalendarDailyData, timingItem: TimingItem): string {
        const time = timingItem.getOutput();
        const day = new Date(dailyData.date).getDate();

        return `day-${day}-time-${time}`;
    }

    public getTestId(appointmentData: ILeadInfo): string {
        const appointmentId = appointmentData.id;

        return `${appointmentId}`;
    }
}
