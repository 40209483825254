<mat-form-field
    appearance="outline"
    [formGroup]="formGroup"
    class="form-field multi-input-auto-field"
>
    <mat-label>{{ field.label }}</mat-label>
    <mat-chip-list #stringsList aria-label="Services selection" [disabled]="field.disabled">
        <mat-chip
            *ngFor="let string of field.strings"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(string)"
        >
            {{ string }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
            #stringsInput
            matInput
            formControlName="{{ field.controlName }}"
            [matAutocomplete]="auto"
            [matChipInputFor]="stringsList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let filteredString of filteredStrings | async" [value]="filteredString">
            {{ filteredString }}
        </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix class="icon" fontSet="material-icons-outlined">{{ field.icon }}</mat-icon>
    <mat-error *ngIf="formGroup.get(field.controlName)?.invalid">{{
        errorTextService.getMessage(formGroup.get(field.controlName))
    }}</mat-error>
</mat-form-field>
<div class="multi-input-auto-buttons" *ngIf="!field?.disabled">
    <button type="button" mat-stroked-button (click)="addAllString()" [color]="'primary'">
        Add all
    </button>
    <button type="button" mat-button (click)="removeAllString()" [color]="'primary'">
        Remove all
    </button>
</div>
