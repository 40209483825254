import { AfterViewChecked, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from 'medvidi';

import { TwilioVoiceLoggingService } from '../../twilio-voice/twilio-voice-logging.service';
import { PhoneClientService } from '../../services/phone-client.service';

@Component({
    selector: 'app-phone-client-dialog',
    templateUrl: './phone-client-dialog.component.html',
    styleUrls: ['./phone-client-dialog.component.scss'],
})
export class PhoneClientDialogComponent implements AfterViewChecked {
    @ViewChild('scrollContainer') scrollContainer: ElementRef;
    private currentLength: number = 0;

    public isShowHelp = false;

    constructor(
        public dialogRef: MatDialogRef<PhoneClientDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public phoneClientService: PhoneClientService,
        public phoneClientLogService: TwilioVoiceLoggingService,
        public authService: AuthService
    ) {}

    public get isIncomingCallsOnline(): boolean {
        return this.phoneClientService.isActive && this.phoneClientService.isReceiveCallsActive;
    }

    public get isUserCanReceiveCalls(): boolean {
        return this.authService?.user?.canReceiveCalls;
    }

    ngAfterViewChecked(): void {
        this.scrollToBottom();
    }

    onClose() {
        this.dialogRef.close();
    }

    scrollToBottom(): void {
        try {
            if (this.currentLength !== this.phoneClientLogService.phoneClientLogMessages.length) {
                this.currentLength = this.phoneClientLogService.phoneClientLogMessages.length;
                const element = this.scrollContainer.nativeElement;
                element.scrollTop = 0;
            }
        } catch (err) {}
    }

    public downloadLogs(): void {
        const logsString = JSON.stringify(this.phoneClientLogService.phoneClientLogMessages);
        const blob = new Blob([logsString], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'logs.txt';
        link.click();

        window.URL.revokeObjectURL(url);
    }

    public activateWithIncomingCalls(): void {
        this.phoneClientService.setReceiveCalls(true);
        void this.phoneClientService.activatePhoneClient();
    }

    trackByFn(index: number, item: { date: Date; message: string }): string {
        return item.message;
    }
}
