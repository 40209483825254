import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserRole } from '../../../med-api/interfaces';
import { ApiAuthService, SystemType, AuthType } from '../../api/auth.service';

@Component({
    selector: 'med-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    @Input() public readonly availableRoles: UserRole[];
    @Input() public readonly systemType: SystemType;
    @Input() public readonly googleRedirectUri: string = new URL(
        'auth/google/callback',
        location.origin
    ).href;
    @Output() onLogged = new EventEmitter();

    public loaded: boolean = false;
    private authType: AuthType = AuthType.BASE;

    constructor(private apiAuthService: ApiAuthService) {}

    async ngOnInit() {
        try {
            const { authType } = await this.apiAuthService.getAuthType(this.systemType);
            this.authType = authType;
            this.loaded = true;
        } catch (err) {
            console.warn('[MEDVIDI LOGIN] - load auth type error: ', err);
            this.loaded = true;
        }
    }

    public needGoogleAuth(): boolean {
        return this.authType === AuthType.GOOGLE;
    }

    public needBaseAuth(): boolean {
        return this.authType === AuthType.BASE;
    }
}
