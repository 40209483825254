<div class="time-slots" *ngIf="schedule.length">
    <div
        *ngFor="let time of schedule; let i = index"
        [class.selected]="isSelected(time)"
        class="time-slots__item"
        (click)="select(time)"
        [attr.data-test-id]="autoTestUtilsService.getDataTestId(doctorId, i)"
    >
        {{ time | momentPipe: timezone:'hh:mm A' }}
    </div>
</div>
<!-- No slots available-->
<div *ngIf="!schedule.length" class="no-slots">No slots available</div>
