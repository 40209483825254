import { ListFilter, ListResponse, ListSortParams } from '../../med-list/interfaces';
import { RefObjectClass } from './task';
import { CallEventType } from './call-event';

export enum ManagerActivityType {
    TASK = 'TASK',
    CALL = 'CALL',
}

export interface IManagerActivity {
    id: number;
    name: string;
    activityType: ManagerActivityType;
    type?: string | CallEventType;
    closeComment?: string;
    refObjectClass?: RefObjectClass;
    leadId: number;
    leadCode?: string;
    leadFirstName: string;
    leadLastName: string;
    userId?: number;
    userFirstName?: string;
    userLastName?: string;
    closedAt?: Date;
    activateAt?: Date;
    createdAt: Date;
}

export interface ManagerActivityListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
    sort?: ListSortParams;
}

export interface ManagerActivityListResponse extends ListResponse {
    items: IManagerActivity[];
    total: number;
}

export interface ManagerStatuses {
    userId: number;
    fullName: string;
    online: boolean;
    readyForCall: boolean;
    disconnectTime: Date;
}

export interface ManagerStatusesResponse {
    data: ManagerStatuses[];
    updateTime: Date;
}
