import { Component, Input, OnInit } from '@angular/core';
import { FormMultiInputField } from '../../../classes';
import { FormGroup } from '@angular/forms';
import { ErrorTextService } from '../../../services/error-text.service';

import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';

@Component({
    selector: 'med-form-multi-input-field',
    templateUrl: './multi-input.component.html',
    styleUrls: ['./multi-input.component.css'],
})
export class FormMultiInputFieldComponent implements OnInit {
    @Input() field: FormMultiInputField;
    @Input() formGroup: FormGroup;

    selectable = true;
    removable = true;
    addOnBlur = true;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

    constructor(public errorTextService: ErrorTextService) {}

    ngOnInit(): void {}

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            this.field.strings.push(value.trim());
        }

        if (input) {
            input.value = '';
        }
    }

    remove(inputString: string): void {
        const index = this.field.strings.indexOf(inputString);

        if (index >= 0) {
            this.field.strings.splice(index, 1);
        }
    }
}
