import { ValidatorFn } from '@angular/forms';

export enum StartViewParameter {
    MULTI_YEAR = 'multi-year',
}

export interface FormFieldParams {
    controlName?: string;
    icon?: string;
    actionIcon?: string;
    label?: string;
    value?: any;
    validators?: (string | ValidatorFn | ValidatorParams)[];
    min?: string | number | Date;
    max?: string | number | Date;
    disabled?: boolean;
    startView?: StartViewParameter;
    format?: string;
}

interface ValidatorParams {
    type: string;
    params: any;
}
