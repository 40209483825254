export enum ManagerEventType {
    SET_MANAGER_CALL_STATE = 'set_manager_call_state',
}

export enum ManagerNotificationMessageTypes {
    TWILIO_ON_HOLD_CALL_MISSED = 'twilio_on_hold_call_missed',
}

export enum ManagerCallState {
    READY_FOR_CALL = 'ready_for_call',
    ON_CALL = 'on_call',
    UNAVAILABLE = 'unavailable',
}
