export enum CommonDataFilterType {
    STATES,
    WEBSITES,
    SERVICES,
    DOCTORS,
    MANAGERS,
    ADMINS,
    SERVICE_ACCOUNTS,
    LETTER_TEMPLATES,
    EMAIL_TEMPLATES,
    INSURANCE_COMPANIES,
    USER_GROUPS,
    TASK_ATTRIBUTES,
}
