import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import { ILeadDocumentsParams, ILeadDocumentsResponse, IUploadFilesParams } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class ApiLeadDocumentService {
  constructor(private http: HttpService) {}

  async uploadFiles(
    params: IUploadFilesParams
  ): Promise<ILeadDocumentsResponse> {
    const { leadId, leadDocumentType, taskId, createdByDoctor, files, isAllowedToPatient } = params;

    const formData = new FormData();
    formData.append('leadId', String(leadId));
    formData.append('leadDocumentType', leadDocumentType);
    formData.append('isAllowedToPatient', JSON.stringify(!!isAllowedToPatient));
    taskId && formData.append('taskId', String(taskId));
    createdByDoctor &&
      formData.append('createdByDoctor', String(createdByDoctor));

    Array.isArray(files)
      ? files.forEach((file, i) => formData.append(`file_${++i}`, file))
      : formData.append('file', files);

    return this.http.post(`lead-documents/upload-files`, formData);
  }

  async leadDocuments(
    params: ILeadDocumentsParams
  ): Promise<ILeadDocumentsResponse> {
    return this.http.post(`lead-documents`, params);
  }

  async delete(id: number): Promise<void> {
    return this.http.delete<void>(`lead-documents/${id}`);
  }
}
