import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'med-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css'],
})
export class ButtonComponent implements OnInit {
    @Input() loading: boolean = false;
    @Input() disabled: boolean = false;
    @Input() text: string;
    @Input() width: number = 140;
    @Output() onclick = new EventEmitter();

    ngOnInit(): void {}

    onClick(): void {
        this.onclick.emit();
    }
}
