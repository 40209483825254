<!-- New Intake -->
<div *ngIf="data.isHasClient; else notHasClient" class="intake">
    <div class="intake-header-container">
        <!-- Header -->
        <div class="intake__header">Intake</div>
        <!-- Close Button -->
        <div class="intake__close-button" (click)="closeDialog()">&times;</div>
    </div>

    <med-print-button
        class="print-button"
        styleRef="/assets/styles/new-intake-print.css"
        title="Intake"
    ></med-print-button>

    <div class="intake-body-container" id="print-section">
        <div
            *ngIf="data?.passportData?.file as file"
            class="intake-step"
        >
            <h1 class="intake-step__header">ID verification</h1>
            <img
                [src]="getFilePath(file.path)"
                [alt]="file.name"
                data-test-id="gov-id-photo"
            />
        </div>
        <div *ngIf="!intakeSteps.length; else intake" class="intake__empty-block">
            Intake form is not completed
        </div>
        <ng-template #intake>
            <!-- Intake Step -->
            <div *ngFor="let intakeStep of intakeSteps" class="intake-step">
                <!-- Intake Step Header -->
                <div class="intake-step__header">{{ intakeStep.header }}</div>
                <div class="intake-step-fields">
                    <!-- Intake Step Fields -->
                    <div *ngFor="let field of intakeStep.fields" class="intake-step-field">
                        <!-- Intake Step Field Label -->
                        <div
                            *ngIf="isShowFieldLabelByStepCode(intakeStep?.code)"
                            class="intake-step-field__label"
                        >
                            {{ field.label }}
                        </div>
                        <!-- Intake Step Field Value -->
                        <div
                            *ngIf="!isMedicalRecordStep(intakeStep.code); else medicalRecords"
                            [ngSwitch]="getFieldTypeByValue(field.value)"
                            class="intake-step-field__value"
                            [attr.data-test-id]="field.label"
                        >
                            <ng-container *ngSwitchCase="IntakeFieldValue.IMAGE">
                                <img
                                    [src]="getFilePath(field.value)"
                                    [alt]="field.value"
                                    data-test-id="gov-id-photo"
                                />
                            </ng-container>
                            <ng-container *ngSwitchCase="IntakeFieldValue.LINK">
                                <div class="link" (click)="openMedicalRecord(field.value)">
                                    {{ getFilePath(field.value) }}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="IntakeFieldValue.TEXT">
                                {{ getTextValue(field) }}
                            </ng-container>
                        </div>
                        <ng-template #medicalRecords>
                            <div
                                *ngFor="let file of field.value"
                                [ngSwitch]="getFieldTypeByValue(file)"
                                class="intake-step-field__value"
                            >
                                <ng-container *ngSwitchCase="IntakeFieldValue.IMAGE">
                                    <img [src]="getFilePath(file)" [alt]="file" />
                                </ng-container>
                                <ng-container *ngSwitchCase="IntakeFieldValue.LINK">
                                    <div class="link" (click)="openMedicalRecord(file)">
                                        {{ getFilePath(file) }}
                                    </div>
                                </ng-container>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<!-- Old Intake (Needed to remove after unsupported web form) -->
<ng-template #notHasClient>
    <div class="wrapper">
        <div class="header">
            <div class="header-data-container">
                <span>Intake</span>
            </div>
            <div class="close-button-container" (click)="closeDialog()">
                <span class="close-button"></span>
            </div>
        </div>

        <med-print-button
            class="print-button"
            styleRef="/assets/styles/intake-print.css"
            title="Intake"
        ></med-print-button>

        <div class="container" id="print-section">
            <div *ngIf="isEmptyIntake()" class="emty-block">
                <p class="title">Intake form is not completed</p>
            </div>
            <div
                class="block"
                *ngFor="let block of data?.applicationData | keyvalue: useOriginalOrder"
            >
                <p class="title">{{ block.value?.name || '-' }}</p>
                <div class="list">
                    <div
                        class="property-block"
                        *ngFor="let condition of block.value?.values || {} | keyvalue: order"
                    >
                        <p *ngIf="condition.value?.name" class="property-name">
                            {{ condition.value?.name }}
                        </p>
                        <div class="property-value">
                            <div *ngFor="let value of getValue(condition.value?.value)">
                                <p
                                    *ngIf="!isFile(value)"
                                    [attr.data-test-id]="condition.value?.name"
                                >
                                    {{ value || '-' }}
                                </p>
                                <img
                                    *ngIf="isImageFile(value)"
                                    width="100%"
                                    class="image-block"
                                    [class.hidden]="isHiddenImg(value)"
                                    [id]="value"
                                    [alt]="condition.value?.name || block.key"
                                    [src]="getFullLink(value)"
                                    (error)="onErrorImgLoad(value)"
                                    data-test-id="gov-id-photo"
                                />
                                <a
                                    *ngIf="isPdfFile(value)"
                                    [href]="getFullLink(value)"
                                    target="_blank"
                                >
                                    {{ getFullLink(value) }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
