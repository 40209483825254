import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'med-print-button',
    templateUrl: './print-button.component.html',
    styleUrls: ['./print-button.component.css'],
})
export class PrintButtonComponent implements OnInit {
    @Input() color: string = 'primary';
    @Input() icon: string = 'print';
    @Input() elementId: string = 'print-section';
    @Input() styleRef?: string;
    @Input() title?: string;

    ngOnInit(): void {}

    private getStyleLink(): string {
        if (this.styleRef) {
            return `<link rel="stylesheet" href="${this.styleRef}">`;
        }

        return '';
    }

    public print(): void {
        const printContents = window.document.getElementById(this.elementId)?.innerHTML;

        if (!printContents) {
            return;
        }

        const popup = window.open('', '_blank', 'top=0, left=0, height=auto, width=auto');
        popup.document.open();
        popup.document.write(`
            <html>
                <head>
                    <title>${this.title || ''}</title>
                    ${this.getStyleLink()}
                </head>
                <body>
                    ${printContents}
                    <script defer>
                        function triggerPrint(event) {
                            window.removeEventListener('load', triggerPrint, false);
                            window.print();
                            setTimeout(function() { window.close(); }, 0);
                        }
                        window.addEventListener('load', triggerPrint, false);
                    </script>
                </body>
            </html>
        `);
        popup.document.close();
    }
}
