import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FormAutocompleteField } from '../../../classes';
import { IAutocompleteOptionsItem } from '../../../interfaces/autocomplete-params';
import { ErrorTextService } from '../../../services/error-text.service';

@Component({
    selector: 'med-autocomplete-field',
    templateUrl: 'autocomplete.component.html',
    styleUrls: ['autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteFieldComponent implements OnInit, OnDestroy {
    @Input() field: FormAutocompleteField;
    @Input() formGroup: FormGroup;
    public filteredOptions: IAutocompleteOptionsItem[] = [];
    public isLoading: boolean;

    private destroy$ = new Subject<void>();

    constructor(public errorTextService: ErrorTextService, private cdr: ChangeDetectorRef) {}

    public get formControl(): FormControl {
        return this.formGroup.get(this.field.controlName) as FormControl;
    }

    public ngOnInit(): void {
        this.handleFormControlChanges();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public displayFunction(value: IAutocompleteOptionsItem): string {
        return value?.text || '';
    }

    public setValue(): void {
        if (typeof this.formControl.value !== 'string' || !this.filteredOptions.length)
            this.formGroup.setErrors(new Error());
    }

    private handleFormControlChanges(): void {
        this.formControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((x) => typeof x === 'string' && x.length >= this.field.minSearchLength),
                tap(() => this.formControl.setErrors({ loading: true })),
                debounceTime(400),
                tap(() => {
                    this.isLoading = true;
                    this.filteredOptions = [];
                    this.cdr.markForCheck();
                }),
                switchMap((value) =>
                    (this.field.isAsyncOptions
                        ? this.field.optionsFunction(value)
                        : of(this.filter(value))
                    ).pipe(
                        tap((options) => {
                            if (value) {
                                const option = options.find(
                                    (elem) => elem.value === this.formControl.value?.value
                                );
                                this.formControl.setErrors(option ? null : { match: true });
                            }
                            this.isLoading = false;
                            this.filteredOptions = options;
                            this.cdr.markForCheck();
                        })
                    )
                )
            )
            .subscribe();
    }

    private filter(value: string): IAutocompleteOptionsItem[] {
        const filterValue = value.toLowerCase();

        return this.field.options.filter((option) =>
            option.text.toLowerCase().includes(filterValue)
        );
    }
}
