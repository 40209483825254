import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    CallEventCreateDeliveredCallLogParams,
    CallEventCreateAcceptCallLogParams,
    CallEventCreateRejectCallLogParams,
    CallEventCreateExpiredCallLogParams,
    ICallEvent,
    CallLogParamsWithUserInfo,
    CallLogConnectionParams,
    CallLogDeviceErrorParams,
    CallOnHoldLogParams,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class CallEventService {
    constructor(private http: HttpService) {}

    async getCallEvents(callSid: string, taskSid?: string): Promise<ICallEvent[]> {
        let params = `callSid=${callSid}`;

        if (taskSid) params += `&taskSid=${taskSid}`;

        return this.http.get<ICallEvent[]>(`call-event?${params}`);
    }

    async createDeliveredCallLog(params: CallEventCreateDeliveredCallLogParams): Promise<void> {
        return this.http.post<void>(`call-event/call-delivered`, params);
    }

    async createAcceptedCallLog(params: CallEventCreateAcceptCallLogParams): Promise<void> {
        return this.http.post<void>(`call-event/call-accepted`, params);
    }

    async createRejectedCallLog(params: CallEventCreateRejectCallLogParams): Promise<void> {
        return this.http.post<void>(`call-event/call-rejected`, params);
    }

    async createExpiredCallLog(params: CallEventCreateExpiredCallLogParams): Promise<void> {
        return this.http.post<void>(`call-event/call-timeout`, params);
    }

    async createDeviceCancelLog(params: CallLogParamsWithUserInfo): Promise<void> {
        return this.http.post<void>(`call-event/connection-cancelled-by-caller`, params);
    }

    async createConnectionOpenedCallLog(params: CallLogConnectionParams): Promise<void> {
        return this.http.post<void>(`call-event/connection-opened`, params);
    }

    async createConnectionClosedCallLog(params: CallLogConnectionParams): Promise<void> {
        return this.http.post<void>(`call-event/connection-closed`, params);
    }

    async createDeviceErrorCallLog(params: CallLogDeviceErrorParams): Promise<void> {
        return this.http.post<void>(`call-event/device-error`, params);
    }

    async createCallOnHoldLog(params: CallOnHoldLogParams): Promise<void> {
        return this.http.post<void>(`call-event/call-on-hold`, params);
    }
}
