import { IManager } from './manager';

export enum CallEventType {
    INCOMING_CALL = 'INCOMING_CALL',
    INCOMING_CALL_DELIVERED = 'INCOMING_CALL_DELIVERED',
    INCOMING_CALL_ACCEPTED = 'INCOMING_CALL_ACCEPTED',
    INCOMING_CALL_REJECTED = 'INCOMING_CALL_REJECTED',
    INCOMING_CALL_FORWARDED = 'INCOMING_CALL_FORWARDED',
    INCOMING_CALL_CANCELED = 'INCOMING_CALL_CANCELED',
    INCOMING_CALL_FINISHED = 'INCOMING_CALL_FINISHED',
    INCOMING_CALL_TIMEOUT = 'INCOMING_CALL_TIMEOUT',
    INCOMING_CALL_LEAD_SELECTED_BUTTON = 'INCOMING_CALL_LEAD_SELECTED_BUTTON',
    INCOMING_CALL_IVR_SCENARIO_STARTED = 'INCOMING_CALL_IVR_SCENARIO_STARTED',
    OUTGOING_CALL = 'OUTGOING_CALL',
    CONNECTION_CANCELLED_BY_THE_CALLER_BEFORE_ACCEPTED_BY_TWILIO_CLIENT = 'CONNECTION_CANCELLED_BY_THE_CALLER_BEFORE_ACCEPTED_BY_TWILIO_CLIENT',
    CONNECTION_IS_OPENED = 'CONNECTION_IS_OPENED',
    CONNECTION_IS_CLOSED = 'CONNECTION_IS_CLOSED',
    DEVICE_ERROR = 'DEVICE_ERROR',
    CALL_REDIRECTED = 'CALL_REDIRECTED',
    CALL_ON_HOLD = 'CALL_ON_HOLD',
    CALL_RESUMED = 'CALL_RESUMED',
}

export const callEventTypeReadable = {
    [CallEventType.INCOMING_CALL]: 'incoming call',
    [CallEventType.INCOMING_CALL_DELIVERED]: 'call delivered',
    [CallEventType.INCOMING_CALL_ACCEPTED]: 'call accepted',
    [CallEventType.INCOMING_CALL_REJECTED]: 'call rejected',
    [CallEventType.INCOMING_CALL_FORWARDED]: 'call forwarded',
    [CallEventType.INCOMING_CALL_CANCELED]: 'call canceled',
    [CallEventType.INCOMING_CALL_TIMEOUT]: 'call skipped',
    [CallEventType.INCOMING_CALL_FINISHED]: 'call finished',
    [CallEventType.INCOMING_CALL_LEAD_SELECTED_BUTTON]: 'incoming call lead selected button',
    [CallEventType.INCOMING_CALL_IVR_SCENARIO_STARTED]: 'incoming call ivr scenario started',
    [CallEventType.OUTGOING_CALL]: 'dialled call',
    [CallEventType.CONNECTION_CANCELLED_BY_THE_CALLER_BEFORE_ACCEPTED_BY_TWILIO_CLIENT]:
        'connection canceled by the caller before accepted by twilio client',
    [CallEventType.CONNECTION_IS_OPENED]: 'connection is opened',
    [CallEventType.CONNECTION_IS_CLOSED]: 'connection is closed',
    [CallEventType.DEVICE_ERROR]: 'device error',
    [CallEventType.CALL_REDIRECTED]: 'call redirected',
    [CallEventType.CALL_ON_HOLD]: 'call onhold',
    [CallEventType.CALL_RESUMED]: 'call resumed',
};

export interface CallEventCreateDeliveredCallLogParams {
    callSid: string;
    workerSid: string;
    taskSid: string;
}

export interface CallEventCreateAcceptCallLogParams {
    callSid: string;
    workerSid: string;
    taskSid: string;
}

export interface CallEventCreateRejectCallLogParams {
    callSid: string;
    workerSid: string;
    taskAttributes: any;
    taskSid: string;
}

export interface CallEventCreateExpiredCallLogParams {
    callSid: string;
    workerSid: string;
    taskSid: string;
}

export type CallLogParamsWithUserInfo = {
    callSid;
    workerSid: string;
    userId: number;
    email: string;
    taskSid: string;
};

export type CallLogConnectionParams = {
    from: string;
    to: string;
    incoming: boolean;
} & CallLogParamsWithUserInfo;

export type CallLogDeviceErrorParams = {
    message: string;
    code: number;
    twilioError: any;
} & CallLogParamsWithUserInfo;

export type CallOnHoldLogParams = {
    from: string;
} & CallLogParamsWithUserInfo;

export interface ICallEvent {
    id: number;
    type: CallEventType;
    callSid: string;
    worker?: IManager;
    rejectedWorkers?: IManager[];
    availableWorkers?: IManager[];
    onHoldWorkers?: IManager[];
    brokenWorkers?: IManager[];
    userGroupName?: string;
    error?: string;
    createdAt: Date;
    updatedAt: Date;
}
