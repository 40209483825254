import { ListResponse } from '../../med-list/interfaces';
import { LeadStatus } from '../interfaces';
import { ListFilter } from '../../med-list/interfaces';

export interface ISalary {
    id: number;
    time: Date;
    price: number;
    lead: {
        id: number;
        fullName: string;
        email: string;
        phone: string;
        status: LeadStatus;
        website: string;
        service: string;
        servicePlan: string;
    };
    doctor: {
        id: number;
        fullName: string;
    };
}

export interface ISalaryListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
}

export interface ISalaryListResponse extends ListResponse {
    items: ISalary[];
    totalAmount: number;
}

export interface ISalaryDownloadParams {
    filters?: ListFilter[];
}

export interface ISummarySalaryDoctorValues {
    amount: number;
    visits: number;
}

export type ISummarySalaryWeeksInfo = Record<number, { start: string; end: string }>;
export type ISummarySalaryPeriodsInfo = Record<number, { start: string; end: string }>;

export interface ISummarySalaryItem {
    total?: ISummarySalaryDoctorValues;
    totalBePaid?: number;
    doctorFullName: string;
    doctorId: number;
    doctorSalary: number;
    1?: ISummarySalaryDoctorValues;
    2?: ISummarySalaryDoctorValues;
    3?: ISummarySalaryDoctorValues;
    4?: ISummarySalaryDoctorValues;
    5?: ISummarySalaryDoctorValues;
    6?: ISummarySalaryDoctorValues;
}

export interface IPayoutsSalaryParams {
    filters?: ListFilter[];
    type?: IPayoutsSalaryPeriod;
}

export interface IPayoutsSalaryResponse {
    items: ISummarySalaryItem[];
    weeksInfo?: ISummarySalaryWeeksInfo;
    periodsInfo?: ISummarySalaryPeriodsInfo;
    totalAmount: number;
}

export enum IPayoutsSalaryPeriod {
    BIMONTHLY = 'BIMONTHLY',
    WEEKS_OF_MONTH = 'WEEKS_OF_MONTH',
}

export const IPayoutsSalaryPeriodReadable = {
    [IPayoutsSalaryPeriod.BIMONTHLY]: 'Bimonthly',
    [IPayoutsSalaryPeriod.WEEKS_OF_MONTH]: 'All weeks of the month',
};
