import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { TIMEZONES } from '../consts/timezones.const';

@Pipe({
    name: 'momentPipe',
})
export class MomentDatePipe implements PipeTransform {
    transform(
        value: any,
        timezone?: string,
        format?: string,
        displayTimezone: boolean = false
    ): string {
        const time = moment(value).tz(
            timezone in TIMEZONES ? TIMEZONES[timezone] : timezone || moment.tz.guess()
        );

        let tz = '';
        if (displayTimezone) {
            tz = ` (${time.format('zz')})`;
        }

        return `${time.format(format || 'M/d/YY, h:mm A')}` + tz;
    }
}
