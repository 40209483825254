import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from } from 'rxjs';
import { ApiProfileService } from '../../../med-api/services';
import { Form, FormBuilder, FormEvent, FormInputField } from '../../../med-form/classes';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'med-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
    public passwordForm: Form;

    @Output() changed: EventEmitter<void> = new EventEmitter();
    @Output() error: EventEmitter<{ error: any }> = new EventEmitter();

    constructor(private authService: AuthService, private apiProfileService: ApiProfileService) {}

    public get isExpiredPasswordMessageNeeded(): boolean {
        return this.authService?.user?.isPasswordExpired;
    }

    ngOnInit() {
        this.initPasswordForm();
    }

    private initPasswordForm(): void {
        const formBuilder = new FormBuilder();
        formBuilder.addField(FormInputField, 'currentPassword', {
            label: 'Current Password',
            validators: ['required', { type: 'minLength', params: 6 }],
            type: 'password',
        });
        formBuilder.addField(FormInputField, 'newPassword', {
            label: 'New Password',
            validators: ['required', { type: 'minLength', params: 6 }],
            type: 'password',
        });

        formBuilder.addSubmitButton('SUBMIT');
        formBuilder.setHandler(FormEvent.SUBMIT, this.onPasswordSubmit.bind(this));

        this.passwordForm = formBuilder.getForm();
    }

    private onPasswordSubmit(formValue): void {
        from(
            this.apiProfileService.changePassword({
                currentPassword: formValue.currentPassword,
                newPassword: formValue.newPassword,
            })
        ).subscribe({
            next: () => this.changed.emit(),
            error: (error) => this.error.emit({ error }),
        });
    }
}
