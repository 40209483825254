import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    IPaymentToDoctor,
    IPaymentToDoctorListParams,
    IPaymentToDoctorListResponse,
    IPaymentToDoctorCreateParams,
    IPaymentToDoctorEditParams,
    IPaymentToDoctorDeleteResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiPaymentToDoctorService {
    constructor(private http: HttpService) {}

    list(params: IPaymentToDoctorListParams): Promise<IPaymentToDoctorListResponse> {
        return this.http.post<IPaymentToDoctorListResponse>(`crm/payment-to-doctor/list`, params);
    }

    create(params: IPaymentToDoctorCreateParams): Promise<IPaymentToDoctor> {
        return this.http.post<IPaymentToDoctor>(`crm/payment-to-doctor`, params);
    }

    edit(id: number, params: IPaymentToDoctorEditParams): Promise<IPaymentToDoctor> {
        return this.http.patch<IPaymentToDoctor>(`crm/payment-to-doctor/${id}`, params);
    }

    delete(id: number): Promise<IPaymentToDoctorDeleteResponse> {
        return this.http.delete<IPaymentToDoctorDeleteResponse>(`crm/payment-to-doctor/${id}`);
    }
}
