import { ListResponse } from '../../med-list/interfaces';
import { IServicePlan } from './service-plan';

export enum ServiceCode {
    ADDICTION_TREATMENT = 'addiction_treatment',
    ADHD = 'adhd',
    ANXIETY = 'anxiety',
    CHRONIC_FATIGUE = 'chronic_fatigue',
    CORONA_EZCARE = 'corona_ezcare',
    COSMETIC_SERVICES = 'cosmetic_services',
    DEPRESSION = 'depression',
    EARWAX_CLEANING = 'earwax_cleaning',
    ESA = 'esa',
    HORMONE_REPLACEMENT_THERAPY_HRT = 'hormone_replacement_therapy_hrt',
    INSOMNIA = 'insomnia',
    MMJ = 'mmj',
    MENS_HEALTH = 'mens_health',
    NARC = 'narc',
    OCD_TREATMENT = 'ocd_treatment',
    PHYSICAL_EXAMINATION = 'physical_examination',
    PAIN_MANAGEMENT = 'pain_management',
    PSY = 'psy',
    SEXUALLY_TRANSMITTED_DISEASE = 'sexually_transmitted_disease',
    SUBSCRIPTION = 'subscription',
    WEIGHT_LOSS = 'weight_loss',
    WOMENS_HEALTH = 'womens_health',
}

export enum ServiceTreatmentType {
    MEDICAL = 'Medical',
    NON_MEDICAL = 'Non-medical',
}

export interface IService {
    id: number;
    code: string;
    name: string;
    visitDuration: number;
    returningVisitDuration?: number;
    needLetter: boolean;
    nextFollowup: number;
    servicePlans: IServicePlan[];
    image: string;
    treatmentType: ServiceTreatmentType;
    type: ServiceType;
    createdAt: Date;
    updatedAt: Date;
}

export interface IServiceSmall {
    id: number;
    name: string;
    code: string;
}

export interface ICommonDataService {
    id: number;
    name: string;
    code: string;
    visitDuration: number;
    returningVisitDuration?: number;
    needLetter: boolean;
    treatmentType: ServiceTreatmentType;
    createdAt: Date;
    updatedAt: Date;
}

export interface IServiceListParams {
    page: number;
    limit: number;
    params?: {
        stateCode?: string;
        zip?: string;
        websiteCode?: string;
    };
    sort?: Record<string, string>;
}

export interface IServiceListResponse extends ListResponse {
    items: IService[];
}

export interface IServiceCreateParams {
    code: string;
    name: string;
    visitDuration: number;
    returningVisitDuration?: number;
    needLetter: boolean;
    nextFollowup: number;
    treatmentType: ServiceTreatmentType;
}

export interface IServiceEditParams {
    code?: string;
    name?: string;
    visitDuration?: number;
    returningVisitDuration?: number;
    needLetter?: boolean;
    nextFollowup?: number;
    image?: string;
    treatmentType?: ServiceTreatmentType;
}

export enum ServiceType {
    MENTAL_HEALTH = 'Mental health',
    OTHER = 'Other',
}
