import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MedFormModule } from 'medvidi';
import { ElementsModule } from '../elements/elements.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [DashboardComponent, ProfileComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ElementsModule,
        RouterModule,
        MedFormModule,
        MatTabsModule,
        MatSnackBarModule,
    ],
})
export class CrmModule {}
