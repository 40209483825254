import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface HttpOptions {
    body?: any;
    headers?: HttpHeaders | { [header: string]: string | string[] };
    observe?: 'body' | 'events' | 'response';
    params?: HttpParams | { [param: string]: string | string[] };
    reportProgress?: boolean;
    responseType?: 'arraybuffer' | 'blob';
    withCredentials?: boolean;
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

@Injectable({ providedIn: 'root' })
export class RestApiService {
    private _baseUrl!: string;

    constructor(private httpClient: HttpClient) {}

    public get baseUrl(): string {
        return this._baseUrl;
    }

    public set baseUrl(value: string) {
        this._baseUrl = value;
    }

    public get(url: string, options?: HttpOptions, customBaseUrl?: string): Observable<any> {
        return this.request('GET', url, options, customBaseUrl);
    }

    public post(
        url: string,
        body?: any,
        options?: HttpOptions,
        customBaseUrl?: string
    ): Observable<any> {
        return this.request('POST', url, { body, ...options }, customBaseUrl);
    }

    public put(
        url: string,
        body: any,
        options?: HttpOptions,
        customBaseUrl?: string
    ): Observable<any> {
        return this.request('PUT', url, { body, ...options }, customBaseUrl);
    }

    public patch(
        url: string,
        body: any,
        options?: HttpOptions,
        customBaseUrl?: string
    ): Observable<any> {
        return this.request('PATCH', url, { body, ...options }, customBaseUrl);
    }

    public delete(url: string, options?: HttpOptions, customBaseUrl?: string): Observable<any> {
        return this.request('DELETE', url, options, customBaseUrl);
    }

    private request(
        method: HttpMethod,
        url: string,
        options?: HttpOptions,
        customBaseUrl?: string
    ): Observable<any> {
        return this.httpClient
            .request(method, `${customBaseUrl || this.baseUrl}/${url}`, options)
            .pipe(
                catchError((err: HttpErrorResponse) =>
                    throwError({ ...err?.error, status: err.status })
                )
            );
    }
}
