export function getSelectOptions(options: string[]): Record<string, any>[] {
    return [
        {
            options: options.map((elem) => ({
                value: elem,
                text: elem,
            })),
        },
    ];
}
