export class TimingItem {
    private id: number;
    private hourStart: number;
    private minuteStart: number;
    private hourEnd: number;
    private minuteEnd: number;
    private date: Date;
    private selected: boolean;
    private fullFormat: boolean;
    private disabled: boolean;
    private busy: boolean;
    private halfBusy: boolean;
    private isAM: boolean;
    private hourDescStart: string;
    private minuteDescStart: string;
    private output: string;

    constructor(
        id: number,
        hourStart: number,
        minuteStart: number,
        hourEnd: number,
        minuteEnd: number,
        selected: boolean,
        date: Date,
        fullFormat: boolean,
        disabled: boolean,
        busy: boolean,
        halfBusy: boolean
    ) {
        this.id = id;
        this.hourStart = hourStart;
        this.minuteStart = minuteStart;
        this.hourEnd = hourEnd;
        this.minuteEnd = minuteEnd;
        this.selected = selected;
        this.date = date;
        this.fullFormat = fullFormat;
        this.disabled = disabled;
        this.busy = busy;
        this.halfBusy = halfBusy;

        this.isAM = hourStart < 12 ? true : false;
        this.hourDescStart = this.getHourDesc(hourStart, fullFormat);
        this.minuteDescStart = this.getMinuteDesc(minuteStart);
        this.output = this.getTimeFormat(
            this.hourDescStart,
            this.minuteDescStart,
            this.isAM,
            this.fullFormat
        );
    }

    public getId(): number {
        return this.id;
    }

    public getHourStart(): number {
        return this.hourStart;
    }

    public getMinuteStart(): number {
        return this.minuteStart;
    }

    public getHourEnd(): number {
        return this.hourEnd;
    }

    public getMinuteEnd(): number {
        return this.minuteEnd;
    }

    public getDate(): Date {
        return this.date;
    }

    public getOutput(): string {
        return this.output;
    }

    public getSelected(): boolean {
        return this.selected;
    }

    public setSelected(value: boolean): void {
        this.selected = value;
    }

    public getDisabled(): boolean {
        return this.disabled;
    }

    public setDisabled(value: boolean): void {
        this.disabled = value;
    }

    public getBusy(): boolean {
        return this.busy;
    }

    public getHalfBusy(): boolean {
        return this.halfBusy;
    }

    private getHourDesc(hour: number, fullFormat: boolean): string {
        if (fullFormat) {
            if (hour < 10) {
                return `0${hour}`;
            } else {
                return `${hour}`;
            }
        } else {
            if (hour === 0) {
                return `12`;
            } else if (hour < 10) {
                return `0${hour}`;
            } else if (hour <= 12) {
                return `${hour}`;
            } else if (hour - 12 < 10) {
                return `0${hour - 12}`;
            } else {
                return `${hour - 12}`;
            }
        }
    }

    private getMinuteDesc(minute: number): string {
        if (minute > 10) {
            return `${minute}`;
        } else {
            return `0${minute}`;
        }
    }

    private getTimeFormat(
        hourDesc: string,
        minuteDesc: string,
        isAm: boolean,
        fullFormat: boolean
    ): string {
        if (!fullFormat) {
            return `${hourDesc}:${minuteDesc} ${isAm ? 'AM' : 'PM'}`;
        } else {
            return `${hourDesc}:${minuteDesc}`;
        }
    }
}
