export enum Timezone {
    PST = 'PST',
    MST = 'MST',
    MST_Arizona = 'MST (Arizona)',
    CST = 'CST',
    EST = 'EST',
    AKST = 'AKST',
    HST = 'HST',
    CET = 'CET',
    MSK = 'MSK',
    IST = 'IST',
}
