import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    IGetDoctorsMasterLoginResponse,
    IManager,
    IManagerEditParams,
    IManagerListParams,
    IManagerListResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiManagerService {
    constructor(private http: HttpService) {}

    async list(params: IManagerListParams): Promise<IManagerListResponse> {
        return this.http.post<IManagerListResponse>(`manager/list`, params);
    }

    async view(id: number): Promise<IManager> {
        return this.http.get<IManager>(`manager/${id}`);
    }

    async viewByUserId(id: number): Promise<IManager> {
        return this.http.get<IManager>(`manager?user=${id}`);
    }

    async edit(id: number, params: IManagerEditParams | FormData): Promise<IManager> {
        return this.http.patch<IManager>(`manager/${id}`, params);
    }

    async viewByToken(): Promise<IManager> {
        return this.http.get<IManager>(`manager/my`);
    }

    async startDay(): Promise<{}> {
        return this.http.post<{}>(`manager/start-day`, {});
    }

    async finishDay(): Promise<{}> {
        return this.http.post<{}>(`manager/finish-day`, {});
    }

    async getDoctorsForMasterLogin(): Promise<IGetDoctorsMasterLoginResponse> {
        return this.http.get<IGetDoctorsMasterLoginResponse>(`manager/doctors-master-login`);
    }
}
