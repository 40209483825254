import { ListFilter } from '../../med-list/interfaces';
import { AppointmentMethodType, ILead, IServiceAccount, IUser, SortValue } from './';

export enum TaskStatus {
    DRAFT = 1, //'draft'
    PENDING = 2, //'pending'
    ATTACHED = 3, //'attached'
    OVERDUE = 4, //'overdue'
    COMPLETED = 5, //'completed'
    ON_HOLD = 6, //'on_hold'
    IN_PROGRESS = 7, //'in_progress'
    PAUSED = 8, //'paused'
    BLOCKED = 9, //'blocked'
}

export const TaskStatusReadable = {
    [TaskStatus.DRAFT]: 'draft',
    [TaskStatus.PENDING]: 'pending',
    [TaskStatus.ATTACHED]: 'attached',
    [TaskStatus.OVERDUE]: 'overdue',
    [TaskStatus.COMPLETED]: 'completed',
    [TaskStatus.ON_HOLD]: 'on hold',
    [TaskStatus.IN_PROGRESS]: 'in progress',
    [TaskStatus.PAUSED]: 'paused',
    [TaskStatus.BLOCKED]: 'blocked',
};

export enum TaskPriority {
    LOWEST = 1,
    LOW = 2,
    MEDIUM = 3,
    HIGH = 4,
    HIGHEST = 5,
    CRITICAL = 6,
}

export const TaskPriorityReadable = {
    [TaskPriority.LOWEST]: 'Lowest',
    [TaskPriority.LOW]: 'Low',
    [TaskPriority.MEDIUM]: 'Medium',
    [TaskPriority.HIGH]: 'High',
    [TaskPriority.HIGHEST]: 'Highest',
    [TaskPriority.CRITICAL]: 'Critical',
};

export enum TaskType {
    INCOMING_CALL = 'incoming_call',
    MISSED_CALL = 'missed_call',
    INCOMING_EMAIL_MESSAGE = 'incoming_email_message',
    INCOMING_SMS = 'incoming_sms',
    INCOMING_FACEBOOK_MESSAGE = 'incoming_facebook_message',
    INCOMING_MOBILE_MESSAGE = 'incoming_mobile_message',
    TWILIO_TASK = 'twilio_task',
    MANUAL = 'manual',
    OTHER = 'other',
    AUTO_TASK = 'auto_task',
    ERX = 'electronic_prescription',
    CALL_SKIPPED = '24', // this value is correct, because I need to convert the value to the lead_event.type
}

export const TaskTypeReadable = {
    [TaskType.INCOMING_CALL]: 'Incoming call',
    [TaskType.MISSED_CALL]: 'Missed call',
    [TaskType.INCOMING_EMAIL_MESSAGE]: 'Incoming email message',
    [TaskType.INCOMING_SMS]: 'Incoming SMS',
    [TaskType.INCOMING_FACEBOOK_MESSAGE]: 'Incoming facebook message',
    [TaskType.INCOMING_MOBILE_MESSAGE]: 'Incoming mobile message',
    [TaskType.TWILIO_TASK]: 'Twilio task',
    [TaskType.MANUAL]: 'Manual',
    [TaskType.OTHER]: 'Other',
    [TaskType.AUTO_TASK]: 'Auto Task',
    [TaskType.ERX]: 'Electronic prescription',
    [TaskType.CALL_SKIPPED]: 'Call skipped',
};

export enum TaskGroup {
    SCHEDULING = 'scheduling',
    COMMUNICATION = 'communication',
    CLINICAL = 'clinical',
    OUTBOUND = 'outbound',
    REFUND = 'refund',
    OTHER = 'other',
}

export const TaskGroupReadable = {
    [TaskGroup.SCHEDULING]: 'Scheduling',
    [TaskGroup.COMMUNICATION]: 'Communication',
    [TaskGroup.CLINICAL]: 'Clinical',
    [TaskGroup.OUTBOUND]: 'Outbound',
    [TaskGroup.REFUND]: 'Refund',
    [TaskGroup.OTHER]: 'Other',
};

export const enum RefObjectClass {
    LEAD = 'Lead',
    EMAIL_MESSAGE = 'EmailMessage',
    FACEBOOK_MESSAGE = 'FacebookMessage',
    SMS = 'Sms',
    CALL = 'Call',
    APPOINTMENT = 'Appointment',
    MOBILE_MESSAGE = 'MobileMessage',
}

export enum TaskCode {
    LEAD_VERIFICATION = 'lead_verification',
    VERIFICATION_ISSUE_REFUND = 'verification_issue_refund',
    REFUND_TASK = 'make_refund',
    CHANGE_PHARMACY = 'change_pharmacy',
    CONTACT_PATIENT_TO_CLARIFY_PRESCRIPTION = 'contact_patient_to_clarify_prescription',
    ADD_LEAD_TO_PM = 'add_lead_to_pm',
    ELIGIBILITY_CHECK = 'eligibility_check',
    CLAIM_AMD = 'claim_amd',
    REMITTANCE_AMD = 'remittance_amd',
    INTAKE_VERIFICATION = 'intake_verification',
    PDMP_VERIFICATION = 'pdmp_verification',
    CREATE_PRESCRIPTION_TASK = 'create_prescription',
    VERIFICATION_ISSUE_INTAKE_GENERAL_INFO = 'verification_issues_intake_general_info',
    VERIFICATION_ISSUE_INTAKE_ID = 'verification_issues_intake_id',
    VERIFICATION_ISSUE_INTAKE_OTHER = 'verification_issues_intake_other',
    VERIFICATION_ISSUE_PDMP_DRUGS = 'verification_issues_pdmp_drugs',
    VERIFICATION_ISSUE_PDMP_MEDICATION = 'verification_issues_pdmp_medication',
    VERIFICATION_ISSUE_PDMP_OTHER = 'verification_issues_pdmp_other',
    ADD_PATIENT_TO_DOSESPOT = 'add_patient_to_dosespot',
    UPDATE_PDMP_CURES = 'update_pdmp_cures',
    PHARMACY_ISSUE = 'pharmacy_issue',
    COMPLETE_FULL_PAYMENT = 'complete_full_payment',
    CANCEL_VISIT_SUBSCRIPTION_FOLLOW_UP_PAYMENT_FAILED = 'cancel_visit_subscription_follow_up_payment_failed',
    CHECK_LEAD_STATUS = 'check_lead_status',
    RESCHEDULE_MISSED_APPOINTMENT = 'reschedule_missed_appointment',
    STRIPE_VALIDATE_DISPUTE = 'stripe_validate_dispute',
    RESCHEDULE_EMAIL_TO_USER = 'reschedule_email_to_user',
    CONFIRM_APPOINTMENT = 'confirm_appointment',
    MAKE_REFUND = 'make_refund',
    CONFIRM_APPOINTMENT_ANOTHER_PROVIDER = 'confirm_appointment_another_provider',
    AUTO_EMAIL_APPOINTMENT_CONFIRMATION = 'auto_email_appointment_confirmation',
    AUTO_SMS_APPOINTMENT_CONFIRMATION = 'auto_sms_appointment_confirmation',
    FOLLOW_UP = 'follow_up',
    FOLLOW_UP_2 = 'follow_up_2',
    AUTO_EMAIL_REMINDER_NEXT_APPOINTMENT = 'auto_email_reminder_next_appointment',
    AUTO_SMS_REMINDER_NEXT_APPOINTMENT = 'auto_sms_reminder_next_appointment',
    AUTO_EMAIL_APPLICATION_LINK_WITHOUT_SERVICE = 'auto_email_application_link_without_service',
    AUTO_SMS_APPLICATION_LINK_WITHOUT_SERVICE = 'auto_sms_application_link_without_service',
    AUTO_EMAIL_APPLICATION_LINK = 'auto_email_application_link',
    AUTO_SMS_APPLICATION_LINK = 'auto_sms_application_link',
    AUTO_EMAIL_GENERAL_FOLLOW_UP = 'auto_email_general_follow_up',
    AUTO_SMS_GENERAL_FOLLOW_UP = 'auto_sms_general_follow_up',
    TO_CHECK_CLIENT = 'to_check_client',
    NO_SHOW = 'no_show',
    AUTO_EMAIL_APPOINTMENT_EXPIRED = 'auto_email_appointment_expired',
    AUTO_SMS_APPOINTMENT_EXPIRED = 'auto_sms_appointment_expired',
    UNUSED_APT_FOLLOW_UP = 'unused_apt_follow_up',
    FOLLOW_UP_COMPLETE_INTAKE = 'follow_up_complete_intake',
    SUBSCRIPTION_SELECT_TREATMENT_DATETIME_COMPLETE_INTAKE = 'subscription_select_treatment_datetime_complete_intake',
    AUTO_EMAIL_APPLICATION_LINK_PAID_CASH = 'auto_email_application_link_paid_cash',
    AUTO_SMS_APPLICATION_LINK_PAID_CASH = 'auto_sms_application_link_paid_cash',
    NEED_LETTER = 'need_letter',
    CHECKED_OUT = 'checked_out',
    CHECK_MEDICAL_RECORDS_AND_REFERRAL_LETTER_FORM_PCP = 'check_medical_records_and_referral_letter_form_pcp',
    VERIFICATION_ISSUES_MEDICAL_RECORDS_AND_REFERRAL_LETTER_ISSUE = 'verification_issues_medical_records_and_referral_letter_issue',
    CONTACT_FOR_SALE_1 = 'contact_for_sale_1',
    CONTACT_FOR_SALE_2 = 'contact_for_sale_2',
    CONTACT_FOR_SALE_1_CALL_BACK = 'contact_for_sale_1_call_back',
    CONTACT_FOR_SALE_2_CALL_BACK = 'contact_for_sale_2_call_back',
    RESCHEDULE_APPOINTMENT = 'reschedule_appointment',
    REPLY_TO_SMS = 'reply_to_sms',
    REPLY_TO_EMAIL = 'reply_to_email',
    CONTACT_TO_PAY = 'contact_to_pay',
    CALL_PATIENT_ON_LAB_ORDER = 'call_patient_on_lab_order',
    CHECK_PATIENT_LAB_RESULTS = 'check_patient_lab_results',
    REVIEW_UPLOADED_DOCS = 'review_uploaded_documentation'
}

export const IntakeVerificationReasons = [
    'Intake is OK',
    'General info issues',
    'ID issues',
    'Refund request',
    'Other',
];

export const StripeValidateADisputeResolutions = ['Dispute is won', 'Dispute is lost'];

export enum StripeValidationDisputeCloseReasons {
    CHECK_RETURNED = 'Check returned',
    CREDIT_NOT_PROCESSED = 'Credit not processed',
    CUSTOMER_INITIATED = 'Customer initiated',
    DEBT_NOT_AUTHORIZED = 'Debit not authorized',
    DUPLICATE = 'Duplicate',
    FRAUDULENT = 'Fraudulent',
    GENERAL = 'General (please, specify in comments)',
    INCORRECT_ACCOUNT_DETAILES = 'inccorect account details',
    INSUFFICIENT_FUNDS = 'insufficient funds',
    PRODUCT_NOT_RECEIVED = 'product not received',
    PRODUCT_UNACCEPTABLE = 'product unacceptable',
    SUBSCRIPTION_CANCELLED = 'subscription cancelled',
    UNRECOGNIZED = 'unrecognized (please, specify in comments)',
}

export const PDMPVerificationReasons = [
    'PDMP is OK',
    'Drug abuse',
    'Medications issues',
    'Need to refund',
    'Other',
];
export const VerificationIssuesReasons = ['Approved', 'Declined', 'Refund'];

export const enum UpdatePDMPAndCuresReason {
    APPROVED = 'Approved',
    DECLINED = 'Declined',
    REFUND = 'Refund',
}

export const RescheduleAppointmentReasons = ['Reschedule', 'Refund'];

export const UpdatePDMPAndCuresReasons = [
    UpdatePDMPAndCuresReason.APPROVED,
    UpdatePDMPAndCuresReason.DECLINED,
    UpdatePDMPAndCuresReason.REFUND,
];

export enum ContactForSaleReason {
    INTERESTED = 'Interested',
    NOT_INTERESTED = 'Not interested',
    DID_NOT_PICK_UP = "Didn't pick up",
    PAID = 'Paid',
    CALL_BACK = 'Call later',
}

export const ContactForSaleReasons = [
    ContactForSaleReason.INTERESTED,
    ContactForSaleReason.NOT_INTERESTED,
    ContactForSaleReason.DID_NOT_PICK_UP,
    ContactForSaleReason.PAID,
    ContactForSaleReason.CALL_BACK,
];

export enum ContactToPayTaskReason {
    INTERESTED = 'Interested',
    NOT_INTERESTED = 'Not interested',
    DID_NOT_PICK_UP = "Didn't pick up",
    PHARMACY_ISSUE = 'Pharmacy issue',
}

export const ContactToPayTaskReasons = [
    ContactToPayTaskReason.INTERESTED,
    ContactToPayTaskReason.NOT_INTERESTED,
    ContactToPayTaskReason.DID_NOT_PICK_UP,
    ContactToPayTaskReason.PHARMACY_ISSUE,
];

export const enum ReviewUploadedDocumentsResolutions {
    DOCUMENT_IS_OK = 'Document is OK',
    REQUESTED_NEW_DOCUMENT = 'Requested new document',
}

export const reviewUploadedDocumentsResolutions = [
    ReviewUploadedDocumentsResolutions.DOCUMENT_IS_OK,
    ReviewUploadedDocumentsResolutions.REQUESTED_NEW_DOCUMENT
];

export const enum MessageType {
    EMAIL = 'email',
    SMS = 'sms',
}

export interface Task {
    id: number;
    name: string;
    code: string;
    description?: string;
    descriptionLink?: string;
    type: TaskType;
    status: TaskStatus;
    userId?: number;
    user?: IUser;
    createdById?: number;
    createdBy?: IUser;
    leadId?: number;
    appointmentMethod?: AppointmentMethodType;
    lead?: ILead;
    leadCode?: string;
    serviceAccountId?: number;
    serviceAccount?: IServiceAccount;
    priority?: number;
    activateAt?: Date;
    closedAt: Date;
    closedAutomatically?: boolean;
    closeComment?: string;
    refObjectClass: RefObjectClass;
    taskGroup: TaskGroup;
    autoParams?: {
        messageType?: string;
        templateCode?: string;
    };
    attachedAt?: Date;
    deadlineTask?: Date;
    createdAt: Date;
    updatedAt: Date;
    numberOfPostpones: number;
    postponedUntilDate: Date;
    isAssignedManagerHasStartedDay: boolean;
    autoPdmpResolution?: string | null;
}

export interface TaskListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
    sort?: {
        priority?: SortValue;
        createdAt?: SortValue;
        closedAt?: SortValue;
        name?: SortValue;
        attachedAt?: SortValue;
    };
    extraSorting?: {
        tasksWithDoctorsFirst: boolean;
    };
}

export interface TaskListResponse {
    tasks: Task[];
    total: number;
}

export type TaskBoardByColumns = {
    toDo: Task[];
    inProgress: Task[];
    onHold: Task[];
    completed: Task[];
};

export interface MyTasksResponse {
    tasks: TaskBoardByColumns;
}

type RefundData = {
    refundCompleted?: boolean;
    amount?: number;
    refundType?: string;
};

type StripeDisputeData = {
    reason?: StripeValidationDisputeCloseReasons;
};

type OtherCloseData = {
    userGroupId: number;
};

type LabOrdersData = {
    activatedAtNextTask: string;
};

export type ContactForSaleData = {
    hours: number;
    minutes: number;
};

type CloseAdditionalData =
    | RefundData
    | StripeDisputeData
    | OtherCloseData
    | ContactForSaleData
    | LabOrdersData;

export interface TaskCloseParams {
    resolution: string;
    description?: string;
    additionalData?: CloseAdditionalData;
}

export interface TaskCreateParams {
    taskCode: string;
    userId?: number;
    usersGroupId?: number;
    leadId?: number;
    leadCode?: string;
    description?: string;
    activateAt?: Date;
    priority?: TaskPriority;
    refObjectClass?: RefObjectClass;
}

export interface TaskResponse {
    task: Task;
}

export interface IPostponeTaskParams {
    resolution: string;
    description?: string;
}
