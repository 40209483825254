import { ListFilter, ListSortParams } from '../../med-list/interfaces';
import {
    IAppointment,
    IClient,
    IInsuranceSmall,
    ILeadInsurance,
    IPromoCode,
    IService,
    IServicePlan,
    IState,
    IWebsite
} from '../interfaces';

export enum LeadStatus {
    NEW = 0,
    APPLICATION = 1,
    PAYMENT = 2,
    NEED_APPOINTMENT = 3,
    WAITING_FOR_APPOINTMENT = 4,
    APPROVED = 5,
    REFUND = 6,
    CLOSED_LOST = 7,
    CLOSED_WON = 8,
    SERVICE_CHOSEN = 9,
    ADDITIONAL_INVOICE = 10,
    CLOSED_TEST = 11,
    APPOINTMENT_EXPIRED = 12,
    NEED_LETTER = 13,
    WAITING_FOR_INTAKE = 14,
    WAITING_FOR_VERIFICATION = 15,
    DOC_UNAVAILABLE = 16,
    WAITING_FOR_FULL_PAYMENT = 17,
    CLOSED_REFUNDED = 18,
    UNUSED_APPOINTMENT = 19,
    CLOSED_UNPAID = 20,
    PHARMACY_ISSUE = 21,
    PENDING = 22,
    ERX_IN_PROGRESS_EXECUTED_BY_MANAGER = 23,
    ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR = 24,
    AWAITING_MEDICATION = 25,
    DISCHARGED = 27,
}

export const LeadStatusReadable = {
    [LeadStatus.NEW]: 'New',
    [LeadStatus.APPLICATION]: 'Application',
    [LeadStatus.SERVICE_CHOSEN]: 'Service Chosen',
    [LeadStatus.PAYMENT]: 'Payment',
    [LeadStatus.NEED_APPOINTMENT]: 'Need Appointment',
    [LeadStatus.WAITING_FOR_APPOINTMENT]: 'Awaiting appointment',
    [LeadStatus.APPOINTMENT_EXPIRED]: 'Appointment Expired',
    [LeadStatus.APPROVED]: 'Approved',
    [LeadStatus.REFUND]: 'Refund',
    [LeadStatus.NEED_LETTER]: 'Need Letter',
    [LeadStatus.ADDITIONAL_INVOICE]: 'Additional Invoice',
    [LeadStatus.CLOSED_LOST]: 'Closed Lost',
    [LeadStatus.CLOSED_WON]: 'Closed Won',
    [LeadStatus.CLOSED_TEST]: 'Closed Test',
    [LeadStatus.WAITING_FOR_INTAKE]: 'Awaiting intake',
    [LeadStatus.CLOSED_REFUNDED]: 'Closed Refunded',
    [LeadStatus.WAITING_FOR_VERIFICATION]: 'Awaiting Verification',
    [LeadStatus.DOC_UNAVAILABLE]: 'Doc unavailable',
    [LeadStatus.WAITING_FOR_FULL_PAYMENT]: 'Awaiting full payment',
    [LeadStatus.CLOSED_UNPAID]: 'Closed Lost: payment overdue',
    [LeadStatus.UNUSED_APPOINTMENT]: 'Unused apt',
    [LeadStatus.PHARMACY_ISSUE]: 'Pharmacy issue',
    [LeadStatus.PENDING]: 'Pending',
    [LeadStatus.ERX_IN_PROGRESS_EXECUTED_BY_MANAGER]: 'eRx in progress. Executed by Manager',
    [LeadStatus.ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR]: 'eRx in progress. Executed by Doctor',
    [LeadStatus.AWAITING_MEDICATION]: 'Awaiting Medication',
    [LeadStatus.DISCHARGED]: 'Discharged lead',
};

export const enum Gender {
    FEMALE,
    MALE,
    OTHER,
}

export const GenderReadable = {
    [Gender.FEMALE]: 'Female',
    [Gender.MALE]: 'Male',
    [Gender.OTHER]: 'Other',
};

export const enum LeadPaymentType {
    CASH = 1,
    ONLINE = 2,
    ON_SITE = 3,
    APPLE_PAY = 4,
    FREE = 5,
}

export const LeadPaymentTypeString = {
    [LeadPaymentType.CASH]: 'Cash',
    [LeadPaymentType.ONLINE]: 'Card',
    [LeadPaymentType.ON_SITE]: 'On site',
    [LeadPaymentType.APPLE_PAY]: 'Apple Pay',
    [LeadPaymentType.FREE]: 'Bundle'
};

export enum LeadCreationType {
    WEBSITE = 0,
    CALENDAR = 1,
    MOBILE = 2,
    FOLLOW_UP = 3,
    INCOMING_CALL = 4,
    SUBSCRIPTION = 5,
    USER_DASHBOARD = 6,
    BY_DOCTOR = 7,
    INCOMING_SMS = 8,
    INCOMING_EMAIL = 9,
}

export interface ILead {
    id: number;
    parentLeadId?: number;
    client?: IClient;
    firstName: string;
    lastName: string;
    fullName: string;
    gender: string;
    email: string;
    phone: string;
    status: LeadStatus;
    source: string;
    serviceTitle: string;
    service: IService;
    additionalServices: IService[];
    state?: IState;
    zip?: string;
    plan: string;
    price: number;
    paidAt: Date;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    amocrmId: number;
    sourceRefId: string;
    websiteId?: number;
    websiteName?: string;
    website: IWebsite;
    clientAppointmentTime?: Date;
    appointment?: IAppointment;
    servicePlan?: IServicePlan;
    paymentType: number;
    isTest: boolean;
    isSubscription: boolean;
    promocode?: string;
    paidAmount?: number;
    needLetter: boolean;
    createdAt: Date;
    updatedAt: Date;
    originalPrice?: number;
    dateOfBirth?: string;
    dosespotId?: number;
    blacklisted: boolean;
    redFlag: boolean;
    finalPaymentDate?: Date;
    creationType: LeadCreationType;
    isTwoStepPaymentAllowed: boolean;
    insuranceId?: number;
    leadInsurance?: ILeadInsurance;
    leadEligibilityResults?: IEligibilityResult[];
    hasInsurance?: boolean;
    coPayAmount?: number;
    childLead?: IChildLeadSmall;
    refund?: RefundData;
    hasActivePharmacyIssue: boolean;
    additionalData: ILeadAdditionalData;
    isRealtimeAppointmentConfirmation?: boolean;
    contactId: number;
    isInitialVisit: boolean
}

export interface ILeadAdditionalData {
    nextVisitTime?: Date;
}

export interface RefundData {
    amount: number;
    id: number;
    reason: string;
    refundType: string;
}

// Transfer from
export interface ICrmLead {
    id: number;
    createdAt: Date;
    amocrmId: number;
    sourceRefId: string;
    client?: IClient;
    fullName: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth?: string;
    gender?: string;
    zip?: string;
    email?: string;
    phone?: string;
    status: number;
    updatedAt: Date;
    service?: string;
    serviceTitle?: string;
    wpProductTitle?: string;
    wpPlanTitle?: string;
    wpPlanPrice?: number;
    isPaid: boolean;
    appointmentTimeUser?: any;
    appointmentTime?: Date;
    applicationId?: string;
    doctor?: { fullName?: string };
    state?: { id?: number; code?: string; name?: string; timezone?: string };
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    isTest: boolean;
    paymentType: number;
    blacklisted: boolean;
    insuranceId?: number;
    leadInsurance?: IInsuranceSmall;
    coPayAmount?: number;
}

export interface LeadListParams {
    filters: ListFilter[];
    page: number;
    limit: number;
    sort?: ListSortParams;
}

export interface LeadListResponse {
    items: ICrmLead[];
    total: number;
    totalAmount: number;
}

export interface LeadGetResponse {
    lead: ICrmLead;
    leadInfo: any;
}

export interface ILeadCreateParams {
    serviceId: number;
    websiteId: number;
    servicePlanId: number;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    gender: string;
    zip: string;
    dateOfBirth?: string;
    reason?: string;
    comment?: string;
    linkToLead?: string;
}

export interface ILeadCreateResponse {
    lead: ICrmLead;
    clientId: number;
}

export interface ILeadCloseParams {
    leadId: number;
    closeComment?: string;
}

export interface ILeadCloseResponse {
    ok?: boolean;
}

export interface ILeadRefundParams {
    leadId: number;
    comment?: string;
}

export interface ILeadRefundResponse {
    ok?: boolean;
}

export interface IApplication {
    id: number;
    data: any;
    formattedData: IFormattedData[];
    isHasClient: boolean;
    uuid: string;
    filledAt?: Date;
    intakeCompleted: boolean;
    isReturning: boolean;
    leadId: number;
    initialLeadId: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface IFormattedData {
    header: string;
    code?: string;
    fields: IFormattedDataField[];
}

export interface IFormattedDataField {
    label: string;
    value: string | string[] | null;
}

export interface ILeadDetailsEditParams {
    field: string;
    value: string;
}

export interface ILeadSendLinkParams {
    leadId: number;
}

export interface ICheckPromoCodeParams {
    promoCode: string;
    zip: string;
    websiteId: number;
    serviceId: number;
}

export interface ICheckPromoCodeResponse {
    data: IPromoCode;
    status: string;
}

export interface IEligibilityResultsParams {
    leadId: number;
    result: number;
    coPayAmount?: number;
    fileName?: string;
    filePath?: string;
    uploadedFileName?: string;
}

export interface IEligibilityResult {
    id: number;
    leadId: number;
    result: number;
    coPayAmount: number | null;
    fileName: string | null;
    filePath: string | null;
    createdBy: ILead;
    updatedAt: string;
    createdAt: string;
}

export interface IEligibilityResultFileResponse {
    uploadedFileName: string;
    filePath: string;
    fileName: string;
}

export interface IChildLeadSmall {
    id: number;
    clientAppointmentTime: string;
    parentId: number;
}

export interface IChildLeadCreateResponse {
    lead: IChildLeadSmall;
}
