import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import { ILeadEventListParams, ILeadEventListResponse } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiLeadEventService {
    constructor(private http: HttpService) {}

    async list(params: ILeadEventListParams): Promise<ILeadEventListResponse> {
        return this.http.post<ILeadEventListResponse>(`lead-event/list`, params);
    }
}
