import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IDoctor, IUser, UserRole } from 'medvidi';
import * as moment from 'moment';
import { ITime } from '../appointment-confirmation.component';

@Component({
    selector: 'app-doctor-schedule',
    templateUrl: './doctor-schedule.component.html',
    styleUrls: ['./doctor-schedule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoctorScheduleComponent {
    @Input() currentUser: IUser;
    @Input() doctor!: IDoctor;
    @Input() selectedDoctorId?: number;
    @Input() schedule!: string[];
    @Input() displayedDate!: Date;
    @Input() selectedDate?: Date;
    @Input() confirmedTime?: string;
    @Input() selectedTime?: ITime;
    @Input() momentTimezone!: string;
    @Input() isConfirmedDateOpened: boolean = false;

    @Output() selectTime = new EventEmitter<{ doctorId: number; time: ITime }>();

    public isNewTimeSelected: boolean = false;

    public get isPersonalAssistanceDoctor(): boolean {
        return (
            this.currentUser.role === UserRole.MANAGER &&
            this.doctor.managerIds?.includes(this.currentUser?.managerId)
        );
    }

    public get shownTime(): string {
        if (this.isCurrentDoctorSelected && !this.isNewTimeSelected && this.isConfirmedDateOpened) {
            return moment(this.confirmedTime).tz(this.momentTimezone).toISOString();
        }

        if (this.isCurrentDoctorSelected && this.isNewTimeSelected) {
            return moment(this.selectedDate)
                .tz(this.momentTimezone)
                .set(this.selectedTime)
                .toISOString();
        }

        return '';
    }

    public get isCurrentDoctorSelected(): boolean {
        return this.selectedDoctorId === this.doctor.id;
    }

    public handleTimeChange(time: string): void {
        const timeMoment = moment(time).tz(this.momentTimezone);
        this.selectTime.emit({
            doctorId: this.doctor.id,
            time: { hours: timeMoment.hours(), minutes: timeMoment.minutes() },
        });

        this.isNewTimeSelected = true;
    }
}
