interface FormError {
    code: string;
    text: string;
}

export enum FormGroupErrorCodeTypes {
    AUTH_INCORRECT_CREDS = 'auth_incorrect_credentials',
    AUTH_ACCOUNT_DISABLED = 'auth_account_disabled',
    INVALID_DATE_RANGE = 'invalidDateRange',
}

export const formFieldErrors: FormError[] = [
    { code: 'required', text: 'Field is required' },
    { code: 'email', text: 'Please enter valid email address' },
    { code: 'not_unique', text: 'This value is already taken' },
    { code: 'minlength', text: 'Minimum length: {X} symbols' },
    { code: 'invalidZipcode', text: 'Zipcode is invalid' },
    { code: 'invalidLanguage', text: 'Invalid symbols are used' },
    { code: 'invalidPhoneNumber', text: 'Phone number is invalid' },
    { code: 'invalidDateFormat', text: 'Unrecognized date format' },
    { code: 'invalidMinDate', text: 'Invalid minimum date value' },
    { code: 'maxlength', text: 'Maximum length: {X} symbols' },
    { code: 'match', text: 'The entered value does not match any of the possible parameters' },
    { code: 'max', text: 'Maximum value is {X}' },
    { code: 'min', text: 'Minimum value is {X}' },
    { code: 'zipCode', text: 'ZIP invalid. Correct format 00000 or 00000-0000.' },
    { code: 'incorrectState', text: "The selected state does not match the patient's state" },
    { code: 'invalidOtpCode', text: 'OTP code is incorrect' },
    { code: 'invalidOtpCodeFormat', text: 'Invalid OTP code. Correct format 000000' },
];

export const formGroupErrors: FormError[] = [
    { code: FormGroupErrorCodeTypes.AUTH_INCORRECT_CREDS, text: 'Invalid email or password' },
    { code: FormGroupErrorCodeTypes.AUTH_ACCOUNT_DISABLED, text: 'Your account is disabled' },
    { code: FormGroupErrorCodeTypes.INVALID_DATE_RANGE, text: 'Invalid date range' },
];
