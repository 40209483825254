import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import { CallListParams, CallListResponse, CallClientTokensResponse } from '../interfaces';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiCallService {
    constructor(private http: HttpService) {}

    async list(params: CallListParams): Promise<CallListResponse> {
        return this.http.post<CallListResponse>(`call/list`, params);
    }

    async getClientTokens(): Promise<CallClientTokensResponse> {
        return this.http.get<CallClientTokensResponse>(`call/client-tokens`);
    }

    public twilioCallContinue(contactUri: string): Observable<{}> {
        return from(
            this.http.post('twilio-call/continue', {
                contactUri,
            })
        );
    }
}
