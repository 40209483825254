import { TaskPriority } from 'medvidi';

export const TASK_PRIORITY_OPTIONS = [
    { value: TaskPriority.CRITICAL, text: 'Critical' },
    { value: TaskPriority.HIGHEST, text: 'Highest' },
    { value: TaskPriority.HIGH, text: 'High' },
    { value: TaskPriority.MEDIUM, text: 'Medium' },
    { value: TaskPriority.LOW, text: 'Low' },
    { value: TaskPriority.LOWEST, text: 'Lowest' },
];

export const TASK_PRIORITY_ICON_PATHS = {
    [TaskPriority.LOWEST]: './assets/img/svg/priority/lowest.svg',
    [TaskPriority.LOW]: './assets/img/svg/priority/low.svg',
    [TaskPriority.MEDIUM]: './assets/img/svg/priority/medium.svg',
    [TaskPriority.HIGH]: './assets/img/svg/priority/high.svg',
    [TaskPriority.HIGHEST]: './assets/img/svg/priority/highest.svg',
    [TaskPriority.CRITICAL]: './assets/img/svg/priority/critical.svg',
};
