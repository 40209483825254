import { IFormattedData } from '../../med-api/interfaces';
import { IUploadedPassportInterface } from '@shared/interfaces';

export interface IIntakeDialogData {
    apiUrl: string;
    applicationData: IFormattedData[] | any;
    isHasClient: boolean;
    passportData: IUploadedPassportInterface;
}

export enum IntakeFieldValue {
    TEXT = 'text',
    IMAGE = 'image',
    LINK = 'link',
}

export enum IntakeStepCode {
    ID_UPLOAD = 'idUpload',
    GENERAL_INFO = 'generalInfo',
    CONDITIONS = 'conditions',
    SELF_REPORT = 'selfReport',
    SUBSTANCES = 'substances',
    ACKNOWLEDGMENT = 'acknowledgment',
    MEDICAL_RECORDS = 'medicalRecords',
    INSURANCE_INFO = 'insuranceInfo'
}
