<div class="content">
    <div *ngFor="let section of dashboardContent">
        <div *ngIf="canDisplaySection(section)" class="section-title">{{ section.label }}</div>
        <div *ngFor="let group of section.children">
            <p *ngIf="canDisplayMenuItem(group)" class="group-title">
                <a [class.isDisabled]="group.disabled" routerLink="{{ group.path }}">{{
                    group.label
                }}</a>
            </p>
        </div>
    </div>
</div>
