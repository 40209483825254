import { FormField } from './form-field';
import { FormSelectField } from './fields/select';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { FormMultiInputField } from './fields/multi-input';
import { FormMultiInputAutoField } from './fields/multi-input-auto';

export class Form {
    private fields: Map<string, FormField>;
    private submitButton: SubmitButton;
    private formGroup: FormGroup;
    private handlers: Map<FormEvent, Function>;
    private hasFormError: boolean;
    private formGroupValidators: ValidatorFn[];

    constructor() {
        this.fields = new Map<string, FormField>();
        this.handlers = new Map<FormEvent, Function>();
        this.hasFormError = false;
    }

    public addField(key: string, field: FormField) {
        this.fields.set(key, field);
    }

    public setSubmitButton(label: string) {
        this.submitButton = {
            label,
        };
    }

    public getSubmitButton(): SubmitButton {
        return this.submitButton;
    }

    public setHandler(key: FormEvent, func: Function) {
        this.handlers.set(key, func);
    }

    public getFormGroup(): FormGroup {
        return this.formGroup;
    }

    public setFormGroupValidators(validators: ValidatorFn[]): void {
        this.formGroupValidators = validators;
    }

    public buildFormGroup() {
        if (this.formGroup) return;
        this.formGroup = new FormGroup({}, this.formGroupValidators || null);
        for (const [controlName, field] of this.fields) {
            this.formGroup.addControl(
                controlName,
                new FormControl({ value: field.value, disabled: field.disabled }, field.validators)
            );
        }

        if (this.handlers.has(FormEvent.CHANGE)) {
            this.formGroup.valueChanges.subscribe((val: any) =>
                this.handlers.get(FormEvent.CHANGE)(val)
            );
        }
    }

    private decorateFormGroupValue(): any {
        const value = this.formGroup.value;

        for (const [controlName, field] of this.fields) {
            if (field instanceof FormMultiInputField || field instanceof FormMultiInputAutoField) {
                value[controlName] = field.strings;
            }
        }

        return value;
    }

    public async submit() {
        try {
            if (this.handlers.has(FormEvent.SUBMIT)) {
                await this.handlers.get(FormEvent.SUBMIT)(this.decorateFormGroupValue());
            }
            if (this.handlers.has(FormEvent.SUCCESS)) {
                await this.handlers.get(FormEvent.SUCCESS)();
            }
        } catch (err) {
            this.handleError(err.errors);
            if (this.handlers.has(FormEvent.ERROR)) {
                await this.handlers.get(FormEvent.ERROR)(err.errors);
            }
        }
    }

    private handleError(errors: any) {
        for (const err of errors) {
            if (err.field && this.formGroup.get(err.field)) {
                const newError = {};
                newError[err.code] = true;
                this.formGroup.get(err.field).setErrors(newError);
            }
        }
    }

    public getFields(): (FormField | FormSelectField)[] {
        return [...this.fields.values()];
    }

    public hasGeneralError(): boolean {
        return this.hasFormError;
    }

    public setGeneralError(hasError: boolean) {
        this.hasFormError = hasError;
    }
}

interface SubmitButton {
    label: string;
}

export enum FormEvent {
    SUBMIT,
    SUCCESS,
    CHANGE,
    ERROR,
}
