import { Injectable } from '@angular/core';

import { RefObjectClass } from '../../med-api/interfaces';
import { LeadTabList } from '../../med-common/interfaces';

@Injectable({
    providedIn: 'root',
})
export class LeadService {
    meta = [
        { class: RefObjectClass.FACEBOOK_MESSAGE, query: 'facebookId' },
        { class: RefObjectClass.SMS, query: 'phone', tab: LeadTabList.PHONE },
        { class: RefObjectClass.CALL, query: 'phone', tab: LeadTabList.PHONE },
        { class: RefObjectClass.EMAIL_MESSAGE, query: 'email', tab: LeadTabList.EMAIL },
        { class: RefObjectClass.LEAD, tab: LeadTabList.ACTIVITY },
        { class: RefObjectClass.APPOINTMENT, tab: LeadTabList.APPOINTMENTS },
    ];

    constructor() {}

    public getRelatedLinkData(
        refObjectClass: RefObjectClass,
        leadId?: number,
        leadCode?: string
    ): { link: string; queryParams: object } {
        const meta = this.getMeta(refObjectClass);

        if (!meta) {
            return null;
        }

        if (leadId) {
            return { link: `/lead/view/${leadId}`, queryParams: { tab: meta.tab } };
        }

        if (!leadCode) {
            return null;
        }

        if (!meta.query) {
            return null;
        }

        return { link: `/lead/new`, queryParams: { [meta.query]: leadCode } };
    }

    private getMeta(refObjectClass: RefObjectClass): {
        class: string;
        tab?: string;
        query?: string;
    } {
        return this.meta.find((x) => x.class === refObjectClass);
    }
}
