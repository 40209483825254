import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'med-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
