import { Injectable } from '@angular/core';

const storageDefault = {
    user: null,
    watchUser: null,
    menuLabel: null,
    additionalMenuOpen: false,
    additionalMenuLabel: null,
    additionalMenu: [],
};

@Injectable({ providedIn: 'root' })
export class AppStorageService {
    private state = storageDefault;

    getAdditionalMenuOpen() {
        return this.state.additionalMenuOpen;
    }
}
