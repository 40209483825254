<div class="container">
    <div class="header">
        <h3 class="header__text">Please select the request resolution</h3>
    </div>
    <div class="body">
        <div class="control">
            <app-select
                [field]="'Request'"
                [groups]="requestReasons"
                [placeholder]="'Choose the reason for the request'"
                [value]="getFormControl('request').value"
                (handleChange)="setValue('request', $event)"
            ></app-select>
        </div>
        <div class="control">
            <app-select
                [field]="'Sub-Request'"
                [disabled]="!getFormControl('request').value"
                [groups]="subRequestReasons"
                [placeholder]="'Choose the reason for the request'"
                [value]="getFormControl('subRequest').value"
                (handleChange)="setValue('subRequest', $event)"
                class="sub-request"
            ></app-select>
        </div>
        <div class="control">
            <app-select
                [field]="'Result'"
                [groups]="reasons"
                [placeholder]="'Choose the result of the request'"
                [value]="getFormControl('result').value"
                (handleChange)="setValue('result', $event)"
                class="result-select"
            ></app-select>
        </div>
    </div>
    <div class="footer">
        <med-button
            (click)="submit()"
            [disabled]="formGroup.invalid"
            [text]="'Confirm'"
            [width]="100"
            class="secondary"
        ></med-button>
    </div>

    <div *ngIf="!loaded" class="loader">
        <mat-spinner [diameter]="50" color="primary"></mat-spinner>
    </div>
</div>
