import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'medvidi';

@Component({
    selector: 'app-master-login',
    templateUrl: './master-login.component.html',
    styleUrls: ['./master-login.component.scss'],
})
export class MasterLoginComponent implements OnInit {
    constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.router.navigate(['/dashboard']);
    }
}
