import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

export interface IOption {
    value: number | string;
    text: any;
    iconPath?: string;
    allowedRoles?: string[];
}

export interface IGroups {
    name?: string;
    options: IOption[];
}

export enum EListPosition {
    ABOVE = 'above',
    BELOW = 'below',
}

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
    @Input() public field: string = 'Field';
    @Input() public placeholder: string = 'Choose...';
    @Input() public multi: boolean = false;
    @Input() public groups: IGroups[] = [];
    @Input() public fieldFullWidth?: boolean = false;
    @Input() public disabled?: boolean = false;
    @Input() public listPosition: EListPosition = EListPosition.BELOW;
    public selected: IOption[] = [];
    public showOptions: boolean = false;
    @Input() private value?: number | string | Array<number | string>;
    @Output() private handleChange = new EventEmitter();
    private active: boolean = false;

    ngOnInit(): void {
        this.initSelected(this.value);
        document.addEventListener('click', () => {
            if (!this.active) {
                this.showOptions = false;
            }
        });
    }

    ngOnChanges(changes): void {
        if (changes.value) {
            this.value = changes.value.currentValue;
            this.selected = [];
            this.initSelected(this.value);
        }

        if (changes.groups) {
            this.initSelected(this.value);
        }
    }

    public onMouseenter(): void {
        this.active = true;
    }

    public onMouseLeave(): void {
        this.active = false;
    }

    public isSelectOption(option: IOption): boolean {
        return !!this.selected.find((op) => op.value === option.value);
    }

    public choiseOption(option: IOption): void {
        if (!this.multi) {
            this.selected = [option];
            this.showOptions = false;
        } else {
            const index = this.selected.findIndex((op) => op.value === option.value);
            if (index < 0) {
                this.selected.push(option);
            } else {
                this.selected.splice(index, 1);
            }
        }

        this.changeSelected();
    }

    private initSelected(value: number | string | Array<number | string>): void {
        const checkValueAndAddSelectedOption = (value: number | string) => {
            for (let group of this.groups) {
                for (let option of group.options) {
                    if (option.value === value) {
                        this.selected.push(option);
                        return;
                    }
                }
            }
        };

        if (Array.isArray(value)) {
            for (let v of value) {
                checkValueAndAddSelectedOption(v);
            }
        } else {
            checkValueAndAddSelectedOption(value);
        }
    }

    private changeSelected(): void {
        if (!this.multi) {
            this.handleChange.emit(this.selected[0]);
        } else {
            this.handleChange.emit(this.selected);
        }
    }
}
