import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DateTime } from 'luxon';
import { IMedicalRecord } from '../../../med-api/interfaces';

interface IRecord {
    luxonTime: DateTime;
    text: string[];
    providerFullName: string;
}

interface IDialogData {
    records: IMedicalRecord[];
}

@Component({
    selector: 'med-medical-records-tab',
    templateUrl: './medical-records.component.html',
    styleUrls: ['./medical-records.component.css'],
})
export class MedicalRecordsComponent implements OnInit {
    public dates: Map<string, IRecord[]> = new Map();
    private records: IMedicalRecord[] = [];

    constructor(
        private dialogRef: MatDialogRef<MedicalRecordsComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IDialogData
    ) {}

    public ngOnInit(): void {
        this.parseRecords();
    }

    private parseRecords(): void {
        const editableDates: Map<string, IRecord[]> = new Map();

        for (const record of this.data.records) {
            const key = DateTime.fromISO(record.createdAt.toLocaleString()).toFormat('MM/dd/y');
            const recordsDate = editableDates.get(key) || this.dates.get(key) || [];

            if (!this.records.find((item) => item.id === record.id)) {
                const blocks = record.note?.split('\n');

                recordsDate.push({
                    luxonTime: DateTime.fromISO(record.createdAt.toLocaleString()),
                    text: blocks,
                    providerFullName: record.user.fullName,
                });
                editableDates.set(key, recordsDate);
                this.records.push(record);
            }
        }

        for (const [key, records] of editableDates) {
            records.sort((a, b) => {
                if (a.luxonTime < b.luxonTime) {
                    return -1;
                }

                return 1;
            });

            this.dates.set(key, records);
        }
    }

    public closeDialog() {
        this.dialogRef.close();
    }
}
