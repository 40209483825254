<div class="calendar-container">
    <mat-calendar
        #calendar
        (selectedChange)="handleDateChange($event)"
        [selected]="selectedDate"
        [dateFilter]="disablePrevDates"
        data-test-id="Change Appointment Calendar"
    ></mat-calendar>
    <div *ngIf="isTimeRequested" class="appointment-client-time">
        <span>
            Requested time by client:
            <span class="time">
                {{ clientRequestedTime | momentPipe: momentTimezone:'L' }}
                <span class="icon">&#8226;</span>
                {{ clientRequestedTime | momentPipe: momentTimezone:'LT' }}
            </span>
        </span>
    </div>
    <div
        *ngIf="isTimeConfirmed"
        class="appointment-confirmed-time"
        data-test-id="Appointment Details"
    >
        <span>
            Confirmed with {{ appointment.doctorName }} on
            <span class="time">
                {{ confirmedTime | momentPipe: momentTimezone:'L' }}
                <span class="icon">&#8226;</span>
                {{ confirmedTime | momentPipe: momentTimezone:'LT' }}
            </span>
            <div>{{ lead.service?.name }}</div>
        </span>
    </div>
</div>
