import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-close-button',
    templateUrl: './close-button.component.html',
    styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent {
    @Output() handleClick = new EventEmitter();

    public handleButtonClick() {
        this.handleClick.emit();
    }
}
