import { ListFilter, ListResponse } from '../../med-list/interfaces';

export enum EFinancialReportTypes {
    REVENUE = 'revenue',
    REFUNDS = 'refunds',
}

export interface IRevenue {
    leadId: number;
    date: string;
    price: number;
    originalPrice: number;
    serviceName: string;
    quantity: number;
    doctorName: string;
    state: string;
    clientId: number;
    isInitial: boolean;
    transactionId: string;
    feeAmount: number;
}

export interface IRefund {
    refundDate: string;
    sum: number;
    quantity: number;
    reason: string;
    date: string;
    price: number;
    serviceName: string;
    doctorName: string;
    isInitial: boolean;
    state: string;
    clientId: number;
    isReturning: boolean;
    transactionId: string;
}

export interface IFinancialReportParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
}

export interface IFinancialReportResponse<T> extends ListResponse {
    items: T[];
    total: number;
}

export type TRevenueResponse = IFinancialReportResponse<IRevenue>;
export type TRefundsResponse = IFinancialReportResponse<IRefund>;
