<div [formGroup]="formGroup" class="form-field">
    <mat-checkbox
        formControlName="{{ field.controlName }}"
        [color]="'primary'"
        [disableRipple]="true"
        >{{ field.label }}</mat-checkbox
    >
    <mat-error *ngIf="formGroup.get(field.controlName)?.invalid">{{
        errorTextService.getMessage(formGroup.get(field.controlName))
    }}</mat-error>
</div>
