import { ILead, IServiceAccount, IUser } from '../../med-api/interfaces';

export interface IManagerMessage {
    feature: string;
    id: number;
    from: string;
    to: string;
    text: string;
    fromDesc?: string;
    toDesc?: string;
    createdAt: Date;
    updatedAt: Date;
    isIncoming: boolean;
    leadInfo?: {
        client?: {
            fullName?: string;
        };
    };
    leadId?: number;
    lead?: ILead;
    userId?: number;
    user?: IUser;
    subject: string;
    html: string;
    status: string;
    direction: string;
    serviceAccountId: number;
    serviceAccount: IServiceAccount;
    attachments: {
        path: string;
        name: string;
        type: string;
    }[];
    serviceReservedForCustomerIo: boolean;
}

export interface IGetManagerEmailMessagesParams {
    limit?: number;
    page?: number;
    filters: {
        email: string;
    };
}

export interface IGetManagerFacebookMessagesParams {
    limit?: number;
    page?: number;
    filters: {
        facebookId: string;
    };
}

export interface IGetManagerMessagesParams {
    limit?: number;
    page?: number;
    filters?: {
        id?: number;
        phone?: string;
        facebookId?: string;
        email?: string;
        leadId?: number;
    };
}

export interface IEmailMessagesList {
    messages: IManagerMessage[];
    total: number;
}

export interface IFacebookMessagesList {
    messages: IManagerMessage[];
    total: number;
}

export interface IManagerMessageCreateParams {
    from: string;
    to: string;
    toLeadId?: number;
    toClientId?: number;
    subject?: string;
    text: string;
    html?: string;
    files?: any[];
    prodMode?: boolean;
}

export interface IManagerEmailMessageCreateParams {
    from: string;
    to: string;
    toLeadId?: number;
    subject?: string;
    text: string;
    html?: string;
    files?: any[];
    settings: {
        prodMode: boolean;
    };
}

export enum IManagerMessageDirection {
    INBOUND = 'inbound',
    OUTBOUND = 'outbound',
}

export enum PhoneChatRecordType {
    MESSAGE = 'message',
    CALL = 'call',
}

export interface IManagerPhoneMessagesResponse {
    records: IManagerPhoneChatMessage[];
}

export interface IManagerSupportMessagesResponse {
    records: IManagerMessage[];
}

export interface IManagerPhoneChatMessage {
    type: string;
    id: number;
    leadCode: string;
    leadId?: number;
    leadFirstName?: string;
    leadLastName?: string;
    userId?: number;
    userFirstName?: string;
    userLastName?: string;
    direction: string;
    serviceAccountId: number;
    subject?: string;
    text?: string;
    isAutoMessage?: boolean;
    recordUrl?: string;
    duration?: number;
    isMissedCall?: boolean;
    startTime?: Date;
    createdAt: Date;
    isIncoming?: boolean;
    serviceReservedForCustomerIo: boolean;
}
