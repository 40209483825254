import { List } from './list';
import { ListColumn } from './list-column';
import { ListColumnParams } from '../interfaces';
import { TemplateRef } from '@angular/core';

export class ListBuilder {
    private list: List;

    constructor() {
        this.list = new List();
    }

    addColumn(columnName: string, columnParams: ListColumnParams) {
        this.list.addColumn(columnName, new ListColumn({ ...columnParams, ...{ columnName } }));
    }

    setDataProvider(func: Function) {
        this.list.setDataProvider(func);
    }

    getList(): List {
        return this.list;
    }

    setCellTemplate(ref: TemplateRef<any>) {
        this.list.setCellTemplate(ref);
    }
}
