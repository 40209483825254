import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AppStateService, AuthService, TaskService } from 'medvidi';
import { AppStorageService } from '../../services/app-storage.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-crm-page-template',
    templateUrl: './crm-page-template.component.html',
    styleUrls: ['./crm-page-template.component.scss'],
})
export class CrmPageTemplateComponent implements OnInit, OnDestroy {
    @Input() route: ActivatedRoute;
    @Input() fullHeight = false;
    @Input() displayTasksComponent = false;

    private destroy$ = new Subject<void>();

    constructor(
        public authService: AuthService,
        public appStorageService: AppStorageService,
        public appStateService: AppStateService,
        public taskService: TaskService,
        private router: Router,
        public activatedRoute: ActivatedRoute
    ) {}

    ngOnInit() {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let route = this.router.routerState.root;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                this.activatedRoute = route;
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
