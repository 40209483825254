import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ElementsModule } from '../elements/elements.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PageForbiddenComponent } from './page-forbidden/page-forbidden.component';
import { SomethingBrokenComponent } from './something-broken/something-broken.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
    declarations: [
        PageNotFoundComponent,
        PageForbiddenComponent,
        SomethingBrokenComponent,
        SessionExpiredComponent,
    ],
    imports: [CommonModule, RouterModule, ElementsModule, MatButtonModule],
    exports: [
        PageNotFoundComponent,
        PageForbiddenComponent,
        SomethingBrokenComponent,
        SessionExpiredComponent,
    ],
})
export class ErrorsModule {}
