import { Injectable } from '@angular/core';
import { ListFilter } from '@shared/lib/med-list/interfaces';
import { DateTime } from 'luxon';
import { AppFileService, HttpService } from '../../med-common/med-common.module';
import {
    EFinancialReportTypes,
    IFinancialReportParams,
    TRefundsResponse,
    TRevenueResponse,
} from '../interfaces/financial-report';

@Injectable({
    providedIn: 'root',
})
export class ApiFinancialReportService {
    constructor(private http: HttpService, private appFileService: AppFileService) {}

    public async revenue(params: IFinancialReportParams): Promise<TRevenueResponse> {
        return this.http.post<TRevenueResponse>('crm/financial-report/revenue', params);
    }

    public async refunds(params: IFinancialReportParams): Promise<TRefundsResponse> {
        return this.http.post<TRefundsResponse>('crm/financial-report/refund', params);
    }

    public async download(type: EFinancialReportTypes, filters: ListFilter[]): Promise<void> {
        const urls = {
            [EFinancialReportTypes.REVENUE]: 'crm/financial-report/revenue/download',
            [EFinancialReportTypes.REFUNDS]: 'crm/financial-report/refund/download',
        };

        const data = await this.http.post<any>(urls[type], { filters }, null, {
            responseType: 'arraybuffer',
        });

        return this.appFileService.downloadFileFromBlob(
            new Blob([data]),
            `${type}_${DateTime.now().toFormat('yyyy_LLL_dd')}.csv`
        );
    }
}
