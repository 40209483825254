import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class MasterAuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const userId = next.queryParams.userId;
        const sessionId = next.queryParams.sessionId;
        return this.performMasterAuth(userId, sessionId);
    }

    async performMasterAuth(userId: string, sessionId: string): Promise<boolean> {
        try {
            await this.authService.masterLogin(userId, sessionId);
            if (this.authService.user.isPasswordExpired) {
                this.router.navigate(['change-password']);
            }
            return true;
        } catch (error) {
            return false;
        }
    }
}
