import { ListFilter, ListResponse, ListSortParams } from '../../med-list/interfaces';
import { IDoctorSmallFormat, IState } from '../interfaces';

export interface IInsuranceSmall {
    id: number;
    name: string;
}

export interface IInsurance {
    id: number;
    name: string;
    states?: IState[];
    doctors?: IDoctorSmallFormat[];
    isEnabled: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface IInsuranceListParams {
    page: number;
    limit: number;
    filters?: ListFilter[];
    sort?: ListSortParams;
}

export interface IInsuranceListResponse extends ListResponse {
    items: IInsurance[];
}

export interface IInsuranceCreateParams {
    name: string;
    states: number[];
    doctors: number[];
    isEnabled: boolean;
}

export interface IInsuranceEditParams {
    name?: string;
    states?: number[];
    doctors?: number[];
    isEnabled?: boolean;
}

export interface IResponsibleParty {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: number;
    state: number;
    city: string;
    address: string;
}

export interface ILeadInsurance {
    id: number;
    leadId: number;
    insuranceId?: number;
    insurance?: IInsuranceSmall;
    insuranceNumber?: string;
    ssn?: string;
    address1?: string;
    address2?: string;
    city?: string;
    language?: string;
    frontOfCardUrl?: string;
    backOfCardUrl?: string;
    available: boolean;
    responsibleParty?: number;
    responsiblePartyData?: IResponsibleParty;
}

export enum ResponsibleParties {
    MYSELF,
    SPOUSE,
    GRANDMOTHER_OR_GRANDFATHER,
    GRANDSON_OR_GRANDDAUGHTER,
    NEPHEW_OR_NIECE,
    ADOPTED_CHILD,
    FOSTER_CHILD,
    WARD,
    STEPSON,
    CHILD,
    EMPLOYEE,
    UNKNOWN,
    HANDICAPPED_DEPENDENT,
    SPONSORED_DEPENDENT,
    DEPENDENT_OF_A_MINOT_DEPENDED,
    SIGNIFICANT_OTHER,
    MOTHER,
    FATHER,
    OTHER_ADULT,
    EMANCIPATED_MINOR,
    ORGAN_DONOR,
    CADAVER_DONOR,
    INJURED_PLAINTIFF,
    CHILD_WHERE_INSURED_HAS_NOT_FINANCIAL_RESPONSIBILITY,
    LIFE_PARTNER,
    OTHER_RELATIONSHIP,
}

export const ResponsiblePartiesLiterals = {
    [ResponsibleParties.MYSELF]: 'Myself',
    [ResponsibleParties.SPOUSE]: 'Spouse',
    [ResponsibleParties.GRANDMOTHER_OR_GRANDFATHER]: 'Grandmother or Grandfather',
    [ResponsibleParties.GRANDSON_OR_GRANDDAUGHTER]: 'Grandson or Granddaughter',
    [ResponsibleParties.NEPHEW_OR_NIECE]: 'Nephew or Niece',
    [ResponsibleParties.ADOPTED_CHILD]: 'Adopted Child',
    [ResponsibleParties.FOSTER_CHILD]: 'Foster Child',
    [ResponsibleParties.WARD]: 'Ward',
    [ResponsibleParties.STEPSON]: 'Stepson',
    [ResponsibleParties.CHILD]: 'Child',
    [ResponsibleParties.EMPLOYEE]: 'Employee',
    [ResponsibleParties.UNKNOWN]: 'Unknown',
    [ResponsibleParties.HANDICAPPED_DEPENDENT]: 'Handicapped Dependent',
    [ResponsibleParties.SPONSORED_DEPENDENT]: 'Sponsored Dependent',
    [ResponsibleParties.DEPENDENT_OF_A_MINOT_DEPENDED]: 'Dependent of a minor depended',
    [ResponsibleParties.SIGNIFICANT_OTHER]: 'Significant other',
    [ResponsibleParties.MOTHER]: 'Mother',
    [ResponsibleParties.FATHER]: 'Father',
    [ResponsibleParties.OTHER_ADULT]: 'Other adult',
    [ResponsibleParties.EMANCIPATED_MINOR]: 'Emancipated minor',
    [ResponsibleParties.ORGAN_DONOR]: 'Organ donor',
    [ResponsibleParties.CADAVER_DONOR]: 'Cadaver donor',
    [ResponsibleParties.INJURED_PLAINTIFF]: 'Injured plaintiff',
    [ResponsibleParties.CHILD_WHERE_INSURED_HAS_NOT_FINANCIAL_RESPONSIBILITY]:
        'Child where insured has no financial responsibility',
    [ResponsibleParties.LIFE_PARTNER]: 'Life partner',
    [ResponsibleParties.OTHER_RELATIONSHIP]: 'Other relationship',
};

export const NO_INSURANCE_ID = -1;
