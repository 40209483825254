import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/services/http.service';
import {
    ICrmAppointmentGetResponse,
    ICrmAppointmentGetByLeadIdResponse,
    ICrmAppointmentConfirmAppointmentParams,
    IAppointmentHistoryResponse,
    IReservedAppointment,
} from '../interfaces';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiCrmAppointmentService {
    constructor(private http: HttpService) {}

    async get(id: number): Promise<ICrmAppointmentGetResponse> {
        return this.http.get<ICrmAppointmentGetResponse>(`crm/appointment/${id}`);
    }

    async getByLeadId(leadId: number): Promise<ICrmAppointmentGetByLeadIdResponse> {
        return this.http.get<ICrmAppointmentGetByLeadIdResponse>(`crm/appointment/lead/${leadId}`);
    }

    async getAppointmentsHistory(leadId: number): Promise<IAppointmentHistoryResponse> {
        return this.http.get<IAppointmentHistoryResponse>(`crm/appointment/history/${leadId}`);
    }

    async confirmAppointment(params: ICrmAppointmentConfirmAppointmentParams): Promise<any> {
        return this.http.post(`crm/appointment/confirm`, params);
    }

    public getReservedAppointmentById(
        id: number
    ): Observable<{ reservedAppointment: IReservedAppointment }> {
        return from(
            this.http.get<{ reservedAppointment: IReservedAppointment }>(
                `crm/reserved-appointment/${id}`
            )
        );
    }
}
