<app-middle-container>
    <div class="error-container">
        <h1 class="error-title">Oops.. Something went wrong</h1>
        <div class="error-code">500</div>
        <div class="error-message">
            Looks like we have an internal issue, please try again in couple minutes.
        </div>
        <a class="back-link" href="/">Reload</a>
    </div>
</app-middle-container>
