import { Injectable } from '@angular/core';
import { HttpService } from 'medvidi';
import { from, Observable } from 'rxjs';

@Injectable()
export class RequestReasonService {
    constructor(private httpService: HttpService) {}

    public createEmptyCallResult(data: EmptyCallResult): Observable<{}> {
        return from(this.httpService.post('call/create-empty-call-result', data));
    }

    public sendRequestReason(data: CallResult): Observable<{}> {
        return from(this.httpService.post('call/save-call-result', data));
    }
}

export interface CallResult {
    callSid: string;
    request: string;
    subRequest: string;
    result: string;
    phone: string;
}

export interface EmptyCallResult {
    callSid: string;
    phone: string;
}
