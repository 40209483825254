export enum NotificationMessageTypes {
    NEW_MESSAGE = 'new_message',
    UPCOMING_APPOINTMENT = 'upcoming_appointment',
    STARTED_APPOINTMENT = 'started_appointment',
    APPOINTMENTS_COUNT_UPDATE = 'appointments_count_update',
    PRESCRIPTIONS_NOTIFICATIONS_DATA_UPDATE = 'prescriptions_notifications_data_update',
    DOCTOR_ASSISTANT_STATUS_UPDATE = 'doctor_assistant_status_update',
}

export interface INotificationsSubscriptionParams {
    doctorId: number;
}
