import { EScrollShadowPositions } from './scroll-shadow.enum';

export const LINEAR_GRADIENT_STYLES = {
    [EScrollShadowPositions.TOP]: 'linear-gradient(0deg, #000 calc(100% - 40px), transparent)',
    [EScrollShadowPositions.BOTTOM]: 'linear-gradient(180deg, #000 calc(100% - 40px), transparent)',
    [EScrollShadowPositions.TOP_AND_BOTTOM]: `linear-gradient(
        #000,
        #000,
        transparent 0,
        #000 40px,
        #000 calc(100% - 40px),
        transparent
    )`,
};
