import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconButtonComponent } from './icon-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    declarations: [IconButtonComponent],
    imports: [CommonModule, MatIconModule, MatTooltipModule],
    exports: [IconButtonComponent],
})
export class IconButtonModule {}
