import { Injectable } from '@angular/core';
import { HttpService, SocketService } from '../../med-common/med-common.module';
import {
    ILetterLeadRecordsParams,
    ILetterListResponse,
    ILetterSendParams,
    ILetterSendResponse,
} from '../interfaces';
import { ISubscribeParams, SocketIoMessageType } from '../../med-chat/interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiLetterService {
    constructor(private http: HttpService, private socket: SocketService) {}

    async leadRecords(params: ILetterLeadRecordsParams): Promise<ILetterListResponse> {
        return this.http.post<ILetterListResponse>(`letter/lead-records`, params);
    }

    async send(uuid: string, params: ILetterSendParams): Promise<ILetterSendResponse> {
        return this.http.post<ILetterSendResponse>(`letter/${uuid}/send`, params);
    }

    async delete(id: number): Promise<void> {
        return this.http.delete<void>(`letter/${id}`);
    }

    subscribeOnLetterCreateEvent(params: ISubscribeParams, cb: () => void): void {
        const roomName = `Letter-${params.leadId}`;
        this.socket.joinRoom({ roomName });
        this.socket.subscribe(SocketIoMessageType.LETTER_CREATED, cb);
    }

    unsubscribeFromLetterCreateEvent(cb: () => void): void {
        this.socket.unsubscribe(SocketIoMessageType.LETTER_CREATED, cb);
    }
}
