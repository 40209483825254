import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ListComponent } from './components/list/list.component';
import { MaterialModule } from '../material.module';
import { MedCommonModule } from '../med-common/med-common.module';

@NgModule({
    declarations: [ListComponent],
    imports: [CommonModule, MaterialModule, MedCommonModule, InfiniteScrollModule],
    exports: [ListComponent],
})
export class MedListModule {}

export { ListComponent } from './components/list/list.component';
export * from './classes';
export * from './interfaces';
