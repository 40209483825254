import { ListColumnParams } from '../interfaces';

export class ListColumn {
    columnName: string;
    label: string;
    maxWidth: number;
    sortable: boolean;

    constructor(params: ListColumnParams) {
        this.columnName = params.columnName;
        this.label = params.label;
        this.maxWidth = params.maxWidth;
        this.sortable = params.sortable || false;
    }
}
