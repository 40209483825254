export enum SocketIoMessageType {
    INCOMING_SMS = 'incoming_sms',
    OUTGOING_SMS = 'outgoing_sms',
    INCOMING_FACEBOOK = 'incoming_facebook',
    OUTGOING_FACEBOOK = 'outgoing_facebook',
    OUTGOING_EMAIL = 'outgoing_email',
    INCOMING_EMAIL = 'incoming_email',
    APPOINTMENT_MESSAGE = 'appointment_message',
    SUPPORT_MESSAGE = 'support_message',
    NOTE_CREATED = 'note_created',
    LETTER_CREATED = 'letter_created',
}

export enum MessageDirection {
    INCOMING,
    OUTGOING,
}

export interface IMessage {
    id: number;
    serviceAccount: object;
    text: string;
    direction: number;
    leadId: number;
    createdAt: string;
    updatedAt: string;
}

export interface IMessageCreateParams {
    toLeadId: number;
    text: string;
    websiteId?: number;
    prodMode: boolean;
}

export interface ISubscribeParams {
    serviceAccountId?: number;
    leadId?: number;
    leadCode?: string;
    subscriptionCode?: string;
}

export enum ChatHistoryItemType {
    MESSAGE = 'message',
    OUTGOING_CALL = 'outgoing_call',
}

interface ChatHistoryCommon {
    type: ChatHistoryItemType;
    date: Date;
}

interface ChatHistoryMessage extends ChatHistoryCommon {
    text: string;
    direction: MessageDirection;
}

interface ChatHistoryOutgoingCall extends ChatHistoryCommon {
    duration: number | string;
    isMissed: boolean;
}

export type ChatHistoryItem = ChatHistoryMessage | ChatHistoryOutgoingCall;
