<div class="container">
    <div class="title-block">
        <p class="text">Doctor’s Records</p>
        <div class="close-button-container" (click)="closeDialog()">
            <span class="close-button"></span>
        </div>
    </div>

    <med-print-button
        class="print-button"
        styleRef="/assets/styles/doctor-records-print.css"
        title="Doctor’s Records"
    ></med-print-button>

    <div class="records-block" #scrollContainer id="print-section">
        <div *ngIf="!dates.size" class="emty-block">
            <p>It's empty here for now</p>
        </div>
        <div class="records-date-block" *ngFor="let date of dates | keyvalue">
            <div class="date">
                {{ date.key | date: 'MM-dd-yyyy' }}
            </div>
            <div class="record" *ngFor="let record of date.value">
                <div class="header">
                    <a class="user">{{ record.providerFullName }}</a>
                    <p class="time">{{ record.luxonTime.toFormat('t a') }}</p>
                </div>
                <p *ngFor="let text of record.text" class="note" [class.emty]="!text">
                    {{ text }}
                </p>
            </div>
        </div>
    </div>
</div>
