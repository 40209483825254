import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MedAuthModule, MedFormModule } from 'medvidi';

import { AuthRoutingModule } from './auth-routing.module';
import { ElementsModule } from '../elements/elements.module';
import { LoginComponent } from './login/login.component';
import { MasterLoginComponent } from './master-login/master-login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
    declarations: [LoginComponent, MasterLoginComponent, ChangePasswordComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ElementsModule,
        AuthRoutingModule,
        MedAuthModule,
        MedFormModule,
    ],
})
export class AuthModule {}
