import { AppointmentMethodType } from '../../med-api/interfaces';

export interface TimingRange {
    startHours: number;
    startMinutes: number;
    endHours: number;
    endMinutes: number;
}

export interface ILeadInfo {
    id: number;
    firstName: string;
    lastName: string;
    inactive: boolean;
    selected?: boolean;
    isInitial?: boolean;
    hasInsurance?: boolean;
    appointmentMethod?: AppointmentMethodType;
    isAppointmentReserved?: boolean;
}

export enum RoundMethod {
    CEIL = 'ceil',
    FLOOR = 'floor',
}
