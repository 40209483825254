import { Injectable } from '@angular/core';
import { HttpService } from '../../med-common/med-common.module';
import {
    IEmailTemplate,
    IEmailTemplateListParams,
    IEmailTemplateListResponse,
    IEmailTemplateParams,
    IEmailTemplateGetContentParams,
    IEmailTemplateGetContentResponse,
    ISmsTemplateGetContentResponse,
} from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiEmailTemplateService {
    constructor(private http: HttpService) {}

    async list(params: IEmailTemplateListParams): Promise<IEmailTemplateListResponse> {
        return this.http.post<IEmailTemplateListResponse>(`email-template/list`, params);
    }

    async create(params: IEmailTemplateParams): Promise<IEmailTemplate> {
        return this.http.post<IEmailTemplate>(`email-template`, params);
    }

    async view(id: number): Promise<IEmailTemplate> {
        return this.http.get<IEmailTemplate>(`email-template/${id}`);
    }

    async edit(id: number, params: IEmailTemplateParams | FormData): Promise<IEmailTemplate> {
        return this.http.patch<IEmailTemplate>(`email-template/${id}`, params);
    }

    async delete(id: number): Promise<void> {
        return this.http.delete(`email-template/${id}`);
    }

    async getContent(
        params: IEmailTemplateGetContentParams
    ): Promise<IEmailTemplateGetContentResponse> {
        return this.http.post<IEmailTemplateGetContentResponse>(`email-template/content`, params);
    }

    async getSmsContent(
        params: IEmailTemplateGetContentParams
    ): Promise<ISmsTemplateGetContentResponse> {
        return this.http.post<ISmsTemplateGetContentResponse>(`email-template/sms-content`, params);
    }
}
